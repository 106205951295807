export function setUserData(data) {
  localStorage.setItem("isLoggedIn", true);
  localStorage.setItem("user", JSON.stringify(data.user));
  localStorage.setItem("token", data.token);
  localStorage.setItem("additionalData", JSON.stringify(data.additionalData));
  if ("roles" in data.additionalData) {
    localStorage.setItem("permissions", data.additionalData.roles.permissions);
  }
}

export function getUserData() {
  let user = {
    isLoggedIn: localStorage.getItem("isLoggedIn"),
    user: JSON.parse(localStorage.getItem("user")),
    token: localStorage.getItem("token"),
    additionalData: JSON.parse(localStorage.getItem("additionalData")),
    permissions: JSON.parse(localStorage.getItem("permissions")),
  };
  return user;
}

export function setProjectId(id) {
  localStorage.setItem("projectId", id);
}

export function setProjectData(data) {
  localStorage.setItem("projectData", JSON.stringify(data));
}

export function getProjectData() {
  let projectData = localStorage.getItem("projectData");
  return JSON.parse(localStorage.getItem("projectData"));
}

export function getProjectID() {
  let projectId = localStorage.getItem("projectId");
  return projectId;
}

export function logoutUser() {
  localStorage.removeItem("isLoggedIn");
  localStorage.removeItem("user");
  localStorage.removeItem("token");
  localStorage.removeItem("projectId");
  localStorage.removeItem("permissions");
}
