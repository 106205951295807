import React, { Component, Fragment } from "react";
import Footer from "../../components/Footer";
import AdminHeader from "../../components/header/AdminHeader";
import AdminMenu from "../../components/sidebar/AdminMenu";
import ProjectHeader from "../../components/header/ProjectHeader";
import Sidebar from "../../components/sidebar/Sidebar";
import ProjectMenu from "../../components/sidebar/ProjectMenu";
import axios from "axios";
import { toast } from "react-toastify";
import "materialize-css";
import { Modal } from "react-materialize";
import { getUserData, getProjectID } from "../../helpers/storage";
import SweetAlert from "react-bootstrap-sweetalert";
import ButtonLoader from "../../components/ButtonLoader";
import { formatAmount, customStyles } from "../../helpers/util";
import { BulletList } from "react-content-loader";
import SimpleReactValidator from "simple-react-validator";
import { Helmet } from "react-helmet";
import "rc-pagination/assets/index.css";
import Pagination from "rc-pagination";
import Select from "rc-select";
import "rc-select/assets/index.less";
import "../../assets/pagination.less";
import localeInfo from "../../helpers/en_US";
import {
  apiListProjects,
  apiGetMasterByType,
  apiListProjectPayment,
  apiCreateProjectPayment,
  apiEditProjectPayment,
  apiGetProjectPaymentById,
} from "../../config/endpoints";

const initialState = {
  fields: {
    project: "",
    tendertype: "",
    rabill: "",
    submitteddate: "",
    receiveddate: "",
    grossamount: 0,
    gstdeducted: 0,
    tdsdeducted: 0,
    securitydeducted: 0,
    labourcess: 0,
    withheld: 0,
    otherdeductions: 0,
    securedadvance: 0,
    netpayment: 0,
    financialyear: "2021-2022",
    remarks: "",
  },
  searchFields: {
    project: "",
    to: "",
    from: "",
  },
  isLoading: false,
  redirect: false,
  errors: {},
  vendorList: [],
  isModalOpen: false,
  isListLoading: false,
  alert: false,
  deleteId: "",
  editId: false,
  editItem: false,
  projectList: [],
  masterListTender: [],
  projectPaymentList: [],
  projectPaymentCount: 0,
  current: 1,
  pageSize: 30,
};

export default class ProjectPayment extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = initialState;
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
  }

  handleCloseModal = () => {
    this.setState(initialState);
    this.loadMaster();
  };
  handleOpenModal = () => {
    this.setState({ isModalOpen: true });
    this.validator.hideMessages();
    this.loadMaster();
    this.loadTender();
  };

  editProjcectPayment(id) {
    // Assuming only image
    this.setState({ editItem: true, editId: id });
    this.handleOpenModal();
    let userData = getUserData();
    fetch(apiGetProjectPaymentById + "?id=" + id, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          this.setState({
            fields: data.data.projectpayment,
          });
        } else {
          alert("something went wrong.");
        }
      });
  }

  showAlert = (id) => {
    //console.log(id);
    this.setState({
      alert: true,
      deleteId: id,
    });
  };
  hideAlert = () => {
    this.setState({
      alert: false,
    });
  };

  loadTender = () => {
    let userData = getUserData();
    fetch(apiGetMasterByType + "?type=tender_type", {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          this.setState({
            masterListTender: data.data.masters,
          });
        } else {
          alert("something went wrong.");
        }
      });
  };

  onChangePagination = (page) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        current: page,
      }),
      this.loadMaster
    );
  };

  onChangePageSize = (current, pageSize) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        current: current,
        pageSize: pageSize,
      }),
      this.loadMaster
    );
  };

  loadMaster = (e) => {
    let userData = getUserData();
    let projectId = getProjectID();
    this.setState({ isListLoading: true });
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        project: projectId,
      },
    }));
    fetch(
      apiListProjectPayment +
        "?page=" +
        this.state.current +
        "&pagesize=" +
        this.state.pageSize +
        "&project_id=" +
        this.state.searchFields.project +
        "&from=" +
        this.state.searchFields.from +
        "&to=" +
        this.state.searchFields.to,
      {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + userData.token,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          //console.log("project payment list", data.data.projectpayment.rows);
          this.setState({
            projectPaymentList: data.data.projectpayment.rows,
            projectPaymentCount: data.data.projectpayment.count,
            isListLoading: false,
          });
        } else {
          alert("something went wrong.");
        }
      });

    fetch(apiListProjects, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          //console.log("project list", data.data.projects.rows);
          this.setState({
            projectList: data.data.projects.rows,
            projectListCount: data.data.projects.count,
          });
        } else {
          alert("something went wrong.");
        }
      });
  };

  clearFilter = () => {
    this.myFormRef.reset();
    //this.setState(this.initialState, this.loadMasterData);
    this.setState(
      {
        searchFields: {
          project: "",
          to: "",
          from: "",
        },
      },
      this.loadMaster
    );
  };

  handleChange = (e) => {
    this.validator.showMessageFor(e.target.name);
    const value = e.target.value;
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        [e.target.name]: value,
      },
    }));
  };

  handleBlur = (e) => {
    this.validator.showMessageFor(e.target.name);
  };

  handleSearch = (e) => {
    this.validator.showMessageFor(e.target.name);
    const value = e.target.value;
    // console.log(e.target.name);
    // console.log(e.target.value);
    this.setState((prevState) => ({
      searchFields: {
        ...prevState.searchFields,
        [e.target.name]: value,
      },
    }));
  };

  submitSearch = (e) => {
    e.preventDefault();
    let userData = getUserData();
    this.setState({ isLoading: true });
    let projectId = getProjectID();
    this.setState({ isListLoading: true });
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        project: projectId,
      },
    }));
    console.log(this.state.searchFields);
    fetch(
      // apiListProjectPayment +
      //   "?page=" +
      //   this.state.current +
      //   "&pagesize=" +
      //   this.state.pageSize +
      //   "&project_id=" +
      //   this.state.searchFields.project,
      apiListProjectPayment +
        "?page=" +
        this.state.current +
        "&pagesize=" +
        this.state.pageSize +
        "&project_id=" +
        this.state.searchFields.project +
        "&from=" +
        this.state.searchFields.from +
        "&to=" +
        this.state.searchFields.to,
      {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + userData.token,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          //console.log("project payment list", data.data.projectpayment.rows);
          this.setState({
            projectPaymentList: data.data.projectpayment.rows,
            projectPaymentCount: data.data.projectpayment.count,
            isListLoading: false,
          });
        } else {
          alert("something went wrong.");
        }
      });
  };

  submitForm = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      /* console.log("submit");
      console.log(this.state);
      console.log(getUserData()); */
      let userData = getUserData();
      if (!this.state.editItem) {
        let data = {
          project: this.state.fields.project,
          tendertype: this.state.fields.tendertype,
          rabill: this.state.fields.rabill,
          submitteddate: this.state.fields.submitteddate,
          receiveddate: this.state.fields.receiveddate,
          grossamount: this.state.fields.grossamount,
          gstdeducted: this.state.fields.gstdeducted,
          tdsdeducted: this.state.fields.tdsdeducted,
          securitydeducted: this.state.fields.securitydeducted,
          labourcess: this.state.fields.labourcess,
          withheld: this.state.fields.withheld,
          otherdeductions: this.state.fields.otherdeductions,
          securedadvance: this.state.fields.securedadvance,
          netpayment: this.state.fields.netpayment,
          financialyear: this.state.fields.financialyear,
          remarks: this.state.fields.remarks,
        };
        this.setState({ isLoading: true });

        //console.log(data);

        axios
          .post(apiCreateProjectPayment, data, {
            headers: {
              Authorization: "Bearer " + userData.token,
            },
          })
          .then(
            (response) => {
              //console.log(response);
              // this.setState({ isLoading: false });
              toast.info("Project Payment created", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
              this.setState(this.initialState);
              this.handleCloseModal();
            },
            (error) => {
              if (
                error.response.status == 500 ||
                error.response.status == 400
              ) {
                toast.error(error.response.data.errorMessage, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                this.setState({ isLoading: false });
              }
            }
          );
      } else {
        let data = {
          project: this.state.fields.project,
          tendertype: this.state.fields.tendertype,
          rabill: this.state.fields.rabill,
          submitteddate: this.state.fields.submitteddate,
          receiveddate: this.state.fields.receiveddate,
          grossamount: this.state.fields.grossamount,
          gstdeducted: this.state.fields.gstdeducted,
          tdsdeducted: this.state.fields.tdsdeducted,
          securitydeducted: this.state.fields.securitydeducted,
          labourcess: this.state.fields.labourcess,
          withheld: this.state.fields.withheld,
          otherdeductions: this.state.fields.otherdeductions,
          securedadvance: this.state.fields.securedadvance,
          netpayment: this.state.fields.netpayment,
          financialyear: this.state.fields.financialyear,
          remarks: this.state.fields.remarks,
          id: this.state.editId,
        };
        this.setState({ isLoading: true });
        axios
          .post(apiEditProjectPayment, data, {
            headers: {
              Authorization: "Bearer " + userData.token,
            },
          })
          .then(
            (response) => {
              //console.log(response);
              // this.setState({ isLoading: false });
              toast.info("Project Payment updated", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
              this.setState(this.initialState);
              this.handleCloseModal();
            },
            (error) => {
              if (
                error.response.status == 500 ||
                error.response.status == 400
              ) {
                toast.error(error.response.data.errorMessage, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                this.setState({ isLoading: false });
              }
            }
          );
      }
    } else {
      //console.log("fail");
      //console.log("errors", this.validator.getErrorMessages());
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  calNetPay = (e) => {
    console.log("net");
    let gross = this.state.fields.grossamount;
    let gstdeducted = this.state.fields.gstdeducted;
    let tdsdeducted = this.state.fields.tdsdeducted;
    let securitydeducted = this.state.fields.securitydeducted;
    let labourcess = this.state.fields.labourcess;
    let withheld = this.state.fields.withheld;
    let otherdeductions = this.state.fields.otherdeductions;
    let securedadvance = this.state.fields.securedadvance;
    let netpay =
      parseFloat(gross) -
      (parseFloat(gstdeducted) +
        parseFloat(tdsdeducted) +
        parseFloat(securitydeducted) +
        parseFloat(labourcess) +
        parseFloat(withheld) +
        parseFloat(otherdeductions) +
        parseFloat(securedadvance));
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        netpayment: netpay,
      },
    }));
    console.log(netpay);
  };

  componentDidMount() {
    //this.loadVendors();
    this.loadMaster();
    this.loadTender();
  }

  render() {
    this.validator.purgeFields();
    return (
      <React.Fragment>
        <Helmet>
          <title>Project Payment | MRC</title>
        </Helmet>
        <section className="admin">
          <AdminHeader></AdminHeader>
          <div className="wrapper d-flex">
            <AdminMenu></AdminMenu>

            {/* <ProjectHeader /> */}
            {/* <ProjectMenu /> */}
            <div className="wrapper_body">
              <div className="row d-flex align-items-center">
                <div className="col m0 d-flex align-items-center">
                  <h3 className="heading">Project Payment</h3>
                </div>
                <div className="col m4 right-align">
                  <button
                    href="javascript:void(0)"
                    className="theme_btn btn  modal-trigger"
                    onClick={this.handleOpenModal}
                  >
                    <i className="material-icons">add</i> Create Payment
                  </button>
                  <Modal
                    actions={[,]}
                    bottomSheet={false}
                    fixedFooter={false}
                    id="Modal-0"
                    open={this.state.isModalOpen}
                    className="cstm-modal"
                    options={{
                      dismissible: false,
                      endingTop: "10%",
                      inDuration: 250,
                      onCloseEnd: null,
                      onCloseStart: null,
                      onOpenEnd: null,
                      onOpenStart: null,
                      opacity: 0.5,
                      outDuration: 250,
                      preventScrolling: true,
                      startingTop: "4%",
                    }}
                  >
                    {/* Close Modal */}
                    <a
                      href="javascript:void(0)"
                      className="modal-close"
                      onClick={this.handleCloseModal}
                    >
                      <span className="material-icons">chevron_right</span>
                    </a>
                    {/* Modal Content */}
                    <h3 className="heading">
                      {this.state.editItem
                        ? "Update Payment"
                        : "Create Payment"}
                    </h3>
                    <p>
                      Enter details to{" "}
                      {this.state.editItem ? "update" : "create new"} Payment
                    </p>
                    <form action id="createContractorForm">
                      <div className="row">
                        <div className="col s12">
                          <label className="label">Project</label>
                          <select
                            name="project"
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            value={this.state.fields.project}
                            // disabled
                          >
                            <option>Select</option>
                            {this.state.projectList.map((project, index) => (
                              <Fragment key={index}>
                                <option value={project.id}>
                                  {project.name}
                                </option>
                              </Fragment>
                            ))}
                          </select>
                          <label className="error">
                            {this.validator.message(
                              "project",
                              this.state.fields.project,
                              "required"
                            )}
                          </label>
                        </div>

                        <div className="col s12">
                          <label className="label">Tender Type</label>
                          <select
                            name="tendertype"
                            value={this.state.fields.tendertype}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                          >
                            <option selected> Select tender Type</option>
                            {this.state.masterListTender.map((type, index) => (
                              <Fragment key={index}>
                                <option value={type.name}>{type.name}</option>
                              </Fragment>
                            ))}
                          </select>
                          <label className="error">
                            {this.validator.message(
                              "tendertype",
                              this.state.fields.tendertype,
                              "required"
                            )}
                          </label>
                        </div>

                        <div className="col s12">
                          <label className="label">RA Bill No.</label>
                          <input
                            type="text"
                            placeholder="Bill No."
                            name="rabill"
                            value={this.state.fields.rabill}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                          />
                          <label className="error">
                            {this.validator.message(
                              "rabill",
                              this.state.fields.rabill,
                              "required"
                            )}
                          </label>
                        </div>

                        <div className="col  s12">
                          <label className="label">Submitted Date</label>
                          <input
                            type="date"
                            name="submitteddate"
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            value={this.state.fields.submitteddate}
                          />

                          <label className="error">
                            {this.validator.message(
                              "submitteddate",
                              this.state.fields.submitteddate,
                              "required"
                            )}
                          </label>
                        </div>

                        <div className="col  s12">
                          <label className="label">Received Date</label>
                          <input
                            type="date"
                            name="receiveddate"
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            value={this.state.fields.receiveddate}
                          />

                          <label className="error">
                            {this.validator.message(
                              "receiveddate",
                              this.state.fields.receiveddate,
                              "required"
                            )}
                          </label>
                        </div>

                        <div className="col s12">
                          <label className="label">Gross Bill Amount</label>
                          <input
                            type="text"
                            placeholder="Gross Bill Amount"
                            name="grossamount"
                            value={this.state.fields.grossamount}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            onKeyUp={this.calNetPay}
                          />
                          <label className="error">
                            {this.validator.message(
                              "grossamount",
                              this.state.fields.grossamount,
                              "required"
                            )}
                          </label>
                        </div>
                        <div className="col s12">
                          <label className="label">GST Deducted</label>
                          <input
                            type="text"
                            placeholder="GST Deducted"
                            name="gstdeducted"
                            value={this.state.fields.gstdeducted}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            onKeyUp={this.calNetPay}
                          />
                          <label className="error">
                            {this.validator.message(
                              "gstdeducted",
                              this.state.fields.gstdeducted,
                              "required"
                            )}
                          </label>
                        </div>
                        <div className="col s12">
                          <label className="label">TDS Deducted</label>
                          <input
                            type="text"
                            placeholder="TDS Deducted"
                            name="tdsdeducted"
                            value={this.state.fields.tdsdeducted}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            onKeyUp={this.calNetPay}
                          />
                          <label className="error">
                            {this.validator.message(
                              "tdtdsdeducteds",
                              this.state.fields.tdsdeducted,
                              "required"
                            )}
                          </label>
                        </div>
                        <div className="col s12">
                          <label className="label">Security Deducted</label>
                          <input
                            type="text"
                            placeholder="Security Deducted"
                            name="securitydeducted"
                            value={this.state.fields.securitydeducted}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            onKeyUp={this.calNetPay}
                          />
                          <label className="error">
                            {this.validator.message(
                              "securitydeducted",
                              this.state.fields.securitydeducted,
                              "required"
                            )}
                          </label>
                        </div>
                        <div className="col s12">
                          <label className="label">Labour Cess</label>
                          <input
                            type="text"
                            placeholder="GST"
                            name="labourcess"
                            value={this.state.fields.labourcess}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            onKeyUp={this.calNetPay}
                          />
                          <label className="error">
                            {this.validator.message(
                              "labourcess",
                              this.state.fields.labourcess,
                              "required"
                            )}
                          </label>
                        </div>

                        <div className="col s12">
                          <label className="label">Withheld</label>
                          <input
                            type="text"
                            placeholder="Withheld"
                            name="withheld"
                            value={this.state.fields.withheld}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            onKeyUp={this.calNetPay}
                          />
                          <label className="error">
                            {this.validator.message(
                              "withheld",
                              this.state.fields.withheld,
                              "required"
                            )}
                          </label>
                        </div>

                        <div className="col s12">
                          <label className="label">Other Deductions</label>
                          <input
                            type="text"
                            placeholder="Other Deductions"
                            name="otherdeductions"
                            value={this.state.fields.otherdeductions}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            onKeyUp={this.calNetPay}
                          />
                          <label className="error">
                            {this.validator.message(
                              "otherdeductions",
                              this.state.fields.otherdeductions,
                              "required"
                            )}
                          </label>
                        </div>

                        <div className="col s12">
                          <label className="label">Secured Advance</label>
                          <input
                            type="text"
                            placeholder="Secured Advance"
                            name="securedadvance"
                            value={this.state.fields.securedadvance}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            onKeyUp={this.calNetPay}
                          />
                          <label className="error">
                            {this.validator.message(
                              "securedadvance",
                              this.state.fields.securedadvance,
                              "required"
                            )}
                          </label>
                        </div>

                        <div className="col s12">
                          <label className="label">Net Payment</label>
                          <input
                            type="text"
                            placeholder="Net Payment"
                            name="netpayment"
                            value={this.state.fields.netpayment}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            readOnly={true}
                          />
                          <label className="error">
                            {this.validator.message(
                              "netpayment",
                              this.state.fields.netpayment,
                              "required"
                            )}
                          </label>
                        </div>

                        <div className="col s12 hide">
                          <label className="label">Financial Year</label>
                          <input
                            type="text"
                            placeholder="Financial Year"
                            name="financialyear"
                            value={this.state.fields.financialyear}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            readOnly={true}
                          />
                          {/* <input
                            type='number'
                            min='1900'
                            max='2099'
                            step='1'
                            name='submitted'
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            value={new Date().getFullYear()}
                          /> */}
                          <label className="error">
                            {this.validator.message(
                              "financialyear",
                              this.state.fields.financialyear,
                              "required"
                            )}
                          </label>
                        </div>

                        <div className="col s12">
                          <label className="label">Remarks</label>
                          <input
                            type="text"
                            placeholder="Remarks"
                            name="remarks"
                            value={this.state.fields.remarks}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                          />
                          <label className="error">
                            {this.validator.message(
                              "remarks",
                              this.state.fields.remarks,
                              "required"
                            )}
                          </label>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col s12 right-align">
                          <button
                            type="submit"
                            form="createContractorForm"
                            className="theme_btn btn"
                            onClick={this.submitForm}
                          >
                            {this.state.editItem
                              ? "Update Project Payment"
                              : "Add Project Payment"}
                            {this.state.isLoading ? (
                              <ButtonLoader></ButtonLoader>
                            ) : null}
                          </button>
                        </div>
                      </div>
                    </form>
                  </Modal>
                </div>
              </div>
              <div className="projects employee">
                <div className="row">
                  <div className="col s12">
                    <p className="grey-text">
                      <b>Filters</b>
                    </p>
                    <form ref={(ele) => (this.myFormRef = ele)}>
                      <div className="row">
                        <div class="col m3 s12">
                          <select
                            name="project"
                            onChange={this.handleSearch}
                            value={this.state.searchFields.project}
                            // disabled
                          >
                            <option>Select</option>
                            {this.state.projectList.map((project, index) => (
                              <Fragment key={index}>
                                <option value={project.id}>
                                  {project.name}
                                </option>
                              </Fragment>
                            ))}
                          </select>
                        </div>

                        <div className="col m3 s12">
                          <input
                            type="date"
                            className=""
                            placeholder="From Date"
                            name="from"
                            onChange={this.handleSearch}
                          />
                        </div>

                        <div className="col m3 s12">
                          {/* <span>-</span> */}
                          <input
                            type="date"
                            className=""
                            placeholder="To Date"
                            name="to"
                            onChange={this.handleSearch}
                          />
                        </div>
                      </div>

                      <div className="row">
                        {/* <a href="#!" className="red-text pt-1 d-flex">Clear Filter</a> */}
                        {/* <button type="submit" className="red-text pt-1">Clear Filter</button> */}
                        <div class="col m3 s12">
                          <a
                            href="javascript:void(0)"
                            class="red-text pt-1"
                            onClick={this.clearFilter}
                          >
                            Clear Filter
                          </a>

                          <button
                            type="submit"
                            className="theme_btn btn filter-submit"
                            onClick={this.submitSearch}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="col s12">
                    <div className="table-responsive">
                      <table>
                        <thead>
                          <tr>
                            <th>Project Name</th>
                            <th>Tender Type</th>
                            <th>RABill No.</th>
                            <th>Submitted Date</th>
                            <th>Received Date</th>
                            <th>Gross Amount</th>
                            <th>GST Deducted</th>
                            <th>TDS Deducted</th>
                            <th>Security Deducted</th>
                            <th>Labour Cess</th>
                            <th>Withheld</th>
                            <th>Other Deductions</th>
                            <th>Security Advance</th>
                            <th>Net Paymeny</th>
                            <th>Financial Year</th>
                            <th>Remarks</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.isListLoading ? (
                            <tr>
                              <td colSpan={5}>
                                <BulletList></BulletList>
                              </td>
                            </tr>
                          ) : (
                            this.state.projectPaymentList.map(
                              (payment, index) => (
                                <tr key={index}>
                                  <td data-th="Project Name">
                                    {payment.projects.name}
                                  </td>
                                  <td data-th="Tender Type">
                                    {payment.tendertype}
                                  </td>
                                  <td data-th="Bill No.">{payment.rabill}</td>
                                  <td data-th="Submitted Date">
                                    {payment.submitteddate}
                                  </td>
                                  <td data-th="Received Date">
                                    {payment.receiveddate}
                                  </td>
                                  <td data-th="Gross Amount">
                                    &#8377;{payment.grossamount}
                                  </td>
                                  <td data-th="GST Deducted">
                                    &#8377;{payment.gstdeducted}
                                  </td>
                                  <td data-th="TDS Deducted">
                                    &#8377;{payment.tdsdeducted}
                                  </td>
                                  <td data-th="Security Deducted">
                                    &#8377;{payment.securitydeducted}
                                  </td>
                                  <td data-th="Labour Cess">
                                    &#8377;{payment.labourcess}
                                  </td>
                                  <td data-th="Withheld">
                                    &#8377;{payment.withheld}
                                  </td>
                                  <td data-th="Other Deductions">
                                    &#8377;{payment.otherdeductions}
                                  </td>
                                  <td data-th="Security Advance">
                                    &#8377;{payment.securedadvance}
                                  </td>
                                  <td data-th="Net Paymeny">
                                    &#8377;{payment.netpayment}
                                  </td>
                                  <td data-th="Financial Year">
                                    {payment.financialyear}
                                  </td>
                                  <td data-th="Remarks">{payment.remarks}</td>
                                  <td>
                                    <a
                                      href="#Modal-0"
                                      className="actions"
                                      node="button"
                                      onClick={() =>
                                        this.editProjcectPayment(payment.id)
                                      }
                                    >
                                      <i className="material-icons">edit</i>
                                    </a>
                                    {/* <a
                                      href='javascript:void(0)'
                                      className='actions'
                                      onClick={() => this.showAlert(vendor.id)}
                                    >
                                      <i className='material-icons'>delete</i>
                                    </a> */}
                                  </td>
                                </tr>
                              )
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {/* pagination */}
                  <div
                    className="col s12"
                    style={{ marginTop: 20, marginBottom: 20 }}
                  >
                    <Pagination
                      pageSize={this.state.pageSize}
                      onChange={this.onChangePagination}
                      current={this.state.current}
                      total={this.state.projectPaymentCount}
                      showSizeChanger
                      defaultPageSize={this.state.pageSize}
                      defaultCurrent={this.state.current}
                      onShowSizeChange={this.onChangePageSize}
                      locale={localeInfo}
                      selectComponentClass={Select}
                      pageSizeOptions={[10, 30, 50, 100]}
                      showTotal={(total, range) =>
                        `${range[0]} - ${range[1]} of ${total} items`
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.state.alert && (
            <SweetAlert
              onConfirm={this.deleteVendor}
              onCancel={this.hideAlert}
              focusCancelBtn={true}
              warning
              showCancel
              confirmBtnText="Yes, delete it!"
              confirmBtnBsStyle="danger"
              title="Are you sure?"
            />
          )}
          <Footer />
        </section>
        {/* Add Contractor Modal */}
      </React.Fragment>
    );
  }
}
