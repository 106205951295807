import React, { Component } from "react";
import { Link } from "react-router-dom";
import { getUserData } from "../../helpers/storage";
import { Collapsible, CollapsibleItem } from "react-materialize";

import { apiGetProject } from "../../config/endpoints";

class ProjectLinks extends Component {
  constructor(props) {
    super(props);
    let userData = getUserData();
    this.state = {
      redirect: false,
      user: userData.user,
      userPermission: userData.permissions,
      additionalData: userData.additionalData,
      projectId: this.props.projectId,
      selected: "",
      projectDetails: { projectType: "" },
      menuAccounts: false,
    };
  }
  componentDidMount = () => {
    // console.log("state", this.state);
    const str = window.location.pathname;
    var last = str.substring(str.lastIndexOf("/") + 1, str.length);
    //console.log("url last", last);
    // alert(last);
    this.setState({
      selected: last,
    });
    let userData = getUserData();
    // alert(last);
    if (!isNaN(last)) {
      fetch(apiGetProject + "?id=" + last, {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + userData.token,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.code == 200) {
            this.setState({
              projectDetails: data.data.project,
            });
          } else {
            alert("something went wrong.");
          }
        });
    }
  };

  openMenuAccounts = () => {
    this.setState((prevState) => ({
      menuAccounts: !prevState.menuAccounts,
    }));
  };

  render() {
    return (
      <>
        {/* <Collapsible accordion>
          <CollapsibleItem
            expanded
            header="Accounting"
            //   icon={<Icon>filter_drama</Icon>}
            node="div"
          > */}
        <ul>
          <li className={this.state.selected === "customer" && "menuSelect"}>
            <a onClick={this.openMenuAccounts}>Accounts</a>
            <ul
              className={
                this.state.menuAccounts ? "submenu-open" : "submenu-close"
              }
            >
              {this.state.user.userType == "2" ||
              (this.state.userPermission &&
                this.state.userPermission["create_daybook[view]"]) ? (
                <li
                  className={this.state.selected === "daybook" && "menuSelect"}
                >
                  <Link to={`/daybook/${this.state.selected}`}>Daybook</Link>{" "}
                </li>
              ) : null}
              {this.state.projectDetails.projectType == "PPP" ? (
                <>
                  {this.state.user.userType == "2" ||
                  (this.state.userPermission &&
                    this.state.userPermission["daily_income[view]"]) ? (
                    <li
                      className={
                        this.state.selected === "dailyincome" && "menuSelect"
                      }
                    >
                      <Link to={`/dailyincome/${this.state.selected}`}>
                        Daily Income
                      </Link>{" "}
                    </li>
                  ) : null}
                </>
              ) : null}
              {this.state.projectDetails.projectType == "Construction" ? (
                <></>
              ) : null}
            </ul>
          </li>

          {this.state.user.userType == "2" ||
          (this.state.userPermission &&
            this.state.userPermission["cash_assign[view]"]) ? (
            <li
              className={this.state.selected === "cashassign" && "menuSelect"}
            >
              <Link to={`/cashassign/${this.state.selected}`}>Assign Cash</Link>
            </li>
          ) : null}
          {this.state.user.userType == 3 ? (
            <li
              className={this.state.selected === "recievables" && "menuSelect"}
            >
              <Link to="/recievables">Recievables</Link>
            </li>
          ) : null}
          <li
            className={this.state.selected === "cashtransfer" && "menuSelect"}
          >
            <i />
            <Link to={`/cashtransfer/${this.state.selected}`}>
              Transfer Cash
            </Link>
          </li>
        </ul>
        {/* </CollapsibleItem>
        </Collapsible> */}
      </>
    );
  }
}

export default ProjectLinks;
