import React, { Component } from "react";
import Footer from "../../components/Footer";
import ProjectHeader from "../../components/header/ProjectHeader";
import Sidebar from "../../components/sidebar/Sidebar";
import ProjectMenu from "../../components/sidebar/ProjectMenu";

import { apiGetProject } from "../../config/endpoints";
import { setUserData, getUserData } from "../../helpers/storage";
import { findAllByTestId } from "@testing-library/react";
import { BulletList } from "react-content-loader";
import { Helmet } from "react-helmet";
import { formatAmount } from "../../helpers/util";

const initialState = {
  isLoading: false,
  redirect: false,
  errors: {},
  projectDetails: false,
  isModalOpen: false,
  isListLoading: false,
  alert: false,
};

export default class ProjectDashboard extends Component {
  constructor(props) {
    super(props);
    let userData = getUserData();
    this.state = {
      userData: userData,
      errors: {},
      projectId: "",
      weatherData: false,
    };
  }

  getWeather = (project_address) => {
    //console.log(project_address);
    const project_city = project_address.replace(/ .*/, "");
    /* console.log("city", project_city);
    console.log("api key", process.env.REACT_APP_WEATHER_API_KEY); */
    this.setState({ isLoading: true });
    fetch(
      `https://api.openweathermap.org/data/2.5/weather?q=${project_city}&units=metric&APPID=${process.env.REACT_APP_WEATHER_API_KEY}`,
      {
        method: "get",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.cod === 200) {
          //console.log("Weather data", data.main);
          this.setState({
            weatherData: data.main,
            isLoading: false,
          });
        } else {
          //console.log("There is some problem while getting weather data.");
          this.setState({
            weatherData: [],
            isLoading: false,
          });
        }
      });
  };

  /* formatAmount(amount) {
    const formattedAmount = parseInt(amount).toLocaleString("hi-IN");
    return formattedAmount;
  } */

  componentWillMount() {
    //console.log("id", this.props.match.params.id);
    let id = this.props.match.params.id;

    this.setState({
      projectId: this.props.match.params.id,
    });
    let userData = getUserData();
    this.setState({ isLoading: true });
    fetch(apiGetProject + "?id=" + id, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          //console.log("project data", data.data.project);
          this.setState({
            projectDetails: data.data.project,
            isLoading: false,
            daybook: data.data.daybook,
          });
          this.getWeather(data.data.project.address);
        } else {
          alert("something went wrong.");
        }
      });
  }

  render() {
    if (this.state.isLoading) {
      return <></>;
    } else {
      return (
        <React.Fragment>
          <Helmet>
            <title>Project Dashboard | MRC</title>
          </Helmet>
          <section className="adminProject">
            <div className="wrapper d-flex">
              <Sidebar />
              <div className="wrapper_right">
                <ProjectHeader />
                <div className="d-flex">
                  <ProjectMenu />
                  <div className="wrapper_body">
                    {this.state.isListLoading ? (
                      <BulletList></BulletList>
                    ) : (
                      <>
                        <div className="row">
                          <div className="col m8 s12">
                            <h3 className="heading">
                              {this.state.projectDetails.name}
                            </h3>
                          </div>
                        </div>
                        <div className="row" style={{ marginTop: 20 }}>
                          {/* <div className='col m4 l3 s12'>
                            <img
                              src={
                                process.env.REACT_APP_APP_URL +
                                "/assets/images/admin/weather.svg"
                              }
                              alt
                            />
                          </div> */}

                          <div className="col m6 l6 s12">
                            <div
                              className="home-insights"
                              style={{ marginBottom: "10px" }}
                            >
                              <p
                                style={{
                                  fontSize: "15px",
                                  fontWeight: 700,
                                }}
                              >
                                {this.state.projectDetails.address}
                              </p>
                              <h3>{this.state.weatherData.temp || 35}°C</h3>
                              <span>
                                Min:&nbsp;
                                <span
                                  style={{
                                    color: "#4F84CC",
                                    fontSize: "20px",
                                    fontWeight: 700,
                                  }}
                                >
                                  {this.state.weatherData.temp_min || 35}°C
                                </span>
                              </span>
                              <span style={{ marginLeft: "20px" }}>
                                Max:&nbsp;{" "}
                                <span
                                  style={{
                                    color: "#4F84CC",
                                    fontSize: "20px",
                                    fontWeight: 700,
                                  }}
                                >
                                  {this.state.weatherData.temp_max || 35}°C
                                </span>
                              </span>
                            </div>
                          </div>

                          {this.state.userData.user.userType == "3" ? null : (
                            <div className="col m6 l6 s12">
                              <div className="home-insights">
                                <p>Expenditure/Awarded</p>
                                <h3>
                                  <i className="fa fa-rupee-sign"></i>{" "}
                                  {formatAmount(this.state.daybook.totalused)}/{" "}
                                  <i className="fa fa-rupee-sign"></i>{" "}
                                  {formatAmount(
                                    this.state.projectDetails.amount
                                  )}
                                </h3>
                              </div>
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </section>
        </React.Fragment>
      );
    }
  }
}
