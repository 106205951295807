import React, { Component, Fragment } from "react";
import Footer from "../../components/Footer";
import AdminHeader from "../../components/header/AdminHeader";
import AdminMenu from "../../components/sidebar/AdminMenu";
import ButtonLoader from "../../components/ButtonLoader";
import axios from "axios";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Moment from "react-moment";
import "materialize-css";
import { Modal, Badge } from "react-materialize";
import { getUserData } from "../../helpers/storage";
import SweetAlert from "react-bootstrap-sweetalert";
import SimpleReactValidator from "simple-react-validator";
import Pagination from "rc-pagination";
import Select from "rc-select";
import "rc-select/assets/index.less";
import "../../assets/pagination.less";
import localeInfo from "../../helpers/en_US";
import { Collapsible, CollapsibleItem } from "react-materialize";
import SignaturePad from "react-signature-pad-wrapper";
import {
  apiListPoReceving,
  apiGetPo,
  apiApprovePo,
} from "../../config/endpoints";
import { Helmet } from "react-helmet";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const initialState = {
  fields: { approvenotes: "" },
  ponumber: 0,
  selectedPo: null,
  selectedPoParticulars: [],
  itemsReceived: [],
  poRecevingList: [],
  poList: [],
  subtotal: 0,
  grandtotalFinal: 0,
  isLoading: false,
  redirect: false,
  errors: {},
  isModalOpen: false,
  isListLoading: false,
  alert: false,
  deleteId: "",
  editId: false,
  editItem: false,
  passId: false,
  pageSize: 30,
  current: 1,
};

let itemsReceived = [];

export default class PoView extends Component {
  sigPad = {};
  constructor(props) {
    super(props);
    this.state = initialState;
    this.selectInputRef = React.createRef();
    this.projectReset = this.projectReset.bind(this);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.validator1 = new SimpleReactValidator({ autoForceUpdate: this });
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleSignClear = () => {
    const signaturePad = this.sigPad.current;

    if (signaturePad) {
      signaturePad.instance.clear();
    }
  };

  loadPoReceiving = (ponumber) => {
    let userData = getUserData();
    fetch(apiListPoReceving + "?ponumber=" + ponumber, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          this.setState({
            poRecevingList: data.data.po.rows,
          });
        } else {
          alert("something went wrong.");
        }
      });
  };

  componentDidMount() {
    this.loadPo();
    //console.log("componentDidMount", this.state.fields.projects);
  }

  loadPo = () => {
    const str = window.location.pathname;
    const poId = str.substring(str.lastIndexOf("/") + 1, str.length);
    let userData = getUserData();
    fetch(apiGetPo + "?id=" + poId, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          this.setState((prevState) => ({
            selectedPo: data.data.po,
            selectedPoParticulars: data.data.po
              ? JSON.parse(data.data.po.particulars)
              : [],
          }));
        } else {
          alert("something went wrong.");
        }
      });
  };

  handleApprove = (e, status) => {
    const str = window.location.pathname;
    const poId = str.substring(str.lastIndexOf("/") + 1, str.length);
    let userData = getUserData();
    const signaturePad = this.sigPad.current;

    if (!signaturePad) {
      return;
    }

    if (signaturePad.isEmpty()) {
      // eslint-disable-next-line no-alert
      alert("Please provide a signature first.");
      return false;
    } else {
      console.log(signaturePad.toDataURL());
      this.setState({ sign: signaturePad.toDataURL() });
      this.setState({ isLoading: true });
      axios
        .post(
          apiApprovePo,
          {
            id: poId,
            approvenotes: this.state.fields.approvenotes,
            status,
            sign: signaturePad.toDataURL(),
          },
          {
            headers: {
              Authorization: "Bearer " + userData.token,
            },
          }
        )
        .then((data) => {
          console.log(data);
          if (data.data.code == 200) {
            //   this.setState((prevState) => ({
            //     selectedPo: data.data.po,
            //     selectedPoParticulars: data.data.po
            //       ? JSON.parse(data.data.po.particulars)
            //       : [],
            //   }));
            this.loadPo();
            this.setState({ isLoading: false });
            toast.info("Po updated", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          } else {
            alert("something went wrong.");
          }
        });
    }
  };

  handleCloseModal = () => {
    this.setState(initialState);
    this.projectReset();
  };

  handleOpenModal = () => {
    this.setState({ isModalOpen: true });
    this.validator.hideMessages();
    //console.log("model open", this.state);
  };

  projectReset = () => {
    // this.selectInputRef.current.select.clearValue();
  };

  showAlert = (id) => {
    //console.log(id);
    this.setState({
      alert: true,
      deleteId: id,
    });
  };

  hideAlert = () => {
    this.setState({
      alert: false,
    });
  };

  handleChange = (e) => {
    this.validator.showMessageFor(e.target.name);
    const value = e.target.value;
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        [e.target.name]: value,
      },
    }));
  };

  handleBlur = (e) => {
    this.validator.showMessageFor(e.target.name);
  };

  onSelect = () => {};

  render() {
    this.validator.purgeFields();

    return (
      <React.Fragment>
        <Helmet>
          <title>Po Receiving | MRC</title>
        </Helmet>
        <section className="admin">
          <AdminHeader></AdminHeader>
          <div className="wrapper d-flex">
            <AdminMenu></AdminMenu>

            <div className="wrapper_body">
              <div className="row d-flex align-items-center">
                <div className="col m0 d-flex align-items-center">
                  <h3 className="heading">Po Receiving </h3>
                </div>
                <div className="col m4 right-align">
                  <Link className=" btn waves-effect" to={"/purchase-order"}>
                    Back
                  </Link>
                </div>
              </div>
              {this.state.selectedPo ? (
                <>
                  <div className="table-responsive">
                    <table>
                      <thead>
                        <tr>
                          <th>PO Details</th>
                          <th> </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td style={{ width: "50%" }} data-th="PO Number">
                            <strong>PO Number</strong>
                          </td>
                          <td style={{ width: "50%" }} data-th="PO Number">
                            <strong>{this.state.selectedPo.ponumber}</strong>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "50%" }} data-th="PO Number">
                            <strong>Status</strong>
                          </td>
                          <td style={{ width: "50%" }} data-th="PO Number">
                            <strong>
                              {this.state.selectedPo.status == "0"
                                ? "Pending"
                                : ""}
                              {this.state.selectedPo.status == "1"
                                ? "Active"
                                : ""}
                              {this.state.selectedPo.status == "2"
                                ? "Completed"
                                : ""}
                              {this.state.selectedPo.status == "3"
                                ? "Cancelled"
                                : ""}
                            </strong>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "50%" }} data-th="PO Number">
                            <strong>Approved Notes</strong>
                          </td>
                          <td style={{ width: "50%" }} data-th="PO Number">
                            <strong>
                              {this.state.selectedPo.approvenotes}
                            </strong>
                          </td>
                        </tr>
                        <tr>
                          <td data-th="PO Date">
                            <strong>Date</strong>
                          </td>
                          <td data-th="PO Date">
                            <strong>{this.state.selectedPo.date}</strong>
                          </td>
                        </tr>
                        <tr>
                          <td data-th="PO Amount">
                            <strong>Amount</strong>
                          </td>
                          <td data-th="PO Amount">
                            <strong>{this.state.selectedPo.grandtotal}</strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table>
                      <thead>
                        <tr>
                          <th>Vendor Details</th>
                          <th> </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td style={{ width: "50%" }} data-th="Vendor Name">
                            <strong>Vendor Name</strong>
                          </td>
                          <td style={{ width: "50%" }} data-th="Vendor Name">
                            <strong>
                              {this.state.selectedPo.vendorDetails.name}
                            </strong>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "50%" }} data-th="Vendor Address">
                            <strong>Address</strong>
                          </td>
                          <td style={{ width: "50%" }} data-th="Vendor Address">
                            <strong>
                              {this.state.selectedPo.vendorDetails.address}
                              <br></br>
                              <b>State:</b>
                              {this.state.selectedPo.vendorDetails.state},{" "}
                              {this.state.selectedPo.vendorDetails.statecode}
                            </strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table>
                      <thead>
                        <tr>
                          <th>Shipping Details</th>
                          <th> </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td data-th="Project Details">
                            <strong>Project Details</strong>
                          </td>
                          <td data-th="Project Details">
                            <strong>
                              {this.state.selectedPo.projectDetails.name}
                            </strong>
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{ width: "50%" }}
                            data-th="Project Address"
                          >
                            <strong>Address</strong>
                          </td>
                          <td
                            style={{ width: "50%" }}
                            data-th="Project Address"
                          >
                            <strong>
                              {this.state.selectedPo.projectDetails.address}
                              <br></br>
                              <b>State:</b>
                              {this.state.selectedPo.projectDetails.state},{" "}
                              {this.state.selectedPo.projectDetails.statecode}
                            </strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table>
                      <thead>
                        <tr>
                          <th>Billing Details</th>
                          <th> </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td data-th="Billing Details">
                            <strong>Billing Details</strong>
                          </td>
                          <td
                            style={{ width: "50%" }}
                            data-th="Billing Details"
                          >
                            <strong>
                              {this.state.selectedPo.billingDetails.name}
                            </strong>
                          </td>
                        </tr>
                        <tr>
                          <td data-th="Billing Address">
                            <strong>Address</strong>
                          </td>
                          <td
                            style={{ width: "50%" }}
                            data-th="Billing Address"
                          >
                            <strong>
                              {this.state.selectedPo.billingDetails.address}
                              <br></br>
                              <b>State:</b>
                              {this.state.selectedPo.billingDetails.state},{" "}
                              {this.state.selectedPo.billingDetails.statecode}
                            </strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <h4>Items</h4>
                  <div className="table-responsive">
                    <table>
                      <thead>
                        <tr>
                          <th>S.No.</th>
                          <th>Particulars</th>
                          <th>Unit</th>
                          <th>Quantity</th>
                          <th>Notes</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.selectedPoParticulars.map((item, index) => (
                          <>
                            <tr>
                              <td>{index + 1}</td>
                              <td>{item.particulars}</td>
                              <td>{item.unit}</td>
                              <td>{item.qty}</td>
                              <td>{item.notes}</td>
                            </tr>
                          </>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  {this.state.selectedPo.status == "0" ||
                  this.state.selectedPo.status == "3" ? (
                    <>
                      <h4>Approve</h4>
                      <table>
                        <tr>
                          <th>Notes</th>
                          <td>
                            <textarea
                              row="2"
                              name="approvenotes"
                              value={this.state.fields.approvenotes}
                              onChange={this.handleChange}
                            >
                              {this.state.fields.approvenotes}
                            </textarea>
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td>
                            <div style={{ width: "350px", marginTop: "20px" }}>
                              <SignaturePad
                                options={{
                                  minWidth: 1,
                                  maxWidth: 5,
                                  backgroundColor: "#d3d3d3",
                                  penColor: "black",
                                }}
                                ref={this.sigPad}
                              />
                              <a
                                className="btn btn-danger"
                                onClick={this.handleSignClear}
                              >
                                Clear Sign
                              </a>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th></th>
                          <td>
                            {!this.state.isLoading ? (
                              <>
                                <a
                                  className="btn btn-danger"
                                  onClick={(e) => this.handleApprove(e, "1")}
                                >
                                  Approve
                                </a>
                                <a
                                  className="btn btn-danger"
                                  onClick={(e) => this.handleApprove(e, "3")}
                                >
                                  Cancel
                                </a>
                              </>
                            ) : (
                              <></>
                            )}
                          </td>
                        </tr>
                      </table>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
          <Footer />
        </section>
      </React.Fragment>
    );
  }
}
