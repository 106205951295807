import React, { Component } from "react";
import { Link, Redirect, useLocation } from "react-router-dom";
import { logoutUser, getUserData } from "../../helpers/storage";
import "materialize-css";
import { Dropdown, Button, Divider, Icon } from "react-materialize";

export default class SuperAdminHeader extends Component {
  constructor(props) {
    super(props);
    let userData = getUserData();
    console.log("logo", userData);
    this.state = {
      redirect: false,
      user: userData.user,
      additionalData: userData.additionalData,
      projectLogo:
        process.env.REACT_APP_APP_URL + "/assets/images/super-admin/logo.png",
      logo: userData.additionalData.logo
        ? userData.additionalData.logo + "?i=" + Math.random()
        : process.env.REACT_APP_APP_URL +
          "/assets/images/super-admin/profile_pic.png",
    };
  }
  componentWillMount() {
    let url = window.location.href.split("/");
    let isProject = url[url.length - 2];
    if (isProject == "project") {
      // alert("get project");
    }
    console.log(window.location.href);
    console.log(url[url.length - 2]);
  }
  handleLogout = (e) => {
    logoutUser();
    this.setState({ redirect: "/", isLoading: false });
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    return (
      <header>
        <div className="row d-flex align-items-center">
          <div className="col d-flex align-items-center">
            <a href="javascript:void(0)" className="hamburger_icon">
              <i className="material-icons">menu</i>
            </a>
            <img
              src={
                process.env.REACT_APP_APP_URL +
                "/assets/images/super-admin/logo.png"
              }
              alt="MRC"
              width={80}
            />
            &nbsp;&nbsp;&nbsp;
            <h5>MRC Group</h5>
          </div>
          <div className="col ml-auto d-flex align-items-center justify-content-end">
            <a href="javascript:void(0)" className="notification">
              <img
                src={
                  process.env.REACT_APP_APP_URL +
                  "/assets/images/notification_ico.svg"
                }
              />
            </a>
            <div className="profile">
              {/* {this.state.additionalData.logo != "" ? (
                <img
                  src={this.state.additionalData.logo}
                  alt="MRC"
                  width={80}
                />
              ) : (
                <img
                  src="assets/images/super-admin/logo.png"
                  alt="MRC"
                  width={80}
                />
              )} */}
              <Dropdown
                id="Dropdown_6"
                className="dropdown-content"
                options={{
                  alignment: "left",
                  autoTrigger: true,
                  closeOnClick: true,
                  constrainWidth: false,
                  container: null,
                  coverTrigger: true,
                  hover: false,
                  inDuration: 150,
                  onCloseEnd: null,
                  onCloseStart: null,
                  onOpenEnd: null,
                  onOpenStart: null,
                  outDuration: 250,
                }}
                trigger={<img src={this.state.logo} alt="MRC" width={80}></img>}
              >
                {/* <a href="/UpdateProfile">Edit Profile</a> */}
                <Link to="/UpdateProfile">Edit Profile</Link>
                <a href="javascript:void(0)" onClick={this.handleLogout}>
                  Logout
                </a>
              </Dropdown>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
