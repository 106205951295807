import React, { Component, Fragment } from "react";
import Footer from "../../components/Footer";
import AdminHeader from "../../components/header/AdminHeader";
import AdminMenu from "../../components/sidebar/AdminMenu";
import ButtonLoader from "../../components/ButtonLoader";
import axios from "axios";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "materialize-css";
import { Modal, Badge } from "react-materialize";
import { getUserData } from "../../helpers/storage";
import SweetAlert from "react-bootstrap-sweetalert";
import SimpleReactValidator from "simple-react-validator";
import Pagination from "rc-pagination";
import Select from "rc-select";
import "rc-select/assets/index.less";
import "../../assets/pagination.less";
import localeInfo from "../../helpers/en_US";
import SignaturePad from "react-signature-pad-wrapper";
import RichTextEditor from "react-rte";
import PropTypes from "prop-types";
import {
  apiDeleteEmployee,
  apiListVendors,
  apiListCustomer,
  apiGetItemMasterList,
  apiGetVendor,
  apiGetItemMaster,
  apiCreatePo,
  apiListPo,
  apiListProjects,
  apiGetProject,
  apiGetGstMaster,
  apiListGstMasters,
  apiGetPo,
  apiEditPo,
  apiGetSettings,
} from "../../config/endpoints";
import { Helmet } from "react-helmet";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const initialState = {
  fields: {
    vendor: "",
    ponumber: "",
    date: "",
    customer: 0,
    billinggst: "",
    project: 0,
    shipname: "",
    shipaddress: "",
    shipgst: "",
    shipstate: "",
    shipstatecode: "",
    vendor: "",
    particulars: "",
    total: "",
    totaltax: "",
    grandtotal: "",
    remarks: "",
    terms: "",
  },
  searchFields: { ponumber: "", project: "", from: "", to: "" },
  inputFields: [
    {
      item: "",
      particulars: "",
      unit: "",
      qty: 1,
      gst: "",
      amount: 0,
      total: 0,
      tax: "",
      notes: "",
    },
  ],
  selectedVendor: { address: "", gst: "", id: "" },
  selectedProject: {
    name: "",
    address: "",
    gst: "",
    statecode: "",
    state: "",
    id: "",
  },
  selectedBillingGst: {
    name: "",
    address: "",
    gst: "",
    statecode: "",
    state: "",
    id: "",
  },
  selectedItems: [],
  vendorList: [],
  customerList: [],
  itemMasterList: [],
  gstMasterList: [],
  projectList: [],
  poList: [],
  totaltax: [],
  grandtotal: [],
  terms: RichTextEditor.createEmptyValue(),
  subtotal: 0,
  grandtotalFinal: 0,
  isLoading: false,
  redirect: false,
  errors: {},
  isModalOpen: false,
  isListLoading: false,
  alert: false,
  deleteId: "",
  editId: false,
  editItem: false,
  duplicateItem: false,
  duplicateId: false,
  passId: false,
  pageSize: 30,
  current: 1,
};

export default class PurchaseOrder extends Component {
  sigPad = {};
  constructor(props) {
    super(props);
    this.state = initialState;
    this.selectInputRef = React.createRef();
    this.projectReset = this.projectReset.bind(this);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.validator1 = new SimpleReactValidator({ autoForceUpdate: this });
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  static propTypes = {
    onChange: PropTypes.func,
  };

  onChangeEditor = (value) => {
    this.setState({ terms: value });
    if (this.props.onChange) {
      // Send the changes up to the parent component as an HTML string.
      // This is here to demonstrate using `.toString()` but in a real app it
      // would be better to avoid generating a string on each change.
      this.props.onChange(value.toString("html"));
    }
  };

  handleSignClear = () => {
    const signaturePad = this.sigPad.current;

    if (signaturePad) {
      signaturePad.instance.clear();
    }
  };

  onChangePagination = (page) => {
    this.setState((prevState) => ({
      ...prevState,
      current: page,
    }));
  };

  onChangePageSize = (current, pageSize) => {
    this.setState((prevState) => ({
      ...prevState,
      current: current,
      pageSize: pageSize,
    }));
  };

  onShowSizeChange = (current, pageSize) => {
    console.log(current);
    this.setState({ pageSize });
  };

  loadMaster = (e) => {
    let userData = getUserData();
    fetch(apiListVendors + "", {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          // console.log(data.data.vendors);
          this.setState({
            vendorList: data.data.vendors.rows,
          });
        } else {
          alert("something went wrong.");
        }
      });

    fetch(apiListCustomer + "", {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          this.setState({
            customerList: data.data.customer.rows,
          });
        } else {
          alert("something went wrong.");
        }
      });

    fetch(apiGetItemMasterList, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          this.setState({
            itemMasterList: data.data.masters.rows,
          });
        } else {
          alert("something went wrong.");
        }
      });

    fetch(apiListGstMasters, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          this.setState({
            gstMasterList: data.data.masters.rows,
          });
        } else {
          alert("something went wrong.");
        }
      });

    fetch(apiListPo + "", {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          this.setState((prevState) => ({
            fields: {
              ...prevState.fields,
              ponumber: data.data.poNumber,
            },
            poList: data.data.po.rows,
            poCount: data.data.po.count,
          }));
        } else {
          alert("something went wrong.");
        }
      });

    fetch(apiListProjects, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          //console.log("project list", data.data.projects.rows);
          this.setState({
            projectList: data.data.projects.rows,
            projectListCount: data.data.projects.count,
          });
        } else {
          alert("something went wrong.");
        }
      });

    fetch(apiGetSettings + "?key=po_terms", {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          // this.setState({
          //   projectData: data.data.projects.rows,
          // });
          // console.log(this.state.projectList);
          // this.setState({
          //   terms: data.data.setting.value,
          // });
          this.setState((prevState) => ({
            fields: {
              ...prevState.fields,
              terms: data.data.setting ? data.data.setting.value : "",
            },
            terms: RichTextEditor.createValueFromString(
              data.data.setting.value,
              "html"
            ),
          }));
        } else {
          alert("something went wrong.");
        }
        // setTimeout(function () {
      });
  };

  handleAddFields = () => {
    const values = [...this.state.inputFields];
    values.push({
      item: "",
      particulars: "",
      unit: "",
      qty: 1,
      gst: "",
      amount: 0,
      total: 0,
      tax: "",
      notes: "",
    });
    this.setState({ inputFields: values });
  };

  handleRemoveFields = (index) => {
    const values = [...this.state.inputFields];
    values.splice(index, 1);
    let tot_tax = [...this.state.totaltax];
    tot_tax.splice(index, 1);
    let grand_tot = [...this.state.grandtotal];
    grand_tot.splice(index, 1);

    this.setState({
      inputFields: values,
      totaltax: tot_tax,
      grandtotal: grand_tot,
    });

    let subTotalAmount = 0;
    let totalAmount = 0;
    for (var i = 0; i < this.state.inputFields.length; i++) {
      console.log("input", this.state.inputFields[i]);
      subTotalAmount += parseInt(this.state.inputFields[i].amount);
      console.log("sub", subTotalAmount);
    }
    this.setState({ subtotal: subTotalAmount });

    for (var i = 0; i < this.state.inputFields.length; i++) {
      console.log("input", this.state.inputFields[i]);
      totalAmount += parseInt(this.state.inputFields[i].total);
      console.log("total", totalAmount);
    }
    this.setState({ subtotal: subTotalAmount });
    this.setState({
      grandtotalFinal: totalAmount,
    });
  };

  handleInputChange = (index, event) => {
    const values = [...this.state.inputFields];
    if (event.target.name === "particulars") {
      values[index].particulars = event.target.value;
    } else if (event.target.name === "unit") {
      values[index].unit = event.target.value;
    } else if (event.target.name === "qty") {
      values[index].qty = event.target.value;
    } else if (event.target.name === "gst") {
      values[index].gst = event.target.value;
    } else if (event.target.name === "amount") {
      values[index].amount = event.target.value;
    } else if (event.target.name === "notes") {
      values[index].notes = event.target.value;
    }
    let quant = parseInt(this.state.inputFields[index].qty);
    let amt = parseInt(this.state.inputFields[index].amount) * quant;
    values[index].total = amt;
    if (
      this.state.inputFields[index].qty &&
      this.state.inputFields[index].gst &&
      this.state.inputFields[index].amount
    ) {
      let per = parseInt(this.state.inputFields[index].gst);
      let quant = parseInt(this.state.inputFields[index].qty);
      let amt = parseInt(this.state.inputFields[index].amount) * quant;
      let tax = amt * (per / 100);
      values[index].tax = tax;
      values[index].total = Math.round(amt + tax);

      this.setState({ totaltax: [...this.state.totaltax, tax] });
      this.setState({
        grandtotal: [...this.state.grandtotal, values[index].total],
      });
    }
    this.setState({ inputFields: values });
    // console.log(this.state.inputFields);

    let subTotalAmount = 0;
    let totalAmount = 0;
    for (var i = 0; i < this.state.inputFields.length; i++) {
      console.log("input", this.state.inputFields[i]);
      subTotalAmount += parseInt(this.state.inputFields[i].amount);
      console.log("sub", subTotalAmount);
    }
    this.setState({ subtotal: subTotalAmount });

    for (var i = 0; i < this.state.inputFields.length; i++) {
      // console.log("input", this.state.inputFields[i]);
      totalAmount += parseInt(this.state.inputFields[i].total);
      // console.log("total", totalAmount);
    }
    this.setState({ subtotal: subTotalAmount });
    this.setState({
      grandtotalFinal: totalAmount,
    });
  };

  componentDidMount() {
    this.loadMaster();
    //console.log("componentDidMount", this.state.fields.projects);
  }

  handleCloseModal = () => {
    this.setState(initialState);
    this.projectReset();
    this.loadMaster();
  };

  handleOpenModal = () => {
    this.setState({ isModalOpen: true });
    this.validator.hideMessages();
    //console.log("model open", this.state);
  };

  projectReset = () => {
    // this.selectInputRef.current.select.clearValue();
  };

  showAlert = (id) => {
    //console.log(id);
    this.setState({
      alert: true,
      deleteId: id,
    });
  };

  setLocation = (e) => {
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        address: e,
      },
    }));
  };

  hideAlert = () => {
    this.setState({
      alert: false,
    });
  };

  deleteEmployee = (e) => {
    //console.log("delete", this.state);
    this.setState({ alert: false });
    let userData = getUserData();
    axios
      .post(
        apiDeleteEmployee,
        { id: this.state.deleteId },
        {
          headers: {
            Authorization: "Bearer " + userData.token,
          },
        }
      )
      .then(
        (response) => {
          //console.log(response);
          this.setState({ isLoading: false });
          toast.info("Employee deleted", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          this.setState(this.initialState);
        },
        (error) => {
          if (error.response.status == 500 || error.response.status == 400) {
            toast.error(error.response.data.errorMessage, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            this.setState({ isLoading: false });
          }
        }
      );
  };

  handleChange = (e) => {
    this.validator.showMessageFor(e.target.name);
    const value = e.target.value;
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        [e.target.name]: value,
      },
    }));
    console.log(this.state.fields);
  };

  handleSetVendor = (e, value) => {
    console.log(value.key);
    let userData = getUserData();
    fetch(apiGetVendor + "?id=" + value.key, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          // console.log(data.data.vendors);
          this.setState((prevState) => ({
            fields: {
              ...prevState.fields,
              vendor: data.data.vendor.id,
            },
            selectedVendor: data.data.vendor,
          }));
        } else {
          alert("something went wrong.");
        }
      });
  };

  handleSetProject = (e, value) => {
    console.log(value.key);
    let userData = getUserData();
    fetch(apiGetProject + "?id=" + value.key, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          this.setState((prevState) => ({
            fields: {
              ...prevState.fields,
              project: data.data.project.id,
            },
            selectedProject: data.data.project,
          }));
          console.log(this.state.fields);
        } else {
          alert("something went wrong.");
        }
      });
  };

  handleSetBilling = (e, value) => {
    console.log(value.key);
    let userData = getUserData();
    fetch(apiGetGstMaster + "?id=" + value.key, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          this.setState((prevState) => ({
            fields: {
              ...prevState.fields,
              billinggst: data.data.master.id,
            },
            selectedBillingGst: data.data.master,
          }));
          console.log(this.state.fields);
        } else {
          alert("something went wrong.");
        }
      });
  };

  handleSetItem = (e, value, index) => {
    console.log(value.key);
    let userData = getUserData();
    fetch(apiGetItemMaster + "?id=" + value.key, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          console.log(data.data.master);
          const values = [...this.state.inputFields];
          values[index].item = data.data.master.id;
          values[index].particulars = data.data.master.name;
          values[index].unit = data.data.master.unitDetails.name;
          this.setState({ inputFields: values });
          // this.setState((prevState) => ({
          //   selectedItem: {
          //     ...prevState.selectedItem,
          //     selectedItem: data.data.master,
          //   },
          // }));
          // this.setState({
          //   selectedItem: data.data.master,
          // });
          // console.log(this.state.selectedItem);
        } else {
          alert("something went wrong.");
        }
      });
  };

  handleBlur = (e) => {
    this.validator.showMessageFor(e.target.name);
  };

  submitForm = (e) => {
    e.preventDefault();
    //console.log("submit");
    // const signaturePad = this.sigPad.current;

    // if (!signaturePad) {
    //   return;
    // }

    // if (signaturePad.isEmpty()) {
    //   // eslint-disable-next-line no-alert
    //   alert("Please provide a signature first.");
    //   return false;
    // } else {
    //   console.log(signaturePad.toDataURL());
    //   this.setState({ sign: signaturePad.toDataURL() });
    //   // window.open(signaturePad.toDataURL());
    // }
    if (this.validator.allValid()) {
      /* console.log("hey", this.state);
      console.log(getUserData()); */
      let userData = getUserData();

      //console.log("selected projects", this.state.selectedProjects);
      //setting project array with project id

      if (!this.state.editItem) {
        let project = [];

        let data = {
          ponumber: this.state.fields.ponumber,
          date: this.state.fields.date,
          customer: this.state.fields.customer,
          project: this.state.fields.project,
          billinggst: this.state.fields.billinggst,
          shipname: this.state.fields.shipname,
          shipaddress: this.state.fields.shipaddress,
          shipgst: this.state.fields.shipgst,
          shipstate: this.state.fields.shipstate,
          shipstatecode: this.state.fields.shipstatecode,
          vendor: this.state.fields.vendor,
          particulars: JSON.stringify(this.state.inputFields),
          total:
            this.state.grandtotal.reduce((a, b) => a + b, 0) -
            this.state.totaltax.reduce((a, b) => a + b, 0),
          totaltax: this.state.totaltax.reduce((a, b) => a + b, 0),
          grandtotal: this.state.grandtotal.reduce((a, b) => a + b, 0),
          remarks: this.state.fields.remarks,
          terms: this.state.terms.toString("html"),
          sign: this.state.sign,
        };

        this.setState({ isLoading: true });
        axios
          .post(apiCreatePo, data, {
            headers: {
              Authorization: "Bearer " + userData.token,
            },
          })
          .then(
            (response) => {
              //console.log(response);
              // this.setState({ isLoading: false });
              toast.info("Po created", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
              this.setState(this.initialState);
              this.handleCloseModal();
            },
            (error) => {
              if (
                error.response.status == 500 ||
                error.response.status == 400
              ) {
                toast.error(error.response.data.errorMessage, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                this.setState({ isLoading: false });
              }
            }
          );
      } else {
        let project = [];

        let data = {
          ponumber: this.state.fields.ponumber,
          date: this.state.fields.date,
          customer: this.state.fields.customer,
          project: this.state.fields.project,
          billinggst: this.state.fields.billinggst,
          shipname: this.state.fields.shipname,
          shipaddress: this.state.fields.shipaddress,
          shipgst: this.state.fields.shipgst,
          shipstate: this.state.fields.shipstate,
          shipstatecode: this.state.fields.shipstatecode,
          vendor: this.state.fields.vendor,
          particulars: JSON.stringify(this.state.inputFields),
          total:
            this.state.grandtotal.reduce((a, b) => a + b, 0) -
            this.state.totaltax.reduce((a, b) => a + b, 0),
          totaltax: this.state.totaltax.reduce((a, b) => a + b, 0),
          grandtotal: this.state.grandtotal.reduce((a, b) => a + b, 0),
          remarks: this.state.fields.remarks,
          terms: this.state.terms.toString("html"),
          sign: this.state.sign,
          id: this.state.editId,
        };

        this.setState({ isLoading: true });
        axios
          .post(apiEditPo, data, {
            headers: {
              Authorization: "Bearer " + userData.token,
            },
          })
          .then(
            (response) => {
              toast.info("Po Updated", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
              this.setState(this.initialState);
              this.handleCloseModal();
            },
            (error) => {
              if (
                error.response.status == 500 ||
                error.response.status == 400
              ) {
                toast.error(error.response.data.errorMessage, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                this.setState({ isLoading: false });
              }
            }
          );
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  handleShippingCheckbox = (e) => {
    if (e.target.checked) {
      this.setState((prevState) => ({
        fields: {
          ...prevState.fields,
          shipname: this.state.selectedProject.name,
          shipaddress: this.state.selectedProject.address,
          shipgst: this.state.selectedProject.gst,
          shipstatecode: this.state.selectedProject.statecode,
        },
      }));
    }
  };

  handleSearch = (e) => {
    const value = e.target.value;
    this.setState((prevState) => ({
      searchFields: {
        ...prevState.searchFields,
        [e.target.name]: value,
      },
    }));
    console.log(this.state.searchFields);
  };

  handleSearchSelect = (e, value) => {
    this.setState((prevState) => ({
      searchFields: {
        ...prevState.searchFields,
        [value.name]: value.key,
      },
    }));
  };

  submitSearch = (e) => {
    e.preventDefault();
    let userData = getUserData();
    fetch(
      apiListPo +
        "?ponumber=" +
        this.state.searchFields.ponumber +
        "&project=" +
        this.state.searchFields.project +
        "&to=" +
        this.state.searchFields.to +
        "&from=" +
        this.state.searchFields.from,
      {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + userData.token,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          this.setState((prevState) => ({
            fields: {
              ...prevState.fields,
              ponumber: data.data.poNumber,
            },
            poList: data.data.po.rows,
          }));
        } else {
          alert("something went wrong.");
        }
      });
  };
  clearFilter = () => {
    this.myFormRef.reset();
    //this.setState(this.initialState, this.loadMasterData);
    this.setState(
      {
        searchFields: {
          ponumber: "",
          project: "",
          from: "",
          to: "",
        },
      },
      this.loadMaster
    );
  };

  editPo = (e, po) => {
    this.setState({ editItem: true, editId: po.id });
    this.handleOpenModal();
    let userData = getUserData();
    fetch(apiGetPo + "?id=" + po.id, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          this.setState({
            inputFields: JSON.parse(data.data.po.particulars),
            remarks: data.data.po.remarks,
            terms: RichTextEditor.createValueFromString(
              data.data.po.terms,
              "html"
            ),
            totaltax: [...this.state.totaltax, data.data.po.totaltax],
            grandtotal: [...this.state.grandtotal, data.data.po.grandtotal],
          });
          this.setState((prevState) => ({
            fields: {
              ...prevState.fields,
              remarks: data.data.po.remarks,
              terms: data.data.po.terms,
              vendor: data.data.po.vendor,
              ponumber: data.data.po.ponumber,
              date: data.data.po.date,
              project: data.data.po.project,
              customer: data.data.po.customer,
              status: data.data.po.status,
            },
            terms: RichTextEditor.createValueFromString(
              data.data.po.terms,
              "html"
            ),
            inputFields: JSON.parse(data.data.po.particulars),
            selectedVendor: data.data.po.vendorDetails,
            selectedBillingGst: data.data.po.billingDetails,
            selectedProject: data.data.po.projectDetails,
            selectedProject: data.data.po.projectDetails,
          }));
          console.log("edit", this.state);
        } else {
          alert("something went wrong.");
        }
      });
  };

  duplicatePo = (e, po) => {
    this.setState({ duplicateItem: true, duplicateItem: po.id });
    this.handleOpenModal();
    let userData = getUserData();
    fetch(apiGetPo + "?id=" + po.id, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          this.setState({
            inputFields: JSON.parse(data.data.po.particulars),
            remarks: data.data.po.remarks,
            terms: RichTextEditor.createValueFromString(
              data.data.po.terms,
              "html"
            ),
            totaltax: [...this.state.totaltax, data.data.po.totaltax],
            grandtotal: [...this.state.grandtotal, data.data.po.grandtotal],
          });
          this.setState((prevState) => ({
            fields: {
              ...prevState.fields,
              remarks: data.data.po.remarks,
              terms: data.data.po.terms,
              vendor: data.data.po.vendor,
              ponumber: data.data.po.ponumber,
              date: data.data.po.date,
              status: data.data.po.status,
            },
            terms: RichTextEditor.createValueFromString(
              data.data.po.terms,
              "html"
            ),
            inputFields: JSON.parse(data.data.po.particulars),
            selectedVendor: data.data.po.vendorDetails,
            selectedBillingGst: data.data.po.billingDetails,
            selectedProject: data.data.po.projectDetails,
          }));
          console.log("edit", this.state);
        } else {
          alert("something went wrong.");
        }
      });
  };

  render() {
    this.validator.purgeFields();

    return (
      <React.Fragment>
        <Helmet>
          <title>Purchase Order | MRC</title>
        </Helmet>
        <section className="admin">
          <AdminHeader></AdminHeader>
          <div className="wrapper d-flex">
            <AdminMenu></AdminMenu>

            <div className="wrapper_body">
              <div className="row d-flex align-items-center">
                <div className="col m0 d-flex align-items-center">
                  <h3 className="heading">Purchase Order</h3>
                </div>

                <div className="col m4 right-align">
                  <a
                    href="javascript:void(0)"
                    className="theme_btn btn waves-effect"
                    onClick={this.handleOpenModal}
                  >
                    <i className="material-icons">add</i> New Purchase Order
                  </a>
                  <Modal
                    actions={[,]}
                    bottomSheet={false}
                    fixedFooter={false}
                    id="Modal-0"
                    open={this.state.isModalOpen}
                    className="cstm-modal w-100"
                    options={{
                      dismissible: false,
                      endingTop: "10%",
                      inDuration: 250,
                      onCloseEnd: null,
                      onCloseStart: null,
                      onOpenEnd: null,
                      onOpenStart: null,
                      opacity: 0.5,
                      outDuration: 250,
                      preventScrolling: true,
                      startingTop: "4%",
                    }}
                  >
                    {/* Close Modal */}
                    <a
                      href="javascript:void(0)"
                      className="modal-close"
                      onClick={this.handleCloseModal}
                    >
                      <span className="material-icons">chevron_right</span>
                    </a>
                    {/* Modal Content */}
                    <h3 className="heading">
                      {this.state.editItem
                        ? "Update Purchase Order"
                        : "Create Purchase Order"}
                    </h3>
                    <p>
                      Enter details to{" "}
                      {this.state.editItem ? "update" : "create"} purchase
                      order.
                    </p>
                    <form
                      action
                      id="createPurchaseOrderForm"
                      ref={(ele) => (this.myFormRef = ele)}
                    >
                      <fieldset className="border">
                        <legend>Purchase Order Details</legend>
                        <div className="row">
                          <div className="col m6 s12">
                            <label className="label">Purchase Order</label>
                            <input
                              type="text"
                              name="ponumber"
                              onChange={this.handleChange}
                              value={this.state.fields.ponumber}
                            />
                          </div>
                          <div className="col m6 s12">
                            <label className="label">Date</label>
                            <input
                              type="date"
                              name="date"
                              onChange={this.handleChange}
                              value={this.state.fields.date}
                            />
                          </div>
                          {/* <div className="col m6 s12">
                            <label className="label">Status</label>
                            <select
                              name="status"
                              onChange={this.handleChange}
                              value={this.state.fields.status}
                            >
                              <option selected>Select</option>
                              <option value="1">Active</option>
                              <option value="2">Complete</option>
                              <option value="3">Cancelled</option>
                            </select>
                          </div> */}
                        </div>
                      </fieldset>
                      <fieldset className="border">
                        <legend>Vendor Details</legend>
                        <div className="row">
                          <div className="col s12 m6">
                            <label className="label">Vendor</label>
                            <Select
                              className="multiSelect"
                              animation={"slide-up"}
                              ref={this.selectInputRef}
                              name="vendor"
                              showSearch={true}
                              onChange={this.handleSetVendor}
                              options={this.state.vendorList.map((vendor) => ({
                                value: vendor.companyname,
                                text: vendor.companyname,
                                key: vendor.id,
                                name: "vendor",
                              }))}
                              value={this.state.selectedVendor.companyname}
                            />
                          </div>
                          <div className="col s12 m6">
                            <label className="label">Address</label>
                            <input
                              type="text"
                              readOnly
                              value={this.state.selectedVendor.address}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col s12 m6">
                            <label className="label">GST</label>
                            <input
                              type="text"
                              readOnly
                              value={this.state.selectedVendor.gst}
                            />
                          </div>
                          <div className="col s12 m6">
                            <label className="label">Name</label>
                            <input
                              type="text"
                              readOnly
                              value={this.state.selectedVendor.name}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col s12 m6">
                            <label className="label">Phone</label>
                            <input
                              type="text"
                              readOnly
                              value={this.state.selectedVendor.phone}
                            />
                          </div>
                        </div>
                      </fieldset>

                      <fieldset className="border">
                        <legend>Billing Address</legend>
                        <div className="row">
                          {/* <div className="col s12">
                            <br />
                            <p>
                              <label>
                                <input
                                  type="checkbox"
                                  className="filled-in"
                                  onChange={this.handleShippingCheckbox}
                                />
                                <span>Same as Billing Address</span>
                              </label>
                            </p>
                            <br /> 
                          </div>*/}
                          <div className="col s12 m6">
                            <label className="label">Customer Name</label>
                            <Select
                              // styles={customStyles}
                              className="multiSelect"
                              animation={"slide-up"}
                              ref={this.selectInputRef}
                              name="customer"
                              showSearch={true}
                              onChange={this.handleSetBilling}
                              options={this.state.gstMasterList.map((gst) => ({
                                value: gst.name,
                                text: gst.name,
                                key: gst.id,
                                name: "customer",
                              }))}
                              // value={[
                              //   {
                              //     value: this.state.selectedBillingGst.name,
                              //     text: this.state.selectedBillingGst.name,
                              //     key: this.state.selectedBillingGst.id,
                              //     name: "customer",
                              //   },
                              // ]}
                              // value={{
                              //   value: this.state.selectedBillingGst.name,
                              //   text: this.state.selectedBillingGst.name,
                              //   key: this.state.selectedBillingGst.id,
                              //   name: "customer",
                              // }}
                              value={this.state.selectedBillingGst.name}
                            />
                          </div>
                          <div className="col s12 m6">
                            <label className="label">Address</label>
                            <input
                              type="text"
                              name="shipaddress"
                              onChange={this.handleChange}
                              readOnly
                              value={this.state.selectedBillingGst.address}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col s12 m6">
                            <label className="label">GSTIN/UIN</label>
                            <input
                              type="text"
                              name="shipgst"
                              onChange={this.handleChange}
                              readOnly
                              value={this.state.selectedBillingGst.gst}
                            />
                          </div>
                          <div className="col s12 m6">
                            <label className="label">State Name</label>
                            <input
                              type="text"
                              name="shipstate"
                              onChange={this.handleChange}
                              readOnly
                              value={this.state.selectedBillingGst.state}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col s12 m6">
                            <label className="label">State Code</label>
                            <input
                              type="text"
                              name="shipstatecode"
                              onChange={this.handleChange}
                              readOnly
                              value={this.state.selectedBillingGst.statecode}
                            />
                          </div>
                        </div>
                      </fieldset>

                      <fieldset className="border">
                        <legend>Shipping Address</legend>
                        <div className="row">
                          <div className="col s12 m6">
                            <label className="label">Project Name</label>
                            <Select
                              // styles={customStyles}
                              className="multiSelect"
                              animation={"slide-up"}
                              ref={this.selectInputRef}
                              name="customer"
                              showSearch={true}
                              onChange={this.handleSetProject}
                              options={this.state.projectList.map(
                                (project) => ({
                                  value: project.name,
                                  text: project.name,
                                  key: project.id,
                                  name: "project",
                                })
                              )}
                              value={this.state.selectedProject.name}
                            />
                          </div>
                          <div className="col s12 m6">
                            <label className="label">Address</label>
                            <input
                              type="text"
                              readOnly
                              value={this.state.selectedProject.address}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col s12 m6">
                            <label className="label">GSTIN/UIN</label>
                            <input
                              type="text"
                              readOnly
                              value={this.state.selectedProject.gst}
                            />
                          </div>
                          <div className="col s12 m6">
                            <label className="label">State Name</label>
                            <input
                              type="text"
                              readOnly
                              value={this.state.selectedProject.state}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col s12 m6">
                            <label className="label">State Code</label>
                            <input
                              type="text"
                              readOnly
                              value={this.state.selectedProject.statecode}
                            />
                          </div>
                        </div>
                      </fieldset>

                      <fieldset className="border">
                        <legend>Description of Product & Services</legend>
                        {this.state.inputFields.map((inputField, index) => (
                          <Fragment key={`${inputField}~${index}`}>
                            <div className="row">
                              <div className="col m1 s12">
                                {index === 0 && (
                                  <label className="label">SNo.</label>
                                )}

                                {index + 1}
                              </div>
                              <div className="col m2 s12">
                                {index === 0 && (
                                  <label className="label">Particulars</label>
                                )}

                                <Select
                                  className="multiSelect"
                                  animation={"slide-up"}
                                  ref={this.selectInputRef}
                                  name="particulars"
                                  showSearch={true}
                                  onChange={(e, value) =>
                                    this.handleSetItem(e, value, index)
                                  }
                                  options={this.state.itemMasterList.map(
                                    (item) => ({
                                      value: item.name,
                                      text: item.name,
                                      key: item.id,
                                      name: "item",
                                    })
                                  )}
                                  // value={this.state.selectedVendor.id}
                                />

                                {/* <input
                                  type="text"
                                  name="particulars"
                                  value={
                                    this.state.inputFields[index].particulars
                                  }
                                  onChange={(event) =>
                                    this.handleInputChange(index, event)
                                  }
                                /> */}
                                <label className="error">
                                  {this.validator.message(
                                    "particulars",
                                    this.state.inputFields[index].particulars,
                                    "required"
                                  )}
                                </label>
                              </div>

                              <div className="col m1 s12">
                                {index === 0 && (
                                  <label className="label">Unit</label>
                                )}

                                <input
                                  type="text"
                                  name="unit"
                                  value={this.state.inputFields[index].unit}
                                  onChange={(event) =>
                                    this.handleInputChange(index, event)
                                  }
                                  readOnly
                                />
                                {/* <label className="error">
                                  {this.validator.message(
                                    "hsn",
                                    this.state.inputFields[index].hsn,
                                    "required"
                                  )}
                                </label> */}
                              </div>

                              <div className="col m2 s12">
                                {index === 0 && (
                                  <label className="label">Rate</label>
                                )}

                                <input
                                  type="text"
                                  name="amount"
                                  value={this.state.inputFields[index].amount}
                                  onChange={(event) =>
                                    this.handleInputChange(index, event)
                                  }
                                />
                                <label className="error">
                                  {this.validator.message(
                                    "amount",
                                    this.state.inputFields[index].amount,
                                    "required"
                                  )}
                                </label>
                              </div>

                              <div className="col m1 s12">
                                {index === 0 && (
                                  <label className="label">Qty</label>
                                )}
                                <input
                                  type="text"
                                  name="qty"
                                  value={this.state.inputFields[index].qty}
                                  onChange={(event) =>
                                    this.handleInputChange(index, event)
                                  }
                                />
                                <label className="error">
                                  {this.validator.message(
                                    "quantity",
                                    this.state.inputFields[index].qty,
                                    "required"
                                  )}
                                </label>
                              </div>

                              <div className="col m1 s12">
                                {index === 0 && (
                                  <label className="label">GST %</label>
                                )}
                                <select
                                  name="gst"
                                  value={this.state.inputFields[index].gst}
                                  onChange={(event) =>
                                    this.handleInputChange(index, event)
                                  }
                                >
                                  <option selected> GST</option>
                                  <option value="0">0%</option>
                                  <option value="5">5%</option>
                                  <option value="12">12%</option>
                                  <option value="18">18%</option>
                                  <option value="28">28%</option>
                                </select>
                                <label className="error">
                                  {this.validator.message(
                                    "gst",
                                    this.state.inputFields[index].gst,
                                    "required"
                                  )}
                                </label>
                              </div>

                              <div className="col m2 s12">
                                {index === 0 && (
                                  <label className="label">Total Amount</label>
                                )}

                                <input
                                  type="text"
                                  name="total"
                                  readOnly
                                  value={this.state.inputFields[index].total}
                                  onChange={(event) =>
                                    this.handleInputChange(index, event)
                                  }
                                />
                              </div>
                              <div className="col m2 s12">
                                {index === 0 && (
                                  <label className="label">Notes</label>
                                )}

                                <input
                                  type="text"
                                  name="notes"
                                  value={this.state.inputFields[index].notes}
                                  onChange={(event) =>
                                    this.handleInputChange(index, event)
                                  }
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col m1 s12">
                                {this.state.inputFields.length === index + 1 ? (
                                  <>
                                    {this.state.inputFields.length > 1 && (
                                      <button
                                        className="theme_btn btn"
                                        type="button"
                                        onClick={() =>
                                          this.handleRemoveFields(index)
                                        }
                                      >
                                        -
                                      </button>
                                    )}

                                    <button
                                      className="theme_btn btn"
                                      type="button"
                                      onClick={() => this.handleAddFields()}
                                    >
                                      +
                                    </button>
                                  </>
                                ) : null}
                              </div>
                            </div>
                          </Fragment>
                        ))}
                        <fieldset>
                          <div className="col m9 s12"></div>
                          <div className="col m3 s12">
                            <h6>
                              <strong>Sub Total:&nbsp;</strong>{" "}
                              <i class="fas fa-rupee-sign"></i>{" "}
                              {this.state.subtotal.toFixed(2)}
                            </h6>
                            {this.state.igst ? (
                              <>
                                {" "}
                                <h6>
                                  <strong>IGST:&nbsp;</strong>{" "}
                                  <i class="fas fa-rupee-sign"></i>{" "}
                                  {this.state.grandtotalFinal -
                                    this.state.subtotal.toFixed(2)}
                                </h6>
                              </>
                            ) : (
                              <>
                                <h6>
                                  <strong>CGST:&nbsp;</strong>{" "}
                                  <i class="fas fa-rupee-sign"></i>{" "}
                                  {(this.state.grandtotalFinal -
                                    this.state.subtotal.toFixed(2)) /
                                    2}
                                </h6>
                                <h6>
                                  <strong>SGST:&nbsp;</strong>{" "}
                                  <i class="fas fa-rupee-sign"></i>{" "}
                                  {(this.state.grandtotalFinal -
                                    this.state.subtotal.toFixed(2)) /
                                    2}
                                </h6>
                              </>
                            )}

                            <h6>
                              <strong>Grand Total:&nbsp;</strong>{" "}
                              <i class="fas fa-rupee-sign"></i>{" "}
                              {this.state.grandtotalFinal.toFixed(2)}
                            </h6>
                          </div>
                        </fieldset>
                      </fieldset>
                      <fieldset className="border">
                        <legend>Remarks & Terms</legend>
                        <div className="row">
                          <div className="col s12 12">
                            <label className="label">Remarks</label>
                            <textarea
                              row="2"
                              name="remarks"
                              value={this.state.fields.remarks}
                              onChange={this.handleChange}
                            >
                              {this.state.fields.remarks}
                            </textarea>
                          </div>
                          <div className="col s12 m12">
                            <label className="label">Terms</label>
                            <textarea
                              row="10"
                              name="terms"
                              value={this.state.fields.terms}
                              onChange={this.handleChange}
                            >
                              {this.state.fields.terms}
                            </textarea>
                            <RichTextEditor
                              className="rte"
                              value={this.state.terms}
                              onChange={this.onChangeEditor}
                            />
                          </div>
                        </div>
                      </fieldset>

                      {/* <div style={{ width: "350px", marginTop: "20px" }}>
                        <SignaturePad
                          options={{
                            minWidth: 1,
                            maxWidth: 5,
                            backgroundColor: "#d3d3d3",
                            penColor: "black",
                          }}
                          ref={this.sigPad}
                        />
                        <a
                          className="btn btn-danger"
                          onClick={this.handleSignClear}
                        >
                          Clear Sign
                        </a>
                      </div> */}

                      <div className="row">
                        <div className="col s12 right-align">
                          <button
                            form="createPurchaseOrderForm"
                            className="theme_btn btn"
                            onClick={this.submitForm}
                          >
                            {this.state.editItem
                              ? "Update Purchase Order"
                              : "Add Purchase Order"}
                            {this.state.isLoading ? (
                              <ButtonLoader></ButtonLoader>
                            ) : null}
                          </button>
                        </div>
                      </div>
                    </form>
                  </Modal>
                </div>
              </div>
              <div className="row">
                <div className="col s12">
                  <p className="grey-text">
                    <b>Filters</b>
                  </p>
                  <form ref={(ele) => (this.myFormRef = ele)}>
                    <div className="row">
                      <div className="col m4 s12">
                        <input
                          type="text"
                          className=""
                          placeholder="Po Number"
                          name="ponumber"
                          onChange={this.handleSearch}
                        />
                      </div>

                      <div className="col m4 s12">
                        <Select
                          // styles={customStyles}
                          name="project"
                          placeholder="Select Project"
                          options={this.state.projectList.map((project) => ({
                            value: project.name,
                            text: project.name,
                            key: project.id,
                            name: "project",
                          }))}
                          onChange={this.handleSearchSelect}
                          id={"selectproject"}
                          showSearch={true}
                          // onSearch={(e) =>
                          //   this.handleSearchSelectInput(e)
                          // }
                          dropdownClassName={"force-zindex"}
                        />
                      </div>

                      <div className="col m4 s12">
                        <Select
                          // styles={customStyles}
                          name="vendor"
                          placeholder="Select Vendor"
                          options={this.state.vendorList.map((vendor) => ({
                            value: vendor.name,
                            text: vendor.name,
                            key: vendor.id,
                            name: "vendor",
                          }))}
                          onChange={this.handleSearch}
                          id={"selectvendor"}
                          showSearch={true}
                          // onSearch={(e) =>
                          //   this.handleSearchSelectInput(e)
                          // }
                          dropdownClassName={"force-zindex"}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col m4 s12">
                        <label className="label">To Date</label>
                        <input
                          type="date"
                          className=""
                          placeholder="From Date"
                          name="from"
                          onChange={this.handleSearch}
                        />
                      </div>

                      <div className="col m4 s12">
                        <label className="label">From Date</label>
                        <input
                          type="date"
                          className=""
                          placeholder="To Date"
                          name="to"
                          onChange={this.handleSearch}
                        />
                      </div>
                    </div>

                    <div className="row">
                      {/* <a href="#!" className="red-text pt-1 d-flex">Clear Filter</a> */}
                      {/* <button type="submit" className="red-text pt-1">Clear Filter</button> */}
                      <div class="col m3 s12">
                        <a
                          href="javascript:Void(0)"
                          class="red-text pt-1"
                          onClick={this.clearFilter}
                        >
                          Clear Filter
                        </a>

                        <button
                          type="submit"
                          className="theme_btn btn filter-submit"
                          onClick={this.submitSearch}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="projects employee">
                <div className="row">
                  <div className="col s12">
                    <div className="table-responsive">
                      <table>
                        <thead>
                          <tr>
                            <th>P.O</th>
                            <th>Status</th>
                            <th>Date</th>
                            <th>Vendor Name</th>
                            <th>Address</th>
                            <th>Total Amount</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.poList.map((po, index) => (
                            <>
                              <tr>
                                <td>{po.ponumber}</td>
                                <td>
                                  {po.status === 0 ? "Pending" : ""}
                                  {po.status === 1 ? "Active" : ""}
                                  {po.status === 2 ? "Completed" : ""}
                                  {po.status === 3 ? "Cancelled" : ""}
                                </td>
                                <td>{po.date}</td>
                                <td>{po.vendorDetails.name}</td>
                                <td>{po.vendorDetails.address}</td>
                                <td>
                                  <i class="fas fa-rupee-sign"></i> {po.total}
                                </td>
                                <td>
                                  {po.status === 1 || po.status === 2 ? (
                                    <>
                                      <a
                                        href={
                                          process.env.REACT_APP_API_ENDPOINT +
                                          "public/po/generatePdf?preview=true&id=" +
                                          po.id
                                        }
                                        target="_blank"
                                      >
                                        <span class="material-icons">
                                          download
                                        </span>
                                      </a>
                                    </>
                                  ) : (
                                    <></>
                                  )}

                                  <Link
                                    to={`poview/${po.id}`}
                                    className="actions"
                                  >
                                    <i className="material-icons">
                                      remove_red_eye
                                    </i>
                                  </Link>
                                  <a
                                    href="javascript:void(0)"
                                    className="actions"
                                    node="button"
                                    onClick={(e) => this.duplicatePo(e, po)}
                                  >
                                    <i className="fa fa-copy fa-2x"></i>
                                  </a>
                                  <a
                                    href="javascript:void(0)"
                                    className="actions"
                                    node="button"
                                    onClick={(e) => this.editPo(e, po)}
                                  >
                                    <i className="material-icons">edit</i>
                                  </a>
                                  <a
                                    href="javascript:void(0)"
                                    className="actions"
                                    node="button"
                                    onClick={(e) =>
                                      this.setState({ alert: true })
                                    }
                                  >
                                    <i className="fa fa-times  fa-2x"></i>
                                  </a>
                                </td>
                              </tr>
                            </>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div
                  className="col s12"
                  style={{ marginTop: 20, marginBottom: 20 }}
                >
                  <Pagination
                    pageSize={this.state.pageSize}
                    onChange={this.onChangePagination}
                    current={this.state.current}
                    total={this.state.poCount}
                    showSizeChanger
                    defaultPageSize={this.state.pageSize}
                    defaultCurrent={this.state.current}
                    onShowSizeChange={this.onChangePageSize}
                    locale={localeInfo}
                    selectComponentClass={Select}
                    pageSizeOptions={[10, 30, 50, 100]}
                    showTotal={(total, range) =>
                      `${range[0]} - ${range[1]} of ${total} items`
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          {this.state.alert && (
            <SweetAlert
              // onConfirm={this.changeStatus}
              onCancel={this.hideAlert}
              focusCancelBtn={true}
              warning
              showCancel
              confirmBtnText="Yes"
              confirmBtnBsStyle="danger"
              title="Are you sure?"
            />
          )}
          <Footer />
        </section>
      </React.Fragment>
    );
  }
}
