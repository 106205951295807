import React, { Component } from "react";

export default class Footer extends Component {
  render() {
    return (
      <footer className='center-align'>
        <p>All Rights Reserved Â© 2021 MRC Group</p>
      </footer>
    );
  }
}
