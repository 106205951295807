import React, { Component } from "react";
import Footer from "../../components/Footer";
import AdminHeader from "../../components/header/AdminHeader";
import AdminMenu from "../../components/sidebar/AdminMenu";
import axios from "axios";
import { toast } from "react-toastify";
import "materialize-css";
import { Modal } from "react-materialize";
import { setUserData, getUserData } from "../../helpers/storage";
import SweetAlert from "react-bootstrap-sweetalert";
import ButtonLoader from "../../components/ButtonLoader";
import { BulletList } from "react-content-loader";
import AutoComplete from "react-google-autocomplete";
import SimpleReactValidator from "simple-react-validator";
import { Helmet } from "react-helmet";
import Pagination from "rc-pagination";
import Select from "rc-select";
import "rc-select/assets/index.less";
import "../../assets/pagination.less";
import localeInfo from "../../helpers/en_US";

import {
  apiListVendors,
  apiCreateVendor,
  apiGetVendor,
  apiDeleteVendor,
  apiEditVendor,
  apiGetMasterByType,
} from "../../config/endpoints";

const initialState = {
  fields: {
    name: "",
    companyname: "",
    gst: "",
    pan: "",
    phone: "",
    email: "",
    address: "",
    type: "",
  },
  isLoading: false,
  redirect: false,
  errors: {},
  vendorList: [],
  isModalOpen: false,
  isListLoading: false,
  alert: false,
  deleteId: "",
  editId: false,
  editItem: false,
  masterListVendor: [],
  vendor: "",
  vendorType: "",
  vendorName: "",
  pageSize: 30,
  current: 1,
};

export default class Contractor extends Component {
  constructor(props) {
    super(props);
    /* this.formRef = React.createRef(); */
    this.state = initialState;
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
  }

  onChangePagination = (page) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        current: page,
      }),
      this.loadVendors
    );
  };
  onChangePageSize = (current, pageSize) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        current: current,
        pageSize: pageSize,
      }),
      this.loadVendors
    );
  };

  onShowSizeChange = (current, pageSize) => {
    console.log(current);
    this.setState({ pageSize });
  };

  onChangePagination = (page) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        current: page,
      }),
      this.loadVendors
    );
  };

  loadVendors = (e) => {
    let userData = getUserData();
    this.setState({ isListLoading: true });
    fetch(
      apiListVendors +
        "?page=" +
        this.state.current +
        "&pagesize=" +
        this.state.pageSize +
        "&type=" +
        this.state.vendorType +
        "&name=" +
        this.state.vendorName,
      {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + userData.token,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          //console.log("vendor list", data.data.vendors);
          this.setState({
            vendorList: data.data.vendors.rows,
            countVendor: data.data.vendors.count,
            isListLoading: false,
          });
        } else {
          alert("something went wrong.");
        }
        // setTimeout(function () {
      });
  };
  handleCloseModal = () => {
    this.setState(initialState);
    this.setState(
      {
        vendorType: "",
      },
      this.loadVendors
    );

    this.loadMaster();
  };
  handleOpenModal = () => {
    this.setState({ isModalOpen: true });
    this.validator.hideMessages();
    this.loadMaster();
  };

  editVendor(id) {
    // Assuming only image
    this.setState({ editItem: true, editId: id });
    this.handleOpenModal();
    let userData = getUserData();
    fetch(apiGetVendor + "?id=" + id, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          this.setState({
            fields: data.data.vendor,
          });
        } else {
          alert("something went wrong.");
        }
      });
    // Updating the city
  }
  showAlert = (id) => {
    //console.log(id);
    this.setState({
      alert: true,
      deleteId: id,
    });
  };

  hideAlert = () => {
    this.setState({
      alert: false,
    });
  };

  loadMaster = (e) => {
    let userData = getUserData();
    fetch(apiGetMasterByType + "?type=vendor_type", {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          //console.log("master vendor list", data.data.masters);
          this.setState({
            masterListVendor: data.data.masters,
          });
        } else {
          alert("something went wrong.");
        }
      });
  };

  setLocation = (e) => {
    this.setState((prevState) => ({
      fields: {
        // object that we want to update
        ...prevState.fields, // keep all other key-value pairs
        address: e, // update the value of specific key
      },
    }));
  };

  deleteVendor = (e) => {
    //console.log("delete", this.state);
    this.setState({ alert: false });
    let userData = getUserData();
    axios
      .post(
        apiDeleteVendor,
        { id: this.state.deleteId },
        {
          headers: {
            Authorization: "Bearer " + userData.token,
          },
        }
      )
      .then(
        (response) => {
          //console.log(response);
          this.setState({ isLoading: false });
          toast.info("Vendor deleted", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          this.setState(this.initialState);
          this.loadVendors();
        },
        (error) => {
          if (error.response.status == 500 || error.response.status == 400) {
            toast.error(error.response.data.errorMessage, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            this.setState({ isLoading: false });
          }
        }
      );
  };

  handleSearch = (e) => {
    this.validator.showMessageFor(e.target.name);
    const value = e.target.value;
    this.setState((prevState) => ({
      ...prevState,
      [e.target.name]: value,
    }));
  };

  clearFilter = () => {
    this.myFormRef.reset();
    this.setState(
      {
        vendorType: "",
        vendorName: "",
      },
      this.loadVendors
    );
    /* this.loadVendors();
    this.loadMaster(); */
  };

  submitSearch = (e) => {
    e.preventDefault();
    let userData = getUserData();

    this.setState({ isLoading: true });
    fetch(
      apiListVendors +
        "?type=" +
        this.state.vendorType +
        "&name=" +
        this.state.vendorName +
        "&page=" +
        this.state.current,
      {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + userData.token,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          this.setState({
            vendorList: data.data.vendors.rows,
            countVendor: data.data.vendors.count,
          });
        } else {
          alert("something went wrong.");
        }
      });
  };

  handleChange = (e) => {
    this.validator.showMessageFor(e.target.name);
    const value = e.target.value;
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        [e.target.name]: value,
      },
    }));
  };

  handleBlur = (e) => {
    this.validator.showMessageFor(e.target.name);
  };

  submitForm = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      /* console.log("submit");
      console.log(this.state);
      console.log(getUserData()); */
      let userData = getUserData();
      if (!this.state.editItem) {
        let data = {
          name: this.state.fields.name,
          companyname: this.state.fields.companyname,
          gst: this.state.fields.gst,
          pan: this.state.fields.pan,
          phone: this.state.fields.phone,
          email: this.state.fields.email,
          address: this.state.fields.address,
          type: this.state.fields.type,
        };
        this.setState({ isLoading: true });
        axios
          .post(apiCreateVendor, data, {
            headers: {
              Authorization: "Bearer " + userData.token,
            },
          })
          .then(
            (response) => {
              //console.log(response);
              // this.setState({ isLoading: false });
              toast.info("Vendor created", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
              this.setState(this.initialState);
              this.handleCloseModal();
            },
            (error) => {
              if (
                error.response.status == 500 ||
                error.response.status == 400
              ) {
                toast.error(error.response.data.errorMessage, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                this.setState({ isLoading: false });
              }
            }
          );
      } else {
        let data = {
          name: this.state.fields.name,
          companyname: this.state.fields.companyname,
          gst: this.state.fields.gst,
          phone: this.state.fields.phone,
          email: this.state.fields.email,
          address: this.state.fields.address,
          pan: this.state.fields.pan,
          type: this.state.fields.type,
          id: this.state.editId,
        };
        this.setState({ isLoading: true });
        axios
          .post(apiEditVendor, data, {
            headers: {
              Authorization: "Bearer " + userData.token,
            },
          })
          .then(
            (response) => {
              //console.log(response);
              // this.setState({ isLoading: false });
              toast.info("Vendor updated", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
              this.setState(this.initialState);
              this.handleCloseModal();
            },
            (error) => {
              if (
                error.response.status == 500 ||
                error.response.status == 400
              ) {
                toast.error(error.response.data.errorMessage, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                this.setState({ isLoading: false });
              }
            }
          );
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  componentDidMount() {
    this.loadVendors();
    this.loadMaster();
  }

  render() {
    this.validator.purgeFields();
    return (
      <React.Fragment>
        <Helmet>
          <title>Contractors | MRC</title>
        </Helmet>
        <section className="admin">
          <AdminHeader></AdminHeader>
          <div className="wrapper d-flex">
            <AdminMenu></AdminMenu>

            <div className="wrapper_body">
              <div className="row d-flex align-items-center">
                <div className="col m0 d-flex align-items-center">
                  <h3 className="heading">Vendor &amp; Contractors</h3>
                </div>
                <div className="col m4 right-align">
                  <a
                    href="javascript:Void(0)"
                    className="theme_btn btn  modal-trigger"
                    onClick={this.handleOpenModal}
                  >
                    <i className="material-icons">add</i> New Contractor
                  </a>
                  <Modal
                    actions={[,]}
                    bottomSheet={false}
                    fixedFooter={false}
                    id="Modal-0"
                    open={this.state.isModalOpen}
                    className="cstm-modal"
                    options={{
                      dismissible: false,
                      endingTop: "10%",
                      inDuration: 250,
                      onCloseEnd: null,
                      onCloseStart: null,
                      onOpenEnd: null,
                      onOpenStart: null,
                      opacity: 0.5,
                      outDuration: 250,
                      preventScrolling: true,
                      startingTop: "4%",
                    }}
                  >
                    {/* Close Modal */}
                    <a
                      href="javascript:Void(0)"
                      className="modal-close"
                      onClick={this.handleCloseModal}
                    >
                      <span className="material-icons">chevron_right</span>
                    </a>
                    {/* Modal Content */}
                    <h3 className="heading">
                      {this.state.editItem
                        ? "Update Contractor"
                        : "New Contractor"}
                    </h3>
                    <p>
                      Enter details to {this.state.editItem ? "update" : "new"}{" "}
                      contractor
                    </p>
                    <form action id="createContractorForm">
                      <div className="row">
                        <div className="col s12">
                          <label className="label">Vendor Name</label>
                          <input
                            type="text"
                            placeholder="Vendor Name"
                            name="name"
                            value={this.state.fields.name}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                          />
                          <label className="error">
                            {this.validator.message(
                              "name",
                              this.state.fields.name,
                              "required"
                            )}
                          </label>
                        </div>
                        <div className="col s12">
                          <label className="label">Company Name</label>
                          <input
                            type="text"
                            placeholder="Company Name"
                            name="companyname"
                            value={this.state.fields.companyname}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                          />
                          <label className="error">
                            {this.validator.message(
                              "companyname",
                              this.state.fields.companyname,
                              "required"
                            )}
                          </label>
                        </div>
                        <div className="col s12">
                          <label className="label">GST</label>
                          <input
                            type="text"
                            placeholder="GST"
                            name="gst"
                            value={this.state.fields.gst}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                          />
                          <label className="error">
                            {this.validator.message(
                              "gst",
                              this.state.fields.gst,
                              "required"
                            )}
                          </label>
                        </div>
                        <div className="col s12">
                          <label className="label">PAN</label>
                          <input
                            type="text"
                            placeholder="PAN"
                            name="pan"
                            value={this.state.fields.pan}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                          />
                          <label className="error">
                            {this.validator.message(
                              "pan",
                              this.state.fields.pan,
                              "required"
                            )}
                          </label>
                        </div>
                        <div className="col m6 s12">
                          <label className="label">Email ID</label>
                          <input
                            type="email"
                            placeholder="Email ID"
                            name="email"
                            value={this.state.fields.email}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                          />
                          <label className="error">
                            {this.validator.message(
                              "email",
                              this.state.fields.email,
                              "required|email"
                            )}
                          </label>
                        </div>
                        <div className="col m6 s12">
                          <label className="label">Phone</label>
                          <input
                            type="text"
                            placeholder="Phone"
                            name="phone"
                            value={this.state.fields.phone}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                          />
                          <label className="error">
                            {this.validator.message(
                              "phone",
                              this.state.fields.phone,
                              "required|numeric"
                            )}
                          </label>
                        </div>
                        <div className="col s12">
                          <label className="label">Address</label>
                          <AutoComplete
                            apiKey={process.env.REACT_APP_GOOGLE_KEY}
                            onPlaceSelected={(place) => {
                              //console.log(place.formatted_address);
                              this.setLocation(place.formatted_address);
                            }}
                            defaultValue={this.state.fields.address}
                            name="address"
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                          />
                          {/* <input
                                type="text"
                                placeholder="Address"
                                name="address"
                                onBlur={this.form.handleBlurEvent}
                                onChange={this.form.handleChangeEvent}
                                value={this.state.fields.address}
                              /> */}
                          <label className="error">
                            {this.validator.message(
                              "address",
                              this.state.fields.address,
                              "required"
                            )}
                          </label>
                        </div>
                        <div className="col s12">
                          <label className="label">Type</label>
                          <select
                            name="type"
                            value={this.state.fields.type}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                          >
                            <option selected> Select</option>
                            {this.state.masterListVendor.map((type, index) => (
                              <>
                                <option value={type.name}>{type.name}</option>
                              </>
                            ))}
                          </select>
                          <label className="error">
                            {this.validator.message(
                              "type",
                              this.state.fields.type,
                              "required"
                            )}
                          </label>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col s12 right-align">
                          <button
                            type="submit"
                            form="createContractorForm"
                            className="theme_btn btn"
                            onClick={this.submitForm}
                          >
                            {this.state.editItem
                              ? "Update Contractor"
                              : "Add Contractor"}
                            {this.state.isLoading ? (
                              <ButtonLoader></ButtonLoader>
                            ) : null}
                          </button>
                        </div>
                      </div>
                    </form>
                  </Modal>
                </div>
              </div>
              <div className="projects employee">
                <div className="row">
                  <div className="col s12">
                    <p className="grey-text">
                      <b>Filters</b>
                    </p>
                    <form ref={(ele) => (this.myFormRef = ele)}>
                      <div className="row">
                        <div className="col m3 s12">
                          <input
                            type="text"
                            className=""
                            placeholder="Vendor Name"
                            name="vendorName"
                            onChange={this.handleSearch}
                          ></input>
                        </div>
                        <div className="col m3 s12">
                          <select
                            name="vendorType"
                            onChange={this.handleSearch}
                          >
                            <option selected>Select Type</option>
                            {this.state.masterListVendor.map(
                              (vendor, index) => (
                                <>
                                  <option value={vendor.name}>
                                    {vendor.name}
                                  </option>
                                </>
                              )
                            )}
                          </select>
                        </div>
                      </div>

                      <div className="row">
                        <div class="col m3 s12">
                          <a
                            href="javascript:Void(0)"
                            class="red-text pt-1"
                            onClick={this.clearFilter}
                          >
                            Clear Filter
                          </a>

                          <button
                            type="submit"
                            className="theme_btn btn filter-submit"
                            onClick={this.submitSearch}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="col s12">
                    <div className="table-responsive">
                      <table>
                        <thead>
                          <tr>
                            {/* <th>SNo.</th> */}
                            <th>Vendor Name</th>
                            <th>Company</th>
                            <th>GST</th>
                            <th>PAN</th>
                            <th>Type</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Address</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.isListLoading ? (
                            <tr>
                              <td colSpan={5}>
                                <BulletList></BulletList>
                              </td>
                            </tr>
                          ) : (
                            this.state.vendorList.map((vendor, index) => (
                              <>
                                <tr key={index}>
                                  {/* <td>{index + 1}</td> */}
                                  <td data-th="Name">{vendor.name}</td>
                                  <td data-th="Company Name">
                                    {vendor.companyname}
                                  </td>
                                  <td data-th="GST">{vendor.gst}</td>
                                  <td data-th="PAN">{vendor.pan}</td>
                                  <td data-th="Type">{vendor.type}</td>
                                  <td data-th="Email">{vendor.email}</td>
                                  <td data-th="Phone">{vendor.phone}</td>
                                  <td data-th="Address">{vendor.address}</td>
                                  <td>
                                    <a
                                      href="#Modal-0"
                                      className="actions"
                                      node="button"
                                      onClick={() => this.editVendor(vendor.id)}
                                    >
                                      <i className="material-icons">edit</i>
                                    </a>
                                    <a
                                      href="javascript:void(0)"
                                      className="actions"
                                      onClick={() => this.showAlert(vendor.id)}
                                    >
                                      <i className="material-icons">delete</i>
                                    </a>
                                  </td>
                                </tr>
                              </>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div
                    className="col s12"
                    style={{ marginTop: 20, marginBottom: 20 }}
                  >
                    {/* <Pagination
                      pageSize={30}
                      onChange={this.onChangePagination}
                      current={this.state.current}
                      total={this.state.countVendor}
                    /> */}
                    <Pagination
                      pageSize={this.state.pageSize}
                      onChange={this.onChangePagination}
                      current={this.state.current}
                      total={this.state.countVendor}
                      showSizeChanger
                      defaultPageSize={this.state.pageSize}
                      defaultCurrent={this.state.current}
                      onShowSizeChange={this.onChangePageSize}
                      locale={localeInfo}
                      selectComponentClass={Select}
                      pageSizeOptions={[10, 30, 50, 100]}
                      showTotal={(total, range) =>
                        `${range[0]} - ${range[1]} of ${total} items`
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.state.alert && (
            <SweetAlert
              onConfirm={this.deleteVendor}
              onCancel={this.hideAlert}
              focusCancelBtn={true}
              warning
              showCancel
              confirmBtnText="Yes, delete it!"
              confirmBtnBsStyle="danger"
              title="Are you sure?"
            />
          )}
          <Footer />
        </section>
        {/* Add Contractor Modal */}
      </React.Fragment>
    );
  }
}
