import "./App.css";
import { Switch, Route, Redirect } from "react-router-dom";
import { BrowserRouter } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import fetchIntercept from "fetch-intercept";

import Contractor from "./pages/admin/Contractor";
import Employee from "./pages/admin/Employee";
import PurchaseOrder from "./pages/admin/PurchaseOrder";
import Projects from "./pages/admin/Projects";
import Roles from "./pages/admin/Roles";
import Boq from "./pages/management/Boq";
// import Forget_Password from "./pages/users/Forget_Password";
import Login from "./pages/users/Login";
import Daybook from "./pages/users/Daybook";
import Drawings from "./pages/users/Drawings";
import Admin from "./pages/users/Admin";
import Dashboard from "./pages/users/Dashboard";
import Companies from "./pages/users/Companies";
import Vendor from "./pages/accounting/Vendor";
import ChangePasswordForm from "./pages/users/ChangePasswordForm";
import UpdateProfile from "./pages/users/UpdateProfile";
import CashAssign from "./pages/users/CashAssign";
import CashRecievables from "./pages/users/CashRecievables";
import EmployeeExpenses from "./pages/users/EmployeeExpenses";
import CashTransfer from "./pages/users/CashTransfer";
import { getUserData, logoutUser } from "./helpers/storage";
import Master from "./pages/users/Master";
import ItemMaster from "./pages/users/ItemMaster";
import BankMaster from "./pages/users/BankMaster";
import CompaniesArchived from "./pages/users/CompaniesArchived";
import EmpReceivables from "./pages/users/EmpReceivables";
import ExpenseHead from "./pages/users/ExpenseHead";
import ExpenseSubHead from "./pages/users/ExpenseSubHead";
import EmployeeDetails from "./pages/admin/EmployeeDetails";
import ProjectDashboard from "./pages/users/ProjectDashboard";
import Invoice from "./pages/users/Invoice";
import CreateInvoice from "./pages/users/CreateInvoice";
import ProjectPayment from "./pages/admin/ProjectPayment";
import DailyIncome from "./pages/admin/DailyIncome";
import Customer from "./pages/admin/Customer";
import RecurringPayments from "./pages/admin/RecurringPayments";
import BankGuarantee from "./pages/admin/BankGuarantee";
import Fdr from "./pages/admin/Fdr";
import GstMaster from "./pages/users/GstMaster";
import PoReceiving from "./pages/admin/PoReceiving";
import PoView from "./pages/admin/PoView";
import Settings from "./pages/users/Settings";
import PoTracking from "./pages/admin/PoTracking";
import PoPayments from "./pages/admin/PoPayments";

// For GET requests
axios.interceptors.request.use(
  (req) => {
    // Add configurations here
    //console.log("rajat request", req);
    return req;
  },
  (err) => {
    return Promise.reject(err);
  }
);

// For POST requests
axios.interceptors.response.use(
  (res) => {
    // Add configurations here
    //console.log("rajatbbb", res);
    if (res.status === 201) {
      console.log("Posted Successfully");
    } else if (res.status === 401) {
      logoutUser();
      return <Redirect to="/" />;
    }

    return res;
  },
  (err) => {
    return Promise.reject(err);
  }
);

/* const unregister = fetchIntercept.register({ */
fetchIntercept.register({
  response: function (response) {
    // Modify the reponse object
    //console.log("fetch interceptopr rajat res", response);
    if (response.status === 401) {
      logoutUser();
      return <Redirect to="/" />;
    }
    return response;
  },
});

export const PrivateRoute = ({ component: Component, ...rest }) => {
  let userData = getUserData();

  let isLoggedIn = userData.isLoggedIn;
  //console.log(isLoggedIn);
  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? (
          <Component key={props.match.params.id || "empty"} {...props} />
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }
    />
  );
};

function App() {
  return (
    <>
      <BrowserRouter basename={"/"}>
        {/* <BrowserRouter> */}
        <Switch>
          <Route path="/" exact>
            <Login />
          </Route>

          <PrivateRoute exact path="/admin" component={Admin} />
          <PrivateRoute exact path="/companies" component={Companies} />
          <PrivateRoute exact path="/daybook/:projectid" component={Daybook} />

          <Route path="/Contractor">
            <Contractor />
          </Route>

          <PrivateRoute exact path="/employees" component={Employee} />
          <PrivateRoute exact path="/settings" component={Settings} />
          <PrivateRoute
            exact
            path="/purchase-order"
            component={PurchaseOrder}
          />
          <PrivateRoute exact path="/poview/:id" component={PoView} />
          <PrivateRoute
            exact
            path="/po-receiving/:id"
            component={PoReceiving}
          />
          <PrivateRoute exact path="/po-tracking" component={PoTracking} />
          <PrivateRoute exact path="/po-payments/:id" component={PoPayments} />
          <PrivateRoute exact path="/projects" component={Projects} />

          <Route path="/Roles">
            <Roles />
          </Route>

          <Route path="/Boq">
            <Boq />
          </Route>

          <PrivateRoute exact path="/dashboard" component={Dashboard} />
          <Route exact path="/project/:id" component={ProjectDashboard} />

          <PrivateRoute
            exact
            path="/companiesarchived"
            component={CompaniesArchived}
          />

          <Route path="/Vendor">
            <Vendor />
          </Route>

          <Route path="/ChangePasswordForm">
            <ChangePasswordForm />
          </Route>

          <Route path="/Master">
            <Master />
          </Route>

          <Route path="/itemmaster">
            <ItemMaster />
          </Route>

          <Route path="/bankmaster">
            <BankMaster />
          </Route>

          <Route path="/gstmaster">
            <GstMaster />
          </Route>

          <PrivateRoute
            exact
            path="/cashassign/:projectid"
            component={CashAssign}
          />
          <PrivateRoute
            exact
            path="/recievables/:projectid"
            component={CashRecievables}
          />

          <PrivateRoute
            exact
            path="/employeeexpenses"
            component={EmployeeExpenses}
          />
          <PrivateRoute
            exact
            path="/cashtransfer/:projectid"
            component={CashTransfer}
          />

          <Route path="/empreceivables">
            <EmpReceivables />
          </Route>

          <PrivateRoute exact path="/expensehead" component={ExpenseHead} />
          <PrivateRoute
            exact
            path="/expensesubhead"
            component={ExpenseSubHead}
          />

          <Route exact path="/employeedetails/:id">
            <EmployeeDetails />
          </Route>

          <Route path="/UpdateProfile">
            <UpdateProfile />
          </Route>
          <Route exact path="/invoice" component={Invoice} />
          <Route exact path="/createinvoice" component={CreateInvoice} />
          <Route exact path="/editinvoice/:id" component={CreateInvoice} />
          <Route exact path="/projectpayment/" component={ProjectPayment} />
          <Route
            exact
            path="/recurringpayments"
            component={RecurringPayments}
          />
          <Route exact path="/bankguarantee" component={BankGuarantee} />
          <Route exact path="/fdr" component={Fdr} />
          <Route exact path="/dailyincome/:projectid" component={DailyIncome} />
          <Route exact path="/customer" component={Customer} />
        </Switch>
      </BrowserRouter>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default App;
