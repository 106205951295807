import React, { Component } from "react";

import ButtonLoader from "../../components/ButtonLoader";
import axios from "axios";
import Footer from "../../components/Footer";
import ProjectHeader from "../../components/header/ProjectHeader";
import Sidebar from "../../components/sidebar/Sidebar";
import ProjectMenu from "../../components/sidebar/ProjectMenu";
import { toast } from "react-toastify";
import { Modal } from "react-materialize";
import { setUserData, getUserData, getProjectID } from "../../helpers/storage";
import SweetAlert from "react-bootstrap-sweetalert";
import { BulletList } from "react-content-loader";
import SimpleReactValidator from "simple-react-validator";
import { Helmet } from "react-helmet";

import {
  apiCreateExpenseHead,
  apiListExpenseHeads,
  apiGetExpenseHead,
  apiListExpenseHeadsParents,
  apiListExpenseSubHeads,
  apiListDaybook,
  apigetDaybook,
  apiDaybookExpenseStatus,
  apiDaybookExpenseLog,
  apiEditExpenseHead,
  apiDeleteExpenseHead,
  apiListVendors,
  apiListProjects,
  apiDaybookCreate,
  apiDaybookEdit,
} from "../../config/endpoints";
const initialState = {
  fields: {
    time: "",
    date: "",
    project: "",
    head: "",
    subhead: "",
    vendor: "",
    billno: "",
    amount: "",
    paymentmode: "",
    remarks: "",
  },
  isLoading: false,
  redirect: false,
  errors: {},
  expenseHeadList: [],
  isModalOpen: false,
  isViewModalOpen: false,
  isListLoading: false,
  alert: false,
  deleteId: "",
  editId: false,
  editItem: false,
  expenseHeadParentsList: [],
  expenseSubHeadList: [],
  vendorList: [],
  projectList: [],
  isRejected: false,
};
export default class Daybook extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.fileChange = this.fileChange.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
  }
  changeStatus = () => {
    let data = {
      id: this.state.statusId,
      type: this.state.statustype,
    };
  };
  showAlert = (id, type) => {
    console.log(id);
    this.setState({
      alert: true,
      statusId: id,
      statustype: type,
    });
  };
  hideAlert = () => {
    this.setState({
      alert: false,
    });
  };
  handleCloseModal = () => {
    this.setState(initialState);
    // this.loadExpenseHeads();
    this.loadMasterData();
    this.validator.hideMessages();
  };
  handleOpenModal = () => {
    //console.log("hey");
    this.loadMasterData();
    this.setState({ isModalOpen: true });
    this.validator.hideMessages();
  };
  handleOpenViewModal = (id) => {
    this.getDaybookExpenseLog(id);
    this.setState({ isViewModalOpen: true });
  };
  handleCloseViewModal = () => {
    this.setState({ isViewModalOpen: false });
  };
  fileChange = (e) => {
    // Assuming only image
    console.log("test", e);
    if (e.target.files[0]) {
      console.log("picture: ", e.target.files);
      this.setState({ bill: e.target.files[0] });
    }
    // Updating the city
  };
  handleChange = (e) => {
    console.log(e.target.name, e.target.value);
    this.validator.showMessageFor(e.target.name);
    const value = e.target.value;
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        [e.target.name]: value,
      },
    }));
    if (e.target.name == "head") {
      let userData = getUserData();
      fetch(apiListExpenseSubHeads + "?id=" + value, {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + userData.token,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.code == 200) {
            this.setState({
              expenseSubHeadList: data.data.expensehead,
            });
          } else {
            alert("something went wrong.");
          }
        });
    }
    if (e.target.name == "type" && e.target.value == "2") {
      this.setState({ isRejected: true });
    }
    console.log(this.state);
  };
  handleSearch = (e) => {
    this.validator.showMessageFor(e.target.name);
    const value = e.target.value;
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        [e.target.name]: value,
      },
    }));
  };
  handleBlur = (e) => {
    //console.log(e);
    this.validator.showMessageFor(e.target.name);
  };
  editMaster(id) {
    // Assuming only image
    this.setState({ editItem: true, editId: id });
    this.handleOpenModal();
    let userData = getUserData();
    let projectId = getProjectID();
    fetch(apigetDaybook + "?id=" + id + "?project_id=" + projectId, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          // this.setState({
          //   projectData: data.data.projects.rows,
          // });
          // console.log(this.state.projectList);
          this.setState({
            fields: data.data.dayBook,
          });
          fetch(apiListExpenseSubHeads + "?id=" + data.data.dayBook.head, {
            method: "get",
            headers: new Headers({
              Authorization: "Bearer " + userData.token,
            }),
          })
            .then((response) => response.json())
            .then((data) => {
              if (data.code == 200) {
                this.setState({
                  expenseSubHeadList: data.data.expensehead,
                });
              } else {
                alert("something went wrong.");
              }
            });
        } else {
          alert("something went wrong.");
        }
        // setTimeout(function () {
      });
    fetch(apiDaybookExpenseLog + "?id=" + id + "?project_id=" + projectId, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          this.setState({
            logs: data.data.log,
          });
          console.log(this.state.logs);
        } else {
          alert("something went wrong.");
        }
      });
    // Updating the city
  }
  getDaybookExpenseLog(id) {
    let userData = getUserData();
    let projectId = getProjectID();
    fetch(apiDaybookExpenseLog + "?id=" + id + "?project_id=" + projectId, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          this.setState({
            logs: data.data.log,
          });
          console.log(this.state.logs);
        } else {
          alert("something went wrong.");
        }
      });
  }
  submitSearch = (e) => {
    e.preventDefault();
    let userData = getUserData();
    let data = {
      from: this.state.fields.from,
      to: this.state.fields.to,
      paymentmode: this.state.fields.paymentmode,
      vendor: this.state.fields.vendor,
    };
    this.setState({ isLoading: true });
    fetch(
      apiListDaybook +
        "?vendor=" +
        this.state.fields.vendor +
        "&project=" +
        this.state.fields.project +
        "&from=" +
        (this.state.fields.from != undefined ? this.state.fields.from : "") +
        "&to=" +
        (this.state.fields.to != undefined ? this.state.fields.to : "") +
        "&paymentmode=" +
        this.state.fields.paymentmode,
      {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + userData.token,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          this.setState({
            dayBook: data.data.dayBook,
          });
          //console.log(this.state.dayBook);
        } else {
          alert("something went wrong.");
        }
        // setTimeout(function () {
      });
  };
  submitForm = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      console.log("submit");
      let userData = getUserData();
      if (!this.state.editItem) {
        var bodyFormData = new FormData();
        bodyFormData.append("amount", this.state.fields.amount);
        bodyFormData.append("billno", this.state.fields.billno);
        bodyFormData.append("date", this.state.fields.date);
        bodyFormData.append("head", this.state.fields.head);
        bodyFormData.append("paymentmode", this.state.fields.paymentmode);
        bodyFormData.append("project", this.state.fields.project);
        bodyFormData.append("remarks", this.state.fields.remarks);
        bodyFormData.append("subhead", this.state.fields.subhead);
        bodyFormData.append("time", this.state.fields.time);
        bodyFormData.append("vendor", this.state.fields.vendor);
        bodyFormData.append("bill", this.state.bill);

        this.setState({ isLoading: true });
        axios
          .post(apiDaybookCreate, bodyFormData, {
            headers: {
              Authorization: "Bearer " + userData.token,
            },
          })
          .then(
            (response) => {
              console.log(response);
              this.setState({ isLoading: false });
              toast.info("Record created", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
              this.setState(this.initialState);
              this.handleCloseModal();
            },
            (error) => {
              if (
                error.response.status == 500 ||
                error.response.status == 400
              ) {
                toast.error(error.response.data.errorMessage, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                this.setState({ isLoading: false });
              }
            }
          );
      } else {
        let data = {
          amount: this.state.fields.amount,
          billno: this.state.fields.billno,
          date: this.state.fields.date,
          head: this.state.fields.head,
          paymentmode: this.state.fields.paymentmode,
          project: this.state.fields.project,
          remarks: this.state.fields.remarks,
          subhead: this.state.fields.subhead,
          time: this.state.fields.time,
          vendor: this.state.fields.vendor,
          id: this.state.editId,
          type: this.state.fields.type,
          statusremarks: this.state.fields.statusremarks,
        };
        this.setState({ isLoading: true });
        axios
          .post(apiDaybookEdit, data, {
            headers: {
              Authorization: "Bearer " + userData.token,
            },
          })
          .then(
            (response) => {
              console.log(response);
              this.setState({ isLoading: false });
              toast.info("Record updated", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
              this.setState(this.initialState);
              this.handleCloseModal();
            },
            (error) => {
              if (
                error.response.status == 500 ||
                error.response.status == 400
              ) {
                toast.error(error.response.data.errorMessage, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                this.setState({ isLoading: false });
              }
            }
          );
      }
    } else {
      console.log("fail");
      console.log("errors", this.validator.getErrorMessages());
      this.validator.showMessages();
      this.forceUpdate();
    }
  };
  loadMasterData = (e) => {
    let userData = getUserData();
    let projectId = getProjectID();
    this.setState({ isListLoading: true });
    fetch(apiListDaybook + "?project_id=" + projectId, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          this.setState({
            dayBook: data.data.dayBook,
          });
          //console.log(this.state.dayBook);
        } else {
          alert("something went wrong.");
        }
        // setTimeout(function () {
      });

    fetch(apiListExpenseHeadsParents, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          this.setState({
            expenseHeadParentsList: data.data.expensehead,
          });
        } else {
          alert("something went wrong.");
        }
      });

    fetch(apiListVendors, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          this.setState({
            vendorList: data.data.vendors.rows,
          });
        } else {
          alert("something went wrong.");
        }
      });

    fetch(apiListProjects, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          this.setState({
            projectList: data.data.projects.rows,
            isListLoading: false,
          });
        } else {
          alert("something went wrong.");
        }
      });
  };
  componentWillMount() {
    this.loadMasterData();
    console.log("projectId inside Employee Expense", getProjectID());
  }
  render() {
    this.validator.purgeFields();
    return (
      <React.Fragment>
        <Helmet>
          <title>Employe Expense | MRC</title>
        </Helmet>
        <section className='adminProject'>
          <div className='wrapper d-flex'>
            <Sidebar />
            <div className='wrapper_right'>
              <ProjectHeader />
              <div className='d-flex'>
                <ProjectMenu />
                <div className='wrapper_body'>
                  <div className='row'>
                    <div className='col m8 s12'>
                      <h3 className='heading'>All Expense</h3>
                    </div>
                    <div className='col m4 right-align'>
                      <a
                        href='javascript:Void(0)'
                        className='theme_btn btn '
                        onClick={this.handleOpenModal}
                      >
                        <i className='material-icons'>add&nbsp;</i>Create New
                        Form
                      </a>
                      <Modal
                        actions={[,]}
                        bottomSheet={false}
                        fixedFooter={false}
                        id='Modal-0'
                        open={this.state.isModalOpen}
                        className='cstm-modal'
                        options={{
                          dismissible: false,
                          endingTop: "10%",
                          inDuration: 250,
                          onCloseEnd: null,
                          onCloseStart: null,
                          onOpenEnd: null,
                          onOpenStart: null,
                          opacity: 0.5,
                          outDuration: 250,
                          preventScrolling: true,
                          startingTop: "4%",
                        }}
                      >
                        {/* Close Modal */}
                        <a
                          href='javascript:Void(0)'
                          className='modal-close'
                          onClick={this.handleCloseModal}
                        >
                          <span className='material-icons'>chevron_right</span>
                        </a>
                        {/* Modal Content */}
                        <h3 className='heading'>Daybook Form</h3>
                        <p>Enter details to create new list</p>
                        <form action id='createListForm'>
                          <div className='row'>
                            <div className='col s12'>
                              <label className='label'>Entry Time</label>
                              <input
                                type='time'
                                placeholder='Time'
                                name='time'
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                                value={this.state.fields.time}
                              />
                              <label className='error'>
                                {this.validator.message(
                                  "time",
                                  this.state.fields.time,
                                  "required"
                                )}
                              </label>
                            </div>
                            <div className='col s12'>
                              <label className='label'>Project</label>
                              <select
                                name='project'
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                                value={this.state.fields.project}
                              >
                                <option>Select</option>
                                {this.state.projectList.map(
                                  (project, index) => (
                                    <>
                                      <option value={project.id}>
                                        {project.name}
                                      </option>
                                    </>
                                  )
                                )}
                              </select>
                              <label className='error'>
                                {this.validator.message(
                                  "project",
                                  this.state.fields.project,
                                  "required"
                                )}
                              </label>
                            </div>
                            <div className='col s12'>
                              <label className='label'>Date</label>
                              <input
                                type='date'
                                placeholder='Purchase Date'
                                name='date'
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                                value={this.state.fields.date}
                              />
                              <label className='error'>
                                {this.validator.message(
                                  "date",
                                  this.state.fields.date,
                                  "required"
                                )}
                              </label>
                            </div>
                            <div className='col s12'>
                              <label className='label'>Head</label>
                              <select
                                name='head'
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                                value={this.state.fields.head}
                              >
                                <option selected>Select</option>
                                {this.state.expenseHeadParentsList.map(
                                  (expenseHead, index) => (
                                    <>
                                      <option value={expenseHead.id}>
                                        {expenseHead.name}
                                      </option>
                                    </>
                                  )
                                )}
                              </select>
                              <label className='error'>
                                {this.validator.message(
                                  "head",
                                  this.state.fields.head,
                                  "required"
                                )}
                              </label>
                            </div>
                            <div className='col s12'>
                              <label className='label'>Sub Head</label>
                              <select
                                name='subhead'
                                onChange={this.handleChange}
                                value={this.state.fields.subhead}
                              >
                                <option selected>Select</option>
                                {this.state.expenseSubHeadList.map(
                                  (expenseHead, index) => (
                                    <>
                                      <option value={expenseHead.id}>
                                        {expenseHead.name}
                                      </option>
                                    </>
                                  )
                                )}
                              </select>
                              {/* <label className="error">
                                {this.validator.message(
                                  "subhead",
                                  this.state.fields.name,
                                  "required"
                                )}
                              </label> */}
                            </div>
                            <div className='col s12'>
                              <label className='label'>Vendor</label>
                              <select
                                name='vendor'
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                                value={this.state.fields.vendor}
                              >
                                <option selected>Select</option>
                                {this.state.vendorList.map((vendor, index) => (
                                  <>
                                    <option value={vendor.id}>
                                      {vendor.name}
                                    </option>
                                  </>
                                ))}
                              </select>
                              <label className='error'>
                                {this.validator.message(
                                  "vendor",
                                  this.state.fields.vendor,
                                  "required"
                                )}
                              </label>
                            </div>
                            <div className='col m6 s12'>
                              <label className='label'>Bill No.</label>
                              <input
                                type='text'
                                placeholder='Bill number'
                                name='billno'
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                                value={this.state.fields.billno}
                              />
                              <label className='error'>
                                {this.validator.message(
                                  "billno",
                                  this.state.fields.billno,
                                  "required"
                                )}
                              </label>
                            </div>

                            <div className='col m6 s12'>
                              <label className='label'>Amount</label>
                              <input
                                type='text'
                                placeholder='Amount'
                                name='amount'
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                                value={this.state.fields.amount}
                              />
                              <label className='error'>
                                {this.validator.message(
                                  "amount",
                                  this.state.fields.amount,
                                  "required"
                                )}
                              </label>
                              <span className='icon'>
                                <i />
                              </span>
                            </div>

                            <div className='col s12'>
                              <label className='label'>Payment Mode</label>
                              <select
                                name='paymentmode'
                                onChange={this.handleChange}
                                value={this.state.fields.paymentmode}
                              >
                                <option selected>Select</option>
                                <option value={1}>Cash</option>
                                <option value={2}>Bank</option>
                              </select>
                              {/* <label className="error">
                                {this.validator.message(
                                  "paymentmode",
                                  this.state.fields.paymentmode,
                                  "required"
                                )}
                              </label> */}
                            </div>
                            <div className='col m6 s12'>
                              <label className='label'>Bill Upload</label>
                              <div className='button-wrap col  s12'>
                                <label className='new-button' for='upload'>
                                  Bill Upload
                                  <input
                                    id='upload'
                                    onChange={this.fileChange}
                                    type='file'
                                    name='bill'
                                  />
                                </label>
                              </div>
                            </div>
                            <div className='col  s12'>
                              <label className='label'>Remarks</label>
                              <input
                                type='text'
                                placeholder='Remarks'
                                name='remarks'
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                                value={this.state.fields.remarks}
                              />
                              <label className='error'>
                                {this.validator.message(
                                  "remarks",
                                  this.state.fields.remarks,
                                  "required"
                                )}
                              </label>
                              <span className='icon'>
                                <i />
                              </span>
                            </div>
                            <div className='col s12'>
                              <label className='label'>Status</label>
                              <select
                                name='type'
                                onChange={this.handleChange}
                                value={this.state.fields.type}
                              >
                                <option selected>Select</option>
                                <option value='1'>Approve</option>
                                <option value='2'>Reject</option>
                              </select>
                            </div>
                            {this.state.isRejected ? (
                              <div className='col s12'>
                                <label className='label'>Reason</label>
                                <input
                                  type='text'
                                  name='statusremarks'
                                  onChange={this.handleChange}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                            <div className='row'>
                              <div className='col s12 right-align'>
                                <button
                                  type='submit'
                                  className='theme_btn btn'
                                  onClick={this.submitForm}
                                >
                                  {this.state.editItem
                                    ? "Update Record"
                                    : "Add Record"}
                                  {this.state.isLoading ? (
                                    <ButtonLoader></ButtonLoader>
                                  ) : null}
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                        <p>Activity Logs</p>
                        <table>
                          {this.state.logs
                            ? this.state.logs.map((lg, index) => (
                                <tr>
                                  <td>{lg.log}</td>
                                </tr>
                              ))
                            : null}
                        </table>
                      </Modal>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col s12'>
                      <div className='table-responsive'>
                        <table>
                          <thead>
                            <tr>
                              <th>Entry Time</th>
                              <th>Employee</th>
                              <th>Project</th>
                              <th>Head</th>
                              <th>Sub Head</th>
                              <th>Bill No.</th>
                              <th>Amount</th>
                              <th>Type</th>
                              <th>Bill</th>
                              <th>Vendor</th>
                              <th>Status</th>
                              <th>Log</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.isListLoading ? (
                              <tr>
                                <td colSpan={5}>
                                  <BulletList></BulletList>
                                </td>
                              </tr>
                            ) : this.state.dayBook &&
                              this.state.dayBook.count > 0 ? (
                              this.state.dayBook.rows.map((db, index) => (
                                <>
                                  <tr key={index}>
                                    <td data-th='Name'>
                                      {db.date} {db.time}
                                    </td>
                                    <td data-th='Project'>
                                      {db.employees.firstName}
                                    </td>
                                    <td data-th='Project'>
                                      {db.projects.name}
                                    </td>
                                    <td data-th='Head'>{db.heads.name}</td>
                                    <td data-th='Subhead'>
                                      {db.subheads.name}
                                    </td>
                                    <td data-th='Billno'>{db.billno}</td>
                                    <td data-th='Amount'>
                                      <i class='fas fa-rupee-sign'></i>{" "}
                                      {db.amount}
                                    </td>
                                    <td data-th='Type'>
                                      {db.paymentmode == 1 ? "Cash" : "Bank"}
                                    </td>
                                    <td data-th='Bill'>
                                      {db.bill ? (
                                        <a href={db.bill} target='_blank'>
                                          View
                                        </a>
                                      ) : (
                                        "-"
                                      )}
                                    </td>
                                    <td data-th='Vendor'>{db.vendors.name}</td>
                                    <td data-th='Status'>
                                      {db.type == 1 ? (
                                        <b className='green-text'>Approved</b>
                                      ) : db.type == 2 ? (
                                        <b className='red-text'>Rejected</b>
                                      ) : (
                                        <b className='amber-text'>Pending</b>
                                      )}
                                    </td>
                                    <td>
                                      <Modal
                                        bottomSheet={false}
                                        fixedFooter={false}
                                        id='Modal-1'
                                        open={this.state.isViewModalOpen}
                                        className='cstm-modal'
                                        options={{
                                          dismissible: false,
                                          endingTop: "10%",
                                          inDuration: 250,
                                          onCloseEnd: null,
                                          onCloseStart: null,
                                          onOpenEnd: null,
                                          onOpenStart: null,
                                          opacity: 0.2,
                                          outDuration: 250,
                                          preventScrolling: true,
                                          startingTop: "4%",
                                        }}
                                      >
                                        {/* Close Modal */}
                                        <a
                                          href='javascript:Void(0)'
                                          className='modal-close'
                                          onClick={this.handleCloseViewModal}
                                        >
                                          <span className='material-icons'>
                                            chevron_right
                                          </span>
                                        </a>
                                        {/* Modal Content */}
                                        <h3 className='heading'>
                                          Activity Logs
                                        </h3>
                                        <p>
                                          Details of the actions in this entry
                                        </p>
                                        <table>
                                          {this.state.logs
                                            ? this.state.logs.map(
                                                (lg, index) => (
                                                  <tr>
                                                    <td>{lg.log}</td>
                                                  </tr>
                                                )
                                              )
                                            : null}
                                        </table>
                                      </Modal>
                                      <a
                                        href='javascript:void(0)'
                                        className='actions'
                                        node='button'
                                        onClick={() =>
                                          this.handleOpenViewModal(db.id)
                                        }
                                      >
                                        View
                                      </a>
                                    </td>
                                    <td>
                                      {db.type != 1 ? (
                                        <a
                                          href='#Modal-0'
                                          className='actions'
                                          node='button'
                                          onClick={() => this.editMaster(db.id)}
                                        >
                                          <i className='material-icons'>edit</i>
                                        </a>
                                      ) : null}
                                    </td>
                                  </tr>
                                </>
                              ))
                            ) : (
                              ""
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.state.alert && (
            <SweetAlert
              onConfirm={this.changeStatus}
              onCancel={this.hideAlert}
              focusCancelBtn={true}
              warning
              showCancel
              confirmBtnText='Yes'
              confirmBtnBsStyle='danger'
              title='Are you sure?'
            />
          )}
          <Footer />
        </section>
        {/* Create_new_project_modal Modal */}
      </React.Fragment>
    );
  }
}
