import React, { Component } from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";
import SideBar from "../SideBar";
import SuperAdminHeader from "../../components/header/SuperAdminHeader";

import axios from "axios";
import { toast } from "react-toastify";
import "materialize-css";
import { getUserData } from "../../helpers/storage";
import { Dropdown } from "react-materialize";
import {
    apiCreateCompany,
    apiListCompanies,
    apiGetCompany,
    apiDeleteCompany,
    apiEditCompany,
    apiGetCompanyArchived,
} from "../../config/endpoints";
import { Helmet } from "react-helmet";
import ReactFormInputValidation from "react-form-input-validation";
import Header from "../Header";
import SweetAlert from "react-bootstrap-sweetalert";
import { BulletList } from "react-content-loader";

const initialState = {
    /* etc */
    fields: {
        name: "",
        ownerName: "",
        address: "",
        website: "",
        email: "",
        password: "",
        confirmPassword: "",
    },
    logo: false,
    isLoading: false,
    redirect: false,
    errors: {},
    companyList: [],
    alert: false,
    editItem: false,
    isModalOpen: false,
    isListLoading: false,
    deleteId: false,
    editId: false,
    logoImage: false,
};

export default class CompaniesArchived extends Component {
    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.state = initialState;
    }

    componentWillMount() {
        this.loadCompanies();
    }
    loadCompanies = (e) => {
        this.setState({ isListLoading: true });
        let userData = getUserData();
        fetch(apiGetCompanyArchived, {
            method: "get",
            headers: new Headers({
                Authorization: "Bearer " + userData.token,
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data.data.companies.rows);
                if (data.code == 200) {
                    this.setState({
                        companyList: data.data.companies.rows,
                    });
                    // console.log(this.state.companyList);
                    this.setState({ isListLoading: false });
                } else {
                    alert("something went wrong.");
                }
                // setTimeout(function () {
            });
    };

    render() {
        // console.log("state", this.state.companyList);
        return (
            <React.Fragment>
                <Helmet>
                    <title>Companies | MRC</title>
                </Helmet>
                <section className="super-admin">
                    <SuperAdminHeader></SuperAdminHeader>

                    <div className="wrapper">
                        <div className="row d-flex align-items-center">
                            <div className="col m0 d-flex align-items-center">
                                <Link className="d-flex" to="/admin">
                                    <img
                                        src="assets/images/super-admin/back-ico.svg"
                                        width={30}
                                    />
                                </Link>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <h3 className="heading mb-0">
                                    Archived Companies
                                </h3>
                            </div>
                            <div className="col m4 right-align">
                                <Link
                                    className="theme_btn btn waves-effect waves-light"
                                    to="/Companies"
                                >
                                    <i className="material-icons">visibility</i>{" "}
                                    View Companies
                                </Link>
                            </div>
                        </div>

                        <div className="company-listing">
                            <div className="row">
                                {this.state.isListLoading ? (
                                    <BulletList></BulletList>
                                ) : (
                                    this.state.companyList.map(
                                        (company, index) => (
                                            <div
                                                className="col m4 s12"
                                                key={index}
                                            >
                                                <div className="outer d-flex">
                                                    <div className="image">
                                                        {company.logo != "" ? (
                                                            <img
                                                                src={
                                                                    company.logo
                                                                }
                                                                alt="Project Thumbnail"
                                                            />
                                                        ) : (
                                                            <img
                                                                src="assets/images/admin/project-photo.png"
                                                                alt="Project Thumbnail"
                                                            />
                                                        )}
                                                    </div>
                                                    <div>
                                                        <div className="action_toggle">
                                                            {/* <Dropdown
                              id={"editmenu_" + index}
                              className="dropdown-content"
                              options={{
                                alignment: "left",
                                autoTrigger: true,
                                closeOnClick: true,
                                constrainWidth: false,
                                container: null,
                                coverTrigger: true,
                                hover: false,
                                inDuration: 150,
                                onCloseEnd: null,
                                onCloseStart: null,
                                onOpenEnd: null,
                                onOpenStart: null,
                                outDuration: 250,
                              }}
                              trigger={
                                <a
                                  href="JavaScript:void(0)"
                                  className="right-align action-trigger"
                                  data-target="action_dropdown"
                                >
                                  <i className="material-icons">more_vert</i>
                                </a>
                              }
                            >
                              <a
                                href="#Modal-0"
                                className="actions"
                                node="button"
                                onClick={() => this.editCompany(company.id)}
                              >
                                <i className="material-icons">edit</i>
                              </a>
                              <a
                                href="javascript:void(0)"
                                className="actions"
                                onClick={() => this.showAlert(company.id)}
                              >
                                <i className="material-icons">delete</i>
                              </a>
                            </Dropdown> */}
                                                        </div>
                                                        <h5>
                                                            <a href="#!">
                                                                {company.name}
                                                            </a>
                                                        </h5>
                                                        <hr />
                                                        <p>
                                                            {company.ownerName}
                                                        </p>
                                                        <a href="mailto:{company.user.email}">
                                                            {company.user.email}
                                                        </a>
                                                        {/* <a href="mailto:info@cityspace.com">
                            info@cityspace.com
                          </a> */}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    )
                                )}
                            </div>
                        </div>
                    </div>

                    {this.state.alert && (
                        <SweetAlert
                            onConfirm={this.deleteCompany}
                            onCancel={this.hideAlert}
                            focusCancelBtn={true}
                            warning
                            showCancel
                            confirmBtnText="Yes, delete it!"
                            confirmBtnBsStyle="danger"
                            title="Are you sure?"
                        />
                    )}
                    <Footer />

                    {/* Create New Company Modal */}
                </section>
            </React.Fragment>
        );
    }
}
