import React, { Component, Fragment } from "react";
import Footer from "../../components/Footer";
import AdminHeader from "../../components/header/AdminHeader";
import AdminMenu from "../../components/sidebar/AdminMenu";
import ButtonLoader from "../../components/ButtonLoader";
import axios from "axios";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "materialize-css";
import { Modal, Badge } from "react-materialize";
import { getUserData } from "../../helpers/storage";
import SweetAlert from "react-bootstrap-sweetalert";
import SimpleReactValidator from "simple-react-validator";
import Pagination from "rc-pagination";
import Select from "rc-select";
import "rc-select/assets/index.less";
import "../../assets/pagination.less";
import localeInfo from "../../helpers/en_US";
import SignaturePad from "react-signature-pad-wrapper";
import { formatAmount, customStyles } from "../../helpers/util";
import Moment from "react-moment";
import {
  apiGetPo,
  apiListDaybook,
  apiEditPo,
  apiGetSettings,
} from "../../config/endpoints";
import { Helmet } from "react-helmet";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const initialState = {
  fields: {
    vendor: "",
    ponumber: "",
    date: "",
    customer: 0,
    billinggst: "",
    project: 0,
    shipname: "",
    shipaddress: "",
    shipgst: "",
    shipstate: "",
    shipstatecode: "",
    vendor: "",
    particulars: "",
    total: "",
    totaltax: "",
    grandtotal: "",
    remarks: "",
    terms: "",
  },
  searchFields: { ponumber: "", project: "", from: "", to: "" },
  inputFields: [
    {
      item: "",
      particulars: "",
      hsn: "",
      qty: 1,
      gst: "",
      amount: 0,
      total: 0,
      tax: "",
      notes: "",
    },
  ],
  selectedItems: [],
  selectedPo: { ponumber: "" },
  vendorList: [],
  customerList: [],
  itemMasterList: [],
  gstMasterList: [],
  projectList: [],
  poList: [],
  poPayments: [],
  totaltax: [],
  grandtotal: [],
  subtotal: 0,
  grandtotalFinal: 0,
  isLoading: false,
  redirect: false,
  errors: {},
  isModalOpen: false,
  isListLoading: false,
  alert: false,
  deleteId: "",
  editId: false,
  editItem: false,
  duplicateItem: false,
  duplicateId: false,
  passId: false,
  poPaymentsCount: 0,
  pageSize: 30,
  current: 1,
};

export default class PoPayments extends Component {
  sigPad = {};
  constructor(props) {
    super(props);
    this.state = initialState;
    this.selectInputRef = React.createRef();
  }

  onChangePagination = (page) => {
    this.setState((prevState) => ({
      ...prevState,
      current: page,
    }));
    this.loadMaster();
  };

  onChangePageSize = (current, pageSize) => {
    this.setState((prevState) => ({
      ...prevState,
      current: current,
      pageSize: pageSize,
    }));
    this.loadMaster();
  };

  onShowSizeChange = (current, pageSize) => {
    console.log(current);
    this.setState({ pageSize });
  };

  loadMaster = (e) => {
    let userData = getUserData();

    const str = window.location.pathname;
    const poId = str.substring(str.lastIndexOf("/") + 1, str.length);
    fetch(apiGetPo + "?id=" + poId, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          // console.log(data.data.vendors);
          this.setState((prevState) => ({
            selectedPo: data.data.po,
            selectedPoParticulars: data.data.po
              ? JSON.parse(data.data.po.particulars)
              : [],
          }));
          this.setState({ isLoading: false });
          console.log(this.state.selectedPo);
        } else {
          alert("something went wrong.");
          this.setState({ isLoading: false });
        }
      });

    fetch(
      apiListDaybook +
        "?po=" +
        poId +
        "&page=" +
        this.state.current +
        "&pagesize=" +
        this.state.pageSize,
      // "&vendor=" +
      // this.state.fields.vendor +
      // "&from=" +
      // (this.state.fields.from != undefined ? this.state.fields.from : "") +
      // "&to=" +
      // (this.state.fields.to != undefined ? this.state.fields.to : "") +
      // "&paymentmode=" +
      // this.state.fields.paymentmode +
      // "&subhead=" +
      // this.state.fields.subhead,
      {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + userData.token,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          console.log("daybook", data.data.dayBook);
          this.setState({
            poPayments: data.data.dayBook.rows,
            poPaymentsCount: data.data.dayBook.count,
          });
        } else {
          alert("something went wrong.");
        }
      });
  };

  componentDidMount() {
    this.loadMaster();
    //console.log("componentDidMount", this.state.fields.projects);
  }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Purchase Order Tracking | MRC</title>
        </Helmet>
        <section className="admin">
          <AdminHeader></AdminHeader>
          <div className="wrapper d-flex">
            <AdminMenu></AdminMenu>

            <div className="wrapper_body">
              <div className="row d-flex align-items-center">
                <div className="col m0 d-flex align-items-center">
                  <h3 className="heading">
                    Payments for {this.state.selectedPo.ponumber}
                  </h3>
                </div>
                <div className="col m4 right-align">
                  <Link
                    to={`/poview/${this.state.selectedPo.id}`}
                    className="actions btn btn-sm btn-primary"
                    node="button"
                  >
                    {" "}
                    View PO
                  </Link>
                </div>
              </div>
              <div className="projects employee">
                <div className="row">
                  <div className="col s12">
                    <div className="table-responsive">
                      <table>
                        <thead>
                          <tr>
                            <th>Entry Time</th>
                            <th>Project</th>
                            <th>Date</th>
                            <th>Sub Head</th>
                            <th>Bill No.</th>
                            <th>Bill</th>
                            <th>Amount</th>
                            <th>Type</th>
                            <th style={{ width: "10%" }}>Vendor</th>
                            <th style={{ width: "10%" }}>Remarks</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.poPayments.map((db, index) => (
                            <>
                              <tr>
                                <td data-th="Time">
                                  <Moment format="YYYY-MM-DD">
                                    {db.createdAt}
                                  </Moment>
                                  &nbsp;&nbsp;
                                  <Moment format="hh:mm:ss">
                                    {db.createdAt}
                                  </Moment>
                                </td>
                                <td data-th="Project">{db.projects.name}</td>
                                <td data-th="Date">{db.date}</td>
                                <td data-th="Subhead">
                                  {db.subheads ? db.subheads.name : ""}
                                </td>
                                <td data-th="Billno">{db.billno}</td>
                                <td data-th="Bill">
                                  {db.bill && (
                                    <a href={db.bill} className="actions">
                                      <i class="material-icons">
                                        file_download
                                      </i>
                                    </a>
                                  )}
                                </td>

                                <td data-th="Amount">
                                  <i class="fas fa-rupee-sign"></i>{" "}
                                  {formatAmount(db.amount)}
                                </td>
                                <td data-th="Mode">
                                  {db.paymentmode == 1 ? "Cash" : "Bank"}
                                </td>
                                <td style={{ width: "10%" }} data-th="vendor">
                                  {db.vendors ? db.vendors.name : "-"}
                                </td>
                                <td data-th="remarks">{db.remarks}</td>
                              </tr>
                            </>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div
                  className="col s12"
                  style={{ marginTop: 20, marginBottom: 20 }}
                >
                  <Pagination
                    pageSize={this.state.pageSize}
                    onChange={this.onChangePagination}
                    current={this.state.current}
                    total={this.state.poPaymentsCount}
                    showSizeChanger
                    defaultPageSize={this.state.pageSize}
                    defaultCurrent={this.state.current}
                    onShowSizeChange={this.onChangePageSize}
                    locale={localeInfo}
                    selectComponentClass={Select}
                    pageSizeOptions={[10, 30, 50, 100]}
                    showTotal={(total, range) =>
                      `${range[0]} - ${range[1]} of ${total} items`
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          {this.state.alert && (
            <SweetAlert
              // onConfirm={this.changeStatus}
              onCancel={this.hideAlert}
              focusCancelBtn={true}
              warning
              showCancel
              confirmBtnText="Yes"
              confirmBtnBsStyle="danger"
              title="Are you sure?"
            />
          )}
          <Footer />
        </section>
      </React.Fragment>
    );
  }
}
