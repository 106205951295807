export function formatAmount(amount) {
  let formattedAmount;
  if (typeof amount === "string") {
    formattedAmount = parseInt(amount).toLocaleString("hi-IN");
  } else {
    formattedAmount = amount.toLocaleString("hi-IN");
  }

  return formattedAmount;
}

export const customStyles = {
  option: (provided, state) => ({
    ...provided,
    padding: 10,
    borderRadius: 0,
    fontSize: 15,
    textAlign: "center",
    color: "black",
  }),
  control: (provided, state) => ({
    ...provided,
    borderRadius: 14,
    borderColor: "#f4f4f4",
    backgroundColor: "#f4f4f4",
    fontSize: 15,
    padding: "5px 5px",
    margin: "0 0 10px 0;",
  }),
  menuList: (provided, state) => ({
    ...provided,
    borderRadius: 0,
  }),
};
