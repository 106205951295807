import React, { Component } from "react";
import Footer from "../../components/Footer";
import ProjectHeader from "../../components/header/ProjectHeader";
import Sidebar from "../../components/sidebar/Sidebar";
import ProjectMenu from "../../components/sidebar/ProjectMenu";
import axios from "axios";
import { toast } from "react-toastify";
import "materialize-css";
import { Modal } from "react-materialize";
import { getUserData, getProjectID } from "../../helpers/storage";
import SweetAlert from "react-bootstrap-sweetalert";
import ButtonLoader from "../../components/ButtonLoader";
import { BulletList } from "react-content-loader";
import SimpleReactValidator from "simple-react-validator";
import { Helmet } from "react-helmet";
import "rc-pagination/assets/index.css";
import Pagination from "rc-pagination";
import Select from "rc-select";
import "rc-select/assets/index.less";
import "../../assets/pagination.less";
import localeInfo from "../../helpers/en_US";

import {
  apiListProjects,
  apiListDailyIncome,
  apiCreateDailyIncome,
  apiEditDailyIncome,
  apiGetDailyIncomeById,
} from "../../config/endpoints";

const initialState = {
  fields: {
    project: "",
    date: "",
    addafee: 0,
    nightaddafee: 0,
    carparking: 0,
    bikeparking: 0,
    carparkingmall: 0,
    bikeparkingmall: 0,
    taxiparking: 0,
    advertisementincome: 0,
    shopelectricity: 0,
    toilets: 0,
    hawkers: 0,
    rent: 0,
    other: 0,
    total: 0,
    remarks: "",
  },
  isLoading: false,
  redirect: false,
  errors: {},
  vendorList: [],
  isModalOpen: false,
  isListLoading: false,
  alert: false,
  deleteId: "",
  editId: false,
  editItem: false,
  dailyIncomeList: [],
  masterListProjects: [],
  masterListVendor: [],
  dailyIncomeCount: 0,
  fileInputs: [""],
  fileValues: [],
  current: 1,
  pageSize: 30,
};

export default class DailyIncome extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = initialState;
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
  }

  handleCloseModal = () => {
    this.setState(initialState);
    this.loadMaster();
  };
  handleOpenModal = () => {
    //console.log("modal open");
    this.setState({ isModalOpen: true });
    this.validator.hideMessages();
    this.loadMaster();
  };

  createFileInputs = () => {
    return this.state.fileInputs.map((el, i) => (
      <div className="row" key={i}>
        <div className="col s10 mt">
          <input
            type="text"
            className="normalfile"
            value={el || 0}
            name={`others[$i]`}
            onChange={this.handleChangeMultiple.bind(this, i)}
          />
        </div>
        <div className="col s2">
          {i !== 0 ? (
            <a
              type="button"
              value="remove"
              className="btn"
              onClick={this.removeClick.bind(this, i)}
            >
              x
            </a>
          ) : (
            ""
          )}
        </div>
      </div>
    ));
  };

  addClick = () => {
    this.setState((prevState) => ({
      fileInputs: [...prevState.fileInputs, ""],
    }));
    console.log(this.state.fileInputs);
  };

  removeClick = (i) => {
    let values = [...this.state.fileInputs];
    values.splice(i, 1);
    console.log(i);
    console.log(values);
    this.setState({ fileInputs: values });
  };

  editDailyIncome(id) {
    this.setState({ editItem: true, editId: id });
    this.handleOpenModal();
    let userData = getUserData();
    fetch(apiGetDailyIncomeById + "?id=" + id, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          this.setState({
            fields: data.data.dailyincome,
          });
        } else {
          alert("something went wrong.");
        }
      });
  }
  showAlert = (id) => {
    //console.log(id);
    this.setState({
      alert: true,
      deleteId: id,
    });
  };
  hideAlert = () => {
    this.setState({
      alert: false,
    });
  };
  onChangePagination = (page) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        current: page,
      }),
      this.loadMaster
      //this.submitSearch
      //this.loadDataForPagination
    );
  };

  onChangePageSize = (current, pageSize) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        current: current,
        pageSize: pageSize,
      }),
      this.loadMaster
    );
  };
  loadMaster = (e) => {
    let userData = getUserData();
    let projectId = getProjectID();
    this.setState({ isListLoading: true });
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        project: projectId,
      },
    }));
    fetch(
      apiListDailyIncome +
        "?page=" +
        this.state.current +
        "&pagesize=" +
        this.state.pageSize +
        "&project_id=" +
        projectId,
      {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + userData.token,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          //console.log("daily income list", data.data.dailyincome.rows);
          this.setState({
            dailyIncomeList: data.data.dailyincome.rows,
            isListLoading: false,
            dailyIncomeCount: data.data.dailyincome.count,
          });
        } else {
          alert("something went wrong.");
        }
      });

    fetch(apiListProjects, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          //console.log("project list", data.data.projects.rows);
          this.setState({
            masterListProjects: data.data.projects.rows,
          });
        } else {
          alert("something went wrong.");
        }
      });
  };

  handleChange = (e) => {
    this.validator.showMessageFor(e.target.name);
    const value = e.target.value;
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        [e.target.name]: value,
      },
    }));
  };

  handleChangeMultiple = (i, e) => {
    this.validator.showMessageFor(e.target.name);
    const value = e.target.value;
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        [e.target.name]: value,
      },
    }));
  };
  handleBlur = (e) => {
    this.validator.showMessageFor(e.target.name);
  };

  submitForm = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      let userData = getUserData();
      if (!this.state.editItem) {
        let data = {
          project: this.state.fields.project,
          date: this.state.fields.date,
          addafee: this.state.fields.addafee,
          nightaddafee: this.state.fields.nightaddafee,
          carparking: this.state.fields.carparking,
          bikeparking: this.state.fields.bikeparking,
          taxiparking: this.state.fields.taxiparking,
          advertisementincome: this.state.fields.advertisementincome,
          shopelectricity: this.state.fields.shopelectricity,
          toilets: this.state.fields.toilets,
          hawkers: this.state.fields.hawkers,
          rent: this.state.fields.rent,
          other: this.state.fields.other,
          total: this.state.fields.total,
          remarks: this.state.fields.remarks,
        };

        //console.log(data);

        this.setState({ isLoading: true });
        axios
          .post(apiCreateDailyIncome, data, {
            headers: {
              Authorization: "Bearer " + userData.token,
            },
          })
          .then(
            (response) => {
              //console.log(response);
              // this.setState({ isLoading: false });
              toast.info("Daily Income created", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
              this.setState(this.initialState);
              this.handleCloseModal();
            },
            (error) => {
              if (
                error.response.status == 500 ||
                error.response.status == 400
              ) {
                toast.error(error.response.data.errorMessage, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                this.setState({ isLoading: false });
              }
            }
          );
      } else {
        let data = {
          project: this.state.fields.project,
          date: this.state.fields.date,
          addafee: this.state.fields.addafee,
          nightaddafee: this.state.fields.nightaddafee,
          carparking: this.state.fields.carparking,
          bikeparking: this.state.fields.bikeparking,
          taxiparking: this.state.fields.taxiparking,
          advertisementincome: this.state.fields.advertisementincome,
          shopelectricity: this.state.fields.shopelectricity,
          toilets: this.state.fields.toilets,
          hawkers: this.state.fields.hawkers,
          rent: this.state.fields.rent,
          other: this.state.fields.other,
          total: this.state.fields.total,
          remarks: this.state.fields.remarks,
          id: this.state.editId,
        };
        this.setState({ isLoading: true });
        axios
          .post(apiEditDailyIncome, data, {
            headers: {
              Authorization: "Bearer " + userData.token,
            },
          })
          .then(
            (response) => {
              //console.log(response);
              // this.setState({ isLoading: false });
              toast.info("Daily Income updated", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
              this.setState(this.initialState);
              this.handleCloseModal();
            },
            (error) => {
              if (
                error.response.status == 500 ||
                error.response.status == 400
              ) {
                toast.error(error.response.data.errorMessage, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                this.setState({ isLoading: false });
              }
            }
          );
      }
    } else {
      console.log("fail");
      console.log("errors", this.validator.getErrorMessages());
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  calTotal = (e) => {
    console.log("net");
    let addafee = this.state.fields.addafee;
    let nightaddafee = this.state.fields.nightaddafee;
    let carparking = this.state.fields.carparking;
    let bikeparking = this.state.fields.bikeparking;
    let carparkingmall = this.state.fields.carparkingmall;
    let bikeparkingmall = this.state.fields.bikeparkingmall;
    let taxiparking = this.state.fields.taxiparking;
    let advertisementincome = this.state.fields.advertisementincome;
    let shopelectricity = this.state.fields.shopelectricity;
    let toilets = this.state.fields.toilets;
    let hawkers = this.state.fields.hawkers;
    let rent = this.state.fields.rent;
    let other = this.state.fields.other;
    let total =
      parseFloat(addafee) +
      parseFloat(nightaddafee) +
      parseFloat(carparking) +
      parseFloat(bikeparking) +
      parseFloat(carparkingmall) +
      parseFloat(bikeparkingmall) +
      parseFloat(taxiparking) +
      parseFloat(advertisementincome) +
      parseFloat(shopelectricity) +
      parseFloat(toilets) +
      parseFloat(hawkers) +
      parseFloat(rent) +
      parseFloat(other);
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        total: total,
      },
    }));
  };
  componentDidMount() {
    this.loadMaster();
  }

  render() {
    this.validator.purgeFields();
    return (
      <React.Fragment>
        <Helmet>
          <title>Daily Income | MRC</title>
        </Helmet>
        <section className="adminProject">
          <div className="wrapper d-flex">
            <Sidebar />
            <div class="wrapper_right">
              <ProjectHeader />
              <div className="d-flex">
                <ProjectMenu />

                <div className="wrapper_body">
                  <div className="row d-flex align-items-center">
                    <div className="col m0 d-flex align-items-center">
                      <h3 className="heading">Daily Income</h3>
                    </div>
                    <div className="col m4 right-align">
                      <a
                        href="javascript:void(0)"
                        className="theme_btn btn  modal-trigger"
                        onClick={this.handleOpenModal}
                      >
                        <i className="material-icons">add</i> Daily Income Form
                      </a>
                      <Modal
                        actions={[,]}
                        bottomSheet={false}
                        fixedFooter={false}
                        id="Modal-0"
                        open={this.state.isModalOpen}
                        className="cstm-modal"
                        options={{
                          dismissible: false,
                          endingTop: "10%",
                          inDuration: 250,
                          onCloseEnd: null,
                          onCloseStart: null,
                          onOpenEnd: null,
                          onOpenStart: null,
                          opacity: 0.5,
                          outDuration: 250,
                          preventScrolling: true,
                          startingTop: "4%",
                        }}
                      >
                        {/* Close Modal */}
                        <a
                          href="javascript:void(0)"
                          className="modal-close"
                          onClick={this.handleCloseModal}
                        >
                          <span className="material-icons">chevron_right</span>
                        </a>
                        {/* Modal Content */}
                        <h3 className="heading">
                          {this.state.editItem
                            ? "Update Daily Income"
                            : "Daily Income Form"}
                        </h3>
                        <p>
                          Enter details to{" "}
                          {this.state.editItem ? "update" : "create new"}{" "}
                          Payment
                        </p>
                        <form action id="createContractorForm">
                          <div className="row">
                            <div className="col s12">
                              <label className="label">Project</label>
                              <select
                                name="project"
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                                value={this.state.fields.project}
                                disabled
                              >
                                <option>Select</option>
                                {this.state.masterListProjects.map(
                                  (project, index) => (
                                    <>
                                      <option value={project.id}>
                                        {project.name}
                                      </option>
                                    </>
                                  )
                                )}
                              </select>
                              <label className="error">
                                {this.validator.message(
                                  "project",
                                  this.state.fields.project,
                                  "required"
                                )}
                              </label>
                            </div>

                            <div className="col  s12">
                              <label className="label">Date</label>
                              <input
                                type="date"
                                name="date"
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                                value={this.state.fields.date}
                              />

                              <label className="error">
                                {this.validator.message(
                                  "date",
                                  this.state.fields.date,
                                  "required"
                                )}
                              </label>
                            </div>

                            <div className="col s12">
                              <label className="label">Adda Fee</label>
                              <input
                                type="text"
                                placeholder="Adda Fee"
                                name="addafee"
                                value={this.state.fields.addafee}
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                                onKeyUp={this.calTotal}
                              />
                              <label className="error">
                                {this.validator.message(
                                  "adda fee",
                                  this.state.fields.addafee,
                                  "required|numeric"
                                )}
                              </label>
                            </div>
                            <div className="col s12">
                              <label className="label">Night Adda Fee</label>
                              <input
                                type="text"
                                placeholder="Night Adda Fee"
                                name="nightaddafee"
                                value={this.state.fields.nightaddafee}
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                                onKeyUp={this.calTotal}
                              />
                              <label className="error">
                                {this.validator.message(
                                  "night adda fee",
                                  this.state.fields.nightaddafee,
                                  "required|numeric"
                                )}
                              </label>
                            </div>
                            <div className="col s12">
                              <label className="label">
                                Car Parking Bus Stand
                              </label>
                              <input
                                type="text"
                                placeholder="Car Parking Bus Stand"
                                name="carparking"
                                value={this.state.fields.carparking}
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                                onKeyUp={this.calTotal}
                              />
                              <label className="error">
                                {this.validator.message(
                                  "carparking",
                                  this.state.fields.carparking,
                                  "required|numeric"
                                )}
                              </label>
                            </div>
                            <div className="col s12">
                              <label className="label">
                                Bike Parking Bus Stand
                              </label>
                              <input
                                type="text"
                                placeholder="Bike Parking Bus Stand"
                                name="bikeparking"
                                value={this.state.fields.bikeparking}
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                                onKeyUp={this.calTotal}
                              />
                              <label className="error">
                                {this.validator.message(
                                  "bikeparking",
                                  this.state.fields.bikeparking,
                                  "required|numeric"
                                )}
                              </label>
                            </div>

                            <div className="col s12">
                              <label className="label">Car Parking Mall</label>
                              <input
                                type="text"
                                placeholder="Car Parking Mall"
                                name="carparkingmall"
                                value={this.state.fields.carparkingmall}
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                                onKeyUp={this.calTotal}
                              />
                              <label className="error">
                                {this.validator.message(
                                  "carparkingmall",
                                  this.state.fields.carparkingmall,
                                  "required|numeric"
                                )}
                              </label>
                            </div>
                            <div className="col s12">
                              <label className="label">Bike Parking Mall</label>
                              <input
                                type="text"
                                placeholder="Bike Parking Mall"
                                name="bikeparkingmall"
                                value={this.state.fields.bikeparkingmall}
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                                onKeyUp={this.calTotal}
                              />
                              <label className="error">
                                {this.validator.message(
                                  "bikeparkingmall",
                                  this.state.fields.bikeparkingmall,
                                  "required|numeric"
                                )}
                              </label>
                            </div>
                            <div className="col s12">
                              <label className="label">
                                Taxi & Auto Parking
                              </label>
                              <input
                                type="text"
                                placeholder="Taxi & Auto Parking"
                                name="taxiparking"
                                value={this.state.fields.taxiparking}
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                                onKeyUp={this.calTotal}
                              />
                              <label className="error">
                                {this.validator.message(
                                  "taxiparking",
                                  this.state.fields.taxiparking,
                                  "required|numeric"
                                )}
                              </label>
                            </div>

                            <div className="col s12">
                              <label className="label">
                                Advertisement Income
                              </label>
                              <input
                                type="text"
                                placeholder="Advertisement Income"
                                name="advertisementincome"
                                value={this.state.fields.advertisementincome}
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                                onKeyUp={this.calTotal}
                              />
                              <label className="error">
                                {this.validator.message(
                                  "advertisementincome",
                                  this.state.fields.advertisementincome,
                                  "required|numeric"
                                )}
                              </label>
                            </div>

                            <div className="col s12">
                              <label className="label">Shop Electricity</label>
                              <input
                                type="text"
                                placeholder="Shop Electricity"
                                name="shopelectricity"
                                value={this.state.fields.shopelectricity}
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                                onKeyUp={this.calTotal}
                              />
                              <label className="error">
                                {this.validator.message(
                                  "shopelectricity",
                                  this.state.fields.shopelectricity,
                                  "required|numeric"
                                )}
                              </label>
                            </div>

                            <div className="col s12">
                              <label className="label">Toilets</label>
                              <input
                                type="text"
                                placeholder="Toilets"
                                name="toilets"
                                value={this.state.fields.toilets}
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                                onKeyUp={this.calTotal}
                              />
                              <label className="error">
                                {this.validator.message(
                                  "toilets",
                                  this.state.fields.toilets,
                                  "required|numeric"
                                )}
                              </label>
                            </div>

                            <div className="col s12">
                              <label className="label">Hawkers</label>
                              <input
                                type="text"
                                placeholder="Hawkers"
                                name="hawkers"
                                value={this.state.fields.hawkers}
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                                onKeyUp={this.calTotal}
                              />
                              <label className="error">
                                {this.validator.message(
                                  "hawkers",
                                  this.state.fields.hawkers,
                                  "required|numeric"
                                )}
                              </label>
                            </div>
                            <div className="col s12">
                              <label className="label">Rent</label>
                              <input
                                type="text"
                                placeholder="Rent"
                                name="rent"
                                value={this.state.fields.rent}
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                                onKeyUp={this.calTotal}
                              />
                              <label className="error">
                                {this.validator.message(
                                  "rent",
                                  this.state.fields.rent,
                                  "required|numeric"
                                )}
                              </label>
                            </div>
                            <div className="col s12">
                              <label className="label">Others</label>
                              {/* <input
                                type="text"
                                placeholder="Others"
                                name="other"
                                value={this.state.fields.other}
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                                onKeyUp={this.calTotal}
                              />
                              <label className="error">
                                {this.validator.message(
                                  "other",
                                  this.state.fields.other,
                                  "required|numeric"
                                )}
                              </label> */}
                              {this.createFileInputs()}
                              <input
                                type="button"
                                className="btn"
                                value="add more"
                                onClick={this.addClick.bind(this)}
                              />
                            </div>
                            <div className="col s12">
                              <label className="label">Total</label>
                              <input
                                type="text"
                                placeholder="Total"
                                name="total"
                                value={this.state.fields.total}
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                                readOnly={true}
                              />
                              <label className="error">
                                {this.validator.message(
                                  "total",
                                  this.state.fields.total,
                                  "required|numeric"
                                )}
                              </label>
                            </div>

                            <div className="col s12">
                              <label className="label">Remarks</label>
                              <input
                                type="text"
                                placeholder="Remarks"
                                name="remarks"
                                value={this.state.fields.remarks}
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                              />
                              <label className="error">
                                {this.validator.message(
                                  "remarks",
                                  this.state.fields.remarks,
                                  "required"
                                )}
                              </label>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col s12 right-align">
                              <button
                                type="submit"
                                form="createContractorForm"
                                className="theme_btn btn"
                                onClick={this.submitForm}
                              >
                                {this.state.editItem
                                  ? "Update Daily Income"
                                  : "Add Daily Income"}
                                {this.state.isLoading ? (
                                  <ButtonLoader></ButtonLoader>
                                ) : null}
                              </button>
                            </div>
                          </div>
                        </form>
                      </Modal>
                    </div>
                  </div>
                  <div className="projects employee">
                    <div className="row">
                      <div className="col s12">
                        <p className="grey-text">
                          <b>Filters</b>
                        </p>
                        <form ref={(ele) => (this.myFormRef = ele)}>
                          <div className="row">
                            <div className="col m3 s12">
                              <input
                                type="date"
                                className=""
                                placeholder="From Date"
                                name="from"
                                onChange={this.handleSearch}
                              />
                            </div>

                            <div className="col m3 s12">
                              {/* <span>-</span> */}
                              <input
                                type="date"
                                className=""
                                placeholder="To Date"
                                name="to"
                                onChange={this.handleSearch}
                              />
                            </div>
                          </div>

                          <div className="row">
                            {/* <a href="#!" className="red-text pt-1 d-flex">Clear Filter</a> */}
                            {/* <button type="submit" className="red-text pt-1">Clear Filter</button> */}
                            <div class="col m3 s12">
                              <a
                                href="javascript:void(0)"
                                class="red-text pt-1"
                                onClick={this.clearFilter}
                              >
                                Clear Filter
                              </a>

                              <button
                                type="submit"
                                className="theme_btn btn filter-submit"
                                onClick={this.submitSearch}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                      <div className="col s12">
                        <div className="table-responsive">
                          <table>
                            <thead>
                              <tr>
                                <th>Date</th>
                                <th>Project Name</th>
                                <th>Adda Fee</th>
                                <th>Night Adda Fee</th>
                                <th>Car Parking</th>
                                <th>Bike Parking</th>
                                <th>Taxi & Auto Parking</th>
                                <th>Advertisement Income</th>
                                <th>Shop Electricity</th>
                                <th>Toilets</th>
                                <th>Hawkers</th>
                                <th>Rent</th>
                                <th>Other</th>
                                <th>Total</th>
                                <th>Remarks</th>
                                <th>Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.isListLoading ? (
                                <tr>
                                  <td colSpan={5}>
                                    <BulletList></BulletList>
                                  </td>
                                </tr>
                              ) : (
                                this.state.dailyIncomeList.map(
                                  (income, index) => (
                                    <tr key={index}>
                                      <td>{income.date} </td>
                                      <td>{income.projects.name} </td>
                                      <td>&#8377;{income.addafee} </td>
                                      <td>&#8377;{income.nightaddafee} </td>
                                      <td>&#8377;{income.carparking}</td>
                                      <td>&#8377;{income.bikeparking} </td>
                                      <td>&#8377;{income.taxiparking} </td>
                                      <td>
                                        &#8377;{income.advertisementincome}{" "}
                                      </td>
                                      <td>&#8377;{income.shopelectricity}</td>
                                      <td>&#8377;{income.toilets}</td>
                                      <td>&#8377;{income.hawkers} </td>
                                      <td>&#8377;{income.rent} </td>
                                      <td>&#8377;{income.other} </td>
                                      <td>&#8377;{income.total} </td>
                                      <td>{income.remarks} </td>
                                      <td>
                                        <a
                                          href="#Modal-0"
                                          className="actions"
                                          node="button"
                                          onClick={() =>
                                            this.editDailyIncome(income.id)
                                          }
                                        >
                                          <i className="material-icons">edit</i>
                                        </a>
                                        {/* <a
                                    href='javascript:void(0)'
                                    className='actions'
                                    onClick={() => this.showAlert(vendor.id)}
                                  >
                                    <i className='material-icons'>delete</i>
                                  </a> */}
                                      </td>
                                    </tr>
                                  )
                                )
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      {/* pagination */}
                      <div
                        className="col s12"
                        style={{ marginTop: 20, marginBottom: 20 }}
                      >
                        <Pagination
                          pageSize={this.state.pageSize}
                          onChange={this.onChangePagination}
                          current={this.state.current}
                          total={this.state.dailyIncomeCount}
                          showSizeChanger
                          defaultPageSize={this.state.pageSize}
                          defaultCurrent={this.state.current}
                          onShowSizeChange={this.onChangePageSize}
                          locale={localeInfo}
                          selectComponentClass={Select}
                          pageSizeOptions={[10, 30, 50, 100]}
                          showTotal={(total, range) =>
                            `${range[0]} - ${range[1]} of ${total} items`
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </section>
      </React.Fragment>
    );
  }
}
