export const apiLogin = process.env.REACT_APP_API_ENDPOINT + "public/login";

export const apiGetDashboard =
  process.env.REACT_APP_API_ENDPOINT + "api/dashboard";
export const apiEditUser = process.env.REACT_APP_API_ENDPOINT + "api/update";
export const apiGetMe = process.env.REACT_APP_API_ENDPOINT + "api/me";

export const apiChangePasswordForAdmin =
  process.env.REACT_APP_API_ENDPOINT + "api/user/changePassword";

export const apiCreateCompany =
  process.env.REACT_APP_API_ENDPOINT + "api/company/create";
export const apiEditCompany =
  process.env.REACT_APP_API_ENDPOINT + "api/company/edit";
export const apiListCompanies =
  process.env.REACT_APP_API_ENDPOINT + "api/company/list";
export const apiGetCompany =
  process.env.REACT_APP_API_ENDPOINT + "api/company/get";
export const apiDeleteCompany =
  process.env.REACT_APP_API_ENDPOINT + "api/company/delete";

export const apiGetCompanyArchived =
  process.env.REACT_APP_API_ENDPOINT + "api/company/allDeleted";

export const apiCreateEmployee =
  process.env.REACT_APP_API_ENDPOINT + "api/employee/create";
export const apiListEmployees =
  process.env.REACT_APP_API_ENDPOINT + "api/employee/list";
export const apiEditEmployee =
  process.env.REACT_APP_API_ENDPOINT + "api/employee/edit";
export const apiGetEmployee =
  process.env.REACT_APP_API_ENDPOINT + "api/employee/get";
export const apiDeleteEmployee =
  process.env.REACT_APP_API_ENDPOINT + "api/employee/delete";

export const apiCreateProject =
  process.env.REACT_APP_API_ENDPOINT + "api/project/create";
export const apiEditProject =
  process.env.REACT_APP_API_ENDPOINT + "api/project/edit";
export const apiListProjects =
  process.env.REACT_APP_API_ENDPOINT + "api/project/list";
export const apiGetProject =
  process.env.REACT_APP_API_ENDPOINT + "api/project/get";
export const apiDeleteProject =
  process.env.REACT_APP_API_ENDPOINT + "api/project/delete";

export const apiCreateVendor =
  process.env.REACT_APP_API_ENDPOINT + "api/vendor/create";
export const apiEditVendor =
  process.env.REACT_APP_API_ENDPOINT + "api/vendor/edit";
export const apiListVendors =
  process.env.REACT_APP_API_ENDPOINT + "api/vendor/list";
export const apiGetVendor =
  process.env.REACT_APP_API_ENDPOINT + "api/vendor/get";
export const apiDeleteVendor =
  process.env.REACT_APP_API_ENDPOINT + "api/vendor/delete";

export const apiCreateMaster =
  process.env.REACT_APP_API_ENDPOINT + "api/master/create";
export const apiEditMaster =
  process.env.REACT_APP_API_ENDPOINT + "api/master/edit";
export const apiListMasters =
  process.env.REACT_APP_API_ENDPOINT + "api/master/list";
export const apiGetMaster =
  process.env.REACT_APP_API_ENDPOINT + "api/master/get";
export const apiDeleteMaster =
  process.env.REACT_APP_API_ENDPOINT + "api/master/delete";
export const apiGetMasterByType =
  process.env.REACT_APP_API_ENDPOINT + "api/master/getByType";

export const apiCreateItemMaster =
  process.env.REACT_APP_API_ENDPOINT + "api/itemmaster/create";
export const apiEditItemMaster =
  process.env.REACT_APP_API_ENDPOINT + "api/itemmaster/edit";
export const apiGetItemMasterList =
  process.env.REACT_APP_API_ENDPOINT + "api/itemmaster/list";
export const apiGetItemMaster =
  process.env.REACT_APP_API_ENDPOINT + "api/itemmaster/get";

export const apiCreateBankMaster =
  process.env.REACT_APP_API_ENDPOINT + "api/bankmaster/create";
export const apiEditBankMaster =
  process.env.REACT_APP_API_ENDPOINT + "api/bankmaster/edit";
export const apiGetBankMaster =
  process.env.REACT_APP_API_ENDPOINT + "api/bankmaster/get";
export const apiGetBankMasterList =
  process.env.REACT_APP_API_ENDPOINT + "api/bankmaster/list";
export const apiDeleteBankMasterList =
  process.env.REACT_APP_API_ENDPOINT + "api/bankmaster/delete";

export const apiCreateGstMaster =
  process.env.REACT_APP_API_ENDPOINT + "api/gstmaster/create";
export const apiEditGstMaster =
  process.env.REACT_APP_API_ENDPOINT + "api/gstmaster/edit";
export const apiListGstMasters =
  process.env.REACT_APP_API_ENDPOINT + "api/gstmaster/list";
export const apiGetGstMaster =
  process.env.REACT_APP_API_ENDPOINT + "api/gstmaster/get";
export const apiDeleteGstMaster =
  process.env.REACT_APP_API_ENDPOINT + "api/gstmaster/delete";

export const apiCreateRole =
  process.env.REACT_APP_API_ENDPOINT + "api/role/create";
export const apiEditRole = process.env.REACT_APP_API_ENDPOINT + "api/role/edit";
export const apiListRoles =
  process.env.REACT_APP_API_ENDPOINT + "api/role/list";
export const apiGetRole = process.env.REACT_APP_API_ENDPOINT + "api/role/get";
export const apiDeleteRole =
  process.env.REACT_APP_API_ENDPOINT + "api/role/delete";

export const apiCreateExpenseHead =
  process.env.REACT_APP_API_ENDPOINT + "api/expensehead/create";
export const apiEditExpenseHead =
  process.env.REACT_APP_API_ENDPOINT + "api/expensehead/edit";
export const apiListExpenseHeads =
  process.env.REACT_APP_API_ENDPOINT + "api/expensehead/list";
export const apiListExpenseHeadsParents =
  process.env.REACT_APP_API_ENDPOINT + "api/expensehead/getAllParents";
export const apiListExpenseSubHeads =
  process.env.REACT_APP_API_ENDPOINT + "api/expensehead/getExpenseSubHead";
export const apiListAllExpenseSubHeads =
  process.env.REACT_APP_API_ENDPOINT + "api/expensehead/subhead";
export const apiGetExpenseHead =
  process.env.REACT_APP_API_ENDPOINT + "api/expensehead/get";
export const apiDeleteExpenseHead =
  process.env.REACT_APP_API_ENDPOINT + "api/expensehead/delete";

export const apiDaybookCreate =
  process.env.REACT_APP_API_ENDPOINT + "api/daybook/create";

export const apiDaybookEdit =
  process.env.REACT_APP_API_ENDPOINT + "api/daybook/edit";
export const apiListDaybook =
  process.env.REACT_APP_API_ENDPOINT + "api/daybook/list";
export const apigetDaybook =
  process.env.REACT_APP_API_ENDPOINT + "api/daybook/single";
export const apiDaybookExpenseStatus =
  process.env.REACT_APP_API_ENDPOINT + "api/daybook/changestatus";
export const apiDaybookExpenseLog =
  process.env.REACT_APP_API_ENDPOINT + "api/daybook/log";
export const apiDaybookDelete =
  process.env.REACT_APP_API_ENDPOINT + "api/daybook/delete";

export const apiCreateCashAssign =
  process.env.REACT_APP_API_ENDPOINT + "api/cash/create";
export const apiEditCashAssign =
  process.env.REACT_APP_API_ENDPOINT + "api/cash/edit";
export const apisingleCashAssign =
  process.env.REACT_APP_API_ENDPOINT + "api/cash/single";
export const apideleteCashAssign =
  process.env.REACT_APP_API_ENDPOINT + "api/cash/delete";

export const apiCreateCashTransfer =
  process.env.REACT_APP_API_ENDPOINT + "api/cashtransfer/create";
export const apiEditCashTransfer =
  process.env.REACT_APP_API_ENDPOINT + "api/cashtransfer/edit";
export const apisingleCashTransfer =
  process.env.REACT_APP_API_ENDPOINT + "api/cashtransfer/single";
export const apideleteCashTransfer =
  process.env.REACT_APP_API_ENDPOINT + "api/cashtransfer/delete";

export const apiCreateCashRecievables =
  process.env.REACT_APP_API_ENDPOINT + "api/recievables/create";
export const apiEditCashRecievables =
  process.env.REACT_APP_API_ENDPOINT + "api/recievables/edit";

export const apisingleCashRecievables =
  process.env.REACT_APP_API_ENDPOINT + "api/recievables/single";
export const apideleteCashRecievables =
  process.env.REACT_APP_API_ENDPOINT + "api/recievables/delete";

export const getcashrecievablesbyid =
  process.env.REACT_APP_API_ENDPOINT + "api/recievables/get";

// export const apiEditExpenseHead =
//   process.env.REACT_APP_API_ENDPOINT + "api/expensehead/edit";
export const apiListCash = process.env.REACT_APP_API_ENDPOINT + "api/cash/list";
export const apiListCashRecievables =
  process.env.REACT_APP_API_ENDPOINT + "api/recievables/list";
export const apiListCashTransfer =
  process.env.REACT_APP_API_ENDPOINT + "api/cashtransfer/list";

export const apiAllModules =
  process.env.REACT_APP_API_ENDPOINT + "api/modules/list";

// export const apiListExpenseHeadsParents =
//   process.env.REACT_APP_API_ENDPOINT + "api/expensehead/getAllParents";
// export const apiGetExpenseHead =
//   process.env.REACT_APP_API_ENDPOINT + "api/expensehead/get";
// export const apiDeleteExpenseHead =
//   process.env.REACT_APP_API_ENDPOINT + "api/expensehead/delete";

export const apiChangePassword =
  process.env.REACT_APP_API_ENDPOINT + "api/changePassword";

//daily income api's

export const apiListDailyIncome =
  process.env.REACT_APP_API_ENDPOINT + "api/payments/dailyincome/list";
export const apiCreateDailyIncome =
  process.env.REACT_APP_API_ENDPOINT + "api/payments/dailyincome";
export const apiEditDailyIncome =
  process.env.REACT_APP_API_ENDPOINT + "api/payments/dailyincome/edit";
export const apiGetDailyIncomeById =
  process.env.REACT_APP_API_ENDPOINT + "api/payments/dailyincome/single";

//project payment api's
export const apiListProjectPayment =
  process.env.REACT_APP_API_ENDPOINT + "api/payments/projectpayment/list";
export const apiCreateProjectPayment =
  process.env.REACT_APP_API_ENDPOINT + "api/payments/projectpayment";
export const apiEditProjectPayment =
  process.env.REACT_APP_API_ENDPOINT + "api/payments/projectpayment/edit";
export const apiGetProjectPaymentById =
  process.env.REACT_APP_API_ENDPOINT + "api/payments/projectpayment/single";

//customer api's
export const apiListCustomer =
  process.env.REACT_APP_API_ENDPOINT + "api/customer/list";
export const apiCreateCustomer =
  process.env.REACT_APP_API_ENDPOINT + "api/customer/create";
export const apiEditCustomer =
  process.env.REACT_APP_API_ENDPOINT + "api/customer/edit";
export const apiGetCustomerById =
  process.env.REACT_APP_API_ENDPOINT + "api/customer/single";

//invoice api's
export const apiListInvoice =
  process.env.REACT_APP_API_ENDPOINT + "api/invoice/list";
export const apiCreateInvoice =
  process.env.REACT_APP_API_ENDPOINT + "api/invoice/create";
export const apiEditInvoice =
  process.env.REACT_APP_API_ENDPOINT + "api/invoice/edit";
export const apiGetInvoiceById =
  process.env.REACT_APP_API_ENDPOINT + "api/invoice/single";

//Recurring api's
export const apiListRecurring =
  process.env.REACT_APP_API_ENDPOINT + "api/recurring/list";
export const apiCreateRecurring =
  process.env.REACT_APP_API_ENDPOINT + "api/recurring/create";
export const apiEditRecurring =
  process.env.REACT_APP_API_ENDPOINT + "api/recurring/edit";
export const apiGetRecurringById =
  process.env.REACT_APP_API_ENDPOINT + "api/recurring/single";
export const apiGetRecurringByType =
  process.env.REACT_APP_API_ENDPOINT + "api/recurring/singleType";

//BG api's
export const apiListBG =
  process.env.REACT_APP_API_ENDPOINT + "api/bankguarantee/list";
export const apiCreateBG =
  process.env.REACT_APP_API_ENDPOINT + "api/bankguarantee/create";
export const apiEditBG =
  process.env.REACT_APP_API_ENDPOINT + "api/bankguarantee/edit";
export const apiGetBGById =
  process.env.REACT_APP_API_ENDPOINT + "api/bankguarantee/single";

//FDR api's
export const apiListFDR = process.env.REACT_APP_API_ENDPOINT + "api/fdr/list";
export const apiCreateFDR =
  process.env.REACT_APP_API_ENDPOINT + "api/fdr/create";
export const apiEditFDR = process.env.REACT_APP_API_ENDPOINT + "api/fdr/edit";
export const apiGetFDRById =
  process.env.REACT_APP_API_ENDPOINT + "api/fdr/single";

export const apiFileUpload =
  process.env.REACT_APP_API_ENDPOINT + "api/user/upload";

//PO api's
export const apiListPo = process.env.REACT_APP_API_ENDPOINT + "api/po/list";
export const apiCreatePo = process.env.REACT_APP_API_ENDPOINT + "api/po/create";
export const apiEditPo = process.env.REACT_APP_API_ENDPOINT + "api/po/edit";
export const apiGetPo = process.env.REACT_APP_API_ENDPOINT + "api/po/single";
export const apiGetPoByNumber =
  process.env.REACT_APP_API_ENDPOINT + "api/po/singlebyponumber";
export const apiApprovePo =
  process.env.REACT_APP_API_ENDPOINT + "api/po/approve";

export const apiListPoReceving =
  process.env.REACT_APP_API_ENDPOINT + "api/po-receiving/list";
export const apiCreatePoReceving =
  process.env.REACT_APP_API_ENDPOINT + "api/po-receiving/create";
export const apiEditPoReceving =
  process.env.REACT_APP_API_ENDPOINT + "api/po-receiving/edit";

export const apiAllSettings =
  process.env.REACT_APP_API_ENDPOINT + "api/settings/list";
export const apiGetSettings =
  process.env.REACT_APP_API_ENDPOINT + "api/settings/get";
export const apiEditSettings =
  process.env.REACT_APP_API_ENDPOINT + "api/settings/edit";

export const apiCreatePromotion =
  process.env.REACT_APP_API_ENDPOINT + "api/employeepromotion/create";

export const apiCreateSalary =
  process.env.REACT_APP_API_ENDPOINT + "api/salary/create";
