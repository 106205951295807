import React, { Component } from 'react'


export default class Footer extends Component {
    render() {
        return (
            <div>
                <footer>
                    <p>All Rights Reserved © 2021 MRC Group</p>
                </footer>
            </div>
        )
    }
}
