import React, { Component } from "react";
import { getUserData } from "../helpers/storage";
import axios from "axios";
import ButtonLoader from "../components/ButtonLoader";
import { toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import { apiChangePassword } from "../config/endpoints";

const initialState = {
  /* etc */
  fields: {
    oldpassword: "",
    newpassword: "",
  },
  isLoading: false,
  redirect: false,
  errors: {},
  companyList: [],
  alert: false,
  editItem: false,
  isModalOpen: false,
  isListLoading: false,
  deleteId: false,
  editId: false,
  user: false,
};

export default class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    // let userData = getUserData();
    /* this.state = {
      userData: userData,
      errors: {},
      isLoading: false,
    }; */
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
  }

  componentWillMount() {
    // let userData = getUserData();
    console.log("userdata", this.state.userData);
  }

  handleChange = (e) => {
    //console.log(e.target.name, e.target.value);
    this.validator.showMessageFor(e.target.name);
    const value = e.target.value;
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        [e.target.name]: value,
      },
    }));
  };

  handleBlur = (e) => {
    this.validator.showMessageFor(e.target.name);
  };

  submitForm = (e) => {
    e.preventDefault();
    console.log("submit form");
    console.log("npass", this.state.fields.newpassword);
    console.log("opass", this.state.fields.oldpassword);

    if (this.validator.allValid()) {
      console.log("submit");
      console.log("inside submit", this.state);
      console.log(getUserData());
      let userData = getUserData();

      let data = {
        oldPassword: this.state.fields.oldpassword,
        newPassword: this.state.fields.newpassword,
      };
      this.setState({ isLoading: true });
      axios
        .post(apiChangePassword, data, {
          headers: {
            Authorization: "Bearer " + userData.token,
          },
        })
        .then(
          (response) => {
            console.log(response);
            //setUserData(response.data.data);
            this.setState({ isLoading: false });
            toast.info("Password updated", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
            this.setState(this.initialState);
            //this.handleCloseModal();
          },
          (error) => {
            if (
              error.response.status === 500 ||
              error.response.status === 400
            ) {
              toast.error(error.response.data.errorMessage, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              this.setState({ isLoading: false });
            }
          }
        );
    } else {
      console.log("fail");
      console.log("errors", this.validator.getErrorMessages());
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  render() {
    return (
      <>
        <div>
          <form onSubmit={this.submitForm}>
            {/* <label>Old Password:</label> */}
            <input
              type="password"
              name="oldpassword"
              placeholder="Old Password"
              onChange={this.handleChange}
              onBlur={this.handleBlur}
            />
            <label className="error">
              {this.validator.message(
                "oldpassword",
                this.state.fields.oldpassword,
                "required"
              )}
            </label>

            {/* <label>New Password:</label> */}
            <input
              type="password"
              name="newpassword"
              placeholder="New Password"
              onChange={this.handleChange}
              onBlur={this.handleBlur}
            />
            <label className="error">
              {this.validator.message(
                "newpassword",
                this.state.fields.newpassword,
                "required"
              )}
            </label>

            <div className="col s12 right-align">
              <button type="submit" className="theme_btn btn">
                Submit
                {this.state.isLoading ? <ButtonLoader></ButtonLoader> : null}
              </button>
            </div>
          </form>
        </div>
      </>
    );
  }
}
