import React, { Component } from "react";
import Footer from "../../components/Footer";
import ProjectHeader from "../../components/header/ProjectHeader";
import Sidebar from "../../components/sidebar/Sidebar";
import ProjectMenu from "../../components/sidebar/ProjectMenu";
import ButtonLoader from "../../components/ButtonLoader";
import { Modal, DatePicker } from "react-materialize";
import axios from "axios";
import { toast } from "react-toastify";
import { setUserData, getUserData, getProjectID } from "../../helpers/storage";
import { formatAmount, customStyles } from "../../helpers/util";
import SweetAlert from "react-bootstrap-sweetalert";
import { BulletList } from "react-content-loader";
import SimpleReactValidator from "simple-react-validator";
import Select from "rc-select";

import {
  apiListEmployees,
  apiCreateCashTransfer,
  apiEditExpenseHead,
  apiListCashTransfer,
  apiEditCashTransfer,
  apisingleCashTransfer,
  apideleteCashTransfer,
} from "../../config/endpoints";
import { Helmet } from "react-helmet";
const initialState = {
  fields: {
    date: "",
    employee: "",
    amount: "",
    remarks: "",
  },
  isLoading: false,
  redirect: false,
  errors: {},
  isModalOpen: false,
  isListLoading: false,
  alert: false,
  deleteId: "",
  editId: false,
  editItem: false,
  cashList: [],
  employeeList: [],
  selectOptionForEmployee: [],
};
export default class CashTransfer extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.handleSearch = this.handleSearch.bind(this);
  }
  handleSearch = (e) => {
    this.validator.showMessageFor(e.target.name);
    const value = e.target.value;
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        [e.target.name]: value,
      },
    }));
  };
  handleCloseModal = () => {
    this.setState(initialState);
    this.loadEmployees();
    this.loadCashList();
    this.validator.hideMessages();
  };
  handleOpenModal = () => {
    //console.log("hey");
    this.setState({ isModalOpen: true });
    this.validator.hideMessages();
  };
  showAlert = (id) => {
    //console.log(id);
    this.setState({
      alert: true,
      deleteId: id,
    });
  };
  hideAlert = () => {
    this.setState({
      alert: false,
    });
  };
  deleteMaster = (e) => {
    //console.log("delete", this.state);
    this.setState({ alert: false });
    let userData = getUserData();
    axios
      .post(
        apideleteCashTransfer,
        { id: this.state.deleteId },
        {
          headers: {
            Authorization: "Bearer " + userData.token,
          },
        }
      )
      .then(
        (response) => {
          //console.log(response);
          this.setState({ isLoading: false });
          toast.info("Record deleted", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          this.setState(this.initialState);
          this.loadCashList();
        },
        (error) => {
          if (error.response.status == 500 || error.response.status == 400) {
            toast.error(error.response.data.errorMessage, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            this.setState({ isLoading: false });
          }
        }
      );
  };
  handleChange = (e) => {
    this.validator.showMessageFor(e.target.name);
    const value = e.target.value;
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        [e.target.name]: value,
      },
    }));
    //console.log(this.state);
  };
  handleBlur = (e) => {
    this.validator.showMessageFor(e.target.name);
  };

  handleSearchSelect = (e) => {
    console.log("subhead select e", e);

    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        [e.name]: e.value,
      },
    }));
  };

  loadEmployees = (e) => {
    let userData = getUserData();
    this.setState({ isListLoading: true });
    fetch(apiListEmployees, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        //console.log(data.data.employees.rows);
        if (data.code == 200) {
          this.setState({
            employeeList: data.data.employees.rows,
            isListLoading: false,
          });
          this.setState((prevState) => ({
            fields: {
              // object that we want to update
              ...prevState.fields, // keep all other key-value pairs
              empId: data.data.empId, // update the value of specific key
            },
          }));

          const employeesOption = data.data.employees.rows.map((employee) => ({
            value: employee.id,
            label: employee.name,
            name: "employee",
          }));
          this.setState({ selectOptionForEmployee: employeesOption });
        } else {
          alert("something went wrong.");
        }
        // setTimeout(function () {
      });
  };
  loadCashList = (e) => {
    let userData = getUserData();
    let projectId = getProjectID();
    this.setState({ isListLoading: true });
    fetch(apiListCashTransfer + "?project_id=" + projectId, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          //console.log("cash transfer list", data.data.cash.rows);
          this.setState({
            cashList: data.data.cash.rows,
            isListLoading: false,
          });
        } else {
          alert("something went wrong.");
        }
      });
  };
  editMaster(id) {
    // Assuming only image
    this.setState({ editItem: true, editId: id });
    this.handleOpenModal();
    let userData = getUserData();
    fetch(apisingleCashTransfer + "?id=" + id, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          this.setState({
            fields: data.data.cash,
          });
        } else {
          alert("something went wrong.");
        }
        // setTimeout(function () {
      });
    // Updating the city
  }
  submitSearch = (e) => {
    e.preventDefault();
    let userData = getUserData();
    let data = {
      from: this.state.fields.vendor,
      to: this.state.fields.to,
      paymentmode: this.state.fields.paymentmode,
      vendor: this.state.fields.vendor,
    };
    this.setState({ isListLoading: true });
    fetch(
      apiListCashTransfer +
        "?employee=" +
        this.state.fields.employee +
        "&from=" +
        (this.state.fields.from != undefined ? this.state.fields.from : "") +
        "&to=" +
        (this.state.fields.to != undefined ? this.state.fields.to : ""),
      {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + userData.token,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          this.setState({
            cashList: data.data.cash.rows,
            isListLoading: false,
          });
          //console.log(this.state.dayBook);
        } else {
          alert("something went wrong.");
        }
        // setTimeout(function () {
      });
  };
  submitForm = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      /* console.log("submit");
      console.log(this.state);
      console.log(getUserData()); */
      let userData = getUserData();
      if (!this.state.editItem) {
        let data = {
          date: this.state.fields.date,
          amount: this.state.fields.amount,
          employee: this.state.fields.employee,
          remarks: this.state.fields.remarks,
        };
        this.setState({ isLoading: true });
        axios
          .post(apiCreateCashTransfer, data, {
            headers: {
              Authorization: "Bearer " + userData.token,
            },
          })
          .then(
            (response) => {
              //console.log(response);
              this.setState({ isLoading: false });
              toast.info("Amount Assigned", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
              this.setState(this.initialState);
              this.handleCloseModal();
            },
            (error) => {
              if (
                error.response.status == 500 ||
                error.response.status == 400
              ) {
                toast.error(error.response.data.errorMessage, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                this.setState({ isLoading: false });
              }
            }
          );
      } else {
        let data = {
          date: this.state.fields.date,
          amount: this.state.fields.amount,
          employee: this.state.fields.employee,
          remarks: this.state.fields.remarks,
          id: this.state.editId,
        };
        this.setState({ isLoading: true });
        axios
          .post(apiEditCashTransfer, data, {
            headers: {
              Authorization: "Bearer " + userData.token,
            },
          })
          .then(
            (response) => {
              //console.log(response);
              this.setState({ isLoading: false });
              toast.info("Expense Head updated", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
              this.setState(this.initialState);
              this.handleCloseModal();
            },
            (error) => {
              if (
                error.response.status == 500 ||
                error.response.status == 400
              ) {
                toast.error(error.response.data.errorMessage, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                this.setState({ isLoading: false });
              }
            }
          );
      }
    } else {
      /* console.log("fail");
      console.log("errors", this.validator.getErrorMessages()); */
      this.validator.showMessages();
      this.forceUpdate();
    }
  };
  componentWillMount() {
    this.loadEmployees();
    this.loadCashList();
    //console.log("projectId inside cash transfer", getProjectID());
  }
  render() {
    this.validator.purgeFields();
    return (
      <React.Fragment>
        <Helmet>
          <title>Cash Transfer | MRC</title>
        </Helmet>
        <section className="adminProject">
          <div className="wrapper d-flex">
            <Sidebar />
            <div class="wrapper_right">
              <ProjectHeader />
              <div className="d-flex">
                <ProjectMenu />
                <div className="wrapper_body">
                  <div className="row">
                    <div className="col m8 s12">
                      <h3 className="heading">Cash Transfer</h3>
                    </div>
                    <div className="col m4 s12 right-align">
                      <a
                        href="javascript:Void(0)"
                        className="theme_btn btn"
                        onClick={this.handleOpenModal}
                      >
                        <i className="material-icons">add&nbsp;</i> New Cash
                        Transfer
                      </a>
                      <Modal
                        actions={[,]}
                        bottomSheet={false}
                        fixedFooter={false}
                        id="Modal-0"
                        open={this.state.isModalOpen}
                        className="cstm-modal"
                        options={{
                          dismissible: false,
                          endingTop: "10%",
                          inDuration: 250,
                          onCloseEnd: null,
                          onCloseStart: null,
                          onOpenEnd: null,
                          onOpenStart: null,
                          opacity: 0.5,
                          outDuration: 250,
                          preventScrolling: true,
                          startingTop: "4%",
                        }}
                      >
                        {/* Close Modal */}
                        <a
                          href="javascript:Void(0)"
                          className="modal-close"
                          onClick={this.handleCloseModal}
                        >
                          <span className="material-icons">chevron_right</span>
                        </a>
                        {/* Modal Content */}
                        <h3 className="heading">Cash Transfer</h3>
                        <p>Enter details to cash transfer</p>
                        <form action id="createListForm">
                          <div className="row">
                            <div className="col s12">
                              <label className="label">Date</label>
                              <input
                                type="date"
                                name="date"
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                                value={this.state.fields.date}
                              />
                              {/* <DatePicker
                                                                id="DatePicker-5"
                                                                onChange={this.handleChange}
                                                                onBlur={this.handleBlur}
                                                                value={this.state.fields.date}
                                                                name="date"
                                                            /> */}
                              <label className="error">
                                {this.validator.message(
                                  "date",
                                  this.state.fields.date,
                                  "required"
                                )}
                              </label>
                            </div>
                            <div className="col s12">
                              <label className="label">Employee</label>
                              {/* <select
                                name="employee"
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                                value={this.state.fields.employee}
                              >
                                <option value="" selected>
                                  Select
                                </option>
                                {this.state.employeeList.map(
                                  (employee, index) => (
                                    <>
                                      <option value={employee.id}>
                                        {employee.name} - {employee.empId}
                                      </option>
                                    </>
                                  )
                                )}
                              </select> */}
                              <Select
                                placeholder="Select Employee"
                                styles={customStyles}
                                options={this.state.selectOptionForEmployee}
                                onChange={this.handleSearchSelect}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: 14,
                                  borderColor: "#f4f4f4",
                                })}
                              />
                              <label className="error">
                                {this.validator.message(
                                  "employee",
                                  this.state.fields.employee,
                                  "required"
                                )}
                              </label>
                            </div>

                            <div className="col  s12">
                              <label className="label">Amount</label>
                              <input
                                type="text"
                                placeholder="Amount"
                                name="amount"
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                                value={this.state.fields.amount}
                              />
                              <label className="error">
                                {this.validator.message(
                                  "amount",
                                  this.state.fields.amount,
                                  "required|numeric"
                                )}
                              </label>
                            </div>

                            <div className="col  s12">
                              <label className="label">Remarks</label>
                              <input
                                type="text"
                                placeholder="Remarks"
                                name="remarks"
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                                value={this.state.fields.remarks}
                              />
                              <label className="error">
                                {this.validator.message(
                                  "remarks",
                                  this.state.fields.remarks,
                                  "required"
                                )}
                              </label>
                            </div>
                            <div className="row">
                              <div className="col s12 right-align">
                                <button
                                  type="submit"
                                  className="theme_btn btn"
                                  onClick={this.submitForm}
                                >
                                  {this.state.editItem
                                    ? "Update Cash"
                                    : "Transfer"}
                                  {this.state.isLoading ? (
                                    <ButtonLoader></ButtonLoader>
                                  ) : null}
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </Modal>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col s12">
                      <p className="grey-text">
                        <b>Filters</b>
                      </p>
                      <form>
                        <div className="row">
                          {/* <div class="col m3 s12">
												<input type="text" placeholder="Search" name="Search">
											</div> */}
                          {/* <div className="col m3 s12"> */}
                          {/* <select
                              name='employee'
                              onChange={this.handleSearch}
                            >
                              <option disabled selected>
                                Select Employee
                              </option>
                              {this.state.employeeList.map(
                                (employee, index) => (
                                  <>
                                    <option value={employee.id}>
                                      {employee.name} - {employee.empId}
                                    </option>
                                  </>
                                )
                              )}
                            </select> */}
                          {/* <Select
                              styles={customStyles}
                              placeholder="Select Employee"
                              options={this.state.selectOptionForEmployee}
                              onChange={this.handleSearchSelect}
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 14,
                                borderColor: "#f4f4f4",
                              })}
                            /> */}
                          {/* </div> */}
                          <div className="col m3 s12">
                            <input
                              type="date"
                              className=""
                              placeholder="From Date"
                              name="from"
                              onChange={this.handleSearch}
                            />
                          </div>
                          <div className="col m3 s12">
                            {/* <span>&nbsp;-&nbsp;</span> */}
                            <input
                              type="date"
                              className=""
                              placeholder="To Date"
                              name="to"
                              onChange={this.handleSearch}
                            />
                          </div>
                        </div>

                        <div className="row">
                          {/* <a href="#!" className="red-text pt-1 d-flex">Clear Filter</a> */}
                          {/* <button type="submit" className="red-text pt-1">Clear Filter</button> */}
                          <div class="col m3 s12">
                            <a href="#!" class="red-text pt-1">
                              Clear Filter
                            </a>

                            <button
                              type="submit"
                              className="theme_btn btn filter-submit"
                              onClick={this.submitSearch}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>

                    <div className="col s12">
                      <div className="table-responsive">
                        <table>
                          <thead>
                            <tr>
                              <th>Date</th>
                              <th>Employee ID</th>
                              <th>Employee Name</th>
                              <th>Amount</th>
                              <th>Remarks</th>
                              <th>Status</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.isListLoading ? (
                              <tr>
                                <td colSpan={5}>
                                  <BulletList></BulletList>
                                </td>
                              </tr>
                            ) : (
                              this.state.cashList.map((cash, index) => (
                                <>
                                  <tr key={index}>
                                    <td data-th="Emp ID">{cash.date}</td>
                                    <td data-th="Name">
                                      {cash.employeeDetails.empId}
                                    </td>
                                    <td data-th="Name">
                                      {cash.employeeDetails.name}
                                    </td>
                                    <td data-th="Email">
                                      {formatAmount(cash.amount)}
                                    </td>
                                    <td data-th="Phone">{cash.remarks}</td>
                                    <td data-th="Status">
                                      {cash.status == 1 ? (
                                        <b className="green-text">Approved</b>
                                      ) : cash.status == 2 ? (
                                        <b className="red-text">Rejected</b>
                                      ) : (
                                        <b className="amber-text">Pending</b>
                                      )}
                                    </td>
                                    <td>
                                      {cash.status == 1 ||
                                      cash.status == 2 ? null : (
                                        <a
                                          href="javascript:void(0)"
                                          className="actions"
                                          onClick={() =>
                                            this.showAlert(cash.id)
                                          }
                                        >
                                          <i className="material-icons">
                                            delete
                                          </i>
                                        </a>
                                      )}
                                    </td>
                                  </tr>
                                </>
                              ))
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.state.alert && (
            <SweetAlert
              onConfirm={this.deleteMaster}
              onCancel={this.hideAlert}
              focusCancelBtn={true}
              warning
              showCancel
              confirmBtnText="Yes, delete it!"
              confirmBtnBsStyle="danger"
              title="Are you sure?"
            />
          )}
          <Footer />
        </section>
      </React.Fragment>
    );
  }
}
