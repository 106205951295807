import React, { Component } from "react";
import Footer from "../../components/Footer";
import ProjectHeader from "../../components/header/ProjectHeader";
import Sidebar from "../../components/sidebar/Sidebar";
import ProjectMenu from "../../components/sidebar/ProjectMenu";
import ButtonLoader from "../../components/ButtonLoader";
import { Modal, DatePicker, Button } from "react-materialize";
import axios from "axios";
import { toast } from "react-toastify";
import { setUserData, getUserData, getProjectID } from "../../helpers/storage";
import { formatAmount } from "../../helpers/util";
import { Tabs, Tab } from "react-materialize";

import SweetAlert from "react-bootstrap-sweetalert";
import { BulletList } from "react-content-loader";
import SimpleReactValidator from "simple-react-validator";

import {
  apiListEmployees,
  apiCreateCashRecievables,
  apiListCashRecievables,
  getcashrecievablesbyid,
  apiEditCashRecievables,
  apiEditCashTransfer,
  apisingleCashRecievables,
  apideleteCashRecievables,
} from "../../config/endpoints";
import { Helmet } from "react-helmet";
const initialState = {
  fields: {
    date: "",
    employee: "",
    amount: "",
    remarks: "",
    status: "",
  },
  isLoading: false,
  redirect: false,
  errors: {},
  isModalOpen: false,
  isViewModalOpen: false,
  isListLoading: false,
  alert: false,
  deleteId: "",
  editId: false,
  editItem: false,
  edit_type: "",
  cashAssignList: [],
  cashTransferList: [],
  employeeList: [],
  logs: [],
  currentTab1: true,
};
export default class CashRecievables extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.editRecievables = this.editRecievables.bind(this);
  }

  handleOpenModal = () => {
    console.log("hey");
    this.setState({ isModalOpen: true });
    this.validator.hideMessages();
  };
  handleCloseModal = () => {
    this.setState(initialState);
    this.loadRecievablesList();
    this.validator.hideMessages();
  };

  handleOpenViewModal = (id, type) => {
    this.getRecievableLog(id, type);
    this.setState({ isViewModalOpen: true });
  };
  handleCloseViewModal = () => {
    this.setState({ isViewModalOpen: false });
  };

  toggleTab = () => {
    this.setState({ currentTab1: !this.state.currentTab1 });
  };

  getRecievableLog(id, type) {
    let userData = getUserData();
    let projectId = getProjectID();
    fetch(getcashrecievablesbyid + "?id=" + id + "&type=" + type, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          this.setState({
            logs: data.data.cashLogs,
          });
          console.log(this.state.logs);
        } else {
          alert("something went wrong.");
        }
      });
  }

  handleChange = (e) => {
    console.log(e.target.name, e.target.value);
    this.validator.showMessageFor(e.target.name);
    const value = e.target.value;
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        [e.target.name]: value,
      },
    }));
  };

  handleBlur = (e) => {
    this.validator.showMessageFor(e.target.name);
  };

  /* handleSearch = (e) => {
    this.validator.showMessageFor(e.target.name);
    const value = e.target.value;
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        [e.target.name]: value,
      },
    }));
  };
  handleCloseModal = () => {
    this.setState(initialState);
    this.loadEmployees();
    this.loadCashList();
    this.validator.hideMessages();
  };
  
  showAlert = (id) => {
    console.log(id);
    this.setState({
      alert: true,
      deleteId: id,
    });
  };
  hideAlert = () => {
    this.setState({
      alert: false,
    });
  };
  deleteMaster = (e) => {
    console.log("delete", this.state);
    this.setState({ alert: false });
    let userData = getUserData();
    axios
      .post(
        apideleteCashRecievables,
        { id: this.state.deleteId },
        {
          headers: {
            Authorization: "Bearer " + userData.token,
          },
        }
      )
      .then(
        (response) => {
          console.log(response);
          this.setState({ isLoading: false });
          toast.info("Record deleted", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          this.setState(this.initialState);
          this.loadCashList();
        },
        (error) => {
          if (error.response.status == 500 || error.response.status == 400) {
            toast.error(error.response.data.errorMessage, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            this.setState({ isLoading: false });
          }
        }
      );
  };
  handleChange = (e) => {
    this.validator.showMessageFor(e.target.name);
    const value = e.target.value;
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        [e.target.name]: value,
      },
    }));
    console.log(this.state);
  };
  
  
   */

  loadEmployees = (e) => {
    let userData = getUserData();
    this.setState({ isListLoading: true });
    fetch(apiListEmployees, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("employee list", data.data.employees.rows);
        if (data.code == 200) {
          this.setState({
            employeeList: data.data.employees.rows,
            isListLoading: false,
          });
          /* this.setState((prevState) => ({
            fields: {
              // object that we want to update
              ...prevState.fields, // keep all other key-value pairs
              empId: data.data.empId, // update the value of specific key
            },
          })); */
        } else {
          alert("something went wrong.");
        }
        // setTimeout(function () {
      });
  };

  loadRecievablesList = (e) => {
    let userData = getUserData();
    let projectId = getProjectID();
    this.setState({ isListLoading: true });
    fetch(apiListCashRecievables + "?project_id=" + projectId, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          console.log("cashtransfer ", data.data.cashTransfer.rows);
          console.log("cashAssign ", data.data.cashAssign.rows);

          this.setState({
            cashTransferList: data.data.cashTransfer.rows,
            cashAssignList: data.data.cashAssign.rows,
            isListLoading: false,
          });
        } else {
          alert("something went wrong.");
        }
      });
  };

  editRecievables(id, type) {
    this.setState({ editItem: true, editId: id, edit_type: type });
    this.handleOpenModal();
    let userData = getUserData();
    fetch(getcashrecievablesbyid + "?id=" + id + "&type=" + type, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === 200) {
          console.log("get recievablesbyid", data.data.recievables);
          this.setState({
            fields: {
              date: data.data.recievables.date,
              /* employee: data.data.recievables.employeeDetails.name, */
              amount: data.data.recievables.amount,
              remarks: data.data.recievables.remarks,
              status: data.data.recievables.status,
            },
            logs: data.data.cashLogs,
          });
        } else {
          alert("something went wrong.");
        }
      });
  }

  loadEmployees = (e) => {
    let userData = getUserData();
    this.setState({ isListLoading: true });
    fetch(apiListEmployees, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        //console.log(data.data.employees.rows);
        if (data.code == 200) {
          this.setState({
            employeeList: data.data.employees.rows,
            isListLoading: false,
          });
          this.setState((prevState) => ({
            fields: {
              // object that we want to update
              ...prevState.fields, // keep all other key-value pairs
              empId: data.data.empId, // update the value of specific key
            },
          }));
        } else {
          alert("something went wrong.");
        }
        // setTimeout(function () {
      });
  };

  submitSearch = (e) => {
    e.preventDefault();
    let userData = getUserData();

    this.setState({ isListLoading: true });
    fetch(
      apiListCashRecievables +
        "?employee=" +
        this.state.fields.employee +
        "&from=" +
        (this.state.fields.from != undefined ? this.state.fields.from : "") +
        "&to=" +
        (this.state.fields.to != undefined ? this.state.fields.to : ""),
      {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + userData.token,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          console.log("inside sumbit serach", data);
          this.setState({
            cashTransferList: data.data.cashTransfer.rows,
            cashAssignList: data.data.cashAssign.rows,
            isListLoading: false,
          });
          //console.log(this.state.dayBook);
        } else {
          alert("something went wrong.");
        }
        // setTimeout(function () {
      });
  };
  /* handleSearch = (e) => {
    this.validator.showMessageFor(e.target.name);
    console.log(e.target.name, e.target.value);
    const value = e.target.value;
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        [e.target.name]: value,
      },
    }));
  }; */

  handleSearch = (e) => {
    this.validator.showMessageFor(e.target.name);
    const value = e.target.value;
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        [e.target.name]: value,
      },
    }));
  };

  submitForm = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      console.log("submit");
      console.log("inside submit", this.state);
      console.log(getUserData());
      let userData = getUserData();

      let data = {
        amount: this.state.fields.amount,
        remarks: this.state.fields.remarks,
        id: this.state.editId,
        status: this.state.fields.status,
      };
      this.setState({ isLoading: true });
      if (this.state.edit_type === "cash_assign") {
        axios
          .post(apiEditCashRecievables, data, {
            headers: {
              Authorization: "Bearer " + userData.token,
            },
          })
          .then(
            (response) => {
              console.log("edit cash assign res", response);
              localStorage.setItem(
                "additionalData",
                JSON.stringify(response.data.data.user)
              );
              //this.setState({ cashInHand: response.data.data.user.cashinhand });
              this.setState({ isLoading: false });
              toast.info("Recievables updated", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
              this.setState(this.initialState);
              this.handleCloseModal();
            },
            (error) => {
              if (
                error.response.status == 500 ||
                error.response.status == 400
              ) {
                toast.error(error.response.data.errorMessage, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                this.setState({ isLoading: false });
              }
            }
          );
      } else if (this.state.edit_type === "cash_transfer") {
        axios
          .post(apiEditCashTransfer, data, {
            headers: {
              Authorization: "Bearer " + userData.token,
            },
          })
          .then(
            (response) => {
              console.log(response);
              console.log("edit cash transfer res", response);

              this.setState({ isLoading: false });
              localStorage.setItem(
                "additionalData",
                JSON.stringify(response.data.data.user)
              );
              toast.info("Recievables updated", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
              this.setState(this.initialState);
              this.handleCloseModal();
            },
            (error) => {
              if (
                error.response.status == 500 ||
                error.response.status == 400
              ) {
                toast.error(error.response.data.errorMessage, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                this.setState({ isLoading: false });
              }
            }
          );
      }
    } else {
      console.log("fail");
      console.log("errors", this.validator.getErrorMessages());
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  componentDidMount() {
    //console.log("cashinhand in recivables", this.state.cashInHand);
    this.loadEmployees();
    this.loadRecievablesList();
    console.log("projectId inside recivables", getProjectID());
  }

  clearFilter = () => {
    this.myFormRef.reset();
    this.loadEmployees();
    this.loadRecievablesList();
  };

  render() {
    this.validator.purgeFields();
    return (
      <React.Fragment>
        <Helmet>
          <title>Employe Expense | MRC</title>
        </Helmet>
        <section className="adminProject">
          <div className="wrapper d-flex">
            <Sidebar />
            <div className="wrapper_right">
              <ProjectHeader />
              <div className="d-flex">
                <ProjectMenu />
                <div className="wrapper_body">
                  <div className="row">
                    <div className="col m8 s12">
                      <h3 className="heading">Recievables</h3>
                    </div>
                    <div className="col m4 s12 right-align">
                      <Modal
                        actions={[,]}
                        bottomSheet={false}
                        fixedFooter={false}
                        id="Modal-0"
                        open={this.state.isModalOpen}
                        className="cstm-modal"
                        options={{
                          dismissible: false,
                          endingTop: "10%",
                          inDuration: 250,
                          onCloseEnd: null,
                          onCloseStart: null,
                          onOpenEnd: null,
                          onOpenStart: null,
                          opacity: 0.5,
                          outDuration: 250,
                          preventScrolling: true,
                          startingTop: "4%",
                        }}
                      >
                        {/* Close Modal */}
                        <a
                          href="javascript:Void(0)"
                          className="modal-close"
                          onClick={this.handleCloseModal}
                        >
                          <span className="material-icons">chevron_right</span>
                        </a>
                        {/* Modal Content */}
                        <h3 className="heading">Recievable</h3>
                        <p>Enter details to recieve cash</p>
                        <form action id="createListForm">
                          <div className="row">
                            <div className="col  s12">
                              <label className="label">Amount</label>
                              <input
                                type="text"
                                placeholder="Amount"
                                name="amount"
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                                value={this.state.fields.amount}
                              />
                              <label className="error">
                                {this.validator.message(
                                  "amount",
                                  this.state.fields.amount,
                                  "required|numeric"
                                )}
                              </label>
                            </div>
                            <div className="col s12">
                              <label className="label">Status</label>
                              <select
                                name="status"
                                onChange={this.handleChange}
                                value={this.state.fields.status}
                              >
                                <option selected>Select</option>
                                <option value="1">Approve</option>
                                <option value="2">Reject</option>
                              </select>
                            </div>

                            <div className="col  s12">
                              <label className="label">Remarks</label>
                              <input
                                type="text"
                                placeholder="Remarks"
                                name="remarks"
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                                value={this.state.fields.remarks}
                              />
                              <label className="error">
                                {this.validator.message(
                                  "remarks",
                                  this.state.fields.remarks,
                                  "required"
                                )}
                              </label>
                            </div>
                            <div className="row">
                              <div className="col s12 right-align">
                                <button
                                  type="submit"
                                  className="theme_btn btn"
                                  onClick={this.submitForm}
                                >
                                  Update
                                  {this.state.isLoading ? (
                                    <ButtonLoader></ButtonLoader>
                                  ) : null}
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                        <p>Activity Logs</p>
                        <table>
                          {this.state.logs
                            ? this.state.logs.map((lg, index) => (
                                <tr>
                                  <td>{lg.log}</td>
                                </tr>
                              ))
                            : null}
                        </table>
                      </Modal>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col s12">
                      <p className="grey-text">
                        <b>Filters</b>
                      </p>
                      <form ref={(ele) => (this.myFormRef = ele)}>
                        <div className="row">
                          {!this.state.currentTab1 ? (
                            <div className="col m3 s12">
                              <select
                                name="employee"
                                onChange={this.handleSearch}
                              >
                                <option selected>Select Employee</option>
                                {this.state.employeeList.map(
                                  (employee, index) => (
                                    <>
                                      <option value={employee.id}>
                                        {employee.name} - {employee.empId}
                                      </option>
                                    </>
                                  )
                                )}
                              </select>
                            </div>
                          ) : null}

                          <div className="col m3 s12">
                            <input
                              type="date"
                              className=""
                              placeholder="From Date"
                              name="from"
                              onChange={this.handleSearch}
                            />
                          </div>
                          <div className="col m3 s12">
                            {/* <span>&nbsp;-&nbsp;</span> */}
                            <input
                              type="date"
                              className=""
                              placeholder="To Date"
                              name="to"
                              onChange={this.handleSearch}
                            />
                          </div>
                        </div>

                        <div className="row">
                          {/* <a href="#!" className="red-text pt-1 d-flex">Clear Filter</a> */}
                          {/* <button type="submit" className="red-text pt-1">Clear Filter</button> */}
                          <div class="col m3 s12">
                            <a
                              href="#!"
                              class="red-text pt-1"
                              onClick={this.clearFilter}
                            >
                              Clear Filter
                            </a>

                            <button
                              type="submit"
                              className="theme_btn btn filter-submit"
                              onClick={this.submitSearch}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>

                    <div className="col s12" style={{ marginTop: "20px" }}>
                      <Tabs
                        className="tab-demo z-depth-1 tab-custom"
                        scope="tabs-24"
                        onChange={this.toggleTab}
                      >
                        <Tab
                          active
                          options={{
                            duration: 300,
                            onShow: null,
                            responsiveThreshold: Infinity,
                            swipeable: false,
                          }}
                          title="Cash Assign & Transfer"
                        >
                          <div className="table-responsive">
                            <table>
                              <thead>
                                <tr>
                                  <th>Date</th>
                                  <th>Employee ID</th>
                                  <th>Employee Name</th>
                                  <th>Type</th>
                                  <th>Amount</th>
                                  <th>Remarks</th>
                                  <th>Status</th>
                                  <th>Log</th>
                                  <th>Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.isListLoading ? (
                                  <tr>
                                    <td colSpan={5}>
                                      <BulletList></BulletList>
                                    </td>
                                  </tr>
                                ) : this.state.cashAssignList &&
                                  this.state.cashAssignList.length > 0 ? (
                                  this.state.cashAssignList.map((db, index) => (
                                    <>
                                      <tr key={index}>
                                        <td data-th="Date">{db.date}</td>
                                        <td data-th="EmpId">
                                          {db.employeeDetails.empId}
                                        </td>
                                        <td data-th="Name">
                                          {db.employeeDetails.name}
                                        </td>
                                        <td data-th="Type">
                                          <span style={{ color: "green" }}>
                                            Assign
                                          </span>
                                        </td>
                                        <td data-th="Amount">
                                          <i class="fas fa-rupee-sign"></i>
                                          {formatAmount(db.amount)}
                                        </td>
                                        <td data-th="Remarks">{db.remarks}</td>
                                        <td data-th="Status">
                                          {db.status == 1 ? (
                                            <b className="green-text">
                                              Approved
                                            </b>
                                          ) : db.status == 2 ? (
                                            <b className="red-text">Rejected</b>
                                          ) : (
                                            <b className="amber-text">
                                              Pending
                                            </b>
                                          )}
                                        </td>

                                        <td>
                                          <Modal
                                            bottomSheet={false}
                                            fixedFooter={false}
                                            id="Modal-1"
                                            open={this.state.isViewModalOpen}
                                            className="cstm-modal"
                                            options={{
                                              dismissible: false,
                                              endingTop: "10%",
                                              inDuration: 250,
                                              onCloseEnd: null,
                                              onCloseStart: null,
                                              onOpenEnd: null,
                                              onOpenStart: null,
                                              opacity: 0.5,
                                              outDuration: 250,
                                              preventScrolling: true,
                                              startingTop: "4%",
                                            }}
                                          >
                                            {/* Close Modal */}
                                            <a
                                              href="javascript:Void(0)"
                                              className="modal-close"
                                              onClick={
                                                this.handleCloseViewModal
                                              }
                                            >
                                              <span className="material-icons">
                                                chevron_right
                                              </span>
                                            </a>
                                            {/* Modal Content */}
                                            <h3 className="heading">
                                              Activity Logs
                                            </h3>
                                            <p>
                                              Details of the actions in this
                                              entry
                                            </p>
                                            <table>
                                              {this.state.logs
                                                ? this.state.logs.map(
                                                    (lg, index) => (
                                                      <tr>
                                                        <td>{lg.log}</td>
                                                      </tr>
                                                    )
                                                  )
                                                : null}
                                            </table>
                                          </Modal>
                                          <a
                                            href="javascript:void(0)"
                                            className="actions"
                                            node="button"
                                            onClick={() =>
                                              this.handleOpenViewModal(
                                                db.id,
                                                "cash_assign"
                                              )
                                            }
                                          >
                                            View
                                          </a>
                                        </td>

                                        <td>
                                          {db.status != 1 ? (
                                            <a
                                              href="#Modal-0"
                                              className="actions"
                                              node="button"
                                              onClick={() =>
                                                this.editRecievables(
                                                  db.id,
                                                  "cash_assign"
                                                )
                                              }
                                            >
                                              <i className="material-icons">
                                                edit
                                              </i>
                                            </a>
                                          ) : null}
                                        </td>
                                      </tr>
                                    </>
                                  ))
                                ) : (
                                  ""
                                )}
                                {this.state.isListLoading ? (
                                  <tr>
                                    <td colSpan={5}>
                                      <BulletList></BulletList>
                                    </td>
                                  </tr>
                                ) : this.state.cashTransferList &&
                                  this.state.cashTransferList.length > 0 ? (
                                  this.state.cashTransferList.map(
                                    (db, index) => (
                                      <>
                                        <tr key={index}>
                                          <td data-th="Date">{db.date}</td>
                                          <td data-th="EmpId">
                                            {db.employeeDetailsSender.empId}
                                          </td>
                                          <td data-th="Name">
                                            {db.employeeDetailsSender.name}
                                          </td>
                                          <td data-th="Type">
                                            <span style={{ color: "orange" }}>
                                              Transfer
                                            </span>
                                          </td>
                                          <td data-th="Amount">
                                            <i class="fas fa-rupee-sign"></i>
                                            {formatAmount(db.amount)}
                                          </td>
                                          <td data-th="Remarks">
                                            {db.remarks}
                                          </td>
                                          <td data-th="Status">
                                            {db.status == 1 ? (
                                              <b className="green-text">
                                                Approved
                                              </b>
                                            ) : db.status == 2 ? (
                                              <b className="red-text">
                                                Rejected
                                              </b>
                                            ) : (
                                              <b className="amber-text">
                                                Pending
                                              </b>
                                            )}
                                          </td>

                                          <td>
                                            <Modal
                                              bottomSheet={false}
                                              fixedFooter={false}
                                              id="Modal-1"
                                              open={this.state.isViewModalOpen}
                                              className="cstm-modal"
                                              options={{
                                                dismissible: false,
                                                endingTop: "10%",
                                                inDuration: 250,
                                                onCloseEnd: null,
                                                onCloseStart: null,
                                                onOpenEnd: null,
                                                onOpenStart: null,
                                                opacity: 0.5,
                                                outDuration: 250,
                                                preventScrolling: true,
                                                startingTop: "4%",
                                              }}
                                            >
                                              {/* Close Modal */}
                                              <a
                                                href="javascript:Void(0)"
                                                className="modal-close"
                                                onClick={
                                                  this.handleCloseViewModal
                                                }
                                              >
                                                <span className="material-icons">
                                                  chevron_right
                                                </span>
                                              </a>
                                              {/* Modal Content */}
                                              <h3 className="heading">
                                                Activity Logs
                                              </h3>
                                              <p>
                                                Details of the actions in this
                                                entry
                                              </p>
                                              <table>
                                                {this.state.logs
                                                  ? this.state.logs.map(
                                                      (lg, index) => (
                                                        <tr>
                                                          <td>{lg.log}</td>
                                                        </tr>
                                                      )
                                                    )
                                                  : null}
                                              </table>
                                            </Modal>
                                            <a
                                              href="javascript:void(0)"
                                              className="actions"
                                              node="button"
                                              onClick={() =>
                                                this.handleOpenViewModal(
                                                  db.id,
                                                  "cash_transfer"
                                                )
                                              }
                                            >
                                              View
                                            </a>
                                          </td>

                                          <td>
                                            {db.status != 1 ? (
                                              <a
                                                href="#Modal-0"
                                                className="actions"
                                                node="button"
                                                onClick={() =>
                                                  this.editRecievables(
                                                    db.id,
                                                    "cash_transfer"
                                                  )
                                                }
                                              >
                                                <i className="material-icons">
                                                  edit
                                                </i>
                                              </a>
                                            ) : null}
                                          </td>
                                        </tr>
                                      </>
                                    )
                                  )
                                ) : (
                                  ""
                                )}
                              </tbody>
                            </table>
                          </div>
                        </Tab>
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.state.alert && (
            <SweetAlert
              onConfirm={this.deleteMaster}
              onCancel={this.hideAlert}
              focusCancelBtn={true}
              warning
              showCancel
              confirmBtnText="Yes, delete it!"
              confirmBtnBsStyle="danger"
              title="Are you sure?"
            />
          )}
          <Footer />
        </section>
      </React.Fragment>
    );
  }
}
