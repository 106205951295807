import React, { Component, Fragment } from "react";
import Footer from "../../components/Footer";
import ProjectHeader from "../../components/header/ProjectHeader";
import Sidebar from "../../components/sidebar/Sidebar";
import ProjectMenu from "../../components/sidebar/ProjectMenu";
import AdminHeader from "../../components/header/AdminHeader";
import AdminMenu from "../../components/sidebar/AdminMenu";
import axios from "axios";
import { toast } from "react-toastify";
import "materialize-css";
import { Modal } from "react-materialize";
import { getUserData } from "../../helpers/storage";
import SweetAlert from "react-bootstrap-sweetalert";
import ButtonLoader from "../../components/ButtonLoader";
import { BulletList } from "react-content-loader";
import SimpleReactValidator from "simple-react-validator";
import { Helmet } from "react-helmet";

import {
  apiListRecurring,
  apiCreateRecurring,
  apiEditRecurring,
  apiGetRecurringById,
  apiGetRecurringByType,
} from "../../config/endpoints";

const initialState = {
  fields: {
    mode: "",
    description: "",
    noofconnections: "",
    companyname: "",
    lastpayment: "",
    amountpaid: "",
    newbilldate: "",
    billamount: "",
    duedate: "",
    bill: "",
    remarks: "",
  },
  isLoading: false,
  redirect: false,
  errors: {},
  vendorList: [],
  isModalOpen: false,
  isListLoading: false,
  alert: false,
  deleteId: "",
  editId: false,
  editItem: false,
  projectList: [],
  masterListTender: [],
  recurringList: [],
};

export default class RecurringPayments extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = initialState;
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
  }

  handleCloseModal = () => {
    this.setState(initialState);
    this.loadRecurring();
  };
  handleOpenModal = () => {
    this.setState({ isModalOpen: true });
    this.validator.hideMessages();
    this.loadRecurring();
  };

  editRecurringPayment(id) {
    // Assuming only image
    this.setState({ editItem: true, editId: id });
    this.handleOpenModal();
    let userData = getUserData();
    fetch(apiGetRecurringById + "?id=" + id, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          this.setState({
            fields: data.data.recurring,
          });
        } else {
          alert("something went wrong.");
        }
      });
  }

  showAlert = (id) => {
    //console.log(id);
    this.setState({
      alert: true,
      deleteId: id,
    });
  };
  hideAlert = () => {
    this.setState({
      alert: false,
    });
  };

  loadRecurring = (e) => {
    let userData = getUserData();
    this.setState({ isListLoading: true });
    fetch(apiListRecurring, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          console.log("recurring payment list", data.data.recurring.rows);
          this.setState({
            recurringList: data.data.recurring.rows,
            isListLoading: false,
          });
        } else {
          alert("something went wrong.");
        }
      });
  };
  handleChange = (e) => {
    this.validator.showMessageFor(e.target.name);
    const value = e.target.value;
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        [e.target.name]: value,
      },
    }));
  };

  handleChangeDesc = (e) => {
    this.validator.showMessageFor(e.target.name);
    const value = e.target.value;
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        [e.target.name]: value,
      },
    }));
    let userData = getUserData();
    this.setState({ isListLoading: true });
    fetch(apiGetRecurringByType + "?description=" + value, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          console.log("recurring payment list", data.data.recurring);
          if (data.data.recurring) {
            this.setState((prevState) => ({
              fields: {
                ...prevState.fields,
                lastpayment: data.data.recurring.billamount,
              },
            }));
          } else {
            this.setState((prevState) => ({
              fields: {
                ...prevState.fields,
                lastpayment: "",
              },
            }));
          }
        } else {
          alert("something went wrong.");
        }
      });
  };

  fileChange = (e) => {
    //console.log("test", e);
    if (e.target.files[0]) {
      //console.log("picture: ", e.target.files);
      //this.setState({ bill: e.target.files[0] });
      this.setState((prevState) => ({
        fields: {
          ...prevState.fields,
          [e.target.name]: e.target.files[0],
        },
      }));
    }
    // Updating the city
  };

  handleBlur = (e) => {
    this.validator.showMessageFor(e.target.name);
  };

  submitForm = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      /* console.log("submit");
      console.log(this.state);
      console.log(getUserData()); */
      let userData = getUserData();
      if (!this.state.editItem) {
        let bodyFormData = new FormData();
        bodyFormData.append("mode", this.state.fields.mode);
        bodyFormData.append("description", this.state.fields.description);
        bodyFormData.append(
          "noofconnections",
          this.state.fields.noofconnections
        );
        bodyFormData.append("companyname", this.state.fields.companyname);
        bodyFormData.append("lastpayment", this.state.fields.lastpayment);
        bodyFormData.append("amountpaid", this.state.fields.amountpaid);
        bodyFormData.append("newbilldate", this.state.fields.newbilldate);
        bodyFormData.append("billamount", this.state.fields.billamount);
        bodyFormData.append("duedate", this.state.fields.duedate);
        bodyFormData.append("bill", this.state.fields.bill);
        bodyFormData.append("remarks", this.state.fields.remarks);
        this.setState({ isLoading: true });

        //console.log(data);

        axios
          .post(apiCreateRecurring, bodyFormData, {
            headers: {
              Authorization: "Bearer " + userData.token,
            },
          })
          .then(
            (response) => {
              //console.log(response);
              // this.setState({ isLoading: false });
              toast.info("Recurring Payment created", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
              this.setState(this.initialState);
              this.handleCloseModal();
            },
            (error) => {
              if (
                error.response.status == 500 ||
                error.response.status == 400
              ) {
                toast.error(error.response.data.errorMessage, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                this.setState({ isLoading: false });
              }
            }
          );
      } else {
        let bodyFormData = new FormData();
        bodyFormData.append("mode", this.state.fields.mode);
        bodyFormData.append("description", this.state.fields.description);
        bodyFormData.append(
          "noofconnections",
          this.state.fields.noofconnections
        );
        bodyFormData.append("companyname", this.state.fields.companyname);
        bodyFormData.append("lastpayment", this.state.fields.lastpayment);
        bodyFormData.append("amountpaid", this.state.fields.amountpaid);
        bodyFormData.append("newbilldate", this.state.fields.newbilldate);
        bodyFormData.append("billamount", this.state.fields.billamount);
        bodyFormData.append("duedate", this.state.fields.duedate);
        bodyFormData.append("bill", this.state.fields.bill);
        bodyFormData.append("remarks", this.state.fields.remarks);
        bodyFormData.append("id", this.state.editId);

        this.setState({ isLoading: true });
        axios
          .post(apiEditRecurring, bodyFormData, {
            headers: {
              Authorization: "Bearer " + userData.token,
            },
          })
          .then(
            (response) => {
              //console.log(response);
              // this.setState({ isLoading: false });
              toast.info("Recurring Payment updated", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
              this.setState(this.initialState);
              this.handleCloseModal();
            },
            (error) => {
              if (
                error.response.status == 500 ||
                error.response.status == 400
              ) {
                toast.error(error.response.data.errorMessage, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                this.setState({ isLoading: false });
              }
            }
          );
      }
    } else {
      //console.log("fail");
      //console.log("errors", this.validator.getErrorMessages());
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  componentDidMount() {
    this.loadRecurring();
  }

  render() {
    this.validator.purgeFields();
    return (
      <React.Fragment>
        <Helmet>
          <title>Recurring Payment | MRC</title>
        </Helmet>
        <section className="admin">
          <AdminHeader></AdminHeader>
          <div className="wrapper d-flex">
            <AdminMenu></AdminMenu>

            <div className="wrapper_body">
              <div className="row d-flex align-items-center">
                <div className="col m0 d-flex align-items-center">
                  <h3 className="heading">Recurring Payment</h3>
                </div>
                <div className="col m4 right-align">
                  <a
                    href="javascript:Void(0)"
                    className="theme_btn btn  modal-trigger"
                    onClick={this.handleOpenModal}
                  >
                    <i className="material-icons">add</i> Create Payment
                  </a>
                  <Modal
                    actions={[,]}
                    bottomSheet={false}
                    fixedFooter={false}
                    id="Modal-0"
                    open={this.state.isModalOpen}
                    className="cstm-modal"
                    options={{
                      dismissible: false,
                      endingTop: "10%",
                      inDuration: 250,
                      onCloseEnd: null,
                      onCloseStart: null,
                      onOpenEnd: null,
                      onOpenStart: null,
                      opacity: 0.5,
                      outDuration: 250,
                      preventScrolling: true,
                      startingTop: "4%",
                    }}
                  >
                    {/* Close Modal */}
                    <a
                      href="javascript:Void(0)"
                      className="modal-close"
                      onClick={this.handleCloseModal}
                    >
                      <span className="material-icons">chevron_right</span>
                    </a>
                    {/* Modal Content */}
                    <h3 className="heading">
                      {this.state.editItem
                        ? "Update Payment"
                        : "Create Payment"}
                    </h3>
                    <p>
                      Enter details to{" "}
                      {this.state.editItem ? "update" : "create new"} Payment
                    </p>
                    <form action id="createContractorForm">
                      <div className="row">
                        <div className="col s12">
                          <label className="label">Mode</label>
                          <select
                            name="mode"
                            value={this.state.fields.mode}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                          >
                            <option>Select Mode</option>
                            <option value="Active">Active</option>
                            <option value="Expired">Expired</option>
                          </select>
                          <label className="error">
                            {this.validator.message(
                              "mode",
                              this.state.fields.mode,
                              "required"
                            )}
                          </label>
                        </div>

                        <div className="col  s12">
                          <label className="label">Description</label>
                          <select
                            name="description"
                            onChange={this.handleChangeDesc}
                            onBlur={this.handleChangeDesc}
                            value={this.state.fields.description}
                          >
                            <option>Select Description</option>
                            <option value="Leased and Rent">
                              Leased and Rent
                            </option>
                            <option value="Electricity">Electricity</option>
                            <option value="Advertisement">Advertisement</option>
                            <option value="Others">Others</option>
                          </select>

                          <label className="error">
                            {this.validator.message(
                              "description",
                              this.state.fields.description,
                              "required"
                            )}
                          </label>
                        </div>

                        <div className="col s12">
                          <label className="label">No. of Connections</label>
                          <input
                            type="text"
                            placeholder="No. of Connections"
                            name="noofconnections"
                            value={this.state.fields.noofconnections}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                          />
                          <label className="error">
                            {this.validator.message(
                              "noofconnections",
                              this.state.fields.noofconnections,
                              "required"
                            )}
                          </label>
                        </div>
                        <div className="col s12">
                          <label className="label">Company Name</label>
                          <input
                            type="text"
                            placeholder="Company Name"
                            name="companyname"
                            value={this.state.fields.companyname}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                          />
                          <label className="error">
                            {this.validator.message(
                              "companyname",
                              this.state.fields.companyname,
                              "required"
                            )}
                          </label>
                        </div>
                        <div className="col s12">
                          <label className="label">Last Payment</label>
                          <input
                            type="text"
                            placeholder="Last Payment"
                            name="lastpayment"
                            value={this.state.fields.lastpayment}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                          />
                          <label className="error">
                            {this.validator.message(
                              "lastpayment",
                              this.state.fields.lastpayment,
                              "required"
                            )}
                          </label>
                        </div>
                        <div className="col s12">
                          <label className="label">Amount Paid</label>
                          <input
                            type="text"
                            placeholder="Amount Paid"
                            name="amountpaid"
                            value={this.state.fields.amountpaid}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                          />
                          <label className="error">
                            {this.validator.message(
                              "amountpaid",
                              this.state.fields.amountpaid,
                              "required"
                            )}
                          </label>
                        </div>

                        <div className="col  s12">
                          <label className="label">New Bill Date</label>
                          <input
                            type="date"
                            name="newbilldate"
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            value={this.state.fields.newbilldate}
                          />

                          <label className="error">
                            {this.validator.message(
                              "newbilldate",
                              this.state.fields.newbilldate,
                              "required"
                            )}
                          </label>
                        </div>

                        <div className="col s12">
                          <label className="label">Bill Amount</label>
                          <input
                            type="text"
                            placeholder="Bill Amount"
                            name="billamount"
                            value={this.state.fields.billamount}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                          />
                          <label className="error">
                            {this.validator.message(
                              "billamount",
                              this.state.fields.billamount,
                              "required"
                            )}
                          </label>
                        </div>

                        <div className="col  s12">
                          <label className="label">Due Date</label>
                          <input
                            type="date"
                            name="duedate"
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            value={this.state.fields.duedate}
                          />

                          <label className="error">
                            {this.validator.message(
                              "duedate",
                              this.state.fields.duedate,
                              "required"
                            )}
                          </label>
                        </div>

                        <div className="col m6 s12">
                          <label className="label">Bill Upload</label>
                          <div className="button-wrap col  s12">
                            <label className="new-button" for="upload">
                              Bill Upload
                              <input
                                id="upload"
                                type="file"
                                name="bill"
                                onChange={this.fileChange}
                              />
                            </label>
                            <br />
                            {this.state.fields.bill && (
                              <>
                                <span>
                                  {this.state.fields.bill.name ||
                                    this.state.fields.bill.split("/").pop()}
                                  <i class="tiny material-icons">attach_file</i>
                                </span>
                              </>
                            )}

                            {this.state.editItem ? null : (
                              <label className="error">
                                {this.validator.message(
                                  "bill upload",
                                  this.state.fields.bill,
                                  "required"
                                )}
                              </label>
                            )}
                          </div>
                        </div>

                        <div className="col s12">
                          <label className="label">Remarks</label>
                          <input
                            type="text"
                            placeholder="Remarks"
                            name="remarks"
                            value={this.state.fields.remarks}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                          />
                          <label className="error">
                            {this.validator.message(
                              "remarks",
                              this.state.fields.remarks,
                              "required"
                            )}
                          </label>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col s12 right-align">
                          <button
                            type="submit"
                            form="createContractorForm"
                            className="theme_btn btn"
                            onClick={this.submitForm}
                          >
                            {this.state.editItem
                              ? "Update Recurring Payment"
                              : "Add Recurring Payment"}
                            {this.state.isLoading ? (
                              <ButtonLoader></ButtonLoader>
                            ) : null}
                          </button>
                        </div>
                      </div>
                    </form>
                  </Modal>
                </div>
              </div>
              <div className="projects employee">
                <div className="row">
                  <div className="col s12">
                    <div className="table-responsive">
                      <table>
                        <thead>
                          <tr>
                            <th>Mode</th>
                            <th>Company name</th>
                            <th>No. of Connections</th>
                            <th>Last Payment</th>
                            <th>Amount Paid</th>
                            <th>Uploaded Bill</th>
                            <th>New Bill Date</th>
                            <th>Bill Amount</th>
                            <th>Due Date</th>
                            <th>Description</th>
                            <th>Remarks</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.isListLoading ? (
                            <tr>
                              <td colSpan={5}>
                                <BulletList></BulletList>
                              </td>
                            </tr>
                          ) : (
                            this.state.recurringList.map((recurring, index) => (
                              <tr key={index}>
                                <td>{recurring.mode}</td>
                                <td>{recurring.companyname}</td>
                                <td>{recurring.noofconnections} </td>
                                <td>{recurring.lastpayment}</td>
                                <td>{recurring.amountpaid} </td>
                                <td>
                                  <a href={recurring.bill} className="actions">
                                    Download
                                    <i class="material-icons">file_download</i>
                                  </a>
                                </td>
                                <td>{recurring.newbilldate}</td>
                                <td>{recurring.billamount}</td>
                                <td>{recurring.duedate}</td>
                                <td>{recurring.description}</td>
                                <td data-th="Remarks">{recurring.remarks} </td>
                                <td>
                                  <a
                                    href="#Modal-0"
                                    className="actions"
                                    node="button"
                                    onClick={() =>
                                      this.editRecurringPayment(recurring.id)
                                    }
                                  >
                                    <i className="material-icons">edit</i>
                                  </a>
                                  {/* <a
                                      href='javascript:void(0)'
                                      className='actions'
                                      onClick={() => this.showAlert(vendor.id)}
                                    >
                                      <i className='material-icons'>delete</i>
                                    </a> */}
                                </td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.state.alert && (
            <SweetAlert
              onConfirm={this.deleteVendor}
              onCancel={this.hideAlert}
              focusCancelBtn={true}
              warning
              showCancel
              confirmBtnText="Yes, delete it!"
              confirmBtnBsStyle="danger"
              title="Are you sure?"
            />
          )}
          <Footer />
        </section>
        {/* Add Contractor Modal */}
      </React.Fragment>
    );
  }
}
