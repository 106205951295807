import React, { Component } from "react";
import Footer from "../Footer";
import Header from "../Header";
import SideBar from "../SideBar";
import { Helmet } from "react-helmet";
import { getUserData } from "../../helpers/storage";

export default class Dashboard extends Component {
  constructor(props) {
    super(props);
    let userData = getUserData();
    console.log("logo", userData);
    this.state = {
      redirect: false,
      user: userData.user,
      additionalData: userData.additionalData,
    };
  }
  render() {
    return (
      <div>
        <div>
          <Helmet>
            <title>Dashboard | MRC</title>
          </Helmet>
          <section className="adminProject">
            <div className="wrapper d-flex">
              <SideBar />

              <div class="wrapper_right">
                <Header />
                <div className="d-flex">
                  <div className="wrapper_body w-100">
                    <div className="row">
                      <div className="col m8 s12">
                        <h3 className="heading">Dashboard</h3>
                      </div>
                    </div>
                    <div className="row" style={{ marginTop: 20 }}>
                      <div className="col m3 s12">
                        <img
                          src={
                            process.env.REACT_APP_APP_URL +
                            "/assets/images/admin/weather.svg"
                          }
                          alt
                        />
                      </div>
                      <div className="col m3 l3 s12">
                        <div className="home-insights">
                          <p>Cash in Hand</p>
                          <h3>
                            <i className="fa fa-rupee-sign"></i>{" "}
                            {this.state.additionalData.cashinhand}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </section>
        </div>
      </div>
    );
  }
}
