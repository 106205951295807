import React, { Component } from 'react'
import Footer from '../Footer'
import Header from '../Header'
import SideBar from '../SideBar'

export default class Boq extends Component {
    render() {
        return (
            <div>
                <div>
                    <title>Project Name | Dashboard</title>
                    {/*Let browser know website is optimized for mobile*/}
                    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                    <section className="adminProject">
                        <div className="wrapper d-flex">
                            <SideBar />
                            <div className="wrapper_right">
                                <Header />
                                <div className="d-flex">

                                    <div className="wrapper_body">
                                        <div className="row">
                                            <div className="col m8 s12">
                                                <h3 className="heading">Bill of Quantities</h3>
                                                <div className="nav-wrapper">
                                                    <a href="javascript:void(0)" className="breadcrumb">Management</a>
                                                    <a href="javascript:void(0)" className="breadcrumb">Bill of Quantities</a>
                                                    <a href="javascript:void(0)" className="breadcrumb">Master</a>
                                                </div>
                                            </div>
                                            <div className="col m4 s12">
                                                <a href="#create_boq_modal" className="float-md-right theme_btn btn waves-effect waves-light modal-trigger"><i className="material-icons">add</i>&nbsp;Create BOQ</a>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col s12">
                                                <div className="table-responsive">
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>Descrition</th>
                                                                <th>Qty</th>
                                                                <th>Unit</th>
                                                                <th>Approved Makes</th>
                                                                <th>Rate</th>
                                                                <th>Amount</th>
                                                                <th>Actions</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td data-th="#">1.1</td>
                                                                <td data-th="Descrition" style={{ whiteSpace: 'normal' }}>
                                                                    <b>Sub-Head 1, Earth Work</b>
                                                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius distinctio ex obcaecati similique a voluptas minus corrupti quam, dolor nisi error soluta ratione temporibus ullam reiciendis laboriosam commodi omnis nobis?</p>
                                                                </td>
                                                                <td data-th="Qty">2429</td>
                                                                <td data-th="Unit">cubic/m</td>
                                                                <td data-th="Approved Makes">N.A.</td>
                                                                <td data-th="Rate">100.00</td>
                                                                <td data-th="Amount"><b>2,42,900.00</b></td>
                                                                <td>
                                                                    <a href="javascript:void(0)" className="actions"><i className="material-icons">edit</i></a>
                                                                    <a href="javascript:void(0)" className="actions"><i className="material-icons">delete</i></a>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td data-th="#">1.2</td>
                                                                <td data-th="Descrition" style={{ whiteSpace: 'normal' }}>
                                                                    <b>Sub-Head 1, Earth Work</b>
                                                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius distinctio ex obcaecati similique a voluptas minus corrupti quam, dolor nisi error soluta ratione temporibus ullam reiciendis laboriosam commodi omnis nobis?</p>
                                                                </td>
                                                                <td data-th="Qty">2429</td>
                                                                <td data-th="Unit">cubic/m</td>
                                                                <td data-th="Approved Makes">N.A.</td>
                                                                <td data-th="Rate">100.00</td>
                                                                <td data-th="Amount"><b>2,42,900.00</b></td>
                                                                <td>
                                                                    <a href="javascript:void(0)" className="actions"><i className="material-icons">edit</i></a>
                                                                    <a href="javascript:void(0)" className="actions"><i className="material-icons">delete</i></a>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </section>
                    {/* Create BOQ Modal */}
                    <div id="create_boq_modal" className="cstm-modal modal">
                        {/* Close Modal */}
                        <a href="javascript:Void(0)" className="modal-close"><span className="material-icons">chevron_right</span></a>
                        {/* Modal Content */}
                        <h3 className="heading">Create BOQ</h3>
                        <p>Enter details below to create BOQ</p>
                        <form action id="createBoqForm">
                            <div className="row">
                                <div className="col s5 m4">

                                    <label className="label">Sr.No.</label>
                                    <input type="text" placeholder="Sr.No." name="sr.No." required />
                                </div>
                                <div className="col s7 m8">
                                    <a href="#!" className="theme_btn btn waves-effect waves-light"><i className="material-icons" /> Import</a>
                                </div>
                                <div className="col s12">

                                    <label className="label">Description</label>
                                    <textarea className="materialize-textarea" name="Description" placeholder="Description" rows={6} defaultValue={""} />
                                    {/* <input type="text" placeholder="Sr.No." name="sr.No." required> */}
                                </div>
                                <div className="col s12 m6">

                                    <label className="label">Quantity</label>
                                    <input type="number" placeholder="Quantity" name="Quantity" required />
                                </div>
                                <div className="col s12 m6">

                                    <label className="label">Select Unit</label>
                                    <select name="select unit">
                                        <option disabled selected>Select Unit</option>
                                        <option value={1}>cubic/m</option>
                                        <option value={1}>sq/m</option>
                                    </select>
                                </div>
                                <div className="col s12">

                                    <label className="label">Approved Makes</label>
                                    <input type="text" placeholder="Approved Makes" name="Approved Makes" />
                                </div>
                                <div className="col s12 m6">

                                    <label className="label">Rate</label>
                                    <input type="text" placeholder="Rate" name="Rate" required />
                                </div>
                                <div className="col s12 m6">

                                    <label className="label">Amount</label>
                                    <input type="text" placeholder="Amount" name="Amount" required />
                                </div>
                                <div className="col s12">
                                    <p>
                                        <label>
                                            <input type="checkbox" className="filled-in" />
                                            <span>Save and create new BOQ</span>
                                        </label>
                                    </p>
                                </div>
                                <div className="col s12 right-align">
                                    <button type="submit" form="createBoqForm" className="theme_btn btn waves-effect waves-light">Submit<i /></button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
        )
    }
}
