import React, { Component } from 'react'
import Footer from '../Footer'
import Header from '../Header'
import SideBar from '../SideBar'
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
// import 'bootstrap/dist/css/bootstrap.min.css';




export default class Drawings extends Component {

    constructor(props) {
        super(props)
        this.state = {
            startDate: new Date()
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(date) {
        this.setState({
            startDate: date
        })
    }



    render() {
        return (
            <div>
                <div>
                    <title>Project Name | Drawings</title>
                    {/*Let browser know website is optimized for mobile*/}
                    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                    <section className="adminProject">
                        <div className="wrapper d-flex">
                            <SideBar />
                            <div className="wrapper_right">
                                <Header />
                                <div className="d-flex">

                                    <div className="wrapper_body">
                                        <div className="row">
                                            <div className="col m8 s12">
                                                <h3 className="heading">Drawings</h3>
                                                <div className="nav-wrapper">
                                                    <a href="javascript:void(0)" className="breadcrumb">Accounting</a>
                                                    <a href="javascript:void(0)" className="breadcrumb">Project Management</a>
                                                    <a href="javascript:void(0)" className="breadcrumb">Drawings</a>
                                                </div>
                                            </div>
                                            <div className="col m4 s12">
                                                <a href="#upload_drawing_modal" className="float-md-right theme_btn btn waves-effect waves-light modal-trigger"><i className="material-icons">add</i>&nbsp;Upload Drawings</a>
                                            </div>
                                        </div>
                                        <div className="category-card">
                                            <div className="row">
                                                <div className="col s12 m4">
                                                    <div className="outer">
                                                        <h5>
                                                            <span className="grey-text">#26</span>
                                                  &nbsp;&nbsp;
                                                 <span>Acme 1 Building Drawing</span>
                                                            <a href="#upload_drawing_modal" className="ml-auto modal-trigger">
                                                                <i className="material-icons">edit</i>
                                                            </a>
                                                        </h5>
                                                        <p><label>Sanction Date : &nbsp;&nbsp;</label><span className="black-text">03/10/2021</span></p>
                                                        <div className="drawing-preview">
                                                            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/69/Putnam_House_-_floor_plans.jpg/1200px-Putnam_House_-_floor_plans.jpg" alt="Acme 1 Building Drawing" />
                                                        </div>
                                                        <div className="badges">
                                                            <p className="chip">Hospitality Block</p>
                                                            <p className="chip">Admin Block</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col s12 m4">
                                                    <div className="outer">
                                                        <h5>
                                                            <span className="grey-text">#15</span>
                      &nbsp;&nbsp;
                      <span>Shivalik Penthouse</span>
                                                            <a href="#upload_drawing_modal" className="ml-auto modal-trigger">
                                                                <i className="material-icons">edit</i>
                                                            </a>
                                                        </h5>
                                                        <p><label>Sanction Date : &nbsp;&nbsp;</label><span className="black-text">03/10/2021</span></p>
                                                        <div className="drawing-preview">
                                                            <img src="https://i1.wp.com/theconstructor.org/wp-content/uploads/2018/09/house-plan.jpeg?fit=1210%2C831&ssl=1" alt="Shivalik Drawing" />
                                                        </div>
                                                        <div className="badges">
                                                            <p className="chip">Bedroom</p>
                                                            <p className="chip">Living</p>
                                                            <p className="chip">Kitchen</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </section>
                    {/* Create Category Modal */}
                    <div id="upload_drawing_modal" className="cstm-modal modal">
                        {/* Close Modal */}
                        <a href="javascript:Void(0)" className="modal-close"><span className="material-icons">chevron_right</span></a>
                        {/* Modal Content */}
                        <h3 className="heading">Upload Drawings</h3>
                        <p>Enter deatils below to upload drawings</p>
                        <form action id="createCategoryForm">
                            <div className="row">
                                <div className="col s12 m8">
                                    <legend>Drawing Name</legend>
                                    <input type="text" placeholder="Drawing Name" name="Drawing Name" required />
                                </div>
                                <div className="col s12 m4">
                                    <legend>Drawing No.</legend>
                                    <input type="text" placeholder="Drawing No." name="Drawing No." required />
                                </div>
                                <div className="col s12">
                                    <label className="label">Categories / Project Elements</label>
                                    <select>
                                        <option disabled selected>Select Categories / Project Elements</option>
                                        <option value={1}>Hospitality Block</option>
                                        <option value={2}>Hotel Management Block</option>
                                        <option value={3}>Computer Science</option>
                                        <option value={4}>Mechanical</option>
                                    </select>
                                </div>
                                <div className="col s12 m6">
                                    <legend>Sanction Date</legend>

                                    <DatePicker
                                        selected={this.state.startDate}
                                        onChange={this.handleChange}
                                        dateFormat="MMMM d, yyyy"
                                        className="form-control"
                                    />



                                </div>

                                <div className="col s12 right-align">
                                    <button type="submit" form="createCategoryForm" className="theme_btn btn waves-effect waves-light">Submit<i /></button>
                                </div>
                            </div>
                        </form>
                    </div>
                    {/*JavaScript at end of body for optimized loading*/}
                </div>

            </div>
        )
    }
}
