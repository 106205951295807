import React, { Component } from "react";

import Footer from "../../components/Footer";
import AdminHeader from "../../components/header/AdminHeader";
import AdminMenu from "../../components/sidebar/AdminMenu";
import ButtonLoader from "../../components/ButtonLoader";
import axios from "axios";
import { toast } from "react-toastify";
import "materialize-css";
import { Modal } from "react-materialize";
import { setUserData, getUserData } from "../../helpers/storage";
import SweetAlert from "react-bootstrap-sweetalert";
import { BulletList } from "react-content-loader";
import SimpleReactValidator from "simple-react-validator";
import "rc-pagination/assets/index.css";
import Pagination from "rc-pagination";
import Select from "rc-select";
import "rc-select/assets/index.less";
import "../../assets/pagination.less";
import localeInfo from "../../helpers/en_US";

import {
  apiCreateExpenseHead,
  apiListExpenseHeads,
  apiGetExpenseHead,
  apiListExpenseHeadsParents,
  apiEditExpenseHead,
  apiDeleteExpenseHead,
} from "../../config/endpoints";
import { Helmet } from "react-helmet";
const initialState = {
  fields: {
    name: "",
    parent: "",
  },
  searchFields: {
    name: "",
  },
  isLoading: false,
  redirect: false,
  errors: {},
  expenseHeadList: [],
  isModalOpen: false,
  isListLoading: false,
  alert: false,
  deleteId: "",
  editId: false,
  editItem: false,
  inputLabel: "Subhead Name",
  expenseHeadParentsList: [],
  expensHeadCount: 0,
  current: 1,
  pageSize: 30,
};
export default class ExpenseHead extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
  }

  onChangePagination = (page) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        current: page,
      }),
      this.loadExpenseHeads
    );
  };

  onChangePageSize = (current, pageSize) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        current: current,
        pageSize: pageSize,
      }),
      this.loadExpenseHeads
    );
  };

  clearFilter = () => {
    this.myFormRef.reset();
    //this.setState(this.initialState, this.loadMasterData);
    this.setState(
      {
        searchFields: {
          name: "",
          type: "",
        },
      },
      this.loadExpenseHeads
    );
  };

  loadExpenseHeads = (e) => {
    let userData = getUserData();
    fetch(apiListExpenseHeads, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          this.setState({
            expenseHeadList: data.data.expenseheads.rows,
          });
        } else {
          alert("something went wrong.");
        }
      });

    fetch(
      apiListExpenseHeadsParents +
        "?page=" +
        this.state.current +
        "&pagesize=" +
        this.state.pageSize,
      {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + userData.token,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          this.setState({
            expenseHeadParentsList: data.data.expensehead,
            expensHeadCount: data.data.expensehead.length,
          });
        } else {
          alert("something went wrong.");
        }
      });
  };

  editExpenseHead(id) {
    // Assuming only image
    this.setState({ editItem: true, editId: id });
    this.handleOpenModal();
    let userData = getUserData();
    fetch(apiGetExpenseHead + "?id=" + id, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          // this.setState({
          //   projectData: data.data.projects.rows,
          // });
          // console.log(this.state.projectList);
          this.setState({
            fields: data.data.expensehead,
          });
        } else {
          alert("something went wrong.");
        }
        // setTimeout(function () {
      });
    // Updating the city
  }

  handleCloseModal = () => {
    this.setState(initialState);
    this.loadExpenseHeads();
    this.validator.hideMessages();
  };

  handleOpenModal = () => {
    this.setState({ isModalOpen: true });
    this.validator.hideMessages();
  };

  showAlert = (id) => {
    this.setState({
      alert: true,
      deleteId: id,
    });
  };

  hideAlert = () => {
    this.setState({
      alert: false,
    });
  };

  deleteExpenseHead = (e) => {
    this.setState({ alert: false });
    let userData = getUserData();
    axios
      .post(
        apiDeleteExpenseHead,
        { id: this.state.deleteId },
        {
          headers: {
            Authorization: "Bearer " + userData.token,
          },
        }
      )
      .then(
        (response) => {
          this.setState({ isLoading: false });
          toast.info("Expense Head deleted", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          this.setState(this.initialState);
          this.loadExpenseHeads();
        },
        (error) => {
          if (error.response.status == 500 || error.response.status == 400) {
            toast.error(error.response.data.errorMessage, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            this.setState({ isLoading: false });
          }
        }
      );
  };

  handleChange = (e) => {
    this.validator.showMessageFor(e.target.name);
    const value = e.target.value;
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        [e.target.name]: value,
      },
    }));
  };

  handleChangeHead = (e) => {
    this.validator.showMessageFor(e.target.name);
    const value = e.target.value;
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        [e.target.name]: value,
      },
    }));

    if (e.target.name == "parent" && e.target.value === "0") {
      this.setState({ inputLabel: "Head Name" });
    } else {
      this.setState({ inputLabel: "Subhead Name" });
    }
  };

  handleBlur = (e) => {
    this.validator.showMessageFor(e.target.name);
  };

  handleSearch = (e) => {
    this.validator.showMessageFor(e.target.name);
    const value = e.target.value;
    this.setState((prevState) => ({
      searchFields: {
        ...prevState.searchFields,
        [e.target.name]: value,
      },
    }));
  };

  submitSearch = (e) => {
    e.preventDefault();
    let userData = getUserData();
    this.setState({ isLoading: true });
    //this.setState({ filteredData: true });
    this.setState({ isListLoading: true });
    fetch(
      apiListExpenseHeadsParents +
        "?page=" +
        this.state.current +
        "&pagesize=" +
        this.state.pageSize +
        "&name=" +
        this.state.searchFields.name,
      {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + userData.token,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          this.setState({
            expenseHeadParentsList: data.data.expensehead,
            expensHeadCount: data.data.expensehead.length,
            isListLoading: false,
          });
        } else {
          alert("something went wrong.");
        }
      });
  };

  submitForm = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      /* console.log("submit");
      console.log(this.state);
      console.log(getUserData()); */
      let userData = getUserData();
      if (!this.state.editItem) {
        let data = {
          name: this.state.fields.name,
          parent: 0,
        };
        this.setState({ isLoading: true });
        axios
          .post(apiCreateExpenseHead, data, {
            headers: {
              Authorization: "Bearer " + userData.token,
            },
          })
          .then(
            (response) => {
              //console.log(response);
              this.setState({ isLoading: false });
              toast.info("Head created", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
              this.loadExpenseHeads();
              this.validator.hideMessages();
              this.setState((prevState) => ({
                fields: {
                  ...prevState.fields,
                  name: "",
                },
              }));
              this.handleCloseModal();
            },
            (error) => {
              if (
                error.response.status == 500 ||
                error.response.status == 400
              ) {
                toast.error(error.response.data.errorMessage, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                this.setState({ isLoading: false });
              }
            }
          );
      } else {
        let data = {
          name: this.state.fields.name,
          parent: this.state.fields.parent,
          id: this.state.editId,
        };
        this.setState({ isLoading: true });
        axios
          .post(apiEditExpenseHead, data, {
            headers: {
              Authorization: "Bearer " + userData.token,
            },
          })
          .then(
            (response) => {
              //console.log(response);
              this.setState({ isLoading: false });
              toast.info("Expense Head updated", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
              this.setState(this.initialState);
              this.handleCloseModal();
            },
            (error) => {
              if (
                error.response.status == 500 ||
                error.response.status == 400
              ) {
                toast.error(error.response.data.errorMessage, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                this.setState({ isLoading: false });
              }
            }
          );
      }
    } else {
      /* console.log("fail");
      console.log("errors", this.validator.getErrorMessages()); */
      this.validator.showMessages();
      this.forceUpdate();
    }
  };
  componentWillMount() {
    this.loadExpenseHeads();
  }
  render() {
    this.validator.purgeFields();
    return (
      <React.Fragment>
        <Helmet>
          <title>Expense Head | MRC</title>
        </Helmet>
        <section className="admin">
          <AdminHeader />
          <div className="wrapper d-flex">
            <AdminMenu />

            <div className="wrapper_body">
              <div className="row d-flex align-items-center">
                <div className="col m0 d-flex align-items-center">
                  <h3 className="heading">Expense Head</h3>
                </div>
                <div className="col m4 right-align">
                  <a
                    href="javascript:void(0)"
                    className="theme_btn btn"
                    onClick={this.handleOpenModal}
                  >
                    <i className="material-icons">add</i> Create New Expense
                    Head
                  </a>
                  <Modal
                    actions={[,]}
                    bottomSheet={false}
                    fixedFooter={false}
                    id="Modal-1"
                    open={this.state.isModalOpen}
                    className="cstm-modal"
                    options={{
                      dismissible: false,
                      endingTop: "10%",
                      inDuration: 250,
                      onCloseEnd: null,
                      onCloseStart: null,
                      onOpenEnd: null,
                      onOpenStart: null,
                      opacity: 0.5,
                      outDuration: 250,
                      preventScrolling: true,
                      startingTop: "4%",
                    }}
                  >
                    {/* Close Modal */}
                    <a
                      href="javascript:Void(0)"
                      className="modal-close"
                      onClick={this.handleCloseModal}
                    >
                      <span className="material-icons">chevron_right</span>
                    </a>
                    {/* Modal Content */}
                    <h3 className="heading">
                      {this.state.editItem ? "Update " : "Create "} Master
                    </h3>
                    <p>
                      Enter details to {this.state.editItem ? "update" : "new"}{" "}
                      head
                    </p>
                    <form action id="createExpenseHeadForm">
                      <div className="row">
                        <fieldset>
                          {/* <legend>Head</legend> */}
                          {/* <div className="col s12">
                                <select
                                  name="parent"
                                  onChange={this.handleChangeHead}
                                  onBlur={this.handleBlur}
                                  value={this.state.fields.parent}
                                >
                                  <option value="" selected>
                                    Select
                                  </option>
                                  <option value="0">Create New Head</option>
                                  {this.state.expenseHeadParentsList.map(
                                    (expenseHead, index) => (
                                      <>
                                        <option value={expenseHead.id}>
                                          {expenseHead.name}
                                        </option>
                                      </>
                                    )
                                  )}
                                </select>
                                <label className="error">
                                  {this.validator.message(
                                    "parent",
                                    this.state.fields.parent,
                                    "required"
                                  )}
                                </label>
                              </div> */}

                          {/* <legend>{this.state.inputLabel}</legend> */}
                          <legend>Head Name</legend>
                          <div className="col s12">
                            <input
                              type="text"
                              placeholder="Head Name"
                              name="name"
                              onChange={this.handleChange}
                              onBlur={this.handleBlur}
                              value={this.state.fields.name}
                            />
                            <label className="error">
                              {this.validator.message(
                                "name",
                                this.state.fields.name,
                                "required"
                              )}
                            </label>
                          </div>
                        </fieldset>

                        <div className="row">
                          <div className="col s12 right-align">
                            <button
                              type="submit"
                              form="createExpenseHeadFormman"
                              className="theme_btn btn "
                              onClick={this.submitForm}
                            >
                              {this.state.editItem
                                ? "Update Expensehead"
                                : "Add Expensehead"}
                              {this.state.isLoading ? (
                                <ButtonLoader></ButtonLoader>
                              ) : null}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </Modal>
                </div>
              </div>
              <div className="projects employee">
                <div className="row">
                  <div className="col s12">
                    <p className="grey-text">
                      <b>Filters</b>
                    </p>
                    <form ref={(ele) => (this.myFormRef = ele)}>
                      <div className="row">
                        <div className="col m3 s12">
                          <input
                            type="text"
                            className=""
                            placeholder="Name"
                            name="name"
                            onChange={this.handleSearch}
                          />
                        </div>

                        <div className="col m2 s12"></div>
                      </div>

                      <div className="row">
                        <div class="col m3 s12">
                          <a
                            href="javascript:void(0)"
                            class="red-text pt-1"
                            onClick={this.clearFilter}
                          >
                            Clear Filter
                          </a>

                          <button
                            type="submit"
                            className="theme_btn btn filter-submit"
                            onClick={this.submitSearch}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="row">
                  <div className="col s12">
                    <div className="table-responsive">
                      <table>
                        <thead>
                          <tr>
                            <th>Name</th>
                            {/* <th>Head/Subhead</th> */}
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.isListLoading ? (
                            <tr>
                              <td colSpan={5}>
                                <BulletList></BulletList>
                              </td>
                            </tr>
                          ) : (
                            this.state.expenseHeadParentsList.map(
                              (expensehead, index) => (
                                <>
                                  <tr key={index}>
                                    <td data-th="Name">{expensehead.name}</td>
                                    {/* <td data-th="Type">
                                          <p className="chip">
                                            {expensehead.headparent == null
                                              ? "Head"
                                              : expensehead.headparent.name}
                                          </p>
                                        </td> */}
                                    <td>
                                      <a
                                        href="#Modal-0"
                                        className="actions"
                                        node="button"
                                        onClick={() =>
                                          this.editExpenseHead(expensehead.id)
                                        }
                                      >
                                        <i className="material-icons">edit</i>
                                      </a>
                                      <a
                                        href="javascript:void(0)"
                                        className="actions"
                                        onClick={() =>
                                          this.showAlert(expensehead.id)
                                        }
                                      >
                                        <i className="material-icons">delete</i>
                                      </a>
                                    </td>
                                  </tr>
                                </>
                              )
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div
                    className="col s12"
                    style={{ marginTop: 20, marginBottom: 20 }}
                  >
                    <Pagination
                      pageSize={this.state.pageSize}
                      onChange={this.onChangePagination}
                      current={this.state.current}
                      total={this.state.expensHeadCount}
                      showSizeChanger
                      defaultPageSize={this.state.pageSize}
                      defaultCurrent={this.state.current}
                      onShowSizeChange={this.onChangePageSize}
                      locale={localeInfo}
                      selectComponentClass={Select}
                      pageSizeOptions={[10, 30, 50, 100]}
                      showTotal={(total, range) =>
                        `${range[0]} - ${range[1]} of ${total} items`
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.state.alert && (
            <SweetAlert
              onConfirm={this.deleteExpenseHead}
              onCancel={this.hideAlert}
              focusCancelBtn={true}
              warning
              showCancel
              confirmBtnText="Yes, delete it!"
              confirmBtnBsStyle="danger"
              title="Are you sure?"
            />
          )}
          <Footer />
        </section>
      </React.Fragment>
    );
  }
}
