import React, { Component } from "react";
import { logoutUser, getUserData } from "../../helpers/storage";
import { Link, Redirect, useLocation } from "react-router-dom";
import { Collapsible, CollapsibleItem } from "react-materialize";

class AdminLinks extends Component {
  constructor(props) {
    super(props);
    let userData = getUserData();
    console.log("logo", userData.permissions);
    this.state = {
      userData: userData,
      permissions: userData.permissions,
      selected: "",
      menuHeads: false,
      menuAdmin: false,
      menuHr: false,
      menuAccounts: false,
      menuPo: false,
    };
  }

  componentDidMount() {
    // console.log(this.state.userData.user.userType);
    const str = window.location.pathname;
    var last = str.substring(str.lastIndexOf("/") + 1, str.length);
    //console.log("url last", last);

    this.setState({
      selected: last,
    });
  }

  openMenuHeads = () => {
    this.setState((prevState) => ({
      menuHeads: !prevState.menuHeads,
    }));
  };

  openMenuAdmin = () => {
    this.setState((prevState) => ({
      menuAdmin: !prevState.menuAdmin,
    }));
  };

  openMenuHr = () => {
    this.setState((prevState) => ({
      menuHr: !prevState.menuHr,
    }));
  };

  openMenuAccounts = () => {
    this.setState((prevState) => ({
      menuAccounts: !prevState.menuAccounts,
    }));
  };

  openMenuPo = () => {
    this.setState((prevState) => ({
      menuPo: !prevState.menuPo,
    }));
  };

  checkPermissions = (key) => {
    console.log("permission", key in this.state.permissions);
    return key in this.state.permissions;
  };

  render() {
    return (
      <ul>
        <li className={this.state.selected === "projects" && "menuSelect"}>
          <i />
          <Link to="/projects">Projects</Link>
        </li>
        {this.state.userData.user.userType == 3 &&
        this.checkPermissions("employee[view]") ? (
          <>
            <li className={this.state.selected === "employees" && "menuSelect"}>
              <i />
              <Link to="/employees">Employees</Link>
            </li>
          </>
        ) : null}
        {this.state.userData.user.userType == 3 &&
        this.checkPermissions("po[view]") ? (
          <>
            <li
              className={
                this.state.selected === "purchase-order" && "menuSelect"
              }
            >
              <i />
              <Link to="/purchase-order">Purchase Order</Link>
            </li>
            {/* <li className={this.state.selected === "employees" && "menuSelect"}>
            <i />
            <Link to="/po-receiving">PO Receiving</Link>
          </li> */}
          </>
        ) : null}
        {this.state.userData.user.userType == 3 &&
        this.checkPermissions("po[view]") ? (
          <>
            <li
              className={this.state.selected === "po-tracking" && "menuSelect"}
            >
              <i />
              <Link to="/po-tracking">PO Tracking</Link>
            </li>
            {/* <li className={this.state.selected === "employees" && "menuSelect"}>
            <i />
            <Link to="/po-receiving">PO Receiving</Link>
          </li> */}
          </>
        ) : null}
        {this.state.userData.user.userType == 3 &&
        this.checkPermissions("contractors[view]") ? (
          <>
            <li
              className={this.state.selected === "Contractor" && "menuSelect"}
            >
              <i />
              <Link to="/Contractor">Contractor</Link>
            </li>
          </>
        ) : null}
        {this.state.userData.user.userType == 3 &&
        this.checkPermissions("customers[view]") ? (
          <>
            <li className={this.state.selected === "customer" && "menuSelect"}>
              <Link to="/customer">Customers</Link>
            </li>
          </>
        ) : null}
        {this.state.userData.user.userType == 3 &&
        this.checkPermissions("master[view]") ? (
          <>
            <li className={this.state.selected === "customer" && "menuSelect"}>
              <a onClick={this.openMenuAdmin}>Admin</a>
              <ul
                className={
                  this.state.menuAdmin ? "submenu-open" : "submenu-close"
                }
              >
                <li
                  className={this.state.selected === "master" && "menuSelect"}
                >
                  <i />
                  <Link to="/master">Master</Link>
                </li>
                <li
                  className={
                    this.state.selected === "gstmaster" && "menuSelect"
                  }
                >
                  <i />
                  <Link to="/gstmaster">GST Master</Link>
                </li>
                <li
                  className={
                    this.state.selected === "gstmaster" && "menuSelect"
                  }
                >
                  <i />
                  <Link to="/bankmaster">Bank Master</Link>
                </li>

                <li
                  className={
                    this.state.selected === "gstmaster" && "menuSelect"
                  }
                >
                  <i />
                  <Link to="/itemmaster">Item Master</Link>
                </li>
              </ul>
            </li>
          </>
        ) : null}
        {this.state.userData.user.userType != 3 ? (
          <>
            <li className={this.state.selected === "customer" && "menuSelect"}>
              <a onClick={this.openMenuHr}>HR</a>
              <ul
                className={this.state.menuHr ? "submenu-open" : "submenu-close"}
              >
                <li
                  className={
                    this.state.selected === "employees" && "menuSelect"
                  }
                >
                  <i />
                  <Link to="/employees">Employees</Link>
                </li>
              </ul>
            </li>
            <li
              className={
                this.state.selected === "purchase-order" && "menuSelect"
              }
            >
              <a onClick={this.openMenuPo}>Purchase Order</a>
              <ul
                className={this.state.menuPo ? "submenu-open" : "submenu-close"}
              >
                <li
                  className={
                    this.state.selected === "purchase-order" && "menuSelect"
                  }
                >
                  <i />
                  <Link to="/purchase-order">Purchase Order</Link>
                </li>
                <li
                  className={
                    this.state.selected === "po-tracking" && "menuSelect"
                  }
                >
                  <i />
                  <Link to="/po-tracking">Po Tracking</Link>
                </li>
              </ul>
            </li>

            <li
              className={this.state.selected === "Contractor" && "menuSelect"}
            >
              <i />
              <Link to="/Contractor">Contractor</Link>
            </li>
            <li className={this.state.selected === "customer" && "menuSelect"}>
              <Link to="/customer">Customers</Link>
            </li>
            <li className={this.state.selected === "customer" && "menuSelect"}>
              <a onClick={this.openMenuAdmin}>Admin</a>
              <ul
                className={
                  this.state.menuAdmin ? "submenu-open" : "submenu-close"
                }
              >
                <li
                  className={this.state.selected === "master" && "menuSelect"}
                >
                  <i />
                  <Link to="/master">Master</Link>
                </li>
                <li
                  className={
                    this.state.selected === "gstmaster" && "menuSelect"
                  }
                >
                  <i />
                  <Link to="/gstmaster">GST Master</Link>
                </li>
                <li
                  className={
                    this.state.selected === "gstmaster" && "menuSelect"
                  }
                >
                  <i />
                  <Link to="/bankmaster">Bank Master</Link>
                </li>
                <li
                  className={this.state.selected === "settings" && "menuSelect"}
                >
                  <i />
                  <Link to="/settings">Settings</Link>
                </li>

                <li className={this.state.selected === "Roles" && "menuSelect"}>
                  <i />
                  <Link to="/Roles">Roles</Link>
                </li>

                <li
                  className={
                    this.state.selected === "gstmaster" && "menuSelect"
                  }
                >
                  <i />
                  <Link to="/itemmaster">Item Master</Link>
                </li>
                <li
                  className={this.state.selected === "customer" && "menuSelect"}
                >
                  <a onClick={this.openMenuHeads}>Heads</a>
                  <ul
                    className={
                      this.state.menuHeads ? "submenu-open" : "submenu-close"
                    }
                  >
                    <li
                      className={
                        this.state.selected === "expensehead" && "menuSelect"
                      }
                    >
                      <i />
                      <Link to="/expensehead">Expense Head</Link>
                    </li>
                    <li
                      className={
                        this.state.selected === "expensesubhead" && "menuSelect"
                      }
                    >
                      <i />
                      <Link to="/expensesubhead">Expense SubHead</Link>
                    </li>
                    <li className={this.state.selected === "" && "menuSelect"}>
                      <i />
                      <Link to="/expensesubhead">Expense SubHead</Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>

            <li className={this.state.selected === "customer" && "menuSelect"}>
              <a onClick={this.openMenuAccounts}>Accounts</a>
              <ul
                className={
                  this.state.menuAccounts ? "submenu-open" : "submenu-close"
                }
              >
                {this.state.userData.user.userType == 2 ||
                (this.state.userData.user.userType == 3 &&
                  this.checkPermissions("project_payments[view]")) ? (
                  <li
                    className={
                      this.state.selected === "projectpayment" && "menuSelect"
                    }
                  >
                    <Link to={`/projectpayment`}>Project Payments</Link>
                  </li>
                ) : null}
                {this.state.userData.user.userType == 2 ||
                (this.state.userData.user.userType == 3 &&
                  this.checkPermissions("bank_guarantee[view]")) ? (
                  <li
                    className={
                      this.state.selected === "bankguarantee" && "menuSelect"
                    }
                  >
                    <Link to={`/bankguarantee`}>Bank Guarantee</Link>
                  </li>
                ) : null}
                {this.state.userData.user.userType == 2 ||
                (this.state.userData.user.userType == 3 &&
                  this.checkPermissions("invoice[view]")) ? (
                  <li
                    className={
                      this.state.selected === "invoice" && "menuSelect"
                    }
                  >
                    <Link to={`/invoice`}>Invoice</Link>{" "}
                  </li>
                ) : null}
                {this.state.userData.user.userType == 2 ||
                (this.state.userData.user.userType == 3 &&
                  this.checkPermissions("fdr[view]")) ? (
                  <li className={this.state.selected === "fdr" && "menuSelect"}>
                    <Link to={`/fdr`}>FDR</Link>{" "}
                  </li>
                ) : null}
                {this.state.userData.user.userType == 2 ||
                (this.state.userData.user.userType == 3 &&
                  this.checkPermissions("recurring_payments[view]")) ? (
                  <li
                    className={
                      this.state.selected === "recurringpayments" &&
                      "menuSelect"
                    }
                  >
                    <Link to={`/recurringpayments`}>Recurring Payments</Link>
                  </li>
                ) : null}
              </ul>
            </li>
          </>
        ) : null}
      </ul>
    );
  }
}

export default AdminLinks;
