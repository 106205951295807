import React, { Component, Fragment } from "react";
import Footer from "../../components/Footer";
import AdminHeader from "../../components/header/AdminHeader";
import AdminMenu from "../../components/sidebar/AdminMenu";
import { Helmet } from "react-helmet";
import ButtonLoader from "../../components/ButtonLoader";
import { getUserData, getProjectID } from "../../helpers/storage";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
import axios from "axios";
// import SignaturePad from "react-signature-canvas";
import SignaturePad from "react-signature-pad-wrapper";

import {
  apiListInvoice,
  apiCreateInvoice,
  apiEditInvoice,
  apiGetInvoiceById,
  apiListCustomer,
  apiGetMasterByType,
  apiListProjects,
  apiGetGstMaster,
  apiListGstMasters,
} from "../../config/endpoints";

const initialState = {
  inputFields: [
    {
      particulars: "",
      hsn: "",
      qty: 1,
      gst: "",
      amount: "",
      total: 0,
      tax: "",
    },
  ],
  customerInfo: {
    customer: "",
    shipname: "",
    shipaddress: "",
    shipgst: "",
    shipstate: "",
    shipstatecode: "",
    billtoname: "",
    billtoaddress: "",
    billtogst: "",
    billtostate: "",
    billtostatecode: "",
  },
  shitocheckbox: true,
  gstid: "",
  igst: true,
  date: "",
  invoiceno: "",
  remarks: "",
  project: "",
  gst: "",
  state_name: "",
  state_code: "",
  statename: "",
  statecode: "",
  deliverynote: "",
  modeofpayment: "",
  referenceno: "",
  otherreference: "",
  buyerorder: "",
  dispatchdoc: "",
  dispatchthrough: "",
  destination: "",
  einvoiceirn: "",
  einvoiceackno: "",
  einvoiceackdate: "",
  einvoiceackqr: "",
  totaltax: [],
  grandtotal: [],
  customerList: [],
  projectList: [],
  masterGstList: [],
  masterStateCodeList: [],
  masterStateNameList: [],
  editId: false,
  editItem: false,
  isLoading: false,
  subtotal: 0,
  grandtotalFinal: 0,
  terms: "",
  trimmedDataURL: null,
};
let currentProjectId = 0;
export default class CreateInvoice extends Component {
  state = { trimmedDataURL: null };
  sigPad = {};
  constructor(props) {
    super(props);
    this.state = initialState;
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    currentProjectId = getProjectID();
    let sigCanvas;
    //this.handleChange = this.handleChange.bind(this);
  }

  // clear = () => {
  //   this.sigPad.clear();
  // };
  // trim = () => {
  //   this.setState({
  //     trimmedDataURL: this.sigPad.getTrimmedCanvas().toDataURL("image/png"),
  //   });
  // };

  handleSignClear = () => {
    const signaturePad = this.sigPad.current;

    if (signaturePad) {
      signaturePad.instance.clear();
    }
  };

  handleAddFields = () => {
    const values = [...this.state.inputFields];
    values.push({
      particulars: "",
      hsn: "",
      qty: 1,
      gst: "",
      amount: 0,
      total: 0,
      tax: "",
    });
    this.setState({ inputFields: values });
  };

  handleRemoveFields = (index) => {
    const values = [...this.state.inputFields];
    values.splice(index, 1);
    let tot_tax = [...this.state.totaltax];
    tot_tax.splice(index, 1);
    let grand_tot = [...this.state.grandtotal];
    grand_tot.splice(index, 1);

    this.setState({
      inputFields: values,
      totaltax: tot_tax,
      grandtotal: grand_tot,
    });

    let subTotalAmount = 0;
    let totalAmount = 0;
    for (var i = 0; i < this.state.inputFields.length; i++) {
      console.log("input", this.state.inputFields[i]);
      subTotalAmount += parseInt(this.state.inputFields[i].amount);
      console.log("sub", subTotalAmount);
    }
    this.setState({ subtotal: subTotalAmount });

    for (var i = 0; i < this.state.inputFields.length; i++) {
      console.log("input", this.state.inputFields[i]);
      totalAmount += parseInt(this.state.inputFields[i].total);
      console.log("total", totalAmount);
    }
    this.setState({ subtotal: subTotalAmount });
    this.setState({
      grandtotalFinal: totalAmount,
    });
  };

  handleCustomer = (e) => {
    let selected_customer;
    console.log(e.target.name, e.target.value);
    this.state.customerList.map((customer) => {
      if (customer.id == e.target.value) {
        this.setState(
          (prevState) => ({
            ...prevState,
            customerInfo: {
              customer: customer.id,
              shipname: customer.companyname,
              shipaddress: customer.address,
              shipgst: customer.gst,
              shipstate: customer.state,
              shipstatecode: customer.statecode,
              billtoname: customer.companyname,
              billtoaddress: customer.companyname,
              billtogst: customer.gst,
              billtostate: customer.state,
              billtostatecode: customer.statecode,
            },
          }),
          () => this.handleIgst()
        );
      }
    });
  };

  handleInputChangeOld = (index, event) => {
    const values = [...this.state.inputFields];
    if (event.target.name === "particulars") {
      values[index].particulars = event.target.value;
    } else if (event.target.name === "hsn") {
      values[index].hsn = event.target.value;
    } else if (event.target.name === "qty") {
      values[index].qty = event.target.value;
    } else if (event.target.name === "gst") {
      values[index].gst = event.target.value;
    } else if (event.target.name === "amount") {
      values[index].amount = event.target.value;
    }

    if (
      this.state.inputFields[index].qty &&
      this.state.inputFields[index].gst &&
      this.state.inputFields[index].amount
    ) {
      let per = parseInt(this.state.inputFields[index].gst);
      let quant = parseInt(this.state.inputFields[index].qty);
      let amt = parseInt(this.state.inputFields[index].amount) * quant;
      let tax = amt * (per / 100);
      values[index].tax = tax;
      values[index].total = amt + tax;
      this.setState({ totaltax: [...this.state.totaltax, tax] });
      this.setState({
        grandtotal: [...this.state.grandtotal, values[index].total],
      });
    }

    this.setState({ inputFields: values });
  };

  handleInputChange = (index, event) => {
    const values = [...this.state.inputFields];
    if (event.target.name === "particulars") {
      values[index].particulars = event.target.value;
    } else if (event.target.name === "hsn") {
      values[index].hsn = event.target.value;
    } else if (event.target.name === "qty") {
      values[index].qty = event.target.value;
    } else if (event.target.name === "gst") {
      values[index].gst = event.target.value;
    } else if (event.target.name === "amount") {
      values[index].amount = event.target.value;
    }
    let quant = parseInt(this.state.inputFields[index].qty);
    let amt = parseInt(this.state.inputFields[index].amount) * quant;
    values[index].total = amt;
    if (
      this.state.inputFields[index].qty &&
      this.state.inputFields[index].gst &&
      this.state.inputFields[index].amount
    ) {
      let per = parseInt(this.state.inputFields[index].gst);
      let quant = parseInt(this.state.inputFields[index].qty);
      let amt = parseInt(this.state.inputFields[index].amount) * quant;
      let tax = amt * (per / 100);
      values[index].tax = tax;
      values[index].total = Math.round(amt + tax);

      this.setState({ totaltax: [...this.state.totaltax, tax] });
      this.setState({
        grandtotal: [...this.state.grandtotal, values[index].total],
      });
    }
    this.setState({ inputFields: values });
    // console.log(this.state.inputFields);

    let subTotalAmount = 0;
    let totalAmount = 0;
    for (var i = 0; i < this.state.inputFields.length; i++) {
      console.log("input", this.state.inputFields[i]);
      subTotalAmount += parseInt(this.state.inputFields[i].amount);
      console.log("sub", subTotalAmount);
    }
    this.setState({ subtotal: subTotalAmount });

    for (var i = 0; i < this.state.inputFields.length; i++) {
      // console.log("input", this.state.inputFields[i]);
      totalAmount += parseInt(this.state.inputFields[i].total);
      // console.log("total", totalAmount);
    }
    this.setState({ subtotal: subTotalAmount });
    this.setState({
      grandtotalFinal: totalAmount,
    });
  };

  handleChange = (e) => {
    this.validator.showMessageFor(e.target.name);
    const value = e.target.value;
    this.setState((prevState) => ({
      ...prevState,
      [e.target.name]: value,
    }));
  };

  handleChangeGst = (e) => {
    this.validator.showMessageFor(e.target.name);
    const value = e.target.value;
    let userData = getUserData();
    fetch(apiGetGstMaster + "?id=" + value, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          this.setState(
            (prevState) => ({
              ...prevState,
              gstid: value,
              gst: data.data.master.gst,
              statename: data.data.master.state,
              statecode: data.data.master.statecode,
            }),
            () => this.handleIgst()
          );
        } else {
          alert("something went wrong.");
        }
      });
  };

  handleCustomerValue = (e) => {
    this.validator.showMessageFor(e.target.name);
    const value = e.target.value;
    this.setState((prevState) => ({
      ...prevState,
      customerInfo: {
        [e.target.name]: value,
      },
    }));
  };

  handleIgst = (e) => {
    console.log("cpm", this.state.statecode);
    console.log("cust", this.state.customerInfo.shipstatecode);
    if (this.state.statecode == this.state.customerInfo.shipstatecode) {
      console.log("same");
      this.setState({ igst: false });
    } else {
      this.setState({ igst: true });
    }
  };

  handleShipToCheck = (e) => {
    let check = this.state.shitocheckbox;
    if (check) {
      this.setState({
        shiptocheck: false,
      });
    } else {
      this.setState({
        shiptocheck: true,
      });
    }
  };
  handleBlur = (e) => {
    this.validator.showMessageFor(e.target.name);
  };

  loadCustomer = (e) => {
    let userData = getUserData();
    this.setState({ isListLoading: true });
    fetch(apiListCustomer, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          console.log("Customer list", data.data.customer.rows);
          this.setState({
            customerList: data.data.customer.rows,
            isListLoading: false,
          });
        } else {
          alert("something went wrong.");
        }
      });
    fetch(apiListProjects, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          //console.log("project list", data.data.projects.rows);
          this.setState({
            projectList: data.data.projects.rows,
          });
        } else {
          alert("something went wrong.");
        }
      });
    fetch(apiListGstMasters, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          this.setState({
            masterGstList: data.data.masters.rows,
          });
        } else {
          alert("something went wrong.");
        }
      });
    fetch(apiGetMasterByType + "?type=state_name", {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          this.setState({
            masterStateNameList: data.data.masters,
          });
        } else {
          alert("something went wrong.");
        }
      });
    fetch(apiGetMasterByType + "?type=state_code", {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          this.setState({
            masterStateCodeList: data.data.masters,
          });
        } else {
          alert("something went wrong.");
        }
      });
  };

  editInvoice = (id) => {
    this.setState({ editItem: true, editId: id });
    //this.handleOpenModal();
    let userData = getUserData();
    fetch(apiGetInvoiceById + "?id=" + id, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          this.setState({
            inputFields: JSON.parse(data.data.invoice.particulars),
            customerInfo: {
              customer: data.data.invoice.customer,
              shipname: data.data.invoice.shipname,
              shipaddress: data.data.invoice.shipaddress,
              shipgst: data.data.invoice.shipgst,
              shipstate: data.data.invoice.shipstate,
              shipstatecode: data.data.invoice.shipstatecode,
            },
            date: data.data.invoice.date,
            invoiceno: data.data.invoice.invoiceno,
            remarks: data.data.invoice.remarks,
            totaltax: [...this.state.totaltax, data.data.invoice.totaltax],
            grandtotal: [
              ...this.state.grandtotal,
              data.data.invoice.grandtotal,
            ],
          });
        } else {
          alert("something went wrong.");
        }
      });
  };

  submitForm = (e) => {
    e.preventDefault();
    const signaturePad = this.sigPad.current;

    if (!signaturePad) {
      return;
    }

    if (signaturePad.isEmpty()) {
      // eslint-disable-next-line no-alert
      alert("Please provide a signature first.");
    } else {
      console.log(signaturePad.toDataURL());
      this.setState({ sign: signaturePad.toDataURL() });
      // window.open(signaturePad.toDataURL());
    }
    if (this.validator.allValid()) {
      /* console.log("submit");
      console.log(this.state);
      console.log(getUserData()); */

      let userData = getUserData();
      if (!this.state.editItem) {
        let data = {
          invoiceno: this.state.invoiceno,
          date: this.state.date,
          customer: this.state.customerInfo.customer,
          shipname: this.state.customerInfo.shipname,
          shipaddress: this.state.customerInfo.shipaddress,
          shipgst: this.state.customerInfo.shipgst,
          shipstate: this.state.customerInfo.shipstate,
          shipstatecode: this.state.customerInfo.shipstatecode,
          billtoname: this.state.customerInfo.billtoname,
          billtoaddress: this.state.customerInfo.billtoaddress,
          billtogst: this.state.customerInfo.billtogst,
          billtostate: this.state.customerInfo.billtostate,
          billtostatecode: this.state.customerInfo.billtostatecode,
          project: this.state.project,
          gst: this.state.gst,
          statename: this.state.statename,
          statecode: this.state.statecode,
          deliverynote: this.state.deliverynote,
          modeofpayment: this.state.modeofpayment,
          referenceno: this.state.referenceno,
          otherreference: this.state.otherreference,
          buyerorder: this.state.buyerorder,
          dispatchdoc: this.state.dispatchdoc,
          dispatchthrough: this.state.dispatchthrough,
          destination: this.state.destination,
          einvoiceirn: this.state.einvoiceirn,
          einvoiceackno: this.state.einvoiceackno,
          einvoiceackdate: this.state.einvoiceackdate,
          einvoiceackqr: this.state.einvoiceackqr,
          particulars: JSON.stringify(this.state.inputFields),
          total:
            this.state.grandtotal.reduce((a, b) => a + b, 0) -
            this.state.totaltax.reduce((a, b) => a + b, 0),
          totaltax: this.state.totaltax.reduce((a, b) => a + b, 0),
          grandtotal: this.state.grandtotal.reduce((a, b) => a + b, 0),
          remarks: this.state.remarks,
          terms: this.state.terms,
          sign: this.state.sign,
        };
        console.log("submitted data", data);
        this.setState({ isLoading: true });
        axios
          .post(apiCreateInvoice, data, {
            headers: {
              Authorization: "Bearer " + userData.token,
            },
          })
          .then(
            (response) => {
              console.log(response);
              toast.info("Invoice created", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
              this.setState(initialState);
              this.props.history.push("/invoice/" + currentProjectId);
            },
            (error) => {
              if (
                error.response.status == 500 ||
                error.response.status == 400
              ) {
                toast.error(error.response.data.errorMessage, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                this.setState({ isLoading: false });
              }
            }
          );
      } else {
        let data = {
          invoiceno: this.state.invoiceno,
          date: this.state.date,
          customer: this.state.customerInfo.customer,
          shipname: this.state.customerInfo.shipname,
          shipaddress: this.state.customerInfo.shipaddress,
          shipgst: this.state.customerInfo.shipgst,
          shipstate: this.state.customerInfo.shipstate,
          shipstatecode: this.state.customerInfo.shipstatecode,
          billtoname: this.state.customerInfo.billtoname,
          billtoaddress: this.state.customerInfo.billtoaddress,
          billtogst: this.state.customerInfo.billtogst,
          billtostate: this.state.customerInfo.billtostate,
          billtostatecode: this.state.customerInfo.billtostatecode,
          project: this.state.project,
          gst: this.state.gst,
          statename: this.state.statename,
          statecode: this.state.statecode,
          deliverynote: this.state.deliverynote,
          modeofpayment: this.state.modeofpayment,
          referenceno: this.state.referenceno,
          otherreference: this.state.otherreference,
          buyerorder: this.state.buyerorder,
          dispatchdoc: this.state.dispatchdoc,
          dispatchthrough: this.state.dispatchthrough,
          destination: this.state.destination,
          einvoiceirn: this.state.einvoiceirn,
          einvoiceackno: this.state.einvoiceackno,
          einvoiceackdate: this.state.einvoiceackdate,
          einvoiceackqr: this.state.einvoiceackqr,
          particulars: JSON.stringify(this.state.inputFields),
          total:
            this.state.grandtotal.reduce((a, b) => a + b, 0) -
            this.state.totaltax.reduce((a, b) => a + b, 0),
          totaltax: this.state.totaltax.reduce((a, b) => a + b, 0),
          grandtotal: this.state.grandtotal.reduce((a, b) => a + b, 0),
          remarks: this.state.remarks,
          terms: this.state.terms,
          sign: this.state.sign,
          id: this.state.editId,
        };
        console.log("edited data", data);
        this.setState({ isLoading: true });
        axios
          .post(apiEditInvoice, data, {
            headers: {
              Authorization: "Bearer " + userData.token,
            },
          })
          .then(
            (response) => {
              console.log(response);
              // this.setState({ isLoading: false });
              toast.info("Invoice Updated", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
              this.setState(initialState);
              this.props.history.push("/invoice");
            },
            (error) => {
              if (
                error.response.status == 500 ||
                error.response.status == 400
              ) {
                toast.error(error.response.data.errorMessage, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                this.setState({ isLoading: false });
              }
            }
          );
      }
    } else {
      console.log("fail");
      console.log("errors", this.validator.getErrorMessages());
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  componentDidMount() {
    this.loadCustomer();
    //const str = window.location.pathname;
    //var id = str.substring(str.lastIndexOf("/") + 1, str.length);
    if (this.props.match.params.id) {
      console.log("url id", this.props.match.params.id);
      this.editInvoice(this.props.match.params.id);
    }
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Invoice | MRC</title>
        </Helmet>

        <section className="admin">
          <AdminHeader></AdminHeader>
          <div className="wrapper d-flex">
            <AdminMenu></AdminMenu>

            <div className="wrapper_body">
              <div className="row">
                <div className="col m8 s12">
                  <h3 className="heading">
                    {this.state.editItem ? "Update Invoice" : "Create Invoice"}
                  </h3>
                </div>
              </div>
              <div className="row">
                <div className="col s12">
                  <form action id="createEmployeeForm">
                    <fieldset>
                      <div className="row">
                        <div className="col m6 s12">
                          <label className="label">Invoice No.</label>

                          <input
                            type="text"
                            placeholder="Invoice No."
                            name="invoiceno"
                            value={this.state.invoiceno}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                          />
                          <label className="error">
                            {this.validator.message(
                              "invoiceno",
                              this.state.invoiceno,
                              "required"
                            )}
                          </label>
                        </div>

                        <div className="col m6 s12">
                          <label className="label">Customer</label>
                          <select
                            name="customer"
                            onChange={this.handleCustomer}
                            onBlur={this.handleBlur}
                            value={this.state.customerInfo.customer}
                          >
                            <option>Select</option>
                            {this.state.customerList.map((customer, index) => (
                              <Fragment key={index}>
                                <option value={customer.id}>
                                  {customer.companyname}
                                </option>
                              </Fragment>
                            ))}
                          </select>
                          <label className="error">
                            {this.validator.message(
                              "customer",
                              this.state.customerInfo.customer,
                              "required"
                            )}
                          </label>
                        </div>

                        <div className="col m6 s12">
                          <label className="label">Project</label>
                          <select
                            name="project"
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            value={this.state.project}
                          >
                            <option>Select</option>
                            {this.state.projectList.map((project, index) => (
                              <Fragment key={index}>
                                <option value={project.id}>
                                  {project.name}
                                </option>
                              </Fragment>
                            ))}
                          </select>
                          <label className="error">
                            {this.validator.message(
                              "project",
                              this.state.project,
                              "required"
                            )}
                          </label>
                        </div>
                        <div className="col m6 s12">
                          <label className="label">GST TIN</label>
                          <select
                            name="gstid"
                            value={this.state.gstid}
                            onChange={this.handleChangeGst}
                          >
                            <option selected> Select GST TIN</option>
                            {this.state.masterGstList.map((type, index) => (
                              <Fragment key={index}>
                                <option value={type.id}>{type.gst}</option>
                              </Fragment>
                            ))}
                          </select>
                          <label className="error">
                            {this.validator.message(
                              "gstid",
                              this.state.gstid,
                              "required"
                            )}
                          </label>
                        </div>

                        <div className="col m6 s12">
                          <label className="label">State Name</label>
                          <input
                            type="text"
                            name="statename"
                            readOnly
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            value={this.state.statename}
                          />
                          <label className="error">
                            {this.validator.message(
                              "statename",
                              this.state.statename,
                              "required"
                            )}
                          </label>
                        </div>

                        <div className="col m6 s12">
                          <label className="label">State Code</label>
                          <input
                            type="text"
                            name="statecode"
                            readOnly
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            value={this.state.statecode}
                          />
                          <label className="error">
                            {this.validator.message(
                              "statecode",
                              this.state.statecode,
                              "required"
                            )}
                          </label>
                        </div>

                        <div className="col m6 s12">
                          <label className="label">Remarks</label>

                          <input
                            type="text"
                            placeholder="remarks"
                            name="remarks"
                            value={this.state.remarks}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                          />
                          <label className="error">
                            {this.validator.message(
                              "remarks",
                              this.state.remarks,
                              "required"
                            )}
                          </label>
                        </div>
                      </div>
                    </fieldset>
                    <fieldset>
                      <h5 className="center-align">
                        <u>Invoice Details</u>
                      </h5>
                      <div className="row">
                        <div className="col m6 s12">
                          <label className="label">Date</label>
                          <input
                            type="date"
                            name="date"
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            value={this.state.date}
                          />

                          <label className="error">
                            {this.validator.message(
                              "date",
                              this.state.date,
                              "required"
                            )}
                          </label>
                        </div>
                        <div className="col m6 s12">
                          <label className="label">Delivery Note</label>
                          <input
                            type="text"
                            name="deliverynote"
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            value={this.state.deliverynote}
                          />

                          <label className="error">
                            {this.validator.message(
                              "deliverynote",
                              this.state.deliverynote,
                              "required"
                            )}
                          </label>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col m6 s12">
                          <label className="label">Mode/Terms of Payment</label>
                          <input
                            type="text"
                            name="modeofpayment"
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            value={this.state.modeofpayment}
                          />

                          <label className="error">
                            {this.validator.message(
                              "modeofpayment",
                              this.state.modeofpayment,
                              "required"
                            )}
                          </label>
                        </div>
                        <div className="col m6 s12">
                          <label className="label">Reference No. & Date</label>
                          <input
                            type="text"
                            name="referenceno"
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            value={this.state.referenceno}
                          />

                          <label className="error">
                            {this.validator.message(
                              "referenceno",
                              this.state.referenceno,
                              "required"
                            )}
                          </label>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col m6 s12">
                          <label className="label">Other References</label>
                          <input
                            type="text"
                            name="otherreference"
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            value={this.state.otherreference}
                          />

                          <label className="error">
                            {this.validator.message(
                              "otherreference",
                              this.state.otherreference,
                              "required"
                            )}
                          </label>
                        </div>
                        <div className="col m6 s12">
                          <label className="label">Buyer's Order No.</label>
                          <input
                            type="text"
                            name="buyerorder"
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            value={this.state.buyerorder}
                          />

                          <label className="error">
                            {this.validator.message(
                              "buyerorder",
                              this.state.buyerorder,
                              "required"
                            )}
                          </label>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col m6 s12">
                          <label className="label">Dispatch Doc No</label>
                          <input
                            type="text"
                            name="dispatchdoc"
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            value={this.state.dispatchdoc}
                          />

                          <label className="error">
                            {this.validator.message(
                              "dispatchdoc",
                              this.state.dispatchdoc,
                              "required"
                            )}
                          </label>
                        </div>
                        <div className="col m6 s12">
                          <label className="label">Dispatch Through</label>
                          <input
                            type="text"
                            name="dispatchthrough"
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            value={this.state.dispatchthrough}
                          />

                          <label className="error">
                            {this.validator.message(
                              "dispatchthrough",
                              this.state.dispatchthrough,
                              "required"
                            )}
                          </label>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col m6 s12">
                          <label className="label">Destination</label>
                          <input
                            type="text"
                            name="destination"
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            value={this.state.destination}
                          />

                          <label className="error">
                            {this.validator.message(
                              "destination",
                              this.state.destination,
                              "required"
                            )}
                          </label>
                        </div>
                      </div>
                    </fieldset>
                    <fieldset>
                      <div className="row">
                        <div className="col m6 s12">
                          <h5 className="center-align">
                            <u>Bill To</u>
                          </h5>
                          <div className="col m12">
                            <label className="label">Name</label>
                            <input
                              type="text"
                              name="billtoname"
                              value={this.state.customerInfo.billtoname}
                              readOnly
                            />
                          </div>
                          <div className="col m12">
                            <label className="label">GSTIN/UIN</label>
                            <input
                              type="text"
                              name="billtogst"
                              value={this.state.customerInfo.billtogst}
                              readOnly
                            />
                          </div>
                          <div className="col m12">
                            <label className="label">Address</label>
                            <input
                              type="text"
                              name="billtoaddress"
                              value={this.state.customerInfo.billtoaddress}
                            />
                          </div>
                          <div className="col m12">
                            <label className="label">State</label>
                            <input
                              type="text"
                              name="billtostate"
                              value={this.state.customerInfo.billtostate}
                            />
                          </div>
                          <div className="col m12">
                            <label className="label">State Code</label>
                            <input
                              type="text"
                              name="billtostatecode"
                              value={this.state.customerInfo.billtostatecode}
                            />
                          </div>
                        </div>
                        <div className="col m6 s12">
                          <h5 className="center-align">
                            <u>Ship To</u>
                            <input
                              type="checkbox"
                              checked={this.state.shitocheckbox}
                              onChange={this.handleShipToCheck}
                            />
                          </h5>
                          <div className="col m12">
                            <label className="label">Name</label>
                            <input
                              type="text"
                              name="shipname"
                              value={this.state.customerInfo.shipname}
                              onChange={this.handleCustomerValue}
                              onBlur={this.handleBlur}
                            />
                          </div>
                          <div className="col m12">
                            <label className="label">GSTIN/UIN</label>
                            <input
                              type="text"
                              name="shipgst"
                              value={this.state.customerInfo.shipgst}
                              onChange={this.handleCustomerValue}
                            />
                          </div>
                          <div className="col m12">
                            <label className="label">Address</label>
                            <input
                              type="text"
                              name="shipaddress"
                              value={this.state.customerInfo.shipaddress}
                              onChange={this.handleCustomerValue}
                            />
                          </div>
                          <div className="col m12">
                            <label className="label">State</label>
                            <input
                              type="text"
                              name="shipstate"
                              value={this.state.customerInfo.shipstate}
                              onChange={this.handleCustomerValue}
                            />
                          </div>
                          <div className="col m12">
                            <label className="label">State Code</label>
                            <input
                              type="text"
                              name="shipstatecode"
                              value={this.state.customerInfo.shipstatecode}
                              onChange={this.handleCustomerValue}
                            />
                          </div>
                        </div>
                      </div>
                    </fieldset>
                    {/* <fieldset>
                          <h5 className='center-align'>
                            <u>Company's Bank Details</u>
                          </h5>
                          <div className='row'>
                            <div className='col m6 s12'>
                              <label className='label'>Bank Name</label>

                              <input
                                type='text'
                                placeholder='Bank Name'
                                name='bankName'
                                value={this.state.fields.bankName}
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                                readOnly
                                value='HDFC Bank LTD.'
                              />
                            </div>

                            <div className='col m6 s12'>
                              <label className='label'>Account Number</label>

                              <input
                                type='text'
                                name='bankName'
                                value={this.state.fields.bankName}
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                                readOnly
                                value='445007789898'
                              />
                            </div>

                            <div className='col m6 s12'>
                              <label className='label'>
                                Branch & IFSC Code
                              </label>

                              <input
                                type='text'
                                placeholder='PAN' 
                                name='branch'
                                value={this.state.fields.pan}
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                                value='Dasuya & HDFC00900'
                              />
                              <label className='error'>
                                {this.validator.message(
                                  "pan",
                                  this.state.fields.pan,
                                  "required"
                                )}
                              </label>
                            </div>
                            <div className='col m6 s12'>
                              <label className='label'>Company's Pan</label>

                              <input
                                type='text'
                                placeholder='PAN'
                                name='pan'
                                value={this.state.fields.pan}
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                                value='AAS6768'
                              />
                              <label className='error'>
                                {this.validator.message(
                                  "pan",
                                  this.state.fields.pan,
                                  "required"
                                )}
                              </label>
                            </div>
                            <div className='col m6 s12'>
                              <label className='label'>Period</label>

                              <input
                                type='text'
                                placeholder='PAN'
                                name='period'
                                value={this.state.fields.pan}
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                                value='01/09/2021 to 01/10/2021'
                              />
                            </div>
                            <div className='col m6 s12'>
                              <label className='label'>Remarks</label>

                              <input
                                type='text'
                                placeholder='PAN'
                                name='remarks'
                                value={this.state.fields.pan}
                                onChange={this.handleChange}
                                onBlur={this.handleBlur} 
                                value='dummy text dummy text'
                              />
                            </div>
                          </div>
                        </fieldset> */}
                    <fieldset>
                      {this.state.inputFields.map((inputField, index) => (
                        <Fragment key={`${inputField}~${index}`}>
                          <div className="row">
                            <div className="col m1 s12">
                              {index === 0 && (
                                <label className="label">SNo.</label>
                              )}

                              <input value={index + 1} />
                            </div>
                            <div className="col m2 s12">
                              {index === 0 && (
                                <label className="label">Particulars</label>
                              )}

                              <input
                                type="text"
                                name="particulars"
                                value={
                                  this.state.inputFields[index].particulars
                                }
                                onChange={(event) =>
                                  this.handleInputChange(index, event)
                                }
                              />
                              <label className="error">
                                {this.validator.message(
                                  "particulars",
                                  this.state.inputFields[index].particulars,
                                  "required"
                                )}
                              </label>
                            </div>

                            <div className="col m2 s12">
                              {index === 0 && (
                                <label className="label">HSN/SAC</label>
                              )}

                              <input
                                type="text"
                                name="hsn"
                                value={this.state.inputFields[index].hsn}
                                onChange={(event) =>
                                  this.handleInputChange(index, event)
                                }
                              />
                              <label className="error">
                                {this.validator.message(
                                  "hsn",
                                  this.state.inputFields[index].hsn,
                                  "required"
                                )}
                              </label>
                            </div>

                            <div className="col m2 s12">
                              {index === 0 && (
                                <label className="label">Amount</label>
                              )}

                              <input
                                type="text"
                                name="amount"
                                value={this.state.inputFields[index].amount}
                                onChange={(event) =>
                                  this.handleInputChange(index, event)
                                }
                              />
                              <label className="error">
                                {this.validator.message(
                                  "amount",
                                  this.state.inputFields[index].amount,
                                  "required"
                                )}
                              </label>
                            </div>

                            <div className="col m1 s12">
                              {index === 0 && (
                                <label className="label">Qty</label>
                              )}
                              <input
                                type="text"
                                name="qty"
                                value={this.state.inputFields[index].qty}
                                onChange={(event) =>
                                  this.handleInputChange(index, event)
                                }
                              />
                              <label className="error">
                                {this.validator.message(
                                  "quantity",
                                  this.state.inputFields[index].qty,
                                  "required"
                                )}
                              </label>
                            </div>

                            <div className="col m2 s12">
                              {index === 0 && (
                                <label className="label">GST %</label>
                              )}
                              <select
                                name="gst"
                                value={this.state.inputFields[index].gst}
                                onChange={(event) =>
                                  this.handleInputChange(index, event)
                                }
                              >
                                <option selected> Select GST</option>
                                <option value="5">5%</option>
                                <option value="12">12%</option>
                                <option value="18">18%</option>
                                <option value="28">28%</option>
                              </select>
                              <label className="error">
                                {this.validator.message(
                                  "gst",
                                  this.state.inputFields[index].gst,
                                  "required"
                                )}
                              </label>
                            </div>

                            <div className="col m2 s12">
                              {index === 0 && (
                                <label className="label">Total Amount</label>
                              )}

                              <input
                                type="text"
                                name="total"
                                readOnly
                                value={this.state.inputFields[index].total}
                                onChange={(event) =>
                                  this.handleInputChange(index, event)
                                }
                              />
                            </div>
                          </div>
                          {this.state.inputFields.length === index + 1 ? (
                            <>
                              {this.state.inputFields.length > 1 && (
                                <button
                                  className="theme_btn btn"
                                  type="button"
                                  onClick={() => this.handleRemoveFields(index)}
                                >
                                  -
                                </button>
                              )}

                              <button
                                className="theme_btn btn"
                                type="button"
                                onClick={() => this.handleAddFields()}
                              >
                                +
                              </button>
                            </>
                          ) : null}
                        </Fragment>
                      ))}
                    </fieldset>
                    <fieldset>
                      <div className="col m12">
                        <h6>Terms & Conditions</h6>
                        <textarea
                          name="terms"
                          className="materialize-textarea"
                          value={this.state.terms}
                          onChange={this.handleChange}
                          onBlur={this.handleBlur}
                        ></textarea>
                      </div>
                    </fieldset>
                    <fieldset>
                      <div className="col m9 s12"></div>
                      <div className="col m3 s12">
                        <h6>
                          <strong>Sub Total:&nbsp;</strong>{" "}
                          <i class="fas fa-rupee-sign"></i>{" "}
                          {this.state.subtotal.toFixed(2)}
                        </h6>
                        {this.state.igst ? (
                          <>
                            {" "}
                            <h6>
                              <strong>IGST:&nbsp;</strong>{" "}
                              <i class="fas fa-rupee-sign"></i>{" "}
                              {this.state.grandtotalFinal -
                                this.state.subtotal.toFixed(2)}
                            </h6>
                          </>
                        ) : (
                          <>
                            <h6>
                              <strong>CGST:&nbsp;</strong>{" "}
                              <i class="fas fa-rupee-sign"></i>{" "}
                              {(this.state.grandtotalFinal -
                                this.state.subtotal.toFixed(2)) /
                                2}
                            </h6>
                            <h6>
                              <strong>SGST:&nbsp;</strong>{" "}
                              <i class="fas fa-rupee-sign"></i>{" "}
                              {(this.state.grandtotalFinal -
                                this.state.subtotal.toFixed(2)) /
                                2}
                            </h6>
                          </>
                        )}

                        <h6>
                          <strong>Grand Total:&nbsp;</strong>{" "}
                          <i class="fas fa-rupee-sign"></i>{" "}
                          {this.state.grandtotalFinal.toFixed(2)}
                        </h6>
                      </div>
                    </fieldset>
                    <div style={{ width: "350px" }}>
                      <SignaturePad
                        options={{
                          minWidth: 1,
                          maxWidth: 5,
                          backgroundColor: "#d3d3d3",
                          penColor: "black",
                        }}
                        ref={this.sigPad}
                      />
                      <a
                        className="btn btn-danger"
                        onClick={this.handleSignClear}
                      >
                        Clear Sign
                      </a>
                    </div>
                    {/* <SignaturePad
                      // canvasProps={{ className: styles.sigPad }}
                      ref={(ref) => {
                        this.sigPad = ref;
                      }}
                    /> */}
                    <fieldset>
                      <div className="row">
                        <div className="col"></div>
                        <div className="col s12 right-align">
                          <button
                            form="createEmployeeForm"
                            className="theme_btn btn"
                            onClick={this.submitForm}
                          >
                            {this.state.editItem
                              ? "Update Invoice"
                              : "Genrate Invoice"}
                            {this.state.isLoading ? (
                              <ButtonLoader></ButtonLoader>
                            ) : null}
                          </button>
                        </div>
                      </div>
                    </fieldset>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <Footer />
          {/* {JSON.stringify(this.state.inputFields, null, 2)}
          {JSON.stringify(this.state.customerInfo, null, 2)} */}
        </section>
      </>
    );
  }
}
