import React, { Component } from "react";
import { Link } from "react-router-dom";
import { setUserData, getUserData } from "../helpers/storage";

export default class SideBar extends Component {
  constructor(props) {
    super(props);
    let userData = getUserData();
    this.state = {
      userData: userData,
      errors: {},
      projectMenu: false,
    };
  }
  componentWillMount() {
    let url = window.location.href.split("/");
    let isProject = url[url.length - 2];
    if (isProject == "project") {
      // alert("sidebar");
      this.setState({ projectMenu: true });
    }
    console.log(window.location.href);
    console.log(url[url.length - 2]);
  }
  render() {
    return (
      <div className='d-flex'>
        <div className='side-bar'>
          <Link
            to='/projects'
            className='tooltipped back_icon'
            data-position='right'
            data-tooltip='Back to admin panel'
          >
            <i className='material-icons'>arrow_back_ios</i>
          </Link>

          <ul>
            {this.state.userData.user.userType == "1" ? (
              <li>
                <Link
                  to='/Admin'
                  className='tooltipped'
                  data-position='right'
                  data-tooltip='Dashboard'
                >
                  <img src='assets/images/admin/dashboard_icon.svg' alt='' />
                </Link>
              </li>
            ) : null}
          </ul>
        </div>

        {/* <MnavBar /> */}
        {/* <div>
          <aside className='aside_navbar'>
            {this.state.userData.user.userType == "2" &&
            this.state.projectMenu == false ? (
              <ul>
                <li>
                  <i />
                  <Link to='/projects'>All Projects</Link>
                </li>
                <li>
                  <i />
                  <Link to='/employees'>Employee</Link>
                </li>
                <li>
                  <i />
                  <Link to='/Roles'>Roles</Link>
                </li>
                <li>
                  <i />
                  <Link to='/Contractor'>Contractor</Link>
                </li>
                <li>
                  <i />
                  <Link to='/Master'>Master</Link>
                </li>
                <li>
                  <i />
                  <Link to='/expensehead'>Expense Head</Link>
                </li>
                <li>
                  <i />
                  <Link to='/daybook'>Admin Expense</Link>
                </li>
                <li>
                  <i />
                  <Link to='/cashassign'>Assign Cash</Link>
                </li>
                <li>
                  <i />
                  <Link to='/employeeexpenses'>All Expenses</Link>
                </li>
                <li>
                  <i />
                  <Link to='/recievables'>Add Recievables</Link>
                </li>
                
              </ul>
            ) : null}
            {this.state.userData.user.userType == "3" &&
            this.state.projectMenu == false ? (
              <ul>
                <li>
                  <i />
                  <Link to='/projects'>All Projects</Link>
                </li>
                <li>
                  <i />
                  <Link to='/daybook'>Add Expense</Link>
                </li>
                <li>
                  <i />
                  <Link to='/cashtransfer'>Transfer Cash</Link>
                </li>
              </ul>
            ) : null}

            {this.state.userData.user.userType == "2" &&
            this.state.projectMenu == true ? (
              <ul class='collapsible'>
                <li class='active'>
                  <a
                    href='javascript:void(0)'
                    class='collapsible-header'
                    tabindex='0'
                  >
                    Physical Progress<i class='material-icons'>expand_more</i>
                  </a>
                  <div class='collapsible-body' style={{ display: "block" }}>
                    <ul>
                      <li>
                        <a href='javascript:void(0)'>Analytics</a>
                      </li>
                      <li class='active'>
                        <a href='project-setup.html'>Project Setup</a>
                      </li>
                      <li>
                        <a href='javascript:void(0)'>Live Bar Chart</a>
                      </li>
                      <li>
                        <a href='javascript:void(0)'>Geotagged Updates</a>
                      </li>
                      <li>
                        <a href='javascript:void(0)'>Gallery</a>
                      </li>
                      <li>
                        <a href='javascript:void(0)'>Supervisors' Activities</a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <a
                    href='javascript:void(0)'
                    class='collapsible-header'
                    tabindex='0'
                  >
                    Project management <i class='material-icons'>expand_more</i>
                  </a>
                  <div class='collapsible-body'>
                    <ul>
                      <li>
                        <a href='drawings.html'>Drawings</a>
                      </li>
                      <li>
                        <a href='#!'>Material</a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <a
                    href='javascript:void(0)'
                    class='collapsible-header'
                    tabindex='0'
                  >
                    Human resources<i class='material-icons'>expand_more</i>
                  </a>
                  <div class='collapsible-body'>
                    <ul>
                      <li>
                        <a href='javascript:void(0)'>Accounts</a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <a
                    href='javascript:void(0)'
                    class='collapsible-header'
                    tabindex='0'
                  >
                    Purchase<i class='material-icons'>expand_more</i>
                  </a>
                  <div class='collapsible-body'>
                    <ul>
                      <li>
                        <a href='javascript:void(0)'>Accounts</a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <a
                    href='javascript:void(0)'
                    class='collapsible-header'
                    tabindex='0'
                  >
                    Accounting <i class='material-icons'>expand_more</i>
                  </a>
                  <div class='collapsible-body'>
                    <ul>
                      <li>
                        <a href='daybook.html'>Daybook form</a>
                      </li>
                      <li>
                        <a href='javascript:void(0)'>Accounts receivables</a>
                      </li>
                      <li>
                        <a href='javascript:void(0)'>Invoices</a>
                      </li>
                      <li>
                        <a href='javascript:void(0)'>Documents with renewals</a>
                      </li>
                      <li>
                        <a href='javascript:void(0)'>Taxes</a>
                      </li>
                      <li>
                        <a href='javascript:void(0)'>Bank</a>
                      </li>
                      <li>
                        <a href='javascript:void(0)'>Ledger</a>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            ) : null}
          </aside>
        </div> */}
      </div>
    );
  }
}
