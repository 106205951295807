import React, { Component } from "react";
import Footer from "../../components/Footer";
import AdminHeader from "../../components/header/AdminHeader";
import AdminMenu from "../../components/sidebar/AdminMenu";

import {
  BrowserRouter as Router,
  Switch,
  Link,
  Route,
  useHistory,
} from "react-router-dom";
import ButtonLoader from "../../components/ButtonLoader";
import axios from "axios";
import { toast } from "react-toastify";
import "materialize-css";
import { Modal, Checkbox } from "react-materialize";
import { getUserData } from "../../helpers/storage";
import ReactFormInputValidation from "react-form-input-validation";
import SweetAlert from "react-bootstrap-sweetalert";
import { ImagePicker } from "react-file-picker";
import { BulletList } from "react-content-loader";
import SimpleReactValidator from "simple-react-validator";
import { Helmet } from "react-helmet";

import {
  apiCreateRole,
  apiListRoles,
  apiEditRole,
  apiGetRole,
  apiDeleteRole,
  apiAllModules,
} from "../../config/endpoints";

let currentCategory = "";
const initialState = {
  /* etc */
  fields: {
    name: "",
  },
  perm: {},
  logo: false,
  isLoading: false,
  redirect: false,
  errors: {},
  rolesList: [],
  alert: false,
  editItem: false,
  isModalOpen: false,
  isListLoading: false,
  deleteId: false,
  editId: false,
  modulesList: [],
  categoriesList: [],
  permissions: {},
};
export default class Roles extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = initialState;
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.logoChange = this.logoChange.bind(this);
    this.editRole = this.editRole.bind(this);
    this.deleteRole = this.deleteRole.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
  }

  showAlert = (id) => {
    //console.log(id);
    this.setState({
      alert: true,
      deleteId: id,
    });
  };

  editRole(id) {
    // Assuming only image
    this.setState({ editItem: true, editId: id });
    let userData = getUserData();
    this.handleOpenModal();
    fetch(apiGetRole + "?id=" + id, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        /* console.log("company", data.data.company);
        console.log("role", data.data.role); */
        if (data.code == 200) {
          this.setState({
            fields: data.data.role,
            permissions: JSON.parse(data.data.role.permissions),
          });
          //console.log("rajat roles", this.state.permissions);
        } else {
          alert("something went wrong.");
        }
        // setTimeout(function () {
      });
    // Updating the city
  }
  componentWillMount() {
    this.loadRoles();
  }
  loadRoles = (e) => {
    this.setState({ isListLoading: true });
    let userData = getUserData();
    fetch(apiListRoles, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          this.setState({
            rolesList: data.data.roles.rows,
          });
          // console.log(this.state.companyList);
          this.setState({ isListLoading: false });
        } else {
          alert("something went wrong.");
        }
        // setTimeout(function () {
      });

    fetch(apiAllModules, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          this.setState({
            modulesList: data.data.modules.rows,
          });
          this.setState({ isListLoading: false });
          //console.log("modules", this.state);
        } else {
          alert("something went wrong.");
        }
      });
  };
  logoChange = (e) => {
    // Assuming only image
    if (e.target.files[0]) {
      //console.log("picture: ", e.target.files);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        //console.log(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
      this.setState({ logo: e.target.files[0] });
    }
    // Updating the city
  };
  deleteRole = (e) => {
    //console.log("delete", this.state);
    this.setState({ alert: false });
    let userData = getUserData();
    axios
      .post(
        apiDeleteRole,
        { id: this.state.deleteId },
        {
          headers: {
            Authorization: "Bearer " + userData.token,
          },
        }
      )
      .then(
        (response) => {
          //console.log(response);
          this.setState({ isLoading: false });
          toast.info("Role deleted", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          this.setState(this.initialState);
          this.loadRoles();
        },
        (error) => {
          if (error.response.status == 500 || error.response.status == 400) {
            toast.error(error.response.data.errorMessage, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            this.setState({ isLoading: false });
          }
        }
      );
  };

  hideAlert = () => {
    this.setState({
      alert: false,
    });
  };
  handleCloseModal = () => {
    //console.log("i ma in close", this.state);
    this.setState(initialState);
    this.loadRoles();
  };
  handleOpenModal = () => {
    this.setState({ isModalOpen: true });
    this.validator.hideMessages();
  };
  handleChange = (e) => {
    this.validator.showMessageFor(e.target.name);
    const value = e.target.value;
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        [e.target.name]: value,
      },
    }));
  };

  handleChangePer = (e) => {
    const value = e.target.value;
    //console.log("checked event", e.target.checked, e.target.name);
    if (e.target.checked) {
      if (e.target.name == "create_daybook[create]") {
        this.setState((prevState) => ({
          perm: {
            ...prevState.perm,
            [e.target.name]: true,
            "create_daybook[view]": true,
          },
        }));
      } else if (e.target.name == "approve_daybook[create]") {
        this.setState((prevState) => ({
          perm: {
            ...prevState.perm,
            [e.target.name]: true,
            "approve_daybook[view]": true,
          },
        }));
      } else if (e.target.name == "company[create]") {
        this.setState((prevState) => ({
          perm: {
            ...prevState.perm,
            [e.target.name]: true,
            "company[view]": true,
          },
        }));
      } else if (e.target.name == "cash_assign[create]") {
        this.setState((prevState) => ({
          perm: {
            ...prevState.perm,
            [e.target.name]: true,
            "cash_assign[view]": true,
          },
        }));
      } else {
        this.setState((prevState) => ({
          perm: {
            ...prevState.perm,
            [e.target.name]: true,
          },
        }));
      }
    } else {
      this.setState((prevState) => ({
        perm: {
          ...prevState.perm,
          [e.target.name]: false,
        },
      }));
    }
    //console.log(this.state);
  };

  handleBlur = (e) => {
    this.validator.showMessageFor(e.target.name);
  };

  submitForm = (e) => {
    e.preventDefault();
    console.log(this.state);
    if (this.validator.allValid()) {
      /* console.log("submit");
      console.log(this.state);
      console.log(getUserData()); */
      let userData = getUserData();
      if (!this.state.editItem) {
        this.setState({ isLoading: true });
        axios
          .post(
            apiCreateRole,
            { name: this.state.fields.name, permissions: this.state.perm },
            {
              headers: {
                Authorization: "Bearer " + userData.token,
              },
            }
          )
          .then(
            (response) => {
              //console.log(response);
              this.setState({ isLoading: false });
              toast.info("Role created", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
              this.setState(this.initialState);
              this.handleCloseModal();
            },
            (error) => {
              if (
                error.response.status == 500 ||
                error.response.status == 400
              ) {
                toast.error(error.response.data.errorMessage, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                this.setState({ isLoading: false });
              }
            }
          );
      } else {
        //edit app
        /* console.log("inside edit ", this.state);
        console.log("old permission", this.state.permissions);
        console.log("newly added permission", this.state.perm); */

        this.setState({ isLoading: true });
        axios
          .post(
            apiEditRole,
            {
              name: this.state.fields.name,
              id: this.state.editId,
              permissions: { ...this.state.permissions, ...this.state.perm },
            },
            {
              headers: {
                Authorization: "Bearer " + userData.token,
              },
            }
          )
          .then(
            (response) => {
              //console.log("edit role", response.data.data);
              this.setState({ isLoading: false });
              toast.info("Role Updated", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
              this.setState(this.initialState);
              this.handleCloseModal();
            },
            (error) => {
              if (
                error.response.status == 500 ||
                error.response.status == 400
              ) {
                toast.error(error.response.data.errorMessage, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                this.setState({ isLoading: false });
              }
            }
          );
      }
    } else {
      /* console.log("fail");
      console.log("errors", this.validator.getErrorMessages()); */
      this.validator.showMessages();
      this.forceUpdate();
    }
  };
  render() {
    this.validator.purgeFields();
    let categoryName = "";
    let isShown = "";
    let modulesListShow = this.state.modulesList.map((module, index) => {
      // console.log(module.category);
      if (isShown == module.category) {
        categoryName = "";
      } else {
        isShown = module.category;
        categoryName = (
          <tr>
            <th colSpan="5">{module.category}</th>
          </tr>
        );
      }
      return (
        <>
          {categoryName}
          <tr key={index}>
            <td>{module.name}</td>
            <td>
              <Checkbox
                id={"view_" + module.id}
                name={module.key + "[view]"}
                value="view"
                checked={
                  module.key + "[view]" in this.state.permissions &&
                  this.state.permissions[module.key + "[view]"] === true
                    ? "checked"
                    : ""
                }
                onChange={this.handleChangePer}
              />
            </td>
            <td>
              <Checkbox
                id={"create_" + module.id}
                name={module.key + "[create]"}
                value="create"
                checked={
                  module.key + "[create]" in this.state.permissions &&
                  this.state.permissions[module.key + "[create]"] === true
                    ? "checked"
                    : ""
                }
                onChange={this.handleChangePer}
              />
            </td>
            <td>
              <Checkbox
                id={"edit_" + module.id}
                name={module.key + "[edit]"}
                value="edit"
                checked={
                  module.key + "[edit]" in this.state.permissions &&
                  this.state.permissions[module.key + "[edit]"] === true
                    ? "checked"
                    : ""
                }
                onChange={this.handleChangePer}
              />
            </td>
            <td>
              <Checkbox
                id={"delete_" + module.id}
                name={module.key + "[delete]"}
                checked={
                  module.key + "[delete]" in this.state.permissions &&
                  this.state.permissions[module.key + "[delete]"] === true
                    ? "checked"
                    : ""
                }
                value="delete"
                onChange={this.handleChangePer}
              />
            </td>
          </tr>
        </>
      );
    });
    return (
      <React.Fragment>
        <Helmet>
          <title>Roles | MRC</title>
        </Helmet>
        <section className="admin">
          <AdminHeader />
          <div className="wrapper d-flex">
            <AdminMenu />

            <div className="wrapper_body">
              <div className="row d-flex align-items-center">
                <div className="col m0 d-flex align-items-center">
                  <h3 className="heading">Roles &amp; Permissions</h3>
                </div>
                <div className="col m4 right-align">
                  <a
                    href="javascript:void(0)"
                    className="theme_btn btn waves-effect"
                    onClick={this.handleOpenModal}
                  >
                    <i className="material-icons">add&nbsp;</i>New Role
                  </a>
                  <Modal
                    actions={[,]}
                    bottomSheet={false}
                    fixedFooter={false}
                    id="Modal-0"
                    open={this.state.isModalOpen}
                    className="cstm-modal"
                    options={{
                      dismissible: false,
                      endingTop: "10%",
                      inDuration: 250,
                      onCloseEnd: null,
                      onCloseStart: null,
                      onOpenEnd: null,
                      onOpenStart: null,
                      opacity: 0.5,
                      outDuration: 250,
                      preventScrolling: true,
                      startingTop: "4%",
                    }}
                  >
                    {/* Close Modal */}
                    <a
                      href="javascript:void(0)"
                      className="modal-close"
                      onClick={this.handleCloseModal}
                    >
                      <span className="material-icons">chevron_right</span>
                    </a>
                    {/* Modal Content */}
                    <div className="row">
                      <div className="col s12">
                        <h3 className="heading">
                          {this.state.editItem
                            ? "Edit Role"
                            : "Create New Role"}
                        </h3>
                        <p>
                          Enter details to{" "}
                          {this.state.editItem ? "edit " : "create"} new list
                        </p>
                      </div>
                    </div>
                    <form action id="createRoleForm">
                      <div className="row">
                        <div className="col s12">
                          <label className="label">Role Name</label>
                          <input
                            type="text"
                            placeholder="Role Name"
                            name="name"
                            value={this.state.fields.name}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                          />
                          <label className="error">
                            {this.validator.message(
                              "name",
                              this.state.fields.name,
                              "required"
                            )}
                          </label>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col s12">
                          <table>
                            <tr>
                              <th>Role</th>
                              <th>View</th>
                              <th>Create</th>
                              <th>Edit</th>
                              <th>Delete</th>
                            </tr>
                            {modulesListShow}
                          </table>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col s12 right-align">
                          <button
                            type="submit"
                            form="createRoleForm"
                            className="theme_btn btn"
                            onClick={this.submitForm}
                          >
                            {this.state.editItem ? "Update Role" : "New Role"}
                            {this.state.isLoading ? (
                              <ButtonLoader></ButtonLoader>
                            ) : null}
                          </button>
                        </div>
                      </div>
                    </form>
                  </Modal>
                </div>
              </div>
              <div className="projects roles">
                <div className="row">
                  <div className="col s12">
                    <div className="table-responsive">
                      <table>
                        <thead>
                          <tr>
                            <th style={{ width: "80%" }}>Name</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.isListLoading ? (
                            <tr>
                              <td colSpan={5}>
                                <BulletList></BulletList>
                              </td>
                            </tr>
                          ) : (
                            this.state.rolesList.map((role, index) => (
                              <>
                                <tr key={index}>
                                  <td data-th="Name">{role.name}</td>
                                  <td>
                                    <a
                                      href="#Modal-0"
                                      className="actions"
                                      node="button"
                                      onClick={() => this.editRole(role.id)}
                                    >
                                      <i className="material-icons">edit</i>
                                    </a>
                                    <a
                                      href="javascript:void(0)"
                                      className="actions"
                                      onClick={() => this.showAlert(role.id)}
                                    >
                                      <i className="material-icons">delete</i>
                                    </a>
                                  </td>
                                </tr>
                              </>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.state.alert && (
            <SweetAlert
              onConfirm={this.deleteRole}
              onCancel={this.hideAlert}
              focusCancelBtn={true}
              warning
              showCancel
              confirmBtnText="Yes, delete it!"
              confirmBtnBsStyle="danger"
              title="Are you sure?"
            />
          )}
          <Footer />
        </section>
        {/* Add Employee Modal */}
      </React.Fragment>
    );
  }
}
