import React, { Component, Fragment } from "react";
import Footer from "../../components/Footer";
import AdminHeader from "../../components/header/AdminHeader";
import AdminMenu from "../../components/sidebar/AdminMenu";
import ButtonLoader from "../../components/ButtonLoader";
import axios from "axios";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "materialize-css";
import { Modal, Badge } from "react-materialize";
import { getUserData } from "../../helpers/storage";
import SweetAlert from "react-bootstrap-sweetalert";
import SimpleReactValidator from "simple-react-validator";
import Pagination from "rc-pagination";
import Select from "rc-select";
import "rc-select/assets/index.less";
import "../../assets/pagination.less";
import localeInfo from "../../helpers/en_US";
import SignaturePad from "react-signature-pad-wrapper";

import {
  apiDeleteEmployee,
  apiListVendors,
  apiListCustomer,
  apiGetItemMasterList,
  apiGetVendor,
  apiGetItemMaster,
  apiCreatePo,
  apiListPo,
  apiListProjects,
  apiGetProject,
  apiGetGstMaster,
  apiListGstMasters,
  apiGetPo,
  apiEditPo,
  apiGetSettings,
} from "../../config/endpoints";
import { Helmet } from "react-helmet";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const initialState = {
  fields: {
    vendor: "",
    ponumber: "",
    date: "",
    customer: 0,
    billinggst: "",
    project: 0,
    shipname: "",
    shipaddress: "",
    shipgst: "",
    shipstate: "",
    shipstatecode: "",
    vendor: "",
    particulars: "",
    total: "",
    totaltax: "",
    grandtotal: "",
    remarks: "",
    terms: "",
  },
  searchFields: { ponumber: "", project: "", from: "", to: "" },
  inputFields: [
    {
      item: "",
      particulars: "",
      hsn: "",
      qty: 1,
      gst: "",
      amount: 0,
      total: 0,
      tax: "",
      notes: "",
    },
  ],
  selectedVendor: { address: "", gst: "", id: "" },
  selectedProject: { address: "", gst: "", statecode: "", state: "", id: "" },
  selectedBillingGst: {
    address: "",
    gst: "",
    statecode: "",
    state: "",
    id: "",
  },
  selectedItems: [],
  vendorList: [],
  customerList: [],
  itemMasterList: [],
  gstMasterList: [],
  projectList: [],
  poList: [],
  totaltax: [],
  grandtotal: [],
  subtotal: 0,
  grandtotalFinal: 0,
  isLoading: false,
  redirect: false,
  errors: {},
  isModalOpen: false,
  isListLoading: false,
  alert: false,
  deleteId: "",
  editId: false,
  editItem: false,
  duplicateItem: false,
  duplicateId: false,
  passId: false,
  pageSize: 30,
  current: 1,
};

export default class PoTracking extends Component {
  sigPad = {};
  constructor(props) {
    super(props);
    this.state = initialState;
    this.selectInputRef = React.createRef();
    this.projectReset = this.projectReset.bind(this);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.validator1 = new SimpleReactValidator({ autoForceUpdate: this });
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  handleSignClear = () => {
    const signaturePad = this.sigPad.current;

    if (signaturePad) {
      signaturePad.instance.clear();
    }
  };

  onChangePagination = (page) => {
    this.setState((prevState) => ({
      ...prevState,
      current: page,
    }));
  };

  onChangePageSize = (current, pageSize) => {
    this.setState((prevState) => ({
      ...prevState,
      current: current,
      pageSize: pageSize,
    }));
  };

  onShowSizeChange = (current, pageSize) => {
    console.log(current);
    this.setState({ pageSize });
  };

  loadMaster = (e) => {
    let userData = getUserData();
    fetch(apiListVendors + "", {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          // console.log(data.data.vendors);
          this.setState({
            vendorList: data.data.vendors.rows,
          });
        } else {
          alert("something went wrong.");
        }
      });

    fetch(apiListCustomer + "", {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          this.setState({
            customerList: data.data.customer.rows,
          });
        } else {
          alert("something went wrong.");
        }
      });

    fetch(apiGetItemMasterList, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          this.setState({
            itemMasterList: data.data.masters.rows,
          });
        } else {
          alert("something went wrong.");
        }
      });

    fetch(apiListGstMasters, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          this.setState({
            gstMasterList: data.data.masters.rows,
          });
        } else {
          alert("something went wrong.");
        }
      });

    fetch(apiListPo + "?status=1", {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          this.setState((prevState) => ({
            fields: {
              ...prevState.fields,
              ponumber: data.data.poNumber,
            },
            poList: data.data.po.rows,
            poCount: data.data.po.count,
          }));
        } else {
          alert("something went wrong.");
        }
      });

    fetch(apiListProjects, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          //console.log("project list", data.data.projects.rows);
          this.setState({
            projectList: data.data.projects.rows,
            projectListCount: data.data.projects.count,
          });
        } else {
          alert("something went wrong.");
        }
      });

    fetch(apiGetSettings + "?key=po_terms", {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          // this.setState({
          //   projectData: data.data.projects.rows,
          // });
          // console.log(this.state.projectList);
          // this.setState({
          //   terms: data.data.setting.value,
          // });
          this.setState((prevState) => ({
            fields: {
              ...prevState.fields,
              terms: data.data.setting ? data.data.setting.value : "",
            },
          }));
        } else {
          alert("something went wrong.");
        }
        // setTimeout(function () {
      });
  };

  handleAddFields = () => {
    const values = [...this.state.inputFields];
    values.push({
      item: "",
      particulars: "",
      hsn: "",
      qty: 1,
      gst: "",
      amount: 0,
      total: 0,
      tax: "",
      notes: "",
    });
    this.setState({ inputFields: values });
  };

  handleRemoveFields = (index) => {
    const values = [...this.state.inputFields];
    values.splice(index, 1);
    let tot_tax = [...this.state.totaltax];
    tot_tax.splice(index, 1);
    let grand_tot = [...this.state.grandtotal];
    grand_tot.splice(index, 1);

    this.setState({
      inputFields: values,
      totaltax: tot_tax,
      grandtotal: grand_tot,
    });

    let subTotalAmount = 0;
    let totalAmount = 0;
    for (var i = 0; i < this.state.inputFields.length; i++) {
      console.log("input", this.state.inputFields[i]);
      subTotalAmount += parseInt(this.state.inputFields[i].amount);
      console.log("sub", subTotalAmount);
    }
    this.setState({ subtotal: subTotalAmount });

    for (var i = 0; i < this.state.inputFields.length; i++) {
      console.log("input", this.state.inputFields[i]);
      totalAmount += parseInt(this.state.inputFields[i].total);
      console.log("total", totalAmount);
    }
    this.setState({ subtotal: subTotalAmount });
    this.setState({
      grandtotalFinal: totalAmount,
    });
  };

  handleInputChange = (index, event) => {
    const values = [...this.state.inputFields];
    if (event.target.name === "particulars") {
      values[index].particulars = event.target.value;
    } else if (event.target.name === "hsn") {
      values[index].hsn = event.target.value;
    } else if (event.target.name === "qty") {
      values[index].qty = event.target.value;
    } else if (event.target.name === "gst") {
      values[index].gst = event.target.value;
    } else if (event.target.name === "amount") {
      values[index].amount = event.target.value;
    } else if (event.target.name === "notes") {
      values[index].notes = event.target.value;
    }
    let quant = parseInt(this.state.inputFields[index].qty);
    let amt = parseInt(this.state.inputFields[index].amount) * quant;
    values[index].total = amt;
    if (
      this.state.inputFields[index].qty &&
      this.state.inputFields[index].gst &&
      this.state.inputFields[index].amount
    ) {
      let per = parseInt(this.state.inputFields[index].gst);
      let quant = parseInt(this.state.inputFields[index].qty);
      let amt = parseInt(this.state.inputFields[index].amount) * quant;
      let tax = amt * (per / 100);
      values[index].tax = tax;
      values[index].total = Math.round(amt + tax);

      this.setState({ totaltax: [...this.state.totaltax, tax] });
      this.setState({
        grandtotal: [...this.state.grandtotal, values[index].total],
      });
    }
    this.setState({ inputFields: values });
    // console.log(this.state.inputFields);

    let subTotalAmount = 0;
    let totalAmount = 0;
    for (var i = 0; i < this.state.inputFields.length; i++) {
      console.log("input", this.state.inputFields[i]);
      subTotalAmount += parseInt(this.state.inputFields[i].amount);
      console.log("sub", subTotalAmount);
    }
    this.setState({ subtotal: subTotalAmount });

    for (var i = 0; i < this.state.inputFields.length; i++) {
      // console.log("input", this.state.inputFields[i]);
      totalAmount += parseInt(this.state.inputFields[i].total);
      // console.log("total", totalAmount);
    }
    this.setState({ subtotal: subTotalAmount });
    this.setState({
      grandtotalFinal: totalAmount,
    });
  };

  componentDidMount() {
    this.loadMaster();
    //console.log("componentDidMount", this.state.fields.projects);
  }

  handleCloseModal = () => {
    this.setState(initialState);
    this.projectReset();
    this.loadMaster();
  };

  handleOpenModal = () => {
    this.setState({ isModalOpen: true });
    this.validator.hideMessages();
    //console.log("model open", this.state);
  };

  projectReset = () => {
    // this.selectInputRef.current.select.clearValue();
  };

  showAlert = (id) => {
    //console.log(id);
    this.setState({
      alert: true,
      deleteId: id,
    });
  };

  setLocation = (e) => {
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        address: e,
      },
    }));
  };

  hideAlert = () => {
    this.setState({
      alert: false,
    });
  };

  deleteEmployee = (e) => {
    //console.log("delete", this.state);
    this.setState({ alert: false });
    let userData = getUserData();
    axios
      .post(
        apiDeleteEmployee,
        { id: this.state.deleteId },
        {
          headers: {
            Authorization: "Bearer " + userData.token,
          },
        }
      )
      .then(
        (response) => {
          //console.log(response);
          this.setState({ isLoading: false });
          toast.info("Employee deleted", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          this.setState(this.initialState);
        },
        (error) => {
          if (error.response.status == 500 || error.response.status == 400) {
            toast.error(error.response.data.errorMessage, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            this.setState({ isLoading: false });
          }
        }
      );
  };

  handleChange = (e) => {
    this.validator.showMessageFor(e.target.name);
    const value = e.target.value;
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        [e.target.name]: value,
      },
    }));
    console.log(this.state.fields);
  };

  handleSetVendor = (e, value) => {
    console.log(value.key);
    let userData = getUserData();
    fetch(apiGetVendor + "?id=" + value.key, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          // console.log(data.data.vendors);
          this.setState((prevState) => ({
            fields: {
              ...prevState.fields,
              vendor: data.data.vendor.id,
            },
            selectedVendor: data.data.vendor,
          }));
        } else {
          alert("something went wrong.");
        }
      });
  };

  handleSetProject = (e, value) => {
    console.log(value.key);
    let userData = getUserData();
    fetch(apiGetProject + "?id=" + value.key, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          this.setState((prevState) => ({
            fields: {
              ...prevState.fields,
              project: data.data.project.id,
            },
            selectedProject: data.data.project,
          }));
          console.log(this.state.fields);
        } else {
          alert("something went wrong.");
        }
      });
  };

  handleSetBilling = (e, value) => {
    console.log(value.key);
    let userData = getUserData();
    fetch(apiGetGstMaster + "?id=" + value.key, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          this.setState((prevState) => ({
            fields: {
              ...prevState.fields,
              billinggst: data.data.master.id,
            },
            selectedBillingGst: data.data.master,
          }));
          console.log(this.state.fields);
        } else {
          alert("something went wrong.");
        }
      });
  };

  handleSetItem = (e, value, index) => {
    console.log(value.key);
    let userData = getUserData();
    fetch(apiGetItemMaster + "?id=" + value.key, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          console.log(data.data.master);
          const values = [...this.state.inputFields];
          values[index].item = data.data.master.id;
          values[index].particulars = data.data.master.name;
          values[index].hsn = data.data.master.hsn;
          this.setState({ inputFields: values });
          // this.setState((prevState) => ({
          //   selectedItem: {
          //     ...prevState.selectedItem,
          //     selectedItem: data.data.master,
          //   },
          // }));
          // this.setState({
          //   selectedItem: data.data.master,
          // });
          // console.log(this.state.selectedItem);
        } else {
          alert("something went wrong.");
        }
      });
  };

  handleBlur = (e) => {
    this.validator.showMessageFor(e.target.name);
  };

  submitForm = (e) => {
    e.preventDefault();
    //console.log("submit");
    // const signaturePad = this.sigPad.current;

    // if (!signaturePad) {
    //   return;
    // }

    // if (signaturePad.isEmpty()) {
    //   // eslint-disable-next-line no-alert
    //   alert("Please provide a signature first.");
    //   return false;
    // } else {
    //   console.log(signaturePad.toDataURL());
    //   this.setState({ sign: signaturePad.toDataURL() });
    //   // window.open(signaturePad.toDataURL());
    // }
    if (this.validator.allValid()) {
      /* console.log("hey", this.state);
      console.log(getUserData()); */
      let userData = getUserData();

      //console.log("selected projects", this.state.selectedProjects);
      //setting project array with project id

      if (!this.state.editItem) {
        let project = [];

        let data = {
          ponumber: this.state.fields.ponumber,
          date: this.state.fields.date,
          customer: this.state.fields.customer,
          project: this.state.fields.project,
          billinggst: this.state.fields.billinggst,
          shipname: this.state.fields.shipname,
          shipaddress: this.state.fields.shipaddress,
          shipgst: this.state.fields.shipgst,
          shipstate: this.state.fields.shipstate,
          shipstatecode: this.state.fields.shipstatecode,
          vendor: this.state.fields.vendor,
          particulars: JSON.stringify(this.state.inputFields),
          total:
            this.state.grandtotal.reduce((a, b) => a + b, 0) -
            this.state.totaltax.reduce((a, b) => a + b, 0),
          totaltax: this.state.totaltax.reduce((a, b) => a + b, 0),
          grandtotal: this.state.grandtotal.reduce((a, b) => a + b, 0),
          remarks: this.state.fields.remarks,
          terms: this.state.fields.terms,
          sign: this.state.sign,
        };

        this.setState({ isLoading: true });
        axios
          .post(apiCreatePo, data, {
            headers: {
              Authorization: "Bearer " + userData.token,
            },
          })
          .then(
            (response) => {
              //console.log(response);
              // this.setState({ isLoading: false });
              toast.info("Po created", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
              this.setState(this.initialState);
              this.handleCloseModal();
            },
            (error) => {
              if (
                error.response.status == 500 ||
                error.response.status == 400
              ) {
                toast.error(error.response.data.errorMessage, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                this.setState({ isLoading: false });
              }
            }
          );
      } else {
        let project = [];

        let data = {
          ponumber: this.state.fields.ponumber,
          date: this.state.fields.date,
          customer: this.state.fields.customer,
          project: this.state.fields.project,
          billinggst: this.state.fields.billinggst,
          shipname: this.state.fields.shipname,
          shipaddress: this.state.fields.shipaddress,
          shipgst: this.state.fields.shipgst,
          shipstate: this.state.fields.shipstate,
          shipstatecode: this.state.fields.shipstatecode,
          vendor: this.state.fields.vendor,
          particulars: JSON.stringify(this.state.inputFields),
          total:
            this.state.grandtotal.reduce((a, b) => a + b, 0) -
            this.state.totaltax.reduce((a, b) => a + b, 0),
          totaltax: this.state.totaltax.reduce((a, b) => a + b, 0),
          grandtotal: this.state.grandtotal.reduce((a, b) => a + b, 0),
          remarks: this.state.fields.remarks,
          terms: this.state.fields.terms,
          sign: this.state.sign,
          id: this.state.editId,
        };

        this.setState({ isLoading: true });
        axios
          .post(apiEditPo, data, {
            headers: {
              Authorization: "Bearer " + userData.token,
            },
          })
          .then(
            (response) => {
              toast.info("Po Updated", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
              this.setState(this.initialState);
              this.handleCloseModal();
            },
            (error) => {
              if (
                error.response.status == 500 ||
                error.response.status == 400
              ) {
                toast.error(error.response.data.errorMessage, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                this.setState({ isLoading: false });
              }
            }
          );
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  handleShippingCheckbox = (e) => {
    if (e.target.checked) {
      this.setState((prevState) => ({
        fields: {
          ...prevState.fields,
          shipname: this.state.selectedProject.name,
          shipaddress: this.state.selectedProject.address,
          shipgst: this.state.selectedProject.gst,
          shipstatecode: this.state.selectedProject.statecode,
        },
      }));
    }
  };

  handleSearch = (e) => {
    const value = e.target.value;
    this.setState((prevState) => ({
      searchFields: {
        ...prevState.searchFields,
        [e.target.name]: value,
      },
    }));
    console.log(this.state.searchFields);
  };

  handleSearchSelect = (e, value) => {
    this.setState((prevState) => ({
      searchFields: {
        ...prevState.searchFields,
        [value.name]: value.key,
      },
    }));
  };

  submitSearch = (e) => {
    e.preventDefault();
    let userData = getUserData();
    fetch(
      apiListPo +
        "?ponumber=" +
        this.state.searchFields.ponumber +
        "&project=" +
        this.state.searchFields.project +
        "&to=" +
        this.state.searchFields.to +
        "&from=" +
        this.state.searchFields.from +
        "&status=1",
      {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + userData.token,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          this.setState((prevState) => ({
            fields: {
              ...prevState.fields,
              ponumber: data.data.poNumber,
            },
            poList: data.data.po.rows,
          }));
        } else {
          alert("something went wrong.");
        }
      });
  };
  clearFilter = () => {
    this.myFormRef.reset();
    //this.setState(this.initialState, this.loadMasterData);
    this.setState(
      {
        searchFields: {
          ponumber: "",
          project: "",
          from: "",
          to: "",
        },
      },
      this.loadMaster
    );
  };
  render() {
    this.validator.purgeFields();

    return (
      <React.Fragment>
        <Helmet>
          <title>Purchase Order Tracking | MRC</title>
        </Helmet>
        <section className="admin">
          <AdminHeader></AdminHeader>
          <div className="wrapper d-flex">
            <AdminMenu></AdminMenu>

            <div className="wrapper_body">
              <div className="row d-flex align-items-center">
                <div className="col m0 d-flex align-items-center">
                  <h3 className="heading">Purchase Order Tracking</h3>
                </div>
              </div>
              <div className="row">
                <div className="col s12">
                  <p className="grey-text">
                    <b>Filters</b>
                  </p>
                  <form ref={(ele) => (this.myFormRef = ele)}>
                    <div className="row">
                      <div className="col m4 s12">
                        <input
                          type="text"
                          className=""
                          placeholder="Po Number"
                          name="ponumber"
                          onChange={this.handleSearch}
                        />
                      </div>

                      <div className="col m4 s12">
                        <Select
                          // styles={customStyles}
                          name="project"
                          placeholder="Select Project"
                          options={this.state.projectList.map((project) => ({
                            value: project.name,
                            text: project.name,
                            key: project.id,
                            name: "project",
                          }))}
                          onChange={this.handleSearchSelect}
                          id={"selectproject"}
                          showSearch={true}
                          // onSearch={(e) =>
                          //   this.handleSearchSelectInput(e)
                          // }
                          dropdownClassName={"force-zindex"}
                        />
                      </div>

                      <div className="col m4 s12">
                        <Select
                          // styles={customStyles}
                          name="vendor"
                          placeholder="Select Vendor"
                          options={this.state.vendorList.map((vendor) => ({
                            value: vendor.name,
                            text: vendor.name,
                            key: vendor.id,
                            name: "vendor",
                          }))}
                          onChange={this.handleSearch}
                          id={"selectvendor"}
                          showSearch={true}
                          // onSearch={(e) =>
                          //   this.handleSearchSelectInput(e)
                          // }
                          dropdownClassName={"force-zindex"}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col m4 s12">
                        <label className="label">To Date</label>
                        <input
                          type="date"
                          className=""
                          placeholder="From Date"
                          name="from"
                          onChange={this.handleSearch}
                        />
                      </div>

                      <div className="col m4 s12">
                        <label className="label">From Date</label>
                        <input
                          type="date"
                          className=""
                          placeholder="To Date"
                          name="to"
                          onChange={this.handleSearch}
                        />
                      </div>
                    </div>

                    <div className="row">
                      {/* <a href="#!" className="red-text pt-1 d-flex">Clear Filter</a> */}
                      {/* <button type="submit" className="red-text pt-1">Clear Filter</button> */}
                      <div class="col m3 s12">
                        <a
                          href="javascript:Void(0)"
                          class="red-text pt-1"
                          onClick={this.clearFilter}
                        >
                          Clear Filter
                        </a>

                        <button
                          type="submit"
                          className="theme_btn btn filter-submit"
                          onClick={this.submitSearch}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="projects employee">
                <div className="row">
                  <div className="col s12">
                    <div className="table-responsive">
                      <table>
                        <thead>
                          <tr>
                            <th>P.O</th>
                            <th>Status</th>
                            <th>Date</th>
                            <th>Project</th>
                            <th>Vendor Name</th>
                            <th>Address</th>
                            <th>Total Amount</th>
                            <th>Actions</th>
                            <th>Receiving</th>
                            <th>Payment</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.poList.map((po, index) => (
                            <>
                              <tr>
                                <td>{po.ponumber}</td>
                                <td>
                                  {po.status == "0" ? "Pending" : ""}
                                  {po.status == "1" ? "Active" : ""}
                                  {po.status == "2" ? "Completed" : ""}
                                  {po.status == "3" ? "Cancelled" : ""}
                                </td>
                                <td>{po.date}</td>
                                <td>{po.projectDetails.name}</td>
                                <td>{po.vendorDetails.name}</td>
                                <td>{po.vendorDetails.address}</td>
                                <td>
                                  <i class="fas fa-rupee-sign"></i> {po.total}
                                </td>
                                <td>
                                  <a
                                    href={
                                      process.env.REACT_APP_API_ENDPOINT +
                                      "public/po/generatePdf?preview=true&id=" +
                                      po.id
                                    }
                                    target="_blank"
                                  >
                                    <span class="material-icons">download</span>
                                  </a>
                                  <Link
                                    to={`poview/${po.id}`}
                                    className="actions"
                                  >
                                    <i className="material-icons">
                                      remove_red_eye
                                    </i>
                                  </Link>
                                  <a
                                    href="javascript:void(0)"
                                    className="actions"
                                    node="button"
                                    onClick={(e) => this.editPo(e, po)}
                                  >
                                    <i className="material-icons">edit</i>
                                  </a>
                                  <a
                                    href="javascript:void(0)"
                                    className="actions"
                                    node="button"
                                    onClick={(e) =>
                                      this.setState({ alert: true })
                                    }
                                  >
                                    <i className="fa fa-times  fa-2x"></i>
                                  </a>
                                </td>
                                <td>
                                  <Link
                                    to={`po-receiving/${po.id}`}
                                    className="actions btn btn-sm btn-primary"
                                    node="button"
                                  >
                                    {" "}
                                    Receiving
                                  </Link>
                                </td>
                                <td>
                                  <Link
                                    to={`po-payments/${po.id}`}
                                    className="actions btn btn-sm btn-primary"
                                    node="button"
                                  >
                                    {" "}
                                    Payments
                                  </Link>
                                </td>
                              </tr>
                            </>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div
                  className="col s12"
                  style={{ marginTop: 20, marginBottom: 20 }}
                >
                  <Pagination
                    pageSize={this.state.pageSize}
                    onChange={this.onChangePagination}
                    current={this.state.current}
                    total={this.state.poCount}
                    showSizeChanger
                    defaultPageSize={this.state.pageSize}
                    defaultCurrent={this.state.current}
                    onShowSizeChange={this.onChangePageSize}
                    locale={localeInfo}
                    selectComponentClass={Select}
                    pageSizeOptions={[10, 30, 50, 100]}
                    showTotal={(total, range) =>
                      `${range[0]} - ${range[1]} of ${total} items`
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          {this.state.alert && (
            <SweetAlert
              // onConfirm={this.changeStatus}
              onCancel={this.hideAlert}
              focusCancelBtn={true}
              warning
              showCancel
              confirmBtnText="Yes"
              confirmBtnBsStyle="danger"
              title="Are you sure?"
            />
          )}
          <Footer />
        </section>
      </React.Fragment>
    );
  }
}
