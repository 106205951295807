import React, { Component } from "react";
import Footer from "../../components/Footer";
import AdminHeader from "../../components/header/AdminHeader";
import AdminMenu from "../../components/sidebar/AdminMenu";
import ButtonLoader from "../../components/ButtonLoader";
import axios from "axios";
import { toast } from "react-toastify";
import "materialize-css";
import { Modal } from "react-materialize";
import { getUserData } from "../../helpers/storage";
import SweetAlert from "react-bootstrap-sweetalert";
import { BulletList } from "react-content-loader";

import {
  apiCreateMaster,
  apiGetMaster,
  apiListMasters,
  apiDeleteMaster,
  apiEditMaster,
  apiGetMasterByType,
  apiCreateItemMaster,
  apiGetItemMasterList,
  apiGetBankMasterList,
  apiGetBankMaster,
  apiCreateBankMaster,
  apiDeleteBankMasterList,
  apiEditBankMaster,
} from "../../config/endpoints";
import { Helmet } from "react-helmet";
import SimpleReactValidator from "simple-react-validator";

const initialState = {
  fields: {
    name: "",
    bankname: "",
    accno: "",
    ifsc: "",
    branch: "",
  },
  isLoading: false,
  redirect: false,
  errors: {},
  bankMasterList: [],
  unitMasterList: [],
  categoryMasterList: [],
  isModalOpen: false,
  isListLoading: false,
  alert: false,
  deleteId: "",
  editId: false,
  editItem: false,
};
export default class BankMaster extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
  }

  loadMasters = (e) => {
    let userData = getUserData();
    fetch(apiGetBankMasterList, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          this.setState({
            bankMasterList: data.data.masters.rows,
          });
        } else {
          alert("something went wrong.");
        }
      });
  };
  editMaster(id) {
    // Assuming only image
    this.setState({ editItem: true, editId: id });
    this.handleOpenModal();
    let userData = getUserData();
    fetch(apiGetBankMaster + "?id=" + id, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          // this.setState({
          //   projectData: data.data.projects.rows,
          // });
          // console.log(this.state.projectList);
          this.setState({
            fields: data.data.master,
          });
        } else {
          alert("something went wrong.");
        }
        // setTimeout(function () {
      });
    // Updating the city
  }
  handleCloseModal = () => {
    this.setState(initialState);
    this.loadMasters();
    this.validator.hideMessages();
  };
  handleOpenModal = () => {
    this.setState({ isModalOpen: true });
    this.validator.hideMessages();
  };
  showAlert = (id) => {
    //console.log(id);
    this.setState({
      alert: true,
      deleteId: id,
    });
  };
  hideAlert = () => {
    this.setState({
      alert: false,
    });
  };
  deleteMaster = (e) => {
    //console.log("delete", this.state);
    this.setState({ alert: false });
    let userData = getUserData();
    axios
      .post(
        apiDeleteBankMasterList,
        { id: this.state.deleteId },
        {
          headers: {
            Authorization: "Bearer " + userData.token,
          },
        }
      )
      .then(
        (response) => {
          //console.log(response);
          this.setState({ isLoading: false });
          toast.info("Master deleted", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          this.setState(this.initialState);
          this.loadMasters();
        },
        (error) => {
          if (error.response.status == 500 || error.response.status == 400) {
            toast.error(error.response.data.errorMessage, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            this.setState({ isLoading: false });
          }
        }
      );
  };
  handleChange = (e) => {
    this.validator.showMessageFor(e.target.name);
    const value = e.target.value;
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        [e.target.name]: value,
      },
    }));
  };
  handleBlur = (e) => {
    this.validator.showMessageFor(e.target.name);
  };

  submitForm = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      /* console.log("submit");
      console.log(this.state);
      console.log(getUserData()); */
      let userData = getUserData();
      if (!this.state.editItem) {
        // let data = {
        //   itemcode: this.state.fields.itemcode,
        //   type: this.state.fields.type,
        // };
        this.setState({ isLoading: true });
        axios
          .post(apiCreateBankMaster, this.state.fields, {
            headers: {
              Authorization: "Bearer " + userData.token,
            },
          })
          .then(
            (response) => {
              //console.log(response);
              this.setState({ isLoading: false });
              toast.info("Bank Master created", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
              this.setState(this.initialState);
              this.handleCloseModal();
            },
            (error) => {
              if (
                error.response.status == 500 ||
                error.response.status == 400
              ) {
                toast.error(error.response.data.errorMessage, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                this.setState({ isLoading: false });
              }
            }
          );
      } else {
        let data = {
          name: this.state.fields.name,
          bankname: this.state.fields.bankname,
          accno: this.state.fields.accno,
          ifsc: this.state.fields.ifsc,
          branch: this.state.fields.branch,
          id: this.state.editId,
        };
        this.setState({ isLoading: true });
        axios
          .post(apiEditBankMaster, data, {
            headers: {
              Authorization: "Bearer " + userData.token,
            },
          })
          .then(
            (response) => {
              //console.log(response);
              this.setState({ isLoading: false });
              toast.info("Bank Master updated", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
              this.setState(this.initialState);
              this.handleCloseModal();
            },
            (error) => {
              if (
                error.response.status == 500 ||
                error.response.status == 400
              ) {
                toast.error(error.response.data.errorMessage, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                this.setState({ isLoading: false });
              }
            }
          );
      }
    } else {
      /* console.log("fail");
      console.log("errors", this.validator.getErrorMessages()); */
      this.validator.showMessages();
      this.forceUpdate();
    }
  };
  componentWillMount() {
    this.loadMasters();
  }
  render() {
    this.validator.purgeFields();
    return (
      <React.Fragment>
        <Helmet>
          <title>Bank Master | MRC</title>
        </Helmet>
        <section className="admin">
          <AdminHeader />
          <div className="wrapper d-flex">
            <AdminMenu />

            <div className="wrapper_body">
              <div className="row d-flex align-items-center">
                <div className="col m0 d-flex align-items-center">
                  <h3 className="heading">Bank Master</h3>
                </div>
                <div className="col m4 right-align">
                  <a
                    href="javascript:void(0)"
                    className="theme_btn btn waves-effect modal-trigger"
                    onClick={this.handleOpenModal}
                  >
                    <i className="material-icons">add</i> New Bank Master
                  </a>
                  <Modal
                    actions={[,]}
                    bottomSheet={false}
                    fixedFooter={false}
                    id="Modal-0"
                    open={this.state.isModalOpen}
                    className="cstm-modal"
                    options={{
                      dismissible: false,
                      endingTop: "10%",
                      inDuration: 250,
                      onCloseEnd: null,
                      onCloseStart: null,
                      onOpenEnd: null,
                      onOpenStart: null,
                      opacity: 0.5,
                      outDuration: 250,
                      preventScrolling: true,
                      startingTop: "4%",
                    }}
                  >
                    {/* Close Modal */}
                    <a
                      href="javascript:void(0)"
                      className="modal-close"
                      onClick={this.handleCloseModal}
                    >
                      <span className="material-icons">chevron_right</span>
                    </a>
                    {/* Modal Content */}
                    <h3 className="heading">
                      {this.state.editItem ? "Update " : "New "} Bank Master
                    </h3>
                    <p>
                      Enter details to {this.state.editItem ? "update" : "new"}{" "}
                      bank master
                    </p>
                    <form action id="createEmployeeForm">
                      <div className="row">
                        <fieldset>
                          <legend>Name</legend>
                          <div className="col s12">
                            <input
                              type="text"
                              placeholder="Name"
                              name="name"
                              value={this.state.fields.name}
                              onChange={this.handleChange}
                              onBlur={this.handleBlur}
                            />
                            <label className="error">
                              {this.validator.message(
                                "name",
                                this.state.fields.name,
                                "required"
                              )}
                            </label>
                          </div>
                          <legend>Bank Name</legend>
                          <div className="col s12">
                            <input
                              type="text"
                              placeholder="Bank Name"
                              name="bankname"
                              value={this.state.fields.bankname}
                              onChange={this.handleChange}
                              onBlur={this.handleBlur}
                            />
                            <label className="error">
                              {this.validator.message(
                                "bankname",
                                this.state.fields.bankname,
                                "required"
                              )}
                            </label>
                          </div>
                          <legend>Account Number</legend>
                          <div className="col s12">
                            <input
                              type="text"
                              placeholder="Account Nnumber"
                              name="accno"
                              value={this.state.fields.accno}
                              onChange={this.handleChange}
                              onBlur={this.handleBlur}
                            />
                            <label className="error">
                              {this.validator.message(
                                "accno",
                                this.state.fields.accno,
                                "required"
                              )}
                            </label>
                          </div>
                          <legend>IFSC</legend>
                          <div className="col s12">
                            <input
                              type="text"
                              placeholder="IFSC"
                              name="ifsc"
                              value={this.state.fields.ifsc}
                              onChange={this.handleChange}
                              onBlur={this.handleBlur}
                            />
                            <label className="error">
                              {this.validator.message(
                                "ifsc",
                                this.state.fields.ifsc,
                                "required"
                              )}
                            </label>
                          </div>
                          <legend>Branch</legend>
                          <div className="col s12">
                            <input
                              type="text"
                              placeholder="IFSC"
                              name="branch"
                              value={this.state.fields.branch}
                              onChange={this.handleChange}
                              onBlur={this.handleBlur}
                            />
                            <label className="error">
                              {this.validator.message(
                                "branch",
                                this.state.fields.branch,
                                "required"
                              )}
                            </label>
                          </div>
                        </fieldset>

                        <div className="row">
                          <div className="col s12 right-align">
                            <button
                              type="submit"
                              form="createEmployeeForm"
                              className="theme_btn btn"
                              onClick={this.submitForm}
                            >
                              {this.state.editItem
                                ? "Update Item Master"
                                : "Add Item Master"}
                              {this.state.isLoading ? (
                                <ButtonLoader></ButtonLoader>
                              ) : null}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </Modal>
                </div>
              </div>
              <div className="projects employee">
                <div className="row">
                  <div className="col s12">
                    <div className="table-responsive">
                      <table>
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Bank Name</th>
                            <th>Account Number</th>
                            <th>IFSC</th>
                            <th>Branch</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.isListLoading ? (
                            <tr>
                              <td colSpan={5}>
                                <BulletList></BulletList>
                              </td>
                            </tr>
                          ) : (
                            this.state.bankMasterList.map((master, index) => (
                              <>
                                <tr key={index}>
                                  <td data-th="Name">{master.name}</td>
                                  <td data-th="BankName">{master.bankname}</td>
                                  <td data-th="Account">{master.accno}</td>
                                  <td data-th="IFSC">{master.ifsc}</td>
                                  <td data-th="Branch">{master.branch}</td>
                                  <td>
                                    <a
                                      href="#Modal-0"
                                      className="actions"
                                      node="button"
                                      onClick={() => this.editMaster(master.id)}
                                    >
                                      <i className="material-icons">edit</i>
                                    </a>
                                    <a
                                      href="javascript:void(0)"
                                      className="actions"
                                      onClick={() => this.showAlert(master.id)}
                                    >
                                      <i className="material-icons">delete</i>
                                    </a>
                                  </td>
                                </tr>
                              </>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.state.alert && (
            <SweetAlert
              onConfirm={this.deleteMaster}
              onCancel={this.hideAlert}
              focusCancelBtn={true}
              warning
              showCancel
              confirmBtnText="Yes, delete it!"
              confirmBtnBsStyle="danger"
              title="Are you sure?"
            />
          )}
          <Footer />
        </section>
      </React.Fragment>
    );
  }
}
