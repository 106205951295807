import React, { Component } from "react";
import RichTextEditor from "react-rte";
import PropTypes from "prop-types";
import Footer from "../../components/Footer";
import AdminHeader from "../../components/header/AdminHeader";
import AdminMenu from "../../components/sidebar/AdminMenu";
import ButtonLoader from "../../components/ButtonLoader";
import axios from "axios";
import { toast } from "react-toastify";
import "materialize-css";
import { Modal } from "react-materialize";
import { getUserData } from "../../helpers/storage";
import SweetAlert from "react-bootstrap-sweetalert";
import { BulletList } from "react-content-loader";

import {
  apiCreateMaster,
  apiGetMaster,
  apiListMasters,
  apiDeleteMaster,
  apiEditMaster,
  apiGetMasterByType,
  apiCreateItemMaster,
  apiGetItemMasterList,
  apiAllSettings,
  apiGetSettings,
  apiEditSettings,
} from "../../config/endpoints";
import { Helmet } from "react-helmet";
import SimpleReactValidator from "simple-react-validator";

const initialState = {
  fields: {
    value: "",
  },
  isLoading: false,
  redirect: false,
  errors: {},
  itemMasterList: [],
  unitMasterList: [],
  categoryMasterList: [],
  settingsList: [],
  isModalOpen: false,
  isListLoading: false,
  alert: false,
  deleteId: "",
  editId: false,
  editItem: false,
  editType: false,
  editKey: false,
  editFile: false,
  editor: RichTextEditor.createEmptyValue(),
};
export default class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    // this.state.editor = RichTextEditor.createEmptyValue();
  }

  static propTypes = {
    onChange: PropTypes.func,
  };

  onChangeEditor = (value) => {
    this.setState({ editor: value });
    if (this.props.onChange) {
      // Send the changes up to the parent component as an HTML string.
      // This is here to demonstrate using `.toString()` but in a real app it
      // would be better to avoid generating a string on each change.
      this.props.onChange(value.toString("html"));
    }
  };

  loadMasters = (e) => {
    let userData = getUserData();
    fetch(apiAllSettings + "?key=po_terms", {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          this.setState({
            settingsList: data.data.settings.rows,
          });
        } else {
          alert("something went wrong.");
        }
      });
  };

  editMaster(id, key, type) {
    // Assuming only image
    this.setState({ editItem: true, editId: id, editType: type, editKey: key });
    this.handleOpenModal();
    let userData = getUserData();
    fetch(apiGetSettings + "?key=" + key, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          this.setState({
            fields: data.data.setting,
          });
          if (data.data.setting.type === "textarea") {
            this.setState({
              editor: RichTextEditor.createValueFromString(
                data.data.setting.value,
                "html"
              ),
            });
          }
        } else {
          alert("something went wrong.");
        }
      });
    // Updating the city
  }

  handleCloseModal = () => {
    this.setState(initialState);
    this.loadMasters();
    this.validator.hideMessages();
  };

  handleOpenModal = () => {
    this.setState({ isModalOpen: true });
    this.validator.hideMessages();
  };
  showAlert = (id) => {
    this.setState({
      alert: true,
      deleteId: id,
    });
  };

  hideAlert = () => {
    this.setState({
      alert: false,
    });
  };

  deleteMaster = (e) => {
    //console.log("delete", this.state);
    this.setState({ alert: false });
    let userData = getUserData();
    axios
      .post(
        apiDeleteMaster,
        { id: this.state.deleteId },
        {
          headers: {
            Authorization: "Bearer " + userData.token,
          },
        }
      )
      .then(
        (response) => {
          //console.log(response);
          this.setState({ isLoading: false });
          toast.info("Master deleted", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          this.setState(this.initialState);
          this.loadMasters();
        },
        (error) => {
          if (error.response.status == 500 || error.response.status == 400) {
            toast.error(error.response.data.errorMessage, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            this.setState({ isLoading: false });
          }
        }
      );
  };

  handleChange = (e) => {
    this.validator.showMessageFor(e.target.name);
    const value = e.target.value;
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        [e.target.name]: value,
      },
    }));
  };

  handleBlur = (e) => {
    this.validator.showMessageFor(e.target.name);
  };

  submitForm = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      /* console.log("submit");
      console.log(this.state);
      console.log(getUserData()); */
      let userData = getUserData();

      // let data = {
      //   name: this.state.fields.name,
      //   value: this.state.fields.value,
      //   type: this.state.editType,
      //   key: this.state.editKey,
      //   id: this.state.editId,
      // };
      var data = new FormData();
      data.append("name", this.state.fields.name);
      if (this.state.editType === "textarea") {
        data.append("value", this.state.editor.toString("html"));
      } else {
        data.append("value", this.state.fields.value);
      }
      data.append("type", this.state.editType);
      data.append("key", this.state.editKey);
      data.append("id", this.state.editId);

      if (this.state.editType == "file") {
        data.append("file", this.state.editFile);
      }

      this.setState({ isLoading: true });
      axios
        .post(apiEditSettings, data, {
          headers: {
            Authorization: "Bearer " + userData.token,
          },
        })
        .then(
          (response) => {
            //console.log(response);
            this.setState({ isLoading: false });
            toast.info("Settings updated", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
            this.setState(this.initialState);
            this.handleCloseModal();
          },
          (error) => {
            if (error.response.status == 500 || error.response.status == 400) {
              toast.error(error.response.data.errorMessage, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              this.setState({ isLoading: false });
            }
          }
        );
    } else {
      /* console.log("fail");
      console.log("errors", this.validator.getErrorMessages()); */
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  fileChange = (e) => {
    console.log(e.target.name, e.target.files[0].name);
    if (e.target.files[0]) {
      console.log(e.target.files[0]);
      this.setState({ editFile: e.target.files[0] });
    }
  };

  handleChange = (e) => {
    this.validator.showMessageFor(e.target.name);
    const value = e.target.value;
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        [e.target.name]: value,
      },
    }));
    console.log(this.state.fields);
  };

  componentWillMount() {
    this.loadMasters();
  }

  render() {
    this.validator.purgeFields();
    return (
      <React.Fragment>
        <Helmet>
          <title>Settings | MRC</title>
        </Helmet>
        <section className="admin">
          <AdminHeader />
          <div className="wrapper d-flex">
            <AdminMenu />

            <div className="wrapper_body">
              <div className="row d-flex align-items-center">
                <div className="col m0 d-flex align-items-center">
                  <h3 className="heading">Settings</h3>
                </div>
                <Modal
                  actions={[,]}
                  bottomSheet={false}
                  fixedFooter={false}
                  id="Modal-0"
                  open={this.state.isModalOpen}
                  className="cstm-modal"
                  options={{
                    dismissible: false,
                    endingTop: "10%",
                    inDuration: 250,
                    onCloseEnd: null,
                    onCloseStart: null,
                    onOpenEnd: null,
                    onOpenStart: null,
                    opacity: 0.5,
                    outDuration: 250,
                    preventScrolling: true,
                    startingTop: "4%",
                  }}
                >
                  {/* Close Modal */}
                  <a
                    href="javascript:void(0)"
                    className="modal-close"
                    onClick={this.handleCloseModal}
                  >
                    <span className="material-icons">chevron_right</span>
                  </a>
                  {/* Modal Content */}
                  <h3 className="heading">
                    {this.state.editItem ? "Update " : "New "} Settings{" "}
                  </h3>

                  <form action id="createEmployeeForm">
                    <div className="row">
                      <fieldset>
                        <legend>Value</legend>
                        <div className="col s12">
                          {this.state.editType == "textarea" ? (
                            <>
                              {/* <textarea
                                value={this.state.fields.value}
                                name="value"
                                onChange={(e) => this.handleChange(e)}
                              >
                                {this.state.fields.value}
                              </textarea> */}
                              <RichTextEditor
                                className="rte"
                                value={this.state.editor}
                                onChange={this.onChangeEditor}
                              />
                            </>
                          ) : (
                            <></>
                          )}
                          {this.state.editType == "file" ? (
                            <>
                              <label className="new-button" htmlFor="upload">
                                Upload File
                                <input
                                  id="upload"
                                  type="file"
                                  name="file"
                                  onChange={this.fileChange}
                                />
                              </label>
                              {this.state.editFile ? (
                                <>
                                  <span>
                                    {this.state.editFile.name}
                                    <i class="tiny material-icons">
                                      attach_file
                                    </i>
                                  </span>
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </fieldset>

                      <div className="row">
                        <div className="col s12 right-align">
                          <button
                            type="submit"
                            form="createEmployeeForm"
                            className="theme_btn btn"
                            onClick={this.submitForm}
                          >
                            {this.state.editItem
                              ? "Update Setting"
                              : "Add Setting"}
                            {this.state.isLoading ? (
                              <ButtonLoader></ButtonLoader>
                            ) : null}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </Modal>
              </div>
              <div className="projects employee">
                <div className="row">
                  <div className="col s12">
                    <div className="table-responsive">
                      <table>
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Value</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.isListLoading ? (
                            <tr>
                              <td colSpan={5}>
                                <BulletList></BulletList>
                              </td>
                            </tr>
                          ) : (
                            this.state.settingsList.map((settings, index) => (
                              <>
                                <tr key={index}>
                                  <td data-th="Name">{settings.name}</td>
                                  <td data-th="Itemcode">
                                    {settings.type === "textarea"
                                      ? settings.value.length > 40
                                        ? settings.value.substring(0, 40 - 3) +
                                          "..."
                                        : settings.value
                                      : ""}
                                    {settings.type === "file" ? (
                                      <>
                                        <a
                                          href={settings.value}
                                          target="_blank"
                                        >
                                          View
                                        </a>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                  <td>
                                    <a
                                      href="#Modal-0"
                                      className="actions"
                                      node="button"
                                      onClick={() =>
                                        this.editMaster(
                                          settings.id,
                                          settings.key,
                                          settings.type
                                        )
                                      }
                                    >
                                      <i className="material-icons">edit</i>
                                    </a>
                                  </td>
                                </tr>
                              </>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.state.alert && (
            <SweetAlert
              onConfirm={this.deleteMaster}
              onCancel={this.hideAlert}
              focusCancelBtn={true}
              warning
              showCancel
              confirmBtnText="Yes, delete it!"
              confirmBtnBsStyle="danger"
              title="Are you sure?"
            />
          )}
          <Footer />
        </section>
      </React.Fragment>
    );
  }
}
