import React, { Component } from "react";
import "materialize-css";
import AdminLinks from "./AdminLinks";

export default class AdminMenu extends Component {
  render() {
    return (
      <>
        <aside className='aside_navbar'>
          <AdminLinks />
        </aside>
        <aside id='slide-out' className='sidenav'>
          <AdminLinks />
        </aside>
      </>
    );
  }
}
