import React, { Component } from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";
import SideBar from "../SideBar";
import SuperAdminHeader from "../../components/header/SuperAdminHeader";

import ButtonLoader from "../../components/ButtonLoader";
import axios from "axios";
import { toast } from "react-toastify";
import "materialize-css";
import { Modal, Dropdown, Button } from "react-materialize";
import { getUserData } from "../../helpers/storage";
import {
  apiCreateCompany,
  apiListCompanies,
  apiGetCompany,
  apiDeleteCompany,
  apiEditCompany,
} from "../../config/endpoints";
import { Helmet } from "react-helmet";
import ReactFormInputValidation from "react-form-input-validation";
import Header from "../Header";
import SweetAlert from "react-bootstrap-sweetalert";
import { ImagePicker } from "react-file-picker";
import { BulletList } from "react-content-loader";
import AutoComplete from "react-google-autocomplete";
import SimpleReactValidator from "simple-react-validator";

const initialState = {
  /* etc */
  fields: {
    name: "",
    ownerName: "",
    address: "",
    website: "",
    email: "",
    password: "",
    confirmPassword: "",
  },
  logo: "",
  isLoading: false,
  redirect: false,
  errors: {},
  companyList: [],
  alert: false,
  editItem: false,
  isModalOpen: false,
  isListLoading: false,
  deleteId: false,
  editId: false,
  logoImage: false,
};

export default class Companies extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = initialState;
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.logoChange = this.logoChange.bind(this);
    this.editCompany = this.editCompany.bind(this);
    this.deleteCompany = this.deleteCompany.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
  }

  showAlert = (id) => {
    console.log(id);
    this.setState({
      alert: true,
      deleteId: id,
    });
  };

  editCompany(id) {
    // Assuming only image
    this.setState({ editItem: true, editId: id });
    let userData = getUserData();
    this.handleOpenModal();
    fetch(apiGetCompany + "?id=" + id, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data.data.company);
        if (data.code == 200) {
          this.setState({
            fields: data.data.company,
            logoImage: data.data.company.logo,
          });
          this.setState((prevState) => ({
            fields: {
              // object that we want to update
              ...prevState.fields, // keep all other key-value pairs
              email: data.data.company.user.email, // update the value of specific key
            },
          }));
        } else {
          alert("something went wrong.");
        }
        // setTimeout(function () {
      });
    // Updating the city
  }
  componentWillMount() {
    this.loadCompanies();
  }
  loadCompanies = (e) => {
    this.setState({ isListLoading: true });
    let userData = getUserData();
    fetch(apiListCompanies, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data.data.companies.rows);
        if (data.code == 200) {
          this.setState({
            companyList: data.data.companies.rows,
          });
          // console.log(this.state.companyList);
          this.setState({ isListLoading: false });
        } else {
          alert("something went wrong.");
        }
        // setTimeout(function () {
      });
  };
  logoChange = (e) => {
    // Assuming only image
    if (e.target.files[0]) {
      console.log("picture: ", e.target.files);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        // console.log(reader.result);
        this.setState({ logoImage: reader.result });
      });
      reader.readAsDataURL(e.target.files[0]);
      this.setState({ logo: e.target.files[0] });
    }
    // Updating the city
  };
  deleteCompany = (e) => {
    console.log("delete", this.state);
    this.setState({ alert: false });
    let userData = getUserData();
    axios
      .post(
        apiDeleteCompany,
        { id: this.state.deleteId },
        {
          headers: {
            Authorization: "Bearer " + userData.token,
          },
        }
      )
      .then(
        (response) => {
          console.log(response);
          this.setState({ isLoading: false });
          toast.info("Company deleted", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          this.setState(this.initialState);
          this.loadCompanies();
        },
        (error) => {
          if (error.response.status == 500 || error.response.status == 400) {
            toast.error(error.response.data.errorMessage, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            this.setState({ isLoading: false });
          }
        }
      );
  };

  setLocation = (e) => {
    this.setState((prevState) => ({
      fields: {
        // object that we want to update
        ...prevState.fields, // keep all other key-value pairs
        address: e, // update the value of specific key
      },
    }));
    console.log(this.state);
  };

  hideAlert = () => {
    this.setState({
      alert: false,
    });
  };
  handleCloseModal = () => {
    console.log("i ma in close", this.state);
    this.setState(initialState);
    this.loadCompanies();
    this.validator.hideMessages();
  };
  handleOpenModal = () => {
    this.setState({ isModalOpen: true });
    this.validator.hideMessages();
  };
  handleChange = (e) => {
    this.validator.showMessageFor(e.target.name);
    const value = e.target.value;
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        [e.target.name]: value,
      },
    }));
  };
  handleBlur = (e) => {
    this.validator.showMessageFor(e.target.name);
  };

  submitForm = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      console.log("submit");
      console.log(this.state);
      console.log(getUserData());
      let userData = getUserData();
      if (!this.state.editItem) {
        var bodyFormData = new FormData();
        bodyFormData.append("name", this.state.fields.name);
        bodyFormData.append("ownerName", this.state.fields.ownerName);
        bodyFormData.append("address", this.state.fields.address);
        bodyFormData.append("website", this.state.fields.website);
        bodyFormData.append("password", this.state.fields.password);
        bodyFormData.append("email", this.state.fields.email);
        bodyFormData.append("logo", this.state.logo);

        this.setState({ isLoading: true });
        axios
          .post(apiCreateCompany, bodyFormData, {
            headers: {
              Authorization: "Bearer " + userData.token,
            },
          })
          .then(
            (response) => {
              console.log(response);
              this.setState({ isLoading: false });
              toast.info("Company created", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
              this.setState(this.initialState);
              this.handleCloseModal();
            },
            (error) => {
              if (
                error.response.status == 500 ||
                error.response.status == 400
              ) {
                toast.error(error.response.data.errorMessage, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                this.setState({ isLoading: false });
              }
            }
          );
      } else {
        //edit app
        console.log(this.state);
        var bodyFormData = new FormData();
        bodyFormData.append("name", this.state.fields.name);
        bodyFormData.append("ownerName", this.state.fields.ownerName);
        bodyFormData.append("address", this.state.fields.address);
        bodyFormData.append("website", this.state.fields.website);
        bodyFormData.append("password", this.state.fields.password);
        bodyFormData.append("email", this.state.fields.email);
        bodyFormData.append("id", this.state.editId);
        bodyFormData.append("logo", this.state.logo);

        this.setState({ isLoading: true });
        axios
          .post(apiEditCompany, bodyFormData, {
            headers: {
              Authorization: "Bearer " + userData.token,
            },
          })
          .then(
            (response) => {
              this.setState({ isLoading: false });
              toast.info("Company Updated", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
              this.setState(this.initialState);
              this.handleCloseModal();
            },
            (error) => {
              if (
                error.response.status == 500 ||
                error.response.status == 400
              ) {
                toast.error(error.response.data.errorMessage, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                this.setState({ isLoading: false });
              }
            }
          );
      }
    } else {
      console.log("fail");
      console.log("errors", this.validator.getErrorMessages());
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  render() {
    this.validator.purgeFields();
    return (
      <React.Fragment>
        <Helmet>
          <title>Companies | MRC</title>
        </Helmet>
        <section className='super-admin'>
          <SuperAdminHeader></SuperAdminHeader>

          <div className='wrapper'>
            <div className='row d-flex align-items-center'>
              <div className='col m0 d-flex align-items-center'>
                <Link className='d-flex' to='/admin'>
                  <img
                    src='assets/images/super-admin/back-ico.svg'
                    width={30}
                  />
                </Link>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <h3 className='heading mb-0'>Company Listing</h3>
              </div>
              <div className='col m4 right-align'>
                <Link to='companiesarchived'>View archived</Link>
              </div>

              <a
                href='javascript:void(0)'
                className='theme_btn btn waves-effect modal-trigger'
                onClick={this.handleOpenModal}
              >
                <i className='material-icons'>add</i> Create New Company
              </a>
              <Modal
                actions={[,]}
                bottomSheet={false}
                fixedFooter={false}
                id='Modal-0'
                open={this.state.isModalOpen}
                className='cstm-modal'
                options={{
                  dismissible: false,
                  endingTop: "10%",
                  inDuration: 250,
                  onCloseEnd: null,
                  onCloseStart: null,
                  onOpenEnd: null,
                  onOpenStart: null,
                  opacity: 0.5,
                  outDuration: 250,
                  preventScrolling: true,
                  startingTop: "4%",
                }}
              >
                {/* Close Modal */}
                <a
                  href='javascript:void(0)'
                  className='modal-close'
                  onClick={this.handleCloseModal}
                >
                  <span className='material-icons'>chevron_right</span>
                </a>
                {/* Modal Content */}
                <div className='row'>
                  <div className='col s12'>
                    <h3 className='heading'>
                      {this.state.editItem
                        ? "Edit Company"
                        : "Create New Company"}
                    </h3>
                    <p>
                      Enter details to{" "}
                      {this.state.editItem ? "edit " : "create"} new list
                    </p>
                  </div>
                </div>
                <form action id='createCompanyForm'>
                  <div className='button-wrap col  s12'>
                    <label className='new-button' for='upload'>
                      {" "}
                      Upload Image
                      <input
                        id='upload'
                        type='file'
                        name='logo'
                        onChange={this.logoChange}
                      />
                    </label>
                    {this.state.editItem ? null : (
                      <label className='error'>
                        {this.validator.message(
                          "logo",
                          this.state.logo,
                          "required"
                        )}
                      </label>
                    )}
                    {this.state.logoImage ? (
                      <img
                        className='logopreview'
                        src={this.state.logoImage}
                      ></img>
                    ) : null}
                  </div>
                  <div className='col s12 '>
                    <label className='label'>Company name</label>
                    <input
                      type='text'
                      placeholder='Company name'
                      name='name'
                      value={this.state.fields.name}
                      onChange={this.handleChange}
                      onBlur={this.handleBlur}
                    />
                    <label className='error'>
                      {this.validator.message(
                        "name",
                        this.state.fields.name,
                        "required"
                      )}
                    </label>
                    <label className='label'>Owner name</label>
                    <input
                      type='text'
                      placeholder='Owner name'
                      name='ownerName'
                      value={this.state.fields.ownerName}
                      onChange={this.handleChange}
                      onBlur={this.handleBlur}
                    />
                    <label className='error'>
                      {this.validator.message(
                        "ownerName",
                        this.state.fields.ownerName,
                        "required"
                      )}
                    </label>
                  </div>

                  <div className='col s12'>
                    <label className='label'>Company address</label>
                    <AutoComplete
                      apiKey={process.env.REACT_APP_GOOGLE_KEY}
                      onPlaceSelected={(place) => {
                        console.log(place.formatted_address);
                        this.setLocation(place.formatted_address);
                      }}
                      defaultValue={this.state.fields.address}
                      name='address'
                      onChange={this.handleChange}
                      onBlur={this.handleBlur}
                    />

                    {/* <input
                              type="text"
                              placeholder=""
                              name="address"
                              onBlur={this.form.handleBlurEvent}
                              onChange={this.form.handleChangeEvent}
                              value={this.state.fields.address}
                            /> */}
                    <label className='error'>
                      {this.validator.message(
                        "address",
                        this.state.fields.address,
                        "required"
                      )}
                    </label>
                  </div>
                  <div className='col m6 s12'>
                    <label className='label'>Website</label>
                    <input
                      type='text'
                      placeholder='Website'
                      name='website'
                      value={this.state.fields.website}
                      onChange={this.handleChange}
                      onBlur={this.handleBlur}
                    />
                    <label className='error'>
                      {this.validator.message(
                        "website",
                        this.state.fields.website,
                        "required|url"
                      )}
                    </label>
                  </div>
                  <div className='col m6 s12'>
                    <label className='label'>Email ID</label>
                    <input
                      type='text'
                      placeholder='Email ID'
                      name='email'
                      value={this.state.fields.email}
                      onChange={this.handleChange}
                      onBlur={this.handleBlur}
                    />
                    <label className='error'>
                      {this.validator.message(
                        "email",
                        this.state.fields.email,
                        "required|email"
                      )}
                    </label>
                  </div>
                  {this.state.editItem ? null : (
                    <>
                      <div className='row'>
                        <div className='col m6 s12'>
                          <label className='label'>Password</label>
                          <input
                            type='password'
                            placeholder='*******'
                            name='password'
                            value={this.state.fields.password}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                          />

                          <label className='error'>
                            {this.validator.message(
                              "password",
                              this.state.fields.email,
                              "required"
                            )}
                          </label>
                        </div>
                        <div className='col m6 s12'>
                          <label className='label'>Confirm Password</label>
                          <input
                            type='password'
                            placeholder='********'
                            name='confirmPassword'
                            value={this.state.fields.confirmPassword}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                          />
                          <label className='error'>
                            {this.validator.message(
                              "confirmPassword",
                              this.state.fields.email,
                              "required"
                            )}
                          </label>
                        </div>
                      </div>
                    </>
                  )}

                  <div className='row'>
                    <div className='col s12 right-align'>
                      <button
                        type='submit'
                        form='createCompanyForm'
                        className='theme_btn btn '
                        onClick={this.submitForm}
                      >
                        {this.state.editItem ? "Update Company" : "Add company"}
                        {this.state.isLoading ? (
                          <ButtonLoader></ButtonLoader>
                        ) : null}
                      </button>
                    </div>
                  </div>
                </form>
              </Modal>
            </div>
            <div className='company-listing'>
              <div className='row'>
                {this.state.isListLoading ? (
                  <BulletList></BulletList>
                ) : (
                  this.state.companyList.map((company, index) => (
                    <div className='col m4 s12' key={index}>
                      <div className='outer d-flex'>
                        <div className='image'>
                          {company.logo != "" ? (
                            <img src={company.logo} alt='Project Thumbnail' />
                          ) : (
                            <img
                              src='assets/images/admin/project-photo.png'
                              alt='Project Thumbnail'
                            />
                          )}
                        </div>
                        <div>
                          <div className='action_toggle'>
                            <Dropdown
                              id={"editmenu_" + index}
                              className='dropdown-content'
                              options={{
                                alignment: "left",
                                autoTrigger: true,
                                closeOnClick: true,
                                constrainWidth: false,
                                container: null,
                                coverTrigger: true,
                                hover: false,
                                inDuration: 150,
                                onCloseEnd: null,
                                onCloseStart: null,
                                onOpenEnd: null,
                                onOpenStart: null,
                                outDuration: 250,
                              }}
                              trigger={
                                <a
                                  href='javascript:void(0)'
                                  className='right-align action-trigger'
                                  data-target='action_dropdown'
                                >
                                  <i className='material-icons'>more_vert</i>
                                </a>
                              }
                            >
                              {/* <Link to="/UpdateProfile">Edit Profile</Link> */}
                              <a
                                href='#Modal-0'
                                className='actions'
                                node='button'
                                onClick={() => this.editCompany(company.id)}
                              >
                                <i className='material-icons'>edit</i>
                              </a>
                              <a
                                href='javascript:void(0)'
                                className='actions'
                                onClick={() => this.showAlert(company.id)}
                              >
                                <i className='material-icons'>delete</i>
                              </a>
                            </Dropdown>
                          </div>
                          <h5>
                            <a href='#!'>{company.name}</a>
                          </h5>
                          <hr />
                          <p>{company.ownerName}</p>
                          <a href='mailto:{company.user.email}'>
                            {company.user.email}
                          </a>
                          {/* <a href="mailto:info@cityspace.com">
                            info@cityspace.com
                          </a> */}
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>

          {this.state.alert && (
            <SweetAlert
              onConfirm={this.deleteCompany}
              onCancel={this.hideAlert}
              focusCancelBtn={true}
              warning
              showCancel
              confirmBtnText='Yes, delete it!'
              confirmBtnBsStyle='danger'
              title='Are you sure?'
            />
          )}
          <Footer />
          {/* Create New Company Modal */}
        </section>
      </React.Fragment>
    );
  }
}
