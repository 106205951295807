import React, { Component, Fragment } from "react";
import Footer from "../../components/Footer";
import ProjectHeader from "../../components/header/ProjectHeader";
import Sidebar from "../../components/sidebar/Sidebar";
import ProjectMenu from "../../components/sidebar/ProjectMenu";
import AdminHeader from "../../components/header/AdminHeader";
import AdminMenu from "../../components/sidebar/AdminMenu";
import axios from "axios";
import { toast } from "react-toastify";
import "materialize-css";
import { Modal } from "react-materialize";
import { getUserData, getProjectID } from "../../helpers/storage";
import SweetAlert from "react-bootstrap-sweetalert";
import ButtonLoader from "../../components/ButtonLoader";
import { BulletList } from "react-content-loader";
import SimpleReactValidator from "simple-react-validator";
import { Helmet } from "react-helmet";
import "rc-pagination/assets/index.css";
import Pagination from "rc-pagination";
import Select from "rc-select";
import "rc-select/assets/index.less";
import "../../assets/pagination.less";
import localeInfo from "../../helpers/en_US";
import {
  apiListProjects,
  apiListFDR,
  apiCreateFDR,
  apiEditFDR,
  apiGetFDRById,
  apiGetMasterByType,
  apiFileUpload,
  apiGetBankMasterList,
} from "../../config/endpoints";

const initialState = {
  fields: {
    mode: "Active",
    bankname: "",
    accountno: "",
    project: "",
    purpose: "",
    issuename: "",
    fdrno: "",
    amount: "",
    issuedate: "",
    maturitydate: "",
    renewal: "",
    interestrate: "",
    submittedto: "",
    submittedby: "",
    renewedupto: "",
    renewalinterestrate: "",
    remarks: "",
    file: "",
  },
  isLoading: false,
  redirect: false,
  errors: {},
  vendorList: [],
  isModalOpen: false,
  isListLoading: false,
  alert: false,
  deleteId: "",
  editId: false,
  editItem: false,
  projectList: [],
  bgList: [],
  fdrList: [],
  recurringList: [],
  bankList: [],
  bankAccountList: [],
  fdrCount: 0,
  current: 1,
  pageSize: 30,
  fileInputs: [""],
  fileValues: [],
};

export default class Fdr extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = initialState;
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
  }

  handleCloseModal = () => {
    this.setState(initialState);
    this.loadFDR();
  };
  handleOpenModal = () => {
    this.setState({ isModalOpen: true });
    this.validator.hideMessages();
    this.loadFDR();
  };

  createFileInputs = () => {
    return this.state.fileInputs.map((el, i) => (
      <div className="row" key={i}>
        <div className="col s10 mt">
          <input
            type="file"
            className="normalfile"
            // value={el || ""}
            onChange={this.fileChange.bind(this, i)}
          />
        </div>
        <div className="col s2">
          {i !== 0 ? (
            <a
              type="button"
              value="remove"
              className="btn"
              onClick={this.removeClick.bind(this, i)}
            >
              x
            </a>
          ) : (
            ""
          )}
        </div>
      </div>
    ));
  };

  addClick = () => {
    this.setState((prevState) => ({
      fileInputs: [...prevState.fileInputs, ""],
    }));
    console.log(this.state.fileInputs);
  };

  removeClick = (i) => {
    let values = [...this.state.fileInputs];
    values.splice(i, 1);
    console.log(i);
    console.log(values);
    this.setState({ fileInputs: values });
  };

  editFdrPayment(id) {
    // Assuming only image
    this.setState({ editItem: true, editId: id });
    this.handleOpenModal();
    let userData = getUserData();
    fetch(apiGetFDRById + "?id=" + id, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          this.setState({
            fields: data.data.fdr,
          });
        } else {
          alert("something went wrong.");
        }
      });
  }

  loadMaster = (e) => {
    let userData = getUserData();
    fetch(apiGetBankMasterList + "?type=bank", {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          //console.log("master vendor list", data.data.masters);
          this.setState({
            bankList: data.data.masters.rows,
          });
        } else {
          alert("something went wrong.");
        }
      });
    fetch(apiGetMasterByType + "?type=bank_account", {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          //console.log("master vendor list", data.data.masters);
          this.setState({
            bankAccountList: data.data.masters,
          });
        } else {
          alert("something went wrong.");
        }
      });
  };
  showAlert = (id) => {
    //console.log(id);
    this.setState({
      alert: true,
      deleteId: id,
    });
  };
  hideAlert = () => {
    this.setState({
      alert: false,
    });
  };
  onChangePagination = (page) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        current: page,
      }),
      this.loadFDR
    );
  };
  onChangePageSize = (current, pageSize) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        current: current,
        pageSize: pageSize,
      }),
      this.loadFDR
    );
  };
  loadFDR = (e) => {
    let userData = getUserData();
    let projectId = getProjectID();
    this.setState({ isListLoading: true });
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        project: projectId,
      },
    }));
    this.loadMaster();
    fetch(
      apiListFDR +
        "?page=" +
        this.state.current +
        "&pagesize=" +
        this.state.pageSize,
      {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + userData.token,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          this.setState({
            fdrList: data.data.fdr.rows,
            fdrCount: data.data.fdr.count,
            isListLoading: false,
          });
        } else {
          alert("something went wrong.");
        }
      });
    fetch(apiListProjects, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          //console.log("project list", data.data.projects.rows);
          this.setState({
            projectList: data.data.projects.rows,
          });
        } else {
          alert("something went wrong.");
        }
      });
  };

  handleChange = (e) => {
    this.validator.showMessageFor(e.target.name);
    const value = e.target.value;
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        [e.target.name]: value,
      },
    }));
  };

  handleSearch = (e) => {
    this.validator.showMessageFor(e.target.name);
    const value = e.target.value;
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        [e.target.name]: value,
      },
    }));
  };

  fileChange = (i, event) => {
    console.log("test", event.target.files[0]);
    let values = [...this.state.fileInputs];
    values[i] = event.target.files[0];
    this.setState({ fileInputs: values });
    //upload file
    let userData = getUserData();
    let bodyFormData = new FormData();
    bodyFormData.append("file", event.target.files[0]);
    bodyFormData.append("type", "banguarantee");
    this.setState({ uploading: true });
    // axios
    //   .post(apiFileUpload, bodyFormData, {
    //     headers: {
    //       Authorization: "Bearer " + userData.token,
    //     },
    //   })
    //   .then((response) => response.json())
    //   .then((data) => {
    //     console.log(data.data.file.Location);
    //     console.log(data.file.Location);
    //     this.setState({ uploading: false });
    //     this.setState((previousState) => ({
    //       fileValues: [...previousState.fileValues, data.data.file.Location],
    //     }));
    //   });

    axios
      .post(apiFileUpload, bodyFormData, {
        headers: {
          Authorization: "Bearer " + userData.token,
        },
      })
      // .then((response) => response.json())
      .then((data) => {
        data = data.data;
        if (data.code == 200) {
          console.log(data.data.file.Location);
          this.setState({ uploading: false });
          this.setState((previousState) => ({
            fileValues: [...previousState.fileValues, data.data.file.Location],
          }));
        } else {
          alert("something went wrong.");
        }
      });

    // if (e.target.files[0]) {
    //   this.setState((prevState) => ({
    //     fields: {
    //       ...prevState.fields,
    //       [e.target.name]: e.target.files[0],
    //     },
    //   }));
    // }
    // Updating the city
  };

  filesUI = (values) => {
    console.log(values);
    if (values != null) {
      return JSON.parse(values).map((el, i) => (
        <div className="row" key={i}>
          <a href={el} className="actions">
            Download
            <i className="material-icons">file_download</i>
          </a>
        </div>
      ));
    } else {
      return <></>;
    }
  };

  handleBlur = (e) => {
    this.validator.showMessageFor(e.target.name);
  };

  submitForm = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      let userData = getUserData();
      if (!this.state.editItem) {
        let bodyFormData = new FormData();

        let formData = {
          mode: this.state.fields.mode,
          bankname: this.state.fields.bankname,
          accountno: this.state.fields.accountno,
          project: this.state.fields.project,
          purpose: this.state.fields.purpose,
          issuename: this.state.fields.issuename,
          fdrno: this.state.fields.fdrno,
          amount: this.state.fields.amount,
          issuedate: this.state.fields.issuedate,
          maturitydate: this.state.fields.maturitydate,
          interestrate: this.state.fields.interestrate,
          submittedto: this.state.fields.submittedto,
          submittedby: this.state.fields.submittedby,
          renewedupto: this.state.fields.renewedupto,
          renewalinterestrate: this.state.fields.renewalinterestrate,
          file: this.state.fields.file,
          remarks: this.state.fields.remarks,
          filevalues: this.state.fileValues,
        };

        this.setState({ isLoading: true });

        //console.log(data);

        axios
          .post(apiCreateFDR, formData, {
            headers: {
              Authorization: "Bearer " + userData.token,
            },
          })
          .then(
            (response) => {
              //console.log(response);
              // this.setState({ isLoading: false });
              toast.info("FDR created", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
              this.setState(this.initialState);
              this.handleCloseModal();
            },
            (error) => {
              if (
                error.response.status == 500 ||
                error.response.status == 400
              ) {
                toast.error(error.response.data.errorMessage, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                this.setState({ isLoading: false });
              }
            }
          );
      } else {
        let bodyFormData = new FormData();
        bodyFormData.append("mode", this.state.fields.mode);
        bodyFormData.append("bankname", this.state.fields.bankname);
        bodyFormData.append("accountno", this.state.fields.accountno);
        bodyFormData.append("project", this.state.fields.project);
        bodyFormData.append("purpose", this.state.fields.purpose);
        bodyFormData.append("issuename", this.state.fields.issuename);
        bodyFormData.append("fdrno", this.state.fields.fdrno);
        bodyFormData.append("amount", this.state.fields.amount);
        bodyFormData.append("issuedate", this.state.fields.issuedate);
        bodyFormData.append("maturitydate", this.state.fields.maturitydate);
        bodyFormData.append("interestrate", this.state.fields.interestrate);
        bodyFormData.append("submittedto", this.state.fields.submittedto);
        bodyFormData.append("submittedby", this.state.fields.submittedby);
        bodyFormData.append("renewedupto", this.state.fields.renewedupto);
        bodyFormData.append(
          "renewalinterestrate",
          this.state.fields.renewalinterestrate
        );
        bodyFormData.append("file", this.state.fields.file);
        bodyFormData.append("remarks", this.state.fields.remarks);
        bodyFormData.append("id", this.state.editId);

        this.setState({ isLoading: true });
        axios
          .post(apiEditFDR, bodyFormData, {
            headers: {
              Authorization: "Bearer " + userData.token,
            },
          })
          .then(
            (response) => {
              //console.log(response);
              toast.info("FDR updated", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
              this.setState(this.initialState);
              this.handleCloseModal();
            },
            (error) => {
              if (
                error.response.status == 500 ||
                error.response.status == 400
              ) {
                toast.error(error.response.data.errorMessage, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                this.setState({ isLoading: false });
              }
            }
          );
      }
    } else {
      //console.log("fail");
      //console.log("errors", this.validator.getErrorMessages());
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  componentDidMount() {
    this.loadFDR();
  }

  render() {
    this.validator.purgeFields();
    return (
      <React.Fragment>
        <Helmet>
          <title>FDR | MRC</title>
        </Helmet>
        <section className="admin">
          <AdminHeader></AdminHeader>
          <div className="wrapper d-flex">
            <AdminMenu></AdminMenu>

            <div className="wrapper_body">
              <div className="row d-flex align-items-center">
                <div className="col m0 d-flex align-items-center">
                  <h3 className="heading">FDR</h3>
                </div>
                <div className="col m4 right-align">
                  <button
                    href="javascript:void(0)"
                    className="theme_btn btn  modal-trigger"
                    onClick={this.handleOpenModal}
                  >
                    <i className="material-icons">add</i> Create FDR
                  </button>
                  <Modal
                    actions={[,]}
                    bottomSheet={false}
                    fixedFooter={false}
                    id="Modal-0"
                    open={this.state.isModalOpen}
                    className="cstm-modal"
                    options={{
                      dismissible: false,
                      endingTop: "10%",
                      inDuration: 250,
                      onCloseEnd: null,
                      onCloseStart: null,
                      onOpenEnd: null,
                      onOpenStart: null,
                      opacity: 0.5,
                      outDuration: 250,
                      preventScrolling: true,
                      startingTop: "4%",
                    }}
                  >
                    {/* Close Modal */}
                    <a
                      href="javascript:Void(0)"
                      className="modal-close"
                      onClick={this.handleCloseModal}
                    >
                      <span className="material-icons">chevron_right</span>
                    </a>
                    {/* Modal Content */}
                    <h3 className="heading">
                      {this.state.editItem ? "Update FDR" : "Create  FDR"}
                    </h3>
                    <p>
                      Enter details to{" "}
                      {this.state.editItem ? "update" : "create new"} FDR
                    </p>
                    <form action id="createContractorForm">
                      <div className="row">
                        <div className="col s12">
                          <label className="label">Status</label>
                          <select
                            name="mode"
                            value={this.state.fields.mode}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            // disabled
                          >
                            {/* <option>Select Mode</option> */}
                            <option selected value="Active">
                              Active
                            </option>
                            <option value="Disbursed">Disbursed</option>
                          </select>
                          <label className="error">
                            {this.validator.message(
                              "mode",
                              this.state.fields.mode,
                              "required"
                            )}
                          </label>
                        </div>

                        <div className="col s12">
                          <label className="label">Issuing Bank Name</label>
                          {/* <input
                                type="text"
                                placeholder="Issuing Bank Name"
                                name="bankname"
                                value={this.state.fields.bankname}
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                              /> */}
                          <select
                            name="bankname"
                            value={this.state.fields.bankname}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                          >
                            <option selected> Select</option>
                            {this.state.bankList.map((type, index) => (
                              <>
                                <option key={index} value={type.name}>
                                  {type.name}
                                </option>
                              </>
                            ))}
                          </select>

                          <label className="error">
                            {this.validator.message(
                              "bankname",
                              this.state.fields.bankname,
                              "required"
                            )}
                          </label>
                        </div>

                        <div className="col s12" style={{ display: "none" }}>
                          <label className="label">Account Number</label>
                          <select
                            name="accountno"
                            value={this.state.fields.accountno}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                          >
                            <option selected> Select</option>
                            {this.state.bankAccountList.map((type, index) => (
                              <>
                                <option key={index} value={type.name}>
                                  {type.name}
                                </option>
                              </>
                            ))}
                          </select>

                          {/* <label className="error">
                                {this.validator.message(
                                  "accountno",
                                  this.state.fields.accountno,
                                  "required"
                                )}
                              </label> */}
                        </div>
                        <div className="col s12">
                          <label className="label">Project</label>
                          <select
                            name="project"
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            value={this.state.fields.project}
                            // disabled
                          >
                            <option>Select</option>
                            {this.state.projectList.map((project, index) => (
                              <Fragment key={index}>
                                <option value={project.id}>
                                  {project.name}
                                </option>
                              </Fragment>
                            ))}
                          </select>
                          <label className="error">
                            {this.validator.message(
                              "project",
                              this.state.fields.project,
                              "required"
                            )}
                          </label>
                        </div>
                        <div className="col s12">
                          <label className="label">Purpose</label>
                          <input
                            type="text"
                            placeholder="Purpose"
                            name="purpose"
                            value={this.state.fields.purpose}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                          />
                          <label className="error">
                            {this.validator.message(
                              "purpose",
                              this.state.fields.purpose,
                              "required"
                            )}
                          </label>
                        </div>
                        <div className="col s12">
                          <label className="label">Issued in favour of</label>
                          <input
                            type="text"
                            placeholder="Issued in favour of"
                            name="issuename"
                            value={this.state.fields.issuename}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                          />
                          <label className="error">
                            {this.validator.message(
                              "issuename",
                              this.state.fields.issuename,
                              "required"
                            )}
                          </label>
                        </div>
                        <div className="col s12">
                          <label className="label">FDR No.</label>
                          <input
                            type="text"
                            placeholder="FDR No."
                            name="fdrno"
                            value={this.state.fields.fdrno}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                          />
                          <label className="error">
                            {this.validator.message(
                              "fdrno",
                              this.state.fields.fdrno,
                              "required"
                            )}
                          </label>
                        </div>
                        <div className="col s12">
                          <label className="label">Amount</label>
                          <input
                            type="text"
                            placeholder="Amount."
                            name="amount"
                            value={this.state.fields.amount}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                          />
                          <label className="error">
                            {this.validator.message(
                              "amount",
                              this.state.fields.amount,
                              "required|number"
                            )}
                          </label>
                        </div>

                        <div className="col  s12">
                          <label className="label">Issue Date</label>
                          <input
                            type="date"
                            name="issuedate"
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            value={this.state.fields.issuedate}
                          />

                          <label className="error">
                            {this.validator.message(
                              "issuedate",
                              this.state.fields.issuedate,
                              "required"
                            )}
                          </label>
                        </div>
                        <div className="col  s12">
                          <label className="label">Maturity Date</label>
                          <input
                            type="date"
                            name="maturitydate"
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            value={this.state.fields.maturitydate}
                          />

                          <label className="error">
                            {this.validator.message(
                              "maturitydate",
                              this.state.fields.maturitydate,
                              "required"
                            )}
                          </label>
                        </div>
                        <div className="col s12">
                          <label className="label">Renewal</label>
                          <select
                            name="renewal"
                            value={this.state.fields.renewal}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                          >
                            <option> Select</option>
                            <option value="Auto Renewal"> Auto Renewal</option>
                            <option value="Manual Renewal">
                              {" "}
                              Manual Renewal
                            </option>
                          </select>

                          <label className="error">
                            {this.validator.message(
                              "renewal",
                              this.state.fields.renewal,
                              "required"
                            )}
                          </label>
                        </div>
                        <div className="col s12">
                          <label className="label">Interest Rate(%)</label>
                          <input
                            type="text"
                            placeholder="Interest Rate"
                            name="interestrate"
                            value={this.state.fields.interestrate}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                          />
                          <label className="error">
                            {this.validator.message(
                              "interestrate",
                              this.state.fields.interestrate,
                              "required|numeric"
                            )}
                          </label>
                        </div>

                        <div className="col s12">
                          <label className="label">Submitted To</label>
                          <input
                            type="text"
                            placeholder="Submitted To"
                            name="submittedto"
                            value={this.state.fields.submittedto}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                          />
                          <label className="error">
                            {this.validator.message(
                              "submittedto",
                              this.state.fields.submittedto,
                              "required"
                            )}
                          </label>
                        </div>

                        <div className="col s12">
                          <label className="label">Submitted By</label>
                          <input
                            type="text"
                            placeholder="Submitted By"
                            name="submittedby"
                            value={this.state.fields.submittedby}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                          />
                          <label className="error">
                            {this.validator.message(
                              "submittedby",
                              this.state.fields.submittedby,
                              "required"
                            )}
                          </label>
                        </div>

                        {/* <div className="col  s12">
                              <label className="label">Renewed Upto</label>
                              <input
                                type="date"
                                name="renewedupto"
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                                value={this.state.fields.renewedupto}
                              />

                              <label className="error">
                                {this.validator.message(
                                  "renewedupto",
                                  this.state.fields.renewedupto,
                                  "required"
                                )}
                              </label>
                            </div> */}

                        {/* <div className="col s12">
                              <label className="label">
                                Renewal Interst Rate
                              </label>
                              <input
                                type="text"
                                placeholder="Renewal Interst Rate"
                                name="renewalinterestrate"
                                value={this.state.fields.renewalinterestrate}
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                              />
                              <label className="error">
                                {this.validator.message(
                                  "renewalinterestrate",
                                  this.state.fields.renewalinterestrate,
                                  "required"
                                )}
                              </label>
                            </div> */}

                        <div className="col s12">
                          <label className="label">Files</label>
                          {/* <div className="button-wrap col  s12">
                                <label className="new-button" for="upload">
                                  File
                                  <input
                                    id="upload"
                                    type="file"
                                    name="file"
                                    onChange={this.fileChange}
                                  />
                                </label>
                                <br />
                                {this.state.fields.file && (
                                  <>
                                    <span>
                                      {this.state.fields.file.name ||
                                        this.state.fields.file.split("/").pop()}
                                      <i class="tiny material-icons">
                                        attach_file
                                      </i>
                                    </span>
                                  </>
                                )}

                                {this.state.editItem ? null : (
                                  <label className="error">
                                    {this.validator.message(
                                      "file upload",
                                      this.state.fields.file,
                                      "required"
                                    )}
                                  </label>
                                )}
                              </div> */}
                          {this.createFileInputs()}
                          <input
                            type="button"
                            className="btn"
                            value="add more"
                            onClick={this.addClick.bind(this)}
                          />
                        </div>

                        <div className="col s12">
                          <label className="label">Remarks</label>
                          <input
                            type="text"
                            placeholder="Remarks"
                            name="remarks"
                            value={this.state.fields.remarks}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                          />
                          <label className="error">
                            {this.validator.message(
                              "remarks",
                              this.state.fields.remarks,
                              "required"
                            )}
                          </label>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col s12 right-align">
                          <button
                            type="submit"
                            form="createContractorForm"
                            className="theme_btn btn"
                            onClick={this.submitForm}
                          >
                            {this.state.editItem ? "Update FDR" : "Add FDR"}
                            {this.state.isLoading ? (
                              <ButtonLoader></ButtonLoader>
                            ) : null}
                          </button>
                        </div>
                      </div>
                    </form>
                  </Modal>
                </div>
              </div>
              <div className="projects employee">
                <div className="row">
                  <div className="col s12">
                    <p className="grey-text">
                      <b>Filters</b>
                    </p>
                    <form ref={(ele) => (this.myFormRef = ele)}>
                      <div className="row">
                        <div className="col m2 s12">
                          <select name="subhead" onChange={this.handleSearch}>
                            <option selected>Select Bank Name</option>
                            {this.state.bankList.map((bank, index) => (
                              <>
                                <option value={bank.id}>{bank.name}</option>
                              </>
                            ))}
                          </select>
                        </div>
                        <div className="col m3 s12">
                          <input
                            type="date"
                            className=""
                            placeholder="From Date"
                            name="from"
                            onChange={this.handleSearch}
                          />
                        </div>

                        <div className="col m3 s12">
                          {/* <span>-</span> */}
                          <input
                            type="date"
                            className=""
                            placeholder="To Date"
                            name="to"
                            onChange={this.handleSearch}
                          />
                        </div>
                        <div className="col m2 s12">
                          <select name="status" onChange={this.handleSearch}>
                            <option selected>Status</option>
                            <option value={1}>Active</option>
                            <option value={2}>Inactive</option>
                          </select>
                        </div>
                      </div>

                      <div className="row">
                        {/* <a href="#!" className="red-text pt-1 d-flex">Clear Filter</a> */}
                        {/* <button type="submit" className="red-text pt-1">Clear Filter</button> */}
                        <div class="col m3 s12">
                          <a
                            href="javascript:void(0)"
                            class="red-text pt-1"
                            onClick={this.clearFilter}
                          >
                            Clear Filter
                          </a>

                          <button
                            type="submit"
                            className="theme_btn btn filter-submit"
                            onClick={this.submitSearch}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="col s12">
                    <div className="table-responsive">
                      <table>
                        <thead>
                          <tr>
                            <th>Mode</th>
                            <th>Bank Name</th>
                            {/* <th>Account No</th> */}
                            <th>Project</th>
                            <th>Purpose</th>
                            <th>Issue Name</th>
                            <th>FDR No.</th>
                            <th>Amount</th>
                            <th>Issue Date</th>
                            <th>Maturity Date</th>
                            <th>Interest Rate</th>
                            <th>Submitted To</th>
                            <th>Submitted By</th>
                            {/* <th>Renewed Upto</th>
                                <th>Renewal Interest Rate</th> */}
                            <th>File</th>
                            <th>Remakrs</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.isListLoading ? (
                            <tr>
                              <td colSpan={5}>
                                <BulletList></BulletList>
                              </td>
                            </tr>
                          ) : (
                            this.state.fdrList.map((fdr, index) => (
                              <tr key={index}>
                                <td>{fdr.mode}</td>
                                <td>{fdr.bankname}</td>
                                {/* <td>{fdr.accountno}</td> */}
                                <td>{fdr.projects.name}</td>
                                <td>{fdr.purpose}</td>
                                <td>{fdr.issuename}</td>
                                <td>{fdr.fdrno}</td>
                                <td>&#8377;{fdr.amount}</td>
                                <td>{fdr.issuedate}</td>
                                <td>{fdr.maturitydate}</td>
                                <td>{fdr.interestrate}%</td>
                                <td>{fdr.submittedto}</td>
                                <td>{fdr.submittedby}</td>
                                {/* <td>{fdr.renewedupto}</td>
                                    <td>{fdr.renewalinterestrate}</td> */}

                                <td>
                                  {fdr.file ? (
                                    <a href={fdr.file} className="actions">
                                      Download
                                      <i className="material-icons">
                                        file_download
                                      </i>
                                    </a>
                                  ) : (
                                    this.filesUI(fdr.filevalues)
                                  )}
                                </td>
                                <td data-th="Remarks">{fdr.remarks}</td>
                                <td>
                                  <a
                                    href="#Modal-0"
                                    className="actions"
                                    node="button"
                                    onClick={() => this.editFdrPayment(fdr.id)}
                                  >
                                    <i className="material-icons">edit</i>
                                  </a>
                                  {/* <a
                                      href='javascript:void(0)'
                                      className='actions'
                                      onClick={() => this.showAlert(vendor.id)}
                                    >
                                      <i className='material-icons'>delete</i>
                                    </a> */}
                                </td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {/* pagination */}
                  <div
                    className="col s12"
                    style={{ marginTop: 20, marginBottom: 20 }}
                  >
                    <Pagination
                      pageSize={this.state.pageSize}
                      onChange={this.onChangePagination}
                      current={this.state.current}
                      total={this.state.fdrCount}
                      showSizeChanger
                      defaultPageSize={this.state.pageSize}
                      defaultCurrent={this.state.current}
                      onShowSizeChange={this.onChangePageSize}
                      locale={localeInfo}
                      selectComponentClass={Select}
                      pageSizeOptions={[10, 30, 50, 100]}
                      showTotal={(total, range) =>
                        `${range[0]} - ${range[1]} of ${total} items`
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.state.alert && (
            <SweetAlert
              onConfirm={this.deleteVendor}
              onCancel={this.hideAlert}
              focusCancelBtn={true}
              warning
              showCancel
              confirmBtnText="Yes, delete it!"
              confirmBtnBsStyle="danger"
              title="Are you sure?"
            />
          )}
          <Footer />
        </section>
        {/* Add Contractor Modal */}
      </React.Fragment>
    );
  }
}
