import React, { Component } from "react";
import "materialize-css";

import ProjectLinks from "./ProjectLinks";

export default class ProjectMenu extends Component {
  componentDidMount = () => {
    console.log("projectid", this.props.projectId);
  };
  render() {
    return (
      <>
        <aside id='slide-out' className='sidenav'>
          <ProjectLinks projectId={this.props.projectId} />
        </aside>

        <aside className='aside_navbar'>
          <ProjectLinks projectId={this.props.projectId} />
        </aside>
      </>
    );
  }
}
