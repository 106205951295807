import React, { Component } from 'react'
import Header from '../Header'
import MnavBar from '../MnavBar'
import SideBar from '../SideBar'
import ButtonLoader from "../../components/ButtonLoader";
import Footer from '../Footer';


export default class EmpReceivables extends Component {
    render() {
        return (

            <div>

                <section className="adminProject">
                    <div className="wrapper d-flex">
                        <SideBar />
                        <div className="wrapper_right">
                            <Header />
                            <div className="d-flex">

                                <div className="wrapper_body">
                                    <div className="row">
                                        <div className="col m8 s12">
                                            <h3 className="heading">Receivables</h3>
                                            {/* <div className="nav-wrapper">
                                                <a href="javascript:void(0)" className="breadcrumb">First</a>
                                                <a href="javascript:void(0)" className="breadcrumb">Second</a>
                                                <a href="javascript:void(0)" className="breadcrumb">Third</a>
                                            </div> */}
                                        </div>
                                        <div className="col m4 s12">
                                            <a href="#create_new_list_modal" className="float-md-right theme_btn btn waves-effect waves-light modal-trigger"><i className="material-icons">add&nbsp;</i>Create New Receivables</a>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col s12">
                                            <div className="table-responsive">
                                                <table>
                                                    <thead>
                                                        <tr>

                                                            <th>Date</th>
                                                            <th>Amount</th>
                                                            <th>Type</th>
                                                            <th>Remarks</th>
                                                            <th>Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {/* <tr>
                                                            <td data-th="Name">Anubhav Mangla</td>
                                                            <td data-th="Email">anubhav.mangla@mrcgroup.com</td>
                                                            <td data-th="Site">IIT, Una</td>
                                                            <td data-th="Date">13-12-2020</td>
                                                            <td data-th="Subhead">Hardware Materials</td>
                                                            <td data-th="Vendor">Bajaj Development Ltd.</td>
                                                            <td data-th="Bill No.">456</td>
                                                            <td data-th="Amount">540.00</td>
                                                            <td data-th="Cash/Bank">Cash</td>
                                                            <td data-th="Remarks">Verified</td>
                                                            <td>
                                                                <a href="javascript:void(0)" className="actions"><i className="material-icons">visibility</i></a>
                                                                <a href="javascript:void(0)" className="actions"><i className="material-icons">edit</i></a>
                                                            </td>
                                                        </tr> */}
                                                        {/* <tr>
                                                            <td data-th="Name">Anubhav Mangla</td>
                                                            <td data-th="Email">anubhav.mangla@mrcgroup.com</td>
                                                            <td data-th="Site">IIT, Una</td>
                                                            <td data-th="Date">13-12-2020</td>
                                                            <td data-th="Subhead">Hardware Materials</td>
                                                            <td data-th="Vendor">Bajaj Development Ltd.</td>
                                                            <td data-th="Bill No.">456</td>
                                                            <td data-th="Amount">540.00</td>
                                                            <td data-th="Cash/Bank">Cash</td>
                                                            <td data-th="Remarks">Verified</td>
                                                            <td>
                                                                <a href="javascript:void(0)" className="actions"><i className="material-icons">visibility</i></a>
                                                                <a href="javascript:void(0)" className="actions"><i className="material-icons">edit</i></a>
                                                            </td>
                                                        </tr> */}
                                                        {/* <tr>
                                                            <td data-th="Name">Anubhav Mangla</td>
                                                            <td data-th="Email">anubhav.mangla@mrcgroup.com</td>
                                                            <td data-th="Site">IIT, Una</td>
                                                            <td data-th="Date">13-12-2020</td>
                                                            <td data-th="Subhead">Hardware Materials</td>
                                                            <td data-th="Vendor">Bajaj Development Ltd.</td>
                                                            <td data-th="Bill No.">456</td>
                                                            <td data-th="Amount">540.00</td>
                                                            <td data-th="Cash/Bank">Cash</td>
                                                            <td data-th="Remarks">Verified</td>
                                                            <td>
                                                                <a href="javascript:void(0)" className="actions"><i className="material-icons">visibility</i></a>
                                                                <a href="javascript:void(0)" className="actions"><i className="material-icons">edit</i></a>
                                                            </td>
                                                        </tr> */}
                                                    </tbody>
                                                </table>
                                            </div>
                                            {/* <ul className="pagination right-align">
                                                <li className="disabled"><a href="javascript:void(0)"><i className="material-icons">chevron_left</i></a></li>
                                                <li className="active"><a href="javascript:void(0)">1</a></li>
                                                <li className="waves-effect"><a href="javascript:void(0)">2</a></li>
                                                <li className="waves-effect"><a href="javascript:void(0)">3</a></li>
                                                <li className="waves-effect"><a href="javascript:void(0)"><i className="material-icons">chevron_right</i></a></li>
                                            </ul> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </section>
                {/* Create_new_project_modal Modal */}
                <div id="create_new_list_modal" className="new-list-modal cstm-modal modal">
                    {/* Close Modal */}
                    <a href="javascript:Void(0)" className="modal-close"><span className="material-icons">chevron_right</span></a>
                    {/* Modal Content */}
                    <h3 className="heading">Receivables</h3>
                    <p>Enter details to receivables</p>
                    <form action id="createListForm">
                        <div className="row">


                            <div className="col s12">
                                <label className="label">Date</label>
                                <input type="date" placeholder="Purchase Date" name="Purchase Date" />
                            </div>
                            <div className="col  s12">
                                <label className="label">Amount</label>
                                <input type="text" placeholder="Amount" name="Amount" />
                            </div>
                            <div className="col  s12">
                                <label className="label">Remarks</label>
                                <input type="text" placeholder="Remarks" name="Remarks" />
                            </div>
                            <div className="row">
                                <div className="col s12 right-align">
                                    <button
                                        type="submit"

                                        className="theme_btn btn waves-effect waves-light"
                                    >

                                        Receivables

                                        <ButtonLoader></ButtonLoader>

                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

        )
    }
}
