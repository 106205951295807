import React, { Component } from 'react'
import Footer from '../Footer'
import Header from '../Header'
import MnavBar from '../MnavBar'
import SideBar from '../SideBar'

export default class ChangePasswordForm extends Component {
  render() {
    return (
      <div>
        <title>Project Name | Dashboard</title>
        <section className="adminProject">
          <div className="wrapper d-flex">
            <SideBar />
            <div className="wrapper_right">
              <Header />
              <div className="d-flex">
                <MnavBar />
                <div className="wrapper_body w-100">
                  <div className="row">
                    <div className="col m8 s12">
                      <h3 >Change Password</h3>
                    </div>
                  </div>

                  <form>

                    <label>Old Password:</label>
                    <input type="password"></input>

                    <label>New Password:</label>
                    <input type="password"></input>

                    <label>Confirm New Password:</label>
                    <input type="password"></input>

                  </form>

                  <div className="col s12 right-align">
                    <button type="submit" form="submit" className="theme_btn btn waves-effect waves-light">Submit</button>
                  </div>

                </div>

              </div>
            </div>
          </div>
          <Footer />
        </section>

      </div>
    )
  }
}
