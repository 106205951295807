import React, { Component } from "react";
import { Redirect, useHistory } from "react-router-dom";
import Footer from "../../components/Footer";
import AdminHeader from "../../components/header/AdminHeader";
import AdminMenu from "../../components/sidebar/AdminMenu";

import ButtonLoader from "../../components/ButtonLoader";
import axios from "axios";
import "materialize-css";
import { Modal } from "react-materialize";
import { toast } from "react-toastify";
import {
  setUserData,
  getUserData,
  setProjectId,
  setProjectData,
} from "../../helpers/storage";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  apiCreateProject,
  apiListProjects,
  apiGetProject,
  apiDeleteProject,
  apiEditProject,
} from "../../config/endpoints";
import { Helmet } from "react-helmet";
import { BulletList } from "react-content-loader";
import ReactFormInputValidation from "react-form-input-validation";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AutoComplete from "react-google-autocomplete";
import InputMask from "react-input-mask";
import SimpleReactValidator from "simple-react-validator";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const initialState = {
  fields: {
    name: "",
    client: "",
    amount: "",
    awardedDate: "",
    address: "",
    gst: "",
    state: "",
    statecode: "",
    pan: "",
    company: "",
    projectType: "",
    witheld: "",
  },
  logo: "",
  file: "",
  isLoading: false,
  redirect: false,
  errors: {},
  projectList: [],
  isModalOpen: false,
  isListLoading: false,
  alert: false,
  deleteId: "",
  editId: false,
  editItem: false,
  logoImage: false,
  user: [],
};
export default class Projects extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.fileChange = this.fileChange.bind(this);
    this.editProject = this.editProject.bind(this);
    this.loadProjects = this.loadProjects.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.openProject = this.openProject.bind(this);
    this.form = new ReactFormInputValidation(this);
    this.handleDate = this.handleDate.bind(this);
    this.submitForm = this.submitForm.bind(this);

    this.form.useRules({
      name: "required",
      client: "required",
      amount: "required|numeric",
      awardedDate: "required",
      address: "required",
      gst: "required",
      pan: "required",
      projectType: "required",
      witheld: "required",
    });
    this.form.onformsubmit = (fields) => {
      // Do you ajax calls here.
      //console.log(fields);
    };
  }
  componentWillMount() {
    this.loadProjects();
  }

  loadProjects = (e) => {
    this.setState({ isListLoading: true });
    let userData = getUserData();
    this.setState({
      user: userData.user,
    });
    fetch(apiListProjects, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        //console.log(data.data.projects.rows);
        if (data.code == 200) {
          this.setState({
            projectList: data.data.projects.rows,
          });
          //console.log("project list", this.state.projectList);
          this.setState({ isListLoading: false });
        } else {
          alert("something went wrong.");
        }
        // setTimeout(function () {
      });
    // return true;
  };
  fileChange(e) {
    // Assuming only image
    if (e.target.files[0]) {
      //console.log("picture: ", e.target.files);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        // console.log(reader.result);
        this.setState({ logoImage: reader.result });
      });
      reader.readAsDataURL(e.target.files[0]);
      this.setState({ file: e.target.files[0] });
    }
    // Updating the city
  }

  editProject = (id) => {
    this.setState({ editItem: true, editId: id });
    this.handleOpenModal();
    let userData = getUserData();
    fetch(apiGetProject + "?id=" + id, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          //console.log(data.data.project);
          this.setState({
            fields: data.data.project,
            logoImage: data.data.project.file,
          });
        } else {
          alert("something went wrong.");
        }
      });
    // Updating the city
  };
  handleCloseModal = () => {
    this.setState(initialState);
    this.loadProjects();
  };
  handleOpenModal = () => {
    this.setState({ isModalOpen: true });
    this.validator.hideMessages();
  };
  handleDate = (event) => {
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        awardedDate: event.target.value,
      },
    }));
    //console.log(this.state);
  };
  setLocation = (e) => {
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        address: e,
      },
    }));
  };
  showAlert = (id) => {
    //console.log(id);
    this.setState({
      alert: true,
      deleteId: id,
    });
  };
  hideAlert = () => {
    this.setState({
      alert: false,
    });
  };
  openProject = (e, id, projectData) => {
    if (e.target.classList.contains("openproject")) {
      this.setState({ redirect: "/project/" + id });
      setProjectId(id);
      setProjectData(projectData);
    }
  };
  deleteProject = (e) => {
    //console.log("delete", this.state);
    this.setState({ alert: false });
    let userData = getUserData();
    axios
      .post(
        apiDeleteProject,
        { id: this.state.deleteId },
        {
          headers: {
            Authorization: "Bearer " + userData.token,
          },
        }
      )
      .then(
        (response) => {
          //console.log(response);
          this.setState({ isLoading: false });
          toast.info("Project deleted", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          this.setState(this.initialState);
          this.loadProjects();
        },
        (error) => {
          if (error.response.status == 500 || error.response.status == 400) {
            toast.error(error.response.data.errorMessage, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            this.setState({ isLoading: false });
          }
        }
      );
  };
  handleChange = (e) => {
    /* console.log(e.target.name, e.target.value); */
    this.validator.showMessageFor(e.target.name);
    const value = e.target.value;
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        [e.target.name]: value,
      },
    }));
  };
  handleBlur = (e) => {
    this.validator.showMessageFor(e.target.name);
  };

  submitForm = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      /* console.log("submit");
      console.log(this.state);
      console.log(getUserData()); */
      let userData = getUserData();
      if (!this.state.editItem) {
        var bodyFormData = new FormData();
        bodyFormData.append("name", this.state.fields.name);
        bodyFormData.append("client", this.state.fields.client);
        bodyFormData.append("amount", this.state.fields.amount);
        bodyFormData.append("awardedDate", this.state.fields.awardedDate);
        bodyFormData.append("address", this.state.fields.address);
        bodyFormData.append("gst", this.state.fields.gst);
        bodyFormData.append("state", this.state.fields.state);
        bodyFormData.append("statecode", this.state.fields.statecode);
        bodyFormData.append("pan", this.state.fields.pan);
        bodyFormData.append("projectType", this.state.fields.projectType);
        bodyFormData.append("witheld", this.state.fields.witheld);
        bodyFormData.append("file", this.state.file);

        this.setState({ isLoading: true });
        axios
          .post(apiCreateProject, bodyFormData, {
            headers: {
              Authorization: "Bearer " + userData.token,
            },
          })
          .then(
            (response) => {
              //console.log(response);
              toast.info("Project created", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
              this.setState(this.initialState);
              this.handleCloseModal();
            },
            (error) => {
              if (
                error.response.status == 500 ||
                error.response.status == 400
              ) {
                toast.error(error.response.data.errorMessage, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                this.setState({ isLoading: false });
              }
            }
          );
      } else {
        var bodyFormData = new FormData();
        bodyFormData.append("name", this.state.fields.name);
        bodyFormData.append("client", this.state.fields.client);
        bodyFormData.append("amount", this.state.fields.amount);
        bodyFormData.append("awardedDate", this.state.fields.awardedDate);
        bodyFormData.append("address", this.state.fields.address);
        bodyFormData.append("gst", this.state.fields.gst);
        bodyFormData.append("state", this.state.fields.state);
        bodyFormData.append("statecode", this.state.fields.statecode);
        bodyFormData.append("pan", this.state.fields.pan);
        bodyFormData.append("projectType", this.state.fields.projectType);
        bodyFormData.append("witheld", this.state.fields.witheld);
        bodyFormData.append("file", this.state.file);
        bodyFormData.append("id", this.state.editId);

        this.setState({ isLoading: true });
        axios
          .post(apiEditProject, bodyFormData, {
            headers: {
              Authorization: "Bearer " + userData.token,
            },
          })
          .then(
            (response) => {
              //console.log(response);
              toast.info("Project Updated", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
              this.setState(this.initialState);
              this.handleCloseModal();
            },
            (error) => {
              if (
                error.response.status == 500 ||
                error.response.status == 400
              ) {
                toast.error(error.response.data.errorMessage, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                this.setState({ isLoading: false });
              }
            }
          );
      }
    } else {
      /* console.log("fail");
      console.log("errors", this.validator.getErrorMessages()); */
      this.validator.showMessages();
      this.forceUpdate();
    }
  };
  render() {
    this.validator.purgeFields();
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    return (
      <React.Fragment>
        <Helmet>
          <title>Project | MRC</title>
        </Helmet>
        <section className="admin">
          <AdminHeader></AdminHeader>
          <div className="wrapper d-flex">
            <AdminMenu></AdminMenu>
            <div className="wrapper_body">
              <div className="row d-flex align-items-center">
                <div className="col m0 d-flex align-items-center">
                  <h3 className="heading">All Projects</h3>
                </div>
                <div className="col m4 right-align">
                  {this.state.user.userType == "2" && (
                    <a
                      href="javascript:void(0)"
                      className="theme_btn btn waves-effect modal-trigger"
                      onClick={this.handleOpenModal}
                    >
                      <i className="material-icons">add</i> New Project
                    </a>
                  )}

                  <Modal
                    actions={[,]}
                    bottomSheet={false}
                    fixedFooter={false}
                    id="Modal-0"
                    open={this.state.isModalOpen}
                    className="cstm-modal"
                    options={{
                      dismissible: false,
                      endingTop: "10%",
                      inDuration: 250,
                      onCloseEnd: null,
                      onCloseStart: null,
                      onOpenEnd: null,
                      onOpenStart: null,
                      opacity: 0.5,
                      outDuration: 250,
                      preventScrolling: true,
                      startingTop: "4%",
                    }}
                  >
                    {/* Close Modal */}
                    <a
                      href="javascript:void(0)"
                      className="modal-close"
                      onClick={this.handleCloseModal}
                    >
                      <span className="material-icons">chevron_right</span>
                    </a>
                    {/* Modal Content */}
                    <h3 className="heading">
                      {this.state.editItem ? "Update Project" : " New Project"}
                    </h3>
                    <p>Enter details below</p>
                    <form action id="createProjectForm">
                      <fieldset>
                        <legend>Upload Project Image</legend>
                        <div className="button-wrap col  s12">
                          <label className="new-button" for="upload">
                            {" "}
                            Upload Project Image
                            <input
                              id="upload"
                              type="file"
                              name="image"
                              onChange={this.fileChange}
                              accept="image/*"
                            />
                          </label>
                          {this.state.logoImage ? (
                            <img
                              className="logopreview"
                              src={this.state.logoImage}
                            ></img>
                          ) : null}
                          {this.state.editItem ? null : (
                            <label className="error">
                              {this.validator.message(
                                "image",
                                this.state.file,
                                "required"
                              )}
                            </label>
                          )}
                        </div>

                        <legend>Project name</legend>
                        <div className="col m6 s12">
                          <input
                            type="text"
                            placeholder="Project name"
                            name="name"
                            value={this.state.fields.name}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                          />
                          <label className="error">
                            {this.validator.message(
                              "name",
                              this.state.fields.name,
                              "required"
                            )}
                          </label>
                        </div>
                        <legend>Client</legend>
                        <div className="col m6 s12">
                          <input
                            type="text"
                            placeholder="Client"
                            name="client"
                            value={this.state.fields.client}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                          />
                          <label className="error">
                            {this.validator.message(
                              "client",
                              this.state.fields.client,
                              "required"
                            )}
                          </label>
                        </div>
                        <legend>Project Amount</legend>
                        <div className="col m6 s12">
                          <input
                            type="text"
                            placeholder="Project Amount"
                            name="amount"
                            value={this.state.fields.amount}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                          />
                          <label className="error">
                            {this.validator.message(
                              "amount",
                              this.state.fields.amount,
                              "required|numeric|min:0,num"
                            )}
                          </label>
                        </div>
                        <legend>Awarded Date</legend>
                        <div className="col m6 s12">
                          {/* <DatePicker
                                  dateFormat="MMMM d, yyyy"
                                  className="form-control"
                                  placeholder="Awarded Date"
                                  name="awardedDate"
                                  selected={this.state.fields.awardedDate}
                                  onChange={this.handleDate}
                                  onSelect={this.handleDate}
                                  value={this.state.fields.awardedDate}
                                /> */}
                          {/* <InputMask
                            mask='99/99/9999'
                            className='form-control'
                            onChange={this.handleChange}
                            onSelect={this.handleChange}
                            name='awardedDate'
                            placeholder='Awarded Date'
                            value={this.state.fields.awardedDate}
                          /> */}
                          {/* <input
                                type="text"
                                placeholder="DD/MM/YYYY"
                                name="awardedDate"
                                onBlur={this.form.handleBlurEvent}
                                onChange={this.form.handleChangeEvent}
                                value={this.state.fields.awardedDate}
                              /> */}

                          <input
                            type="date"
                            placeholder="Awarded Date"
                            name="awardedDate"
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            value={this.state.fields.awardedDate}
                          />
                          <label className="error">
                            {this.validator.message(
                              "awardedDate",
                              this.state.fields.awardedDate,
                              "required"
                            )}
                          </label>
                        </div>

                        <legend>Address</legend>
                        <div className="col m6 s12">
                          <AutoComplete
                            apiKey={process.env.REACT_APP_GOOGLE_KEY}
                            onPlaceSelected={(place) => {
                              //console.log(place.formatted_address);
                              this.setLocation(place.formatted_address);
                            }}
                            defaultValue={this.state.fields.address}
                            name="address"
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                          />
                          {/* <input
                                type="text"
                                placeholder="Address"
                                name="address"
                                onBlur={this.form.handleBlurEvent}
                                onChange={this.form.handleChangeEvent}
                                value={this.state.fields.address}
                              /> */}
                          <label className="error">
                            {this.validator.message(
                              "address",
                              this.state.fields.address,
                              "required"
                            )}
                          </label>
                        </div>
                        <legend>GST No.</legend>
                        <div className="col m6 s12">
                          <input
                            type="text"
                            placeholder="GST No"
                            name="gst"
                            value={this.state.fields.gst}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                          />
                          <label className="error">
                            {this.validator.message(
                              "gst",
                              this.state.fields.gst,
                              "required"
                            )}
                          </label>
                        </div>
                        <legend>State</legend>
                        <div className="col m6 s12">
                          <input
                            type="text"
                            placeholder="State"
                            name="state"
                            value={this.state.fields.state}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                          />
                          <label className="error">
                            {this.validator.message(
                              "state",
                              this.state.fields.state,
                              "required"
                            )}
                          </label>
                        </div>
                        <legend>State Code</legend>
                        <div className="col m6 s12">
                          <input
                            type="text"
                            placeholder="State Code"
                            name="statecode"
                            value={this.state.fields.statecode}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                          />
                          <label className="error">
                            {this.validator.message(
                              "statecode",
                              this.state.fields.statecode,
                              "required"
                            )}
                          </label>
                        </div>

                        <legend>PAN No.</legend>
                        <div className="col m6 s12">
                          <input
                            type="text"
                            placeholder="PAN No"
                            name="pan"
                            value={this.state.fields.pan}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                          />
                          <label className="error">
                            {this.validator.message(
                              "pan",
                              this.state.fields.pan,
                              "required"
                            )}
                          </label>
                        </div>

                        <legend>Witheld</legend>
                        <div className="col m6 s12">
                          <input
                            type="text"
                            placeholder="Witheld"
                            name="witheld"
                            value={this.state.fields.witheld}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                          />
                          <label className="error">
                            {this.validator.message(
                              "witheld",
                              this.state.fields.witheld,
                              "required|numeric"
                            )}
                          </label>
                        </div>

                        <legend>Type</legend>
                        <div className="col m6 s12">
                          <select
                            name="projectType"
                            value={this.state.fields.projectType}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                          >
                            <option value="">Select Project Type</option>
                            <option>PPP</option>
                            <option>Construction</option>
                          </select>
                          <label className="error">
                            {this.validator.message(
                              "projectType",
                              this.state.fields.projectType,
                              "required"
                            )}
                          </label>
                        </div>
                      </fieldset>
                      <div className="row">
                        <div className="col s12 right-align">
                          <button
                            type="submit"
                            form="createProjectForm"
                            className="theme_btn btn "
                            onClick={this.submitForm}
                          >
                            {this.state.editItem
                              ? "Update Project"
                              : "Add Project"}
                            {this.state.isLoading ? (
                              <ButtonLoader></ButtonLoader>
                            ) : null}
                          </button>
                        </div>
                      </div>
                    </form>
                  </Modal>
                  {/* <a
                        href="#create_new_project_modal"
                        className="right theme_btn btn waves-effect waves-light modal-trigger"
                      >
                        <i className="material-icons">add&nbsp;</i>Create
                        project
                      </a> */}
                </div>
              </div>
              <div className="projects">
                <div className="row">
                  {this.state.isListLoading ? (
                    <BulletList></BulletList>
                  ) : (
                    this.state.projectList.map((project, index) => (
                      <div
                        className="col m4 s12"
                        key={index}
                        onClick={(e) =>
                          this.openProject(e, project.id, project)
                        }
                      >
                        <div className="projects_outer d-flex openproject">
                          <div className="image">
                            {project.file != "" ? (
                              <img
                                src={project.file}
                                alt="Project Thumbnail"
                                className="openproject"
                              />
                            ) : (
                              <img
                                src="assets/images/admin/project-photo.png"
                                alt="Project Thumbnail"
                                className="openproject"
                              />
                            )}
                          </div>
                          <div>
                            <h5 className="openproject">{project.name}</h5>
                            <p>{project.client}</p>
                            <p className="project-label">
                              {project.projectType}
                            </p>
                            <a
                              href="javascript:void(0)"
                              className="actions"
                              node="button"
                              onClick={() => this.editProject(project.id)}
                            >
                              <i className="material-icons">edit</i>
                            </a>
                            {/* <a
                              href="javascript:void(0)"
                              className="actions"
                              onClick={() => this.showAlert(project.id)}
                            >
                              <i className="material-icons">delete</i>
                            </a> */}
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
          </div>

          {this.state.alert && (
            <SweetAlert
              onConfirm={this.deleteProject}
              onCancel={this.hideAlert}
              focusCancelBtn={true}
              warning
              showCancel
              confirmBtnText="Yes, delete it!"
              confirmBtnBsStyle="danger"
              title="Are you sure?"
            />
          )}
          <Footer />
        </section>
      </React.Fragment>
    );
  }
}
