import React, { Component } from "react";
import Footer from "../../components/Footer";
import AdminHeader from "../../components/header/AdminHeader";
import AdminMenu from "../../components/sidebar/AdminMenu";
import axios from "axios";
import { toast } from "react-toastify";
import "materialize-css";
import { Modal } from "react-materialize";
import { getUserData } from "../../helpers/storage";
import SweetAlert from "react-bootstrap-sweetalert";
import ButtonLoader from "../../components/ButtonLoader";
import { BulletList } from "react-content-loader";
import SimpleReactValidator from "simple-react-validator";
import { Helmet } from "react-helmet";
import { RegionDropdown } from "react-country-region-selector";
import Pagination from "rc-pagination";
import Select from "rc-select";
import "rc-select/assets/index.less";
import "../../assets/pagination.less";
import localeInfo from "../../helpers/en_US";

import {
  apiListCustomer,
  apiCreateCustomer,
  apiEditCustomer,
  apiGetCustomerById,
} from "../../config/endpoints";

const initialState = {
  fields: {
    companyname: "",
    address: "",
    gst: "",
    state: "",
    statecode: "",
    contactperson: "",
    phone: "",
    email: "",
  },
  searchFields: {
    companyname: "",
    state: "",
  },
  isLoading: false,
  redirect: false,
  errors: {},
  customerList: [],
  customerCount: 0,
  isModalOpen: false,
  isListLoading: false,
  alert: false,
  deleteId: "",
  editId: false,
  editItem: false,
  customerList: [],
  masterListProjects: [],
  masterListVendor: [],
  pageSize: 30,
  current: 1,
};

export default class Customer extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
  }

  onChangePagination = (page) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        current: page,
      }),
      this.loadMaster
    );
  };

  onChangePageSize = (current, pageSize) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        current: current,
        pageSize: pageSize,
      }),
      this.loadMaster
    );
  };

  onShowSizeChange = (current, pageSize) => {
    console.log(current);
    this.setState({ pageSize });
  };

  clearFilter = () => {
    this.myFormRef.reset();
    //this.setState(this.initialState, this.loadMasterData);
    this.setState(
      {
        searchFields: {
          name: "",
          type: "",
        },
      },
      this.loadMaster
    );
  };

  handleCloseModal = () => {
    this.setState(initialState);
    this.loadMaster();
  };

  handleOpenModal = () => {
    //console.log("modal open");
    this.setState({ isModalOpen: true });
    this.validator.hideMessages();
    this.loadMaster();
  };

  editCustomer(id) {
    this.setState({ editItem: true, editId: id });
    this.handleOpenModal();
    let userData = getUserData();
    fetch(apiGetCustomerById + "?id=" + id, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          this.setState({
            fields: data.data.customer,
          });
        } else {
          alert("something went wrong.");
        }
      });
  }

  showAlert = (id) => {
    //console.log(id);
    this.setState({
      alert: true,
      deleteId: id,
    });
  };

  hideAlert = () => {
    this.setState({
      alert: false,
    });
  };

  loadMaster = (e) => {
    let userData = getUserData();
    this.setState({ isListLoading: true });
    fetch(
      apiListCustomer +
        "?page=" +
        this.state.current +
        "&pagesize=" +
        this.state.pageSize,
      {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + userData.token,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          //console.log("Customer list", data.data.customer.rows);
          this.setState({
            customerList: data.data.customer.rows,
            customerCount: data.data.customer.count,
            isListLoading: false,
          });
        } else {
          alert("something went wrong.");
        }
      });
  };

  handleChange = (e) => {
    this.validator.showMessageFor(e.target.name);
    const value = e.target.value;
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        [e.target.name]: value,
      },
    }));
  };

  handleSearch = (e) => {
    this.validator.showMessageFor(e.target.name);
    const value = e.target.value;
    this.setState((prevState) => ({
      searchFields: {
        ...prevState.searchFields,
        [e.target.name]: value,
      },
    }));
  };

  submitSearch = (e) => {
    e.preventDefault();
    let userData = getUserData();
    this.setState({ isLoading: true });
    //this.setState({ filteredData: true });
    this.setState({ isListLoading: true });
    fetch(
      apiListCustomer +
        "?page=" +
        this.state.current +
        "&pagesize=" +
        this.state.pageSize +
        "&companyname=" +
        this.state.searchFields.companyname +
        "&state=" +
        this.state.searchFields.state,
      {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + userData.token,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          //console.log("Customer list", data.data.customer.rows);
          this.setState({
            customerList: data.data.customer.rows,
            customerCount: data.data.customer.count,
            isListLoading: false,
          });
        } else {
          alert("something went wrong.");
        }
      });
  };

  selectRegion(val) {
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        state: val,
      },
    }));
  }

  selectSearchRegion(val) {
    this.setState((prevState) => ({
      searchFields: {
        ...prevState.searchFields,
        state: val,
      },
    }));
  }

  handleBlur = (e) => {
    this.validator.showMessageFor(e.target.name);
  };

  submitForm = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      let userData = getUserData();
      if (!this.state.editItem) {
        let data = {
          companyname: this.state.fields.companyname,
          address: this.state.fields.address,
          gst: this.state.fields.gst,
          state: this.state.fields.state,
          statecode: this.state.fields.statecode,
          contactperson: this.state.fields.contactperson,
          phone: this.state.fields.phone,
          email: this.state.fields.email,
        };

        this.setState({ isLoading: true });
        axios
          .post(apiCreateCustomer, data, {
            headers: {
              Authorization: "Bearer " + userData.token,
            },
          })
          .then(
            (response) => {
              toast.info("Customer created", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
              this.setState(this.initialState);
              this.handleCloseModal();
            },
            (error) => {
              if (
                error.response.status == 500 ||
                error.response.status == 400
              ) {
                toast.error(error.response.data.errorMessage, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                this.setState({ isLoading: false });
              }
            }
          );
      } else {
        let data = {
          companyname: this.state.fields.companyname,
          address: this.state.fields.address,
          gst: this.state.fields.gst,
          state: this.state.fields.state,
          statecode: this.state.fields.statecode,
          contactperson: this.state.fields.contactperson,
          phone: this.state.fields.phone,
          email: this.state.fields.email,
          id: this.state.editId,
        };
        this.setState({ isLoading: true });
        axios
          .post(apiEditCustomer, data, {
            headers: {
              Authorization: "Bearer " + userData.token,
            },
          })
          .then(
            (response) => {
              //console.log(response);
              // this.setState({ isLoading: false });
              toast.info("Customer updated", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
              this.setState(this.initialState);
              this.handleCloseModal();
            },
            (error) => {
              if (
                error.response.status == 500 ||
                error.response.status == 400
              ) {
                toast.error(error.response.data.errorMessage, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                this.setState({ isLoading: false });
              }
            }
          );
      }
    } else {
      console.log("fail");
      console.log("errors", this.validator.getErrorMessages());
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  componentDidMount() {
    this.loadMaster();
  }

  render() {
    this.validator.purgeFields();
    return (
      <React.Fragment>
        <Helmet>
          <title>Customer | MRC</title>
        </Helmet>
        <section className="admin">
          <AdminHeader></AdminHeader>
          <div className="wrapper d-flex">
            <AdminMenu></AdminMenu>

            <div className="wrapper_body">
              <div className="row d-flex align-items-center">
                <div className="col m0 d-flex align-items-center">
                  <h3 className="heading">Customers</h3>
                </div>
                <div className="col m4 right-align">
                  <a
                    href="javascript:void(0)"
                    className="theme_btn btn  modal-trigger"
                    onClick={this.handleOpenModal}
                  >
                    <i className="material-icons">add</i> Create Customer
                  </a>
                  <Modal
                    actions={[,]}
                    bottomSheet={false}
                    fixedFooter={false}
                    id="Modal-0"
                    open={this.state.isModalOpen}
                    className="cstm-modal"
                    options={{
                      dismissible: false,
                      endingTop: "10%",
                      inDuration: 250,
                      onCloseEnd: null,
                      onCloseStart: null,
                      onOpenEnd: null,
                      onOpenStart: null,
                      opacity: 0.5,
                      outDuration: 250,
                      preventScrolling: true,
                      startingTop: "4%",
                    }}
                  >
                    {/* Close Modal */}
                    <a
                      href="javascript:void(0)"
                      className="modal-close"
                      onClick={this.handleCloseModal}
                    >
                      <span className="material-icons">chevron_right</span>
                    </a>

                    <h3 className="heading">
                      {this.state.editItem
                        ? "Update Daily Income"
                        : "Daily Income Form"}
                    </h3>
                    <p>
                      Enter details to{" "}
                      {this.state.editItem ? "update" : "create new"} Payment
                    </p>
                    <form action id="createContractorForm">
                      <div className="row">
                        <div className="col s12">
                          <label className="label">Company Name</label>
                          <input
                            type="text"
                            placeholder="Company name"
                            name="companyname"
                            value={this.state.fields.companyname}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                          />
                          <label className="error">
                            {this.validator.message(
                              "companyname",
                              this.state.fields.companyname,
                              "required"
                            )}
                          </label>
                        </div>

                        <div className="col s12">
                          <label className="label">Contact Person</label>
                          <input
                            type="text"
                            placeholder="Contact Person"
                            name="contactperson"
                            value={this.state.fields.contactperson}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                          />
                          <label className="error">
                            {this.validator.message(
                              "contactperson",
                              this.state.fields.contactperson,
                              "required"
                            )}
                          </label>
                        </div>

                        <div className="col s12">
                          <label className="label">Email</label>
                          <input
                            type="text"
                            placeholder="Email"
                            name="email"
                            value={this.state.fields.email}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                          />
                          <label className="error">
                            {this.validator.message(
                              "email",
                              this.state.fields.email,
                              "required"
                            )}
                          </label>
                        </div>

                        <div className="col s12">
                          <label className="label">Address</label>
                          <input
                            type="text"
                            placeholder="Address"
                            name="address"
                            value={this.state.fields.address}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                          />
                          <label className="error">
                            {this.validator.message(
                              "address",
                              this.state.fields.address,
                              "required"
                            )}
                          </label>
                        </div>

                        <div className="col s12">
                          <label className="label">State</label>

                          <RegionDropdown
                            country="India"
                            name="state"
                            value={this.state.fields.state}
                            onChange={(val) => this.selectRegion(val)}
                            defaultOptionLabel="Select State"
                          />
                          <label className="error">
                            {this.validator.message(
                              "state",
                              this.state.fields.state,
                              "required"
                            )}
                          </label>
                        </div>
                        <div className="col s12">
                          <label className="label">State code</label>
                          <input
                            type="text"
                            placeholder="State code"
                            name="statecode"
                            value={this.state.fields.statecode}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                          />
                          <label className="error">
                            {this.validator.message(
                              "statecode",
                              this.state.fields.statecode,
                              "required"
                            )}
                          </label>
                        </div>

                        <div className="col s12">
                          <label className="label">GST</label>
                          <input
                            type="text"
                            placeholder="GST"
                            name="gst"
                            value={this.state.fields.gst}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                          />
                          <label className="error">
                            {this.validator.message(
                              "gst",
                              this.state.fields.gst,
                              "required"
                            )}
                          </label>
                        </div>

                        <div className="col s12">
                          <label className="label">Phone</label>
                          <input
                            type="text"
                            placeholder="phone"
                            name="phone"
                            value={this.state.fields.phone}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                          />
                          <label className="error">
                            {this.validator.message(
                              "phone",
                              this.state.fields.phone,
                              "required"
                            )}
                          </label>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col s12 right-align">
                          <button
                            type="submit"
                            form="createContractorForm"
                            className="theme_btn btn"
                            onClick={this.submitForm}
                          >
                            {this.state.editItem
                              ? "Update Customer"
                              : "Add Customer"}
                            {this.state.isLoading ? (
                              <ButtonLoader></ButtonLoader>
                            ) : null}
                          </button>
                        </div>
                      </div>
                    </form>
                  </Modal>
                </div>
              </div>
              <div className="projects employee">
                <div className="row">
                  <div className="col s12">
                    <p className="grey-text">
                      <b>Filters</b>
                    </p>
                    <form ref={(ele) => (this.myFormRef = ele)}>
                      <div className="row">
                        <div className="col m3 s12">
                          <input
                            type="text"
                            className=""
                            placeholder="Name"
                            name="companyname"
                            onChange={this.handleSearch}
                          />
                        </div>

                        <div className="col m2 s12">
                          <RegionDropdown
                            country="India"
                            name="state"
                            value={this.state.searchFields.state}
                            onChange={(val) => this.selectSearchRegion(val)}
                            defaultOptionLabel="Select State"
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div class="col m3 s12">
                          <a
                            href="javascript:void(0)"
                            class="red-text pt-1"
                            onClick={this.clearFilter}
                          >
                            Clear Filter
                          </a>

                          <button
                            type="submit"
                            className="theme_btn btn filter-submit"
                            onClick={this.submitSearch}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="row">
                  <div className="col s12">
                    <div className="table-responsive">
                      <table>
                        <thead>
                          <tr>
                            <th>Company Name</th>
                            <th>Contact Person</th>
                            <th>Email</th>
                            <th>GST</th>
                            <th>Address</th>
                            <th>State</th>
                            <th>State Code</th>
                            <th>Phone</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.isListLoading ? (
                            <tr>
                              <td colSpan={5}>
                                <BulletList></BulletList>
                              </td>
                            </tr>
                          ) : (
                            this.state.customerList.map((customer, index) => (
                              <tr key={index}>
                                <td>{customer.companyname} </td>
                                <td>{customer.contactperson} </td>
                                <td>{customer.email} </td>
                                <td>{customer.gst} </td>
                                <td>{customer.address} </td>
                                <td>{customer.state}</td>
                                <td>{customer.statecode} </td>
                                <td>{customer.phone} </td>
                                <td>
                                  <a
                                    href="#Modal-0"
                                    className="actions"
                                    node="button"
                                    onClick={() =>
                                      this.editCustomer(customer.id)
                                    }
                                  >
                                    <i className="material-icons">edit</i>
                                  </a>
                                  {/* <a
                                    href='javascript:void(0)'
                                    className='actions'
                                    onClick={() => this.showAlert(vendor.id)}
                                  >
                                    <i className='material-icons'>delete</i>
                                  </a> */}
                                </td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div
                    className="col s12"
                    style={{ marginTop: 20, marginBottom: 20 }}
                  >
                    <Pagination
                      pageSize={this.state.pageSize}
                      onChange={this.onChangePagination}
                      current={this.state.current}
                      total={this.state.customerCount}
                      showSizeChanger
                      defaultPageSize={this.state.pageSize}
                      defaultCurrent={this.state.current}
                      onShowSizeChange={this.onChangePageSize}
                      locale={localeInfo}
                      selectComponentClass={Select}
                      pageSizeOptions={[10, 30, 50, 100]}
                      showTotal={(total, range) =>
                        `${range[0]} - ${range[1]} of ${total} items`
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </section>
        {/* Add Contractor Modal */}
      </React.Fragment>
    );
  }
}
