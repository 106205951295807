import React, { Component } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Footer from "../../components/Footer";
import ProjectHeader from "../../components/header/ProjectHeader";
import Sidebar from "../../components/sidebar/Sidebar";
import ProjectMenu from "../../components/sidebar/ProjectMenu";
import AdminHeader from "../../components/header/AdminHeader";
import AdminMenu from "../../components/sidebar/AdminMenu";
import { Link } from "react-router-dom";
import { getUserData, getProjectID } from "../../helpers/storage";

import { formatAmount } from "../../helpers/util";
import SweetAlert from "react-bootstrap-sweetalert";
import { BulletList } from "react-content-loader";
import SimpleReactValidator from "simple-react-validator";
import { Helmet } from "react-helmet";
import { apiListInvoice } from "../../config/endpoints";
import Pagination from "rc-pagination";
import Select from "rc-select";
import "rc-select/assets/index.less";
import "../../assets/pagination.less";
import localeInfo from "../../helpers/en_US";
import Moment from "react-moment";
const initialState = {
  fields: {
    time: "",
    date: "",
    project: "",
    head: "",
    subhead: "",
    vendor: "",
    billno: "",
    amount: "",
    paymentmode: "",
    remarks: "",
    status: "",
  },
  isLoading: false,
  redirect: false,
  errors: {},
  expenseHeadList: [],
  isModalOpen: false,
  isViewModalOpen: false,
  isListLoading: false,
  alert: false,
  deleteId: "",
  editId: false,
  editItem: false,
  expenseHeadParentsList: [],
  expenseSubHeadList: [],
  vendorList: [],
  invoiceList: [],
  invoiceCount: "",
  logs: [],
  bill: null,
  user: [],
  userPermission: [],
  cashInHand: "",
  current: 1,
  pageSize: 30,
};

export default class Invoice extends Component {
  constructor(props) {
    super(props);

    this.state = initialState;
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(date) {
    this.setState({
      startDate: date,
    });
  }

  handleSearch = (e) => {
    this.validator.showMessageFor(e.target.name);
    const value = e.target.value;
    /* this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        [e.target.name]: value,
      },
    })); */
  };

  /* handleCloseModal = () => {
    this.setState(initialState);
    //this.loadMasterData();
    this.validator.hideMessages();
  };
  handleOpenModal = () => {
    //console.log("hey");
    //this.loadMasterData();
    this.setState({ isModalOpen: true });
    this.validator.hideMessages();
  }; */

  loadMaster = (e) => {
    let userData = getUserData();
    let projectId = getProjectID();
    this.setState({ isListLoading: true });
    fetch(
      apiListInvoice +
        "?page=" +
        this.state.current +
        "&pagesize=" +
        this.state.pageSize,
      {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + userData.token,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          console.log("Invoice list", data.data.invoice.rows);
          this.setState({
            invoiceList: data.data.invoice.rows,
            invoiceCount: data.data.invoice.count,
            isListLoading: false,
          });
        } else {
          alert("something went wrong.");
        }
      });
  };

  onChangePagination = (page) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        current: page,
      }),
      this.loadMaster
      //this.submitSearch
      //this.loadDataForPagination
    );
  };
  onChangePageSize = (current, pageSize) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        current: current,
        pageSize: pageSize,
      }),
      this.loadMasterData
    );
  };

  onShowSizeChange = (current, pageSize) => {
    console.log(current);
    this.setState({ pageSize });
  };

  componentDidMount() {
    this.loadMaster();
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Invoice | MRC</title>
        </Helmet>

        <section className="admin">
          <AdminHeader></AdminHeader>
          <div className="wrapper d-flex">
            <AdminMenu></AdminMenu>

            <div className="wrapper_body">
              <div className="row">
                <div className="col m8 s12">
                  <h3 className="heading">Invoice</h3>
                </div>
                <div className="col m4 s12 right-align">
                  <Link to="/createinvoice" className="theme_btn btn ">
                    <i className="material-icons">add&nbsp;</i>Create New
                    Invoice
                  </Link>
                </div>
              </div>
              <div className="row">
                <div className="col s12">
                  <p className="grey-text">
                    <b>Filters</b>
                  </p>
                  <form /* ref={(ele) => (this.myFormRef = ele)} */>
                    <div className="row">
                      <div className="col m3 s12">
                        <label>Invoice Number</label>
                        <input
                          type="text"
                          className=""
                          placeholder="Invoice Number"
                          name="invoice"
                          /* onChange={this.handleSearch} */
                        />
                      </div>

                      <div className="col m3 s12">
                        <label>Customer</label>
                        <input
                          type="text"
                          className=""
                          placeholder="Customer Name"
                          name="customer"
                          /* onChange={this.handleSearch} */
                        />
                      </div>
                      <div className="col m3 s12">
                        <label>From Date</label>
                        <input
                          type="date"
                          className=""
                          placeholder="From Date"
                          name="from"
                          /* onChange={this.handleSearch} */
                        />
                      </div>

                      <div className="col m3 s12">
                        <label>To Date</label>
                        <input
                          type="date"
                          className=""
                          placeholder="To Date"
                          name="to"
                          /* onChange={this.handleSearch} */
                        />
                      </div>
                      <div className="col m2 s12">
                        <label>Payment Mode</label>
                        <select
                          name="paymentmode"
                          /* onChange={this.handleSearch} */
                        >
                          <option selected>Payment</option>
                          <option value={1}>Cash</option>
                          <option value={2}>Bank</option>
                        </select>
                      </div>
                    </div>

                    <div className="row">
                      {/* <a href="#!" className="red-text pt-1 d-flex">Clear Filter</a> */}
                      {/* <button type="submit" className="red-text pt-1">Clear Filter</button> */}
                      <div class="col m3 s12">
                        <a
                          href="javascript:Void(0)"
                          class="red-text pt-1"
                          /* onClick={this.clearFilter} */
                        >
                          Clear Filter
                        </a>

                        <button
                          type="submit"
                          className="theme_btn btn filter-submit"
                          /* onClick={this.submitSearch} */
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>

                <div className="col s12">
                  <div className="table-responsive">
                    <table>
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Invoice No.</th>
                          <th>Company Name</th>
                          <th>Contact Person</th>
                          <th>GST</th>
                          <th>Total</th>
                          <th>Total Tax</th>
                          <th>Grand Total</th>
                          <th>Address</th>
                          <th>State</th>
                          <th>State Code</th>
                          <th>Download</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.isListLoading ? (
                          <tr>
                            <td colSpan={5}>
                              <BulletList></BulletList>
                            </td>
                          </tr>
                        ) : (
                          this.state.invoiceList.map((invoice, index) => (
                            <tr key={index}>
                              <td>{invoice.date}</td>
                              <td>{invoice.invoiceno}</td>
                              <td>
                                {invoice.customerDetails
                                  ? invoice.customerDetails.companyname
                                  : ""}
                              </td>
                              <td>
                                {invoice.customerDetails
                                  ? invoice.customerDetails.contactperson
                                  : ""}
                              </td>
                              <td>{invoice.shipgst}</td>
                              <td>
                                {" "}
                                <i class="fas fa-rupee-sign"></i>{" "}
                                {formatAmount(invoice.total)}
                              </td>
                              <td>
                                {" "}
                                <i class="fas fa-rupee-sign"></i>{" "}
                                {formatAmount(invoice.totaltax)}
                              </td>
                              <td>
                                {" "}
                                <i class="fas fa-rupee-sign"></i>{" "}
                                {formatAmount(invoice.grandtotal)}
                              </td>
                              <td>{invoice.shipaddress}</td>
                              <td>{invoice.shipstate}</td>
                              <td>{invoice.shipstatecode}</td>
                              <td>
                                <a
                                  href={
                                    process.env.REACT_APP_API_ENDPOINT +
                                    "public/invoice/generatePdf?preview=true&id=" +
                                    invoice.id
                                  }
                                  target="_blank"
                                >
                                  <span class="material-icons">download</span>
                                </a>
                              </td>
                              <td align="center">
                                <Link
                                  to={"/editinvoice/" + invoice.id}
                                  className="actions"
                                >
                                  <i className="material-icons">edit</i>
                                </Link>
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div
                  className="col s12"
                  style={{ marginTop: 20, marginBottom: 20 }}
                >
                  <Pagination
                    pageSize={this.state.pageSize}
                    onChange={this.onChangePagination}
                    current={this.state.current}
                    total={this.state.invoiceCount}
                    showSizeChanger
                    defaultPageSize={this.state.pageSize}
                    defaultCurrent={this.state.current}
                    onShowSizeChange={this.onChangePageSize}
                    locale={localeInfo}
                    selectComponentClass={Select}
                    pageSizeOptions={[10, 30, 50, 100]}
                    showTotal={(total, range) =>
                      `${range[0]} - ${range[1]} of ${total} items`
                    }
                  />
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </section>
        {/* Create Invoice Modal */}
        <div
          id="create_invoice_modal"
          className="new-list-modal cstm-modal modal"
        >
          {/* Close Modal */}
          <a href="javascript:Void(0)" className="modal-close">
            <span className="material-icons">chevron_right</span>
          </a>
          {/* Modal Content */}
          <h3 className="heading">Create Invoice</h3>
          <p>Enter details to create new invoice</p>
          <form action id="createInvoiceForm">
            <div className="row">
              <div className="col s12">
                <legend>Invoice No.</legend>
                <input
                  type="text"
                  placeholder="Invoice No."
                  name="Invoice No."
                  required
                />
              </div>
              <div className="col s12 ">
                <legend>Company GST</legend>
                <input
                  type="text"
                  placeholder="Company GST"
                  name="Company GST"
                  required
                />
              </div>
              <div className="col s12">
                <legend>Company Name</legend>
                <input
                  type="text"
                  placeholder="Company Name"
                  name="Company Name"
                  required
                />
              </div>
              <div className="col m6 s12">
                <legend>Invoice Date</legend>
                <DatePicker
                  selected={this.state.startDate}
                  onChange={this.handleChange}
                  dateFormat="MMMM d, yyyy"
                  className="form-control"
                />
              </div>
              <div className="col m6 s12">
                <legend>Due Date</legend>
                <DatePicker
                  selected={this.state.startDate}
                  onChange={this.handleChange}
                  dateFormat="MMMM d, yyyy"
                  className="form-control"
                />
              </div>
              <div className="col  s12">
                <legend>Amount</legend>
                <input type="text" placeholder="Amount" name="Amount" />
                <span className="icon">
                  <i />
                </span>
              </div>
              <div className="col s12 right-align">
                <button
                  type="submit"
                  form="createInvoiceForm"
                  className="theme_btn btn waves-effect waves-light"
                >
                  Create Invoice<i className="material-icons">&nbsp;add</i>
                </button>
              </div>
            </div>
          </form>
        </div>
      </>
    );
  }
}
