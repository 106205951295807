import React, { Component } from "react";
import { Link, Redirect, useLocation } from "react-router-dom";
import { logoutUser, getUserData } from "../../helpers/storage";
import "materialize-css";
import { Dropdown, Button, Divider, Icon } from "react-materialize";

export default class Sidebar extends Component {
  constructor(props) {
    super(props);
    let userData = getUserData();
    this.state = {
      redirect: false,
      user: userData.user,
      additionalData: userData.additionalData,
    };
  }
  componentWillMount() {}
  render() {
    return (
      <div className="side-bar">
        <Link
          to="/projects"
          className="tooltipped back_icon"
          data-position="right"
          data-tooltip="Back to admin panel"
        >
          <i className="material-icons">arrow_back_ios</i>
        </Link>
        <ul>
          {/* <li>
            <a
              href="/dashboard"
              className="tooltipped"
              data-position="right"
              data-tooltip="Dashboard"
            >
              <img
                src={
                  process.env.REACT_APP_APP_URL +
                  "/assets/images/admin/dashboard_icon.svg"
                }
              />
            </a>
          </li> */}
          <li>
            <a
              href="#"
              className="tooltipped"
              data-position="right"
              data-tooltip="Accounting"
            >
              <img
                src={
                  process.env.REACT_APP_APP_URL +
                  "/assets/images/admin/accounting_icon.svg"
                }
              />
            </a>
          </li>
          {/* <li>
            <a
              href="project-setup.html"
              className="tooltipped"
              data-position="right"
              data-tooltip="Settings"
            >
              <img
                src={
                  process.env.REACT_APP_APP_URL +
                  "/assets/images/admin/setting_icon.svg"
                }
              />
            </a>
          </li> */}
        </ul>
      </div>
    );
  }
}
