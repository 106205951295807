import React, { Component, Fragment } from "react";
import Footer from "../../components/Footer";
import AdminHeader from "../../components/header/AdminHeader";
import AdminMenu from "../../components/sidebar/AdminMenu";
import ButtonLoader from "../../components/ButtonLoader";
import axios from "axios";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Moment from "react-moment";
import "materialize-css";
import { Modal, Badge } from "react-materialize";
import { getUserData } from "../../helpers/storage";
import SweetAlert from "react-bootstrap-sweetalert";
import SimpleReactValidator from "simple-react-validator";
import Pagination from "rc-pagination";
import Select from "rc-select";
import "rc-select/assets/index.less";
import "../../assets/pagination.less";
import localeInfo from "../../helpers/en_US";
import { Collapsible, CollapsibleItem } from "react-materialize";

import {
  apiGetPoByNumber,
  apiListPoReceving,
  apiCreatePoReceving,
  apiListPo,
  apiGetPo,
  apiGetMasterByType,
} from "../../config/endpoints";
import { Helmet } from "react-helmet";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const initialState = {
  fields: {},
  weightofload: 0,
  loadedvehicleweight: 0,
  emptyvehicleweight: 0,
  vehicleno: "",
  invoiceno: "",
  invoicedate: "",
  invoiceissue: "",
  ewaybill: "",
  ewaybillno: "",
  mtc: "",
  mtcdate: "",
  wbm: "",
  loadedvehicleweight: "",
  loadedvehicleunit: "",
  emptyvehicleweight: "",
  emptyvehicleweightunit: "",
  weightofload: "",
  weightofloadunit: "",
  inputFields: [
    {
      particulars: "",
      hsn: "",
      qty: 1,
      gst: "",
      amount: 0,
      total: 0,
      tax: "",
    },
  ],
  ponumber: 0,
  date: "",
  invoiceno: "",
  remarks: "",
  ewaybill: false,
  mtc: false,
  selectedPo: null,
  selectedPoParticulars: [],
  totalItemsReceived: [],
  itemsReceived: [],
  poRecevingList: [],
  poList: [],
  unitMasterList: [],
  subtotal: 0,
  grandtotalFinal: 0,
  isLoading: false,
  redirect: false,
  errors: {},
  isModalOpen: false,
  isListLoading: false,
  alert: false,
  deleteId: "",
  editId: false,
  editItem: false,
  passId: false,
  pageSize: 30,
  current: 1,
};

let itemsReceived = [];
let totalItemsReceived = [];

export default class PoReceiving extends Component {
  sigPad = {};
  constructor(props) {
    super(props);
    this.state = initialState;
    this.selectInputRef = React.createRef();
    this.projectReset = this.projectReset.bind(this);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.validator1 = new SimpleReactValidator({ autoForceUpdate: this });
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  loadPoReceiving = (ponumber) => {
    let userData = getUserData();
    fetch(apiListPoReceving + "?ponumber=" + ponumber, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          this.setState({
            poRecevingList: data.data.po.rows,
          });
          if (data.data.po.count > 0) {
            this.handleTotalReceived();
          }
        } else {
          alert("something went wrong.");
        }
      });
  };

  componentDidMount() {
    this.loadPoDetails();
    //console.log("componentDidMount", this.state.fields.projects);
  }

  loadPoDetails = () => {
    let userData = getUserData();
    const str = window.location.pathname;
    const poId = str.substring(str.lastIndexOf("/") + 1, str.length);
    fetch(apiGetPo + "?id=" + poId, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          // console.log(data.data.vendors);
          this.setState((prevState) => ({
            selectedPo: data.data.po,
            selectedPoParticulars: data.data.po
              ? JSON.parse(data.data.po.particulars)
              : [],
          }));
          this.setState({ isLoading: false });
          console.log(this.state.selectedPo);
          this.loadPoReceiving(data.data.po.ponumber);
        } else {
          alert("something went wrong.");
          this.setState({ isLoading: false });
        }
      });
    fetch(apiGetMasterByType + "?type=item_unit", {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          this.setState({
            unitMasterList: data.data.masters,
          });
        } else {
          alert("something went wrong.");
        }
      });
  };

  handleCloseModal = () => {
    this.setState(initialState);
    this.projectReset();
  };

  handleOpenModal = () => {
    this.setState({ isModalOpen: true });
    this.validator.hideMessages();
    //console.log("model open", this.state);
  };

  projectReset = () => {
    // this.selectInputRef.current.select.clearValue();
  };

  showAlert = (id) => {
    //console.log(id);
    this.setState({
      alert: true,
      deleteId: id,
    });
  };

  setLocation = (e) => {
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        address: e,
      },
    }));
  };

  hideAlert = () => {
    this.setState({
      alert: false,
    });
  };

  handleChange = (e) => {
    this.validator.showMessageFor(e.target.name);
    const value = e.target.value;
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        [e.target.name]: value,
      },
    }));
  };

  handleChangeWeight = (e) => {
    this.validator.showMessageFor(e.target.name);
    const value = e.target.value;
    this.setState(
      (prevState) => ({
        [e.target.name]: value,
      }),
      () => {
        let weight =
          this.state.loadedvehicleweight - this.state.emptyvehicleweight;
        this.setState((prevState) => ({
          weightofload: weight,
        }));
      }
    );
  };

  handleBlur = (e) => {
    this.validator.showMessageFor(e.target.name);
  };

  handleReceiving = (index, item, e) => {
    console.log(e.target.value);
    console.log(item);
    console.log(index);
    itemsReceived[index] = item;
    itemsReceived[index].received = e.target.value;
    console.log("items", itemsReceived);
  };

  handleTotalReceived = () => {
    let receviedList = this.state.poRecevingList;

    Object.keys(receviedList).forEach(async function (key) {
      console.log(key, receviedList[key]);
    });

    var holder = {};
    var holderQty = {};
    console.log("selected", this.state.selectedPoParticulars);
    var selectedPoParticulars = this.state.selectedPoParticulars;

    receviedList.forEach(function (d) {
      if (holder.hasOwnProperty(d.itemid)) {
        holder[d.itemid] = parseInt(holder[d.itemid]) + parseInt(d.qty);
      } else {
        holder[d.itemid] = d.qty;
      }
    });

    for (var prop in holder) {
      const myKey = Object.keys(selectedPoParticulars).find(
        (x) => selectedPoParticulars[x].item === parseInt(prop)
      );
      console.log("pro", prop);
      console.log("key");
      let qty = selectedPoParticulars[myKey].qty;
      let pending = qty - holder[prop];
      totalItemsReceived[prop] = { received: holder[prop], pending: pending };
      // totalItemsReceived.push({ itemid: prop, value: holder[prop] });
    }
    this.setState({ totalItemsReceived: totalItemsReceived });
    console.log("totalItemsReceived", totalItemsReceived);
  };

  handleChange = (e) => {
    this.validator.showMessageFor(e.target.name);
    const value = e.target.value;
    this.setState((prevState) => ({
      [e.target.name]: value,
    }));
  };

  fileChange = (e) => {
    console.log(e.target.name, e.target.files[0].name);
    if (e.target.files[0]) {
      this.setState({ [e.target.name]: e.target.files[0] });
    }
    //console.log("file change", this.state);
  };

  handleCheckboxChange = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      let userData = getUserData();
      this.setState({ isLoading: true });
      // let data = {
      //   ponumber: this.state.selectedPo.ponumber,
      //   poid: this.state.selectedPo.id,
      //   particulars: JSON.stringify(itemsReceived),
      //   date: this.state.date,
      //   invoiceno: this.state.invoiceno,
      //   remarks: this.state.remarks,
      // };
      var bodyFormData = new FormData();
      bodyFormData.append("ponumber", this.state.selectedPo.ponumber);
      bodyFormData.append("particulars", JSON.stringify(itemsReceived));
      bodyFormData.append("poid", this.state.selectedPo.id);
      bodyFormData.append("date", this.state.date);
      bodyFormData.append("vehicleno", this.state.vehicleno);
      bodyFormData.append("vehiclephoto", this.state.vehiclephoto);
      bodyFormData.append("invoiceno", this.state.invoiceno);
      bodyFormData.append("invoicedate", this.state.invoicedate);
      bodyFormData.append("invoicephoto", this.state.invoicephoto);
      bodyFormData.append("invoiceissue", this.state.invoiceissue);
      bodyFormData.append("remarks", this.state.remarks);
      bodyFormData.append("ewaybill", this.state.ewaybill);
      bodyFormData.append("ewaybillno", this.state.ewaybillno);
      bodyFormData.append("ewaybilldate", this.state.ewaybilldate);
      bodyFormData.append("ewaybillphoto", this.state.ewaybillphoto);
      bodyFormData.append("mtc", this.state.mtc);
      bodyFormData.append("mtcdate", this.state.mtcdate);
      bodyFormData.append("mtcphoto", this.state.mtcphoto);
      bodyFormData.append("wbm", this.state.wbm);
      bodyFormData.append(
        "loadedvehicleweight",
        this.state.loadedvehicleweight
      );
      bodyFormData.append("loadedvehicleunit", this.state.loadedvehicleunit);
      bodyFormData.append("loadedvehiclephoto", this.state.loadedvehiclephoto);
      bodyFormData.append("emptyvehicleweight", this.state.emptyvehicleweight);

      bodyFormData.append(
        "emptyvehicleweightunit",
        this.state.emptyvehicleweightunit
      );

      bodyFormData.append(
        "emptyvehicleweightphoto",
        this.state.emptyvehicleweightphoto
      );
      bodyFormData.append("weightofload", this.state.weightofload);
      bodyFormData.append("weightofloadunit", this.state.weightofloadunit);
      bodyFormData.append("weightofloadphoto", this.state.weightofloadphoto);

      axios
        .post(apiCreatePoReceving, bodyFormData, {
          headers: {
            Authorization: "Bearer " + userData.token,
          },
        })
        .then(
          (response) => {
            //console.log(response);
            this.setState({ isLoading: false });
            toast.info("Receiving created", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
            // this.setState(this.initialState);
            this.loadPoReceiving(this.state.selectedPo.ponumber);
          },
          (error) => {
            if (error.response.status == 500 || error.response.status == 400) {
              toast.error(error.response.data.errorMessage, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              this.setState({ isLoading: false });
            }
          }
        );
    } else {
      /* console.log("fail");
      console.log("errors", this.validator.getErrorMessages()); */
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  onSelect = () => {};

  render() {
    this.validator.purgeFields();

    return (
      <React.Fragment>
        <Helmet>
          <title>Po Receiving | MRC</title>
        </Helmet>
        <section className="admin">
          <AdminHeader></AdminHeader>
          <div className="wrapper d-flex">
            <AdminMenu></AdminMenu>

            <div className="wrapper_body">
              <div className="row d-flex align-items-center">
                <div className="col m0 d-flex align-items-center">
                  <h3 className="heading">Material Receiving</h3>
                </div>
              </div>

              {this.state.selectedPo ? (
                <>
                  <Collapsible accordion>
                    <>
                      <CollapsibleItem
                        //   expanded
                        header={`PO Details For ${this.state.selectedPo.ponumber}`}
                        //   icon={<Icon>filter_drama</Icon>}
                        node="div"
                        onSelect={this.onSelect}
                      >
                        <div className="table-responsive">
                          <table>
                            <thead>
                              <tr>
                                <th>PO Details</th>
                                <th> </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td data-th="PO Number">
                                  <strong>PO Number</strong>
                                </td>
                                <td data-th="PO Number">
                                  <strong>
                                    {this.state.selectedPo.ponumber}
                                  </strong>
                                </td>
                              </tr>
                              <tr>
                                <td data-th="PO Date">
                                  <strong>Date</strong>
                                </td>
                                <td data-th="PO Date">
                                  <strong>{this.state.selectedPo.date}</strong>
                                </td>
                              </tr>
                              <tr>
                                <td data-th="PO Amount">
                                  <strong>Amount</strong>
                                </td>
                                <td data-th="PO Amount">
                                  <strong>
                                    {this.state.selectedPo.grandtotal}
                                  </strong>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <table>
                            <thead>
                              <tr>
                                <th>Vendor Details</th>
                                <th> </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td data-th="Vendor Name">
                                  <strong>Vendor Name</strong>
                                </td>
                                <td data-th="Vendor Name">
                                  <strong>
                                    {this.state.selectedPo.vendorDetails.name}
                                  </strong>
                                </td>
                              </tr>
                              <tr>
                                <td data-th="Vendor Address">
                                  <strong>Address</strong>
                                </td>
                                <td data-th="Vendor Address">
                                  <strong>
                                    {
                                      this.state.selectedPo.vendorDetails
                                        .address
                                    }
                                    <br></br>
                                    <b>State:</b>
                                    {
                                      this.state.selectedPo.vendorDetails.state
                                    },{" "}
                                    {
                                      this.state.selectedPo.vendorDetails
                                        .statecode
                                    }
                                  </strong>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <table>
                            <thead>
                              <tr>
                                <th>Shipping Details</th>
                                <th> </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td data-th="Project Details">
                                  <strong>Project Details</strong>
                                </td>
                                <td data-th="Project Details">
                                  <strong>
                                    {this.state.selectedPo.projectDetails.name}
                                  </strong>
                                </td>
                              </tr>
                              <tr>
                                <td data-th="Project Address">
                                  <strong>Address</strong>
                                </td>
                                <td data-th="Project Address">
                                  <strong>
                                    {
                                      this.state.selectedPo.projectDetails
                                        .address
                                    }
                                    <br></br>
                                    <b>State:</b>
                                    {this.state.selectedPo.projectDetails.state}
                                    ,{" "}
                                    {
                                      this.state.selectedPo.projectDetails
                                        .statecode
                                    }
                                  </strong>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <table>
                            <thead>
                              <tr>
                                <th>Billing Details</th>
                                <th> </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td data-th="Billing Details">
                                  <strong>Billing Details</strong>
                                </td>
                                <td data-th="Billing Details">
                                  <strong>
                                    {this.state.selectedPo.billingDetails.name}
                                  </strong>
                                </td>
                              </tr>
                              <tr>
                                <td data-th="Billing Address">
                                  <strong>Address</strong>
                                </td>
                                <td data-th="Billing Address">
                                  <strong>
                                    {
                                      this.state.selectedPo.billingDetails
                                        .address
                                    }
                                    <br></br>
                                    <b>State:</b>
                                    {this.state.selectedPo.billingDetails.state}
                                    ,{" "}
                                    {
                                      this.state.selectedPo.billingDetails
                                        .statecode
                                    }
                                  </strong>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </CollapsibleItem>
                    </>
                  </Collapsible>

                  <h6>Receiving Entry For {this.state.selectedPo.ponumber}</h6>
                  <div className="row">
                    <div className="col s6">
                      <table className="table-responsive">
                        <tbody>
                          <tr>
                            <td>Date of Receiving</td>
                            <td>
                              <input
                                type="date"
                                name="date"
                                onChange={this.handleChange}
                                value={this.state.date}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Vehicle Number</td>
                            <td>
                              <input
                                type={"text"}
                                name="vehicleno"
                                onChange={this.handleChange}
                                value={this.state.vehicleno}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Vehicle Photo</td>
                            <label className="new-button" htmlFor="upload">
                              Upload Photo
                              <input
                                id="upload"
                                type="file"
                                name="vehiclephoto"
                                onChange={this.fileChange}
                              />
                            </label>
                            <br />
                            {this.state.vehiclephoto ? (
                              <>
                                <span>
                                  {this.state.vehiclephoto.name}
                                  <i class="tiny material-icons">attach_file</i>
                                </span>
                              </>
                            ) : (
                              <></>
                            )}
                          </tr>
                          <tr>
                            <td>Invoice Number</td>
                            <td>
                              <input
                                type={"text"}
                                name="invoiceno"
                                onChange={this.handleChange}
                                value={this.state.invoiceno}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Invoice Date</td>
                            <td>
                              <input
                                type={"date"}
                                name="invoicedate"
                                onChange={this.handleChange}
                                value={this.state.invoicedate}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Invoice Photo</td>
                            <td>
                              <label className="new-button" htmlFor="upload1">
                                Upload Photo
                                <input
                                  id="upload1"
                                  type="file"
                                  name="invoicephoto"
                                  onChange={this.fileChange}
                                />
                              </label>
                              <br />
                              {this.state.invoicephoto ? (
                                <>
                                  <span>
                                    {this.state.invoicephoto.name}
                                    <i class="tiny material-icons">
                                      attach_file
                                    </i>
                                  </span>
                                </>
                              ) : (
                                <></>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>Any Issues in Invoice</td>
                            <td>
                              <input
                                type={"date"}
                                name="invoiceissue"
                                onChange={this.handleChange}
                                value={this.state.invoiceissue}
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>Remarks</td>
                            <td>
                              <textarea
                                name="remarks"
                                onChange={this.handleChange}
                              >
                                {this.state.remarks}
                              </textarea>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="col s6">
                      <table className="table-responsive">
                        <tbody>
                          <tr>
                            <td>E-way Bill</td>
                            <td>
                              <input
                                type="checkbox"
                                name="ewaybill"
                                onChange={this.handleCheckboxChange}
                                checked={this.state.ewaybill}
                                className="simplecheckbox"
                              />
                            </td>
                          </tr>
                          {this.state.ewaybill ? (
                            <>
                              {" "}
                              <tr>
                                <td>E-way Bill Number</td>
                                <td>
                                  <input
                                    type={"text"}
                                    name="ewaybillno"
                                    onChange={this.handleChange}
                                    value={this.state.ewaybillno}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>E-way Bill Date</td>
                                <td>
                                  <input
                                    type={"date"}
                                    name="ewaybilldate"
                                    onChange={this.handleChange}
                                    value={this.state.ewaybilldate}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>E-way Bill Photo</td>
                                <td>
                                  <label
                                    className="new-button"
                                    htmlFor="upload2"
                                  >
                                    Upload Photo
                                    <input
                                      id="upload2"
                                      type="file"
                                      name="ewaybillphoto"
                                      onChange={this.fileChange}
                                    />
                                  </label>
                                  <br />
                                  {this.state.ewaybillphoto ? (
                                    <>
                                      <span>
                                        {this.state.ewaybillphoto.name}
                                        <i class="tiny material-icons">
                                          attach_file
                                        </i>
                                      </span>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </td>
                              </tr>
                            </>
                          ) : (
                            <></>
                          )}

                          <tr>
                            <td>Material Test Certificate</td>
                            <td>
                              <input
                                type="checkbox"
                                name="mtc"
                                onChange={this.handleCheckboxChange}
                                checked={this.state.mtc}
                                className="simplecheckbox"
                              />
                            </td>
                          </tr>
                          {this.state.mtc ? (
                            <>
                              <tr>
                                <td>MTC Date</td>
                                <td>
                                  <input
                                    type="date"
                                    name="mtcdate"
                                    onChange={this.handleChange}
                                    value={this.state.mtcdate}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>MTC Photo</td>
                                <td>
                                  <label
                                    className="new-button"
                                    htmlFor="upload3"
                                  >
                                    Upload Photo
                                    <input
                                      id="upload3"
                                      type="file"
                                      name="mtcphoto"
                                      onChange={this.fileChange}
                                    />
                                  </label>
                                  <br />
                                  {this.state.mtcphoto ? (
                                    <>
                                      <span>
                                        {this.state.mtcphoto.name}
                                        <i class="tiny material-icons">
                                          attach_file
                                        </i>
                                      </span>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </td>
                              </tr>
                            </>
                          ) : (
                            <></>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col s12">
                      <h6>
                        Weigh Bridge Measurements:{" "}
                        <input
                          type="checkbox"
                          name="wbm"
                          onChange={this.handleCheckboxChange}
                          checked={this.state.wbm}
                          className="simplecheckbox"
                        />
                      </h6>

                      {this.state.wbm ? (
                        <>
                          <table className="table-responsive">
                            <tr>
                              <td>Loaded Vehicle Weight</td>
                              <td>
                                <input
                                  type="text"
                                  name="loadedvehicleweight"
                                  onChange={this.handleChangeWeight}
                                  value={this.state.loadedvehicleweight}
                                />
                              </td>
                              <td>
                                <select
                                  name="loadedvehicleunit"
                                  value={this.state.loadedvehicleunit}
                                  onChange={this.handleChange}
                                  onBlur={this.handleBlur}
                                >
                                  <option selected value={""}>
                                    Select
                                  </option>
                                  {this.state.unitMasterList.map(
                                    (master, index) => (
                                      <>
                                        <option value={master.id}>
                                          {master.name}
                                        </option>
                                      </>
                                    )
                                  )}
                                </select>
                              </td>
                              <td>
                                <label className="new-button" htmlFor="upload4">
                                  Upload Photo
                                  <input
                                    id="upload4"
                                    type="file"
                                    name="loadedvehicleweightphoto"
                                    onChange={this.fileChange}
                                  />
                                </label>
                                <br />
                                {this.state.loadedvehicleweightphoto ? (
                                  <>
                                    <span>
                                      {this.state.loadedvehicleweightphoto.name}
                                      <i class="tiny material-icons">
                                        attach_file
                                      </i>
                                    </span>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>Empty Vehicle Weight</td>
                              <td>
                                <input
                                  type="text"
                                  name="emptyvehicleweight"
                                  onChange={this.handleChangeWeight}
                                  value={this.state.emptyvehicleweight}
                                />
                              </td>
                              <td>
                                <select
                                  name="emptyvehicleweightunit"
                                  value={this.state.emptyvehicleweightunit}
                                  onChange={this.handleChange}
                                  onBlur={this.handleBlur}
                                >
                                  <option selected value={""}>
                                    Select
                                  </option>
                                  {this.state.unitMasterList.map(
                                    (master, index) => (
                                      <>
                                        <option value={master.id}>
                                          {master.name}
                                        </option>
                                      </>
                                    )
                                  )}
                                </select>
                              </td>
                              <td>
                                <label className="new-button" htmlFor="upload5">
                                  Upload Photo
                                  <input
                                    id="upload5"
                                    type="file"
                                    name="emptyvehicleweightphoto"
                                    onChange={this.fileChange}
                                  />
                                </label>
                                <br />
                                {this.state.emptyvehicleweightphoto ? (
                                  <>
                                    <span>
                                      {this.state.emptyvehicleweightphoto.name}
                                      <i class="tiny material-icons">
                                        attach_file
                                      </i>
                                    </span>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>Weight of Load</td>
                              <td>{this.state.weightofload}</td>
                              <td>
                                <select
                                  name="weightofloadunit"
                                  value={this.state.fields.weightofloadunit}
                                  onChange={this.handleChange}
                                  onBlur={this.handleBlur}
                                >
                                  <option selected value={""}>
                                    Select
                                  </option>
                                  {this.state.unitMasterList.map(
                                    (master, index) => (
                                      <>
                                        <option value={master.id}>
                                          {master.name}
                                        </option>
                                      </>
                                    )
                                  )}
                                </select>
                              </td>
                              <td>
                                <td>
                                  <label
                                    className="new-button"
                                    htmlFor="upload6"
                                  >
                                    Upload Photo
                                    <input
                                      id="upload6"
                                      type="file"
                                      name="weightofloadphoto"
                                      onChange={this.fileChange}
                                    />
                                  </label>
                                  <br />
                                  {this.state.weightofloadphoto ? (
                                    <>
                                      <span>
                                        {this.state.weightofloadphoto.name}
                                        <i class="tiny material-icons">
                                          attach_file
                                        </i>
                                      </span>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </td>
                              </td>
                            </tr>
                          </table>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table>
                      <thead>
                        <tr>
                          <th style={{ textAlign: "center" }} colSpan={5}>
                            Purchase Order
                          </th>
                          <th style={{ textAlign: "center" }} colSpan={2}>
                            Receiving
                          </th>
                          <th style={{ textAlign: "center" }} colSpan={2}>
                            Records
                          </th>
                        </tr>
                      </thead>
                      <thead>
                        <tr>
                          <th>S.No.</th>
                          <th>Particulars</th>
                          <th>Quantity</th>
                          <th>Rate</th>
                          <th>Amount</th>
                          <th>Quantity</th>
                          <th>Amount</th>
                          <th>Total Received</th>
                          <th>Pending</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.selectedPoParticulars.map((item, index) => (
                          <>
                            <tr>
                              <td>{index + 1}</td>
                              <td>{item.particulars}</td>
                              <td>{item.qty}</td>
                              <td>{item.amount}</td>
                              <td>{item.total}</td>
                              <td>
                                <input
                                  type={"number"}
                                  name=""
                                  //   value={0}
                                  onChange={(e) =>
                                    this.handleReceiving(index, item, e)
                                  }
                                />
                              </td>
                              <td>Amount</td>
                              <td>
                                {this.state.totalItemsReceived[item.item]
                                  ? this.state.totalItemsReceived[item.item]
                                      .received
                                  : ""}
                              </td>
                              <td>
                                {this.state.totalItemsReceived[item.item]
                                  ? this.state.totalItemsReceived[item.item]
                                      .pending
                                  : ""}
                              </td>
                            </tr>
                          </>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <p className="mt-2">
                    <button
                      onClick={this.handleSubmit}
                      className="theme_btn btn"
                    >
                      Save
                    </button>
                  </p>
                </>
              ) : (
                <></>
              )}
              {this.state.poRecevingList.length > 0 ? (
                <>
                  <h6>Recevied List For{this.state.selectedPo.ponumber}</h6>
                  <div className="table-responsive">
                    <table>
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Invoice No</th>
                          <th>Particulars</th>
                          <th>HSN</th>
                          <th>Recevied</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.poRecevingList.map((value, index) => (
                          <>
                            <tr>
                              <td>{value.date}</td>
                              <td>{value.invoiceno}</td>
                              <td>{value.itemDetails.name}</td>
                              <td>{value.itemDetails.hsn}</td>
                              <td>{value.qty}</td>
                            </tr>
                          </>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <Collapsible accordion>
                    {/* {this.state.poRecevingList.map((value, index) => (
                      <>
                        <CollapsibleItem
                          //   expanded
                          header={
                            <Moment format="DD-MM-YYYY HH:mm">
                              {value.createdAt}
                            </Moment>
                          }
                          //   icon={<Icon>filter_drama</Icon>}
                          node="div"
                          onSelect={this.onSelect}
                        >
                          <div className="table-responsive">
                            <table>
                              <thead>
                                <tr>
                                  <th>S.No.</th>
                                  <th>Particulars</th>
                                  <th>HSN</th>
                                  <th>Recevied</th>
                                </tr>
                              </thead>
                              <tbody>
                                {JSON.parse(value.particulars).map(
                                  (item, index) => (
                                    <>
                                      <tr>
                                        <td>{index + 1}</td>
                                        <td>{item.particulars}</td>
                                        <td>{item.hsn}</td>
                                        <td>{item.received}</td>
                                      </tr>
                                    </>
                                  )
                                )}
                              </tbody>
                            </table>
                          </div>
                        </CollapsibleItem>
                      </>
                    ))} */}
                  </Collapsible>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
          <Footer />
        </section>
      </React.Fragment>
    );
  }
}
