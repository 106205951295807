import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Link,
  Route,
  useHistory,
} from "react-router-dom";
import Footer from "../../components/Footer";
import SideBar from "../SideBar";
import SuperAdminHeader from "../../components/header/SuperAdminHeader";
import { apiGetDashboard } from "../../config/endpoints";
import { Helmet } from "react-helmet";
import { getUserData } from "../../helpers/storage";
import { BulletList } from "react-content-loader";
// import "./../../public/sass/components/_variables.scss";
// import "./../../public/sass/components/_color-variables.scss";
import "./../../public/sass/materialize.scss";
import "./../../public/sass/style.scss";
// import "./../../public/sass/pages/_header.scss";

const initialState = {
  /* etc */
  isLoading: false,
  redirect: false,
  errors: {},
  alert: false,
  editItem: false,
  isListLoading: false,
  userData: [],
  dashboard: [],
};
export default class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    console.log(getUserData());
  }
  componentWillMount() {
    this.loadDashboard();
  }
  loadDashboard = (e) => {
    this.setState({ isListLoading: true });
    let userData = getUserData();
    fetch(apiGetDashboard, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          this.setState({
            dashboard: data.data,
          });
          // console.log(this.state.companyList);
          this.setState({ isListLoading: false });
        } else {
          alert("something went wrong.");
        }
        // setTimeout(function () {
      });
  };
  render() {
    let userData = getUserData();

    return (
      <React.Fragment>
        <Helmet>
          <title>Admin | MRC</title>
        </Helmet>
        {this.state.isListLoading ? (
          <BulletList></BulletList>
        ) : (
          <section className='super-admin'>
            <SuperAdminHeader></SuperAdminHeader>
            <div className='wrapper'>
              <div className='row d-flex align-items-center'>
                <div className='col m4'>
                  <h6>Welcome</h6>
                  <h3 className='heading'>
                    {userData.user.firstName} {userData.user.lastName}
                  </h3>
                </div>
                <div className='col m8 right-align'>
                  <Link
                    className='theme_btn btn waves-effect waves-light'
                    to='/Companies'
                  >
                    <i className='material-icons'>visibility</i> View Companies
                  </Link>
                </div>
              </div>
              <div className='insights'>
                <div className='row'>
                  <div className='col m4 s12 card-margin'>
                    <div className='outer'>
                      <p>Total Companies</p>
                      <h4>{this.state.dashboard.users.total}</h4>
                    </div>
                  </div>
                  <div className='col m4 s12 card-margin'>
                    <div className='outer'>
                      <p>Active Companies</p>
                      <h4>{this.state.dashboard.users.active}</h4>
                    </div>
                  </div>
                  <div className='col m4 s12 card-margin'>
                    <div className='outer inactive'>
                      <p>Inactive Companies</p>
                      <h4>{this.state.dashboard.users.deleted}</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col s12'>
                  {/* <canvas id="myChart" width="900px" height="200"></canvas> */}
                </div>
              </div>
            </div>
            <Footer></Footer>
          </section>
        )}
      </React.Fragment>
    );
  }
}
