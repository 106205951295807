import React, { Component } from "react";
import Footer from "../Footer";
import Header from "../Header";
import SideBar from "../SideBar";
import CompanyProfile from "../../components/CompanyProfile";
import EmployeeProfile from "../../components/EmployeeProfile";

import AdminProfile from "../../components/AdminProfile";
import ChangePassword from "../../components/ChangePassword";
import { setUserData, getUserData } from "../../helpers/storage";

export default class UpdateProfile extends Component {
  constructor(props) {
    super(props);
    let userData = getUserData();
    //console.log(userData);
    this.state = {
      userData: userData,
      errors: {},
    };
  }
  componentWillMount() {
    let userData = getUserData();
    this.setState({ user: userData, isListLoading: true });
  }
  render() {
    return (
      <div>
        <section className='admin'>
          <title>Project Name | Dashboard</title>
          <section className='adminProject'>
            <div className='wrapper d-flex'>
              <SideBar />
              <div className='wrapper_right'>
                <Header />
                <div className='d-flex'>
                  <div className='wrapper_body w-100'>
                    <div className='row'>
                      <div className='col m6 s12'>
                        <h3 className='password-heading'>
                          {this.state.userData.user.userType == "3"
                            ? "Profile Details"
                            : "Update Profile"}{" "}
                        </h3>
                        {this.state.userData.user.userType == "1" ? (
                          <AdminProfile></AdminProfile>
                        ) : null}
                        {this.state.userData.user.userType == "2" ? (
                          <CompanyProfile></CompanyProfile>
                        ) : null}
                        {this.state.userData.user.userType == "3" ? (
                          <EmployeeProfile />
                        ) : null}
                      </div>
                      <div className='col m6 s12'>
                        <h3 className='password-heading'>Change Password</h3>
                        <ChangePassword></ChangePassword>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </section>
        </section>
      </div>
    );
  }
}
