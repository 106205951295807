import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import ReactFormInputValidation from "react-form-input-validation";
import { apiLogin } from "../../config/endpoints";
import axios from "axios";
import { toast } from "react-toastify";
import { setUserData, getUserData } from "../../helpers/storage";
import ButtonLoader from "../../components/ButtonLoader";

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        email: "",
        password: "",
      },
      isLoading: false,
      redirect: false,
      errors: {},
    };
    this.form = new ReactFormInputValidation(this);
    this.form.useRules({
      password: "required",
      email: "required|email",
    });
    this.form.onformsubmit = (fields) => {
      // Do you ajax calls here.
      /* console.log(fields);
      console.log(apiLogin); */
      this.setState({ isLoading: true });
      axios.post(apiLogin, this.state.fields).then(
        (response) => {
          //console.log(response);
          setUserData(response.data.data);
          let userData = getUserData();
          // console.log(userData.user.userType);
          if (userData.user.userType == 1) {
            //admin
            this.setState({ redirect: "/admin", isLoading: false });
          }
          if (userData.user.userType == 2) {
            //company admin
            this.setState({ redirect: "/projects", isLoading: false });
          }
          if (userData.user.userType === 3) {
            //employee
            this.setState({ redirect: "/projects", isLoading: false });
          } else {
            //everybody else
            this.setState({ redirect: "/dashboard", isLoading: false });
          }
        },
        (error) => {
          if (error.response.status == 401) {
            toast.error(error.response.data.errorMessage, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            this.setState({ isLoading: false });
          }
        }
      );
    };
  }
  componentWillMount() {
    let userData = getUserData();
    //console.log(userData);
    if (userData.user != null || userData.user != undefined) {
      if (userData.user.userType === 1) {
        //admin
        this.setState({ redirect: "/companies", isLoading: false });
      }
      if (userData.user.userType === 2) {
        //company admin
        this.setState({ redirect: "/projects", isLoading: false });
      }
      if (userData.user.userType === 3) {
        //employee
        this.setState({ redirect: "/projects", isLoading: false });
      } else {
        //everybody else
        // this.setState({ redirect: "/dashboard", isLoading: false });
      }
    }
  }
  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    return (
      <div>
        <div>
          <Helmet>
            <title>Log In | MRC</title>
          </Helmet>
          {/*Let browser know website is optimized for mobile*/}
          <section className='login_page'>
            <div className='row valign-wrapper'>
              <div className='col m6 s12 p-0'>
                <div className='left_column d-flex align-items-center justify-content-center'>
                  <img
                    src='assets/images/login_logo.png'
                    alt='MRC'
                    width={110}
                  />
                  <p className='copyright'>
                    All Rights Reserved © 2021 MRC Group
                  </p>
                </div>
              </div>
              <div className='col m6 s12'>
                <div className='right_column mx-auto'>
                  <h3 className='heading'>Log In</h3>
                  <p>Enter details to log into your account</p>
                  <form
                    action=''
                    id='loginForm'
                    onSubmit={this.form.handleSubmit}
                  >
                    <input
                      type='email'
                      placeholder='Email address'
                      name='email'
                      onBlur={this.form.handleBlurEvent}
                      onChange={this.form.handleChangeEvent}
                      value={this.state.fields.email}
                    />
                    <label className='error'>
                      {this.state.errors.email ? this.state.errors.email : ""}
                    </label>
                    <input
                      type='password'
                      placeholder='Password'
                      name='password'
                      onBlur={this.form.handleBlurEvent}
                      onChange={this.form.handleChangeEvent}
                      value={this.state.fields.password}
                    />
                    <label className='error'>
                      {this.state.errors.password
                        ? this.state.errors.password
                        : ""}
                    </label>
                    {/* <Link
                      className="w-100 d-block right-align"
                      to="/Forget_Password"
                    >
                      Forgot Password?
                    </Link> */}
                    <button
                      type='submit'
                      className='theme_btn btn waves-effect waves-light white-text'
                    >
                      LogIn To Account{" "}
                      {this.state.isLoading ? (
                        <ButtonLoader></ButtonLoader>
                      ) : null}
                    </button>
                    {/* <Link
                      className="theme_btn btn waves-effect waves-light white-text"
                      to="/Projects"
                    >
                      LogIn To Account
                    </Link> */}

                    {/* <button class="theme_btn btn waves-effect waves-light" form="loginForm">LogIn To Account</button> */}
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}
