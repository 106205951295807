import React, { Component } from "react";
import Header from "../Header";
import SideBar from "../SideBar";
import Footer from "../Footer";

import "materialize-css";

export default class Vendor extends Component {
  render() {
    return (
      <div>
        <title>Project Name | Dashboard</title>
        <section className="adminProject">
          <div className="wrapper d-flex">
            <SideBar />
            <div className="wrapper_right">
              <Header />
              <div className="d-flex">
                <div className="wrapper_body">
                  <div className="row">
                    <div className="col m8 s12">
                      <h3 className="heading">Vendor</h3>
                      {/* <div className="nav-wrapper">
                        <a href="javascript:void(0)" className="breadcrumb">
                          First
                        </a>
                        <a href="javascript:void(0)" className="breadcrumb">
                          Second
                        </a>
                        <a href="javascript:void(0)" className="breadcrumb">
                          Third
                        </a>
                      </div> */}
                    </div>
                    <div className="col m4 s12">
                      <a
                        href="#create_new_list_modal"
                        className="float-md-right theme_btn btn waves-effect waves-light modal-trigger"
                      >
                        [<i className="material-icons">add&nbsp;</i>Create New
                        Vendor
                      </a>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col s12">
                      <div className="table-responsive">{/*  */}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </section>
        {/* Create_new_project_modal Modal */}
        <div
          id="create_new_list_modal"
          className="new-list-modal cstm-modal modal"
        >
          {/* Close Modal */}
          <a href="javascript:Void(0)" className="modal-close">
            <span className="material-icons">chevron_right</span>
          </a>
          {/* Modal Content */}
          <h3 className="heading">Add Vendor</h3>
          <p>Enter details to create new list</p>
          <form action id="createListForm">
            <div className="row">
              <div className="col s12">
                <label className="label">Company Name</label>
                <input
                  type="text"
                  placeholder="Company Name"
                  name="Company Name"
                  required
                />
              </div>
              <div className="col s12">
                <label className="label">Head</label>
                <select name="Head" multiple>
                  <option disabled selected>
                    Head
                  </option>
                  <option value={1}>Electrical</option>
                  <option value={2}>Labour</option>
                  <option value={3}>False Celling</option>
                </select>
              </div>
              <div className="col m6 s12">
                <label className="label">Contact Person</label>

                <input
                  type="text"
                  placeholder="Contact Person"
                  name="Contact Person"
                />
              </div>
              <div className="col m6 s12">
                <label className="label">Mobile No.</label>

                <input type="text" placeholder="Mobile No" name="Mobile No" />
              </div>
              <div className="col m6 s12">
                <label className="label">Address</label>

                <input type="text" placeholder="Address" name="Address" />
              </div>
              <div className="col m6 s12">
                <label className="label">Email ID</label>

                <input type="text" placeholder="Email ID" name="Email ID" />
                <span className="icon">
                  <i />
                </span>
              </div>
              <div className="col m6 s12">
                <label className="label">GST</label>

                <input type="text" placeholder="GST " name="GST" />
                <span className="icon">
                  <i />
                </span>
              </div>
              <div className="col m6 s12">
                <label className="label">PAN</label>

                <input type="text" placeholder="PAN " name="PAN" />
                <span className="icon">
                  <i />
                </span>
              </div>
              <div className="col s12 right-align">
                <button
                  type="submit"
                  form="createListForm"
                  className="theme_btn btn waves-effect waves-light"
                >
                  Add Vendor<i className="material-icons">&nbsp;add</i>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
