import React, { Component } from "react";
import AdminHeader from "../../components/header/AdminHeader";
import AdminMenu from "../../components/sidebar/AdminMenu";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
import Footer from "../Footer";
import axios from "axios";
import { Helmet } from "react-helmet";
import { BulletList } from "react-content-loader";
import { Tabs, Tab } from "react-materialize";
import { getUserData } from "../../helpers/storage";
import Select from "rc-select";
import "rc-select/assets/index.less";
import Moment from "react-moment";

import {
  apiGetEmployee,
  apiListRoles,
  apiListProjects,
  apiGetMasterByType,
  apiCreatePromotion,
  apiCreateSalary,
} from "../../config/endpoints";

export default class EmployeeDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      employeeData: {},
      user: {},
      assignedProjects: [],
      employeePromotions: [],
      isListLoading: false,
      masterListRoles: [],
      masterListProjects: [],
      masterListDesignation: [],
      masterListCompanyPerk: [],
      fields: {
        designation: "",
        perks: "",
        salary: "",
        remarks: "",
      },
      salaryFields: {
        basic: 0,
        epfo: 0,
        esic: 0,
        hra: 0,
        conveyance: 0,
        travelling: 0,
        allowance: 0,
      },
      salaryTotal: 0,
    };
    this.selectInputRef = React.createRef();
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.validatorSalary = new SimpleReactValidator({ autoForceUpdate: this });
  }

  loadEmployee = async () => {
    const str = window.location.pathname;
    const empId = str.substring(str.lastIndexOf("/") + 1, str.length);
    //console.log("rajat id -", empId);
    let userData = getUserData();
    const config = {
      headers: { Authorization: `Bearer ${userData.token}` },
    };
    //console.log("vvjhvw", `${apiGetEmployee}?id=${empId}`);
    try {
      this.setState({ isListLoading: true });
      const res = await axios.get(`${apiGetEmployee}?id=${empId}`, config);
      /* console.log(
        "rajatdata ",
        res.data.data.employee.projects.split`,`.map((x) => +x)
      ); */
      if (res.data.code === 200) {
        this.setState({
          employeeData: res.data.data.employee,
          user: res.data.data.employee.user,
          employeePromotions: res.data.data.employeePromotion,
          assignedProjects: res.data.data.employee.projects.split`,`.map(
            (x) => +x
          ),
          isListLoading: false,
        });
        this.setState((prevState) => ({
          employee: res.data.data.employee.id,
        }));
        this.setState((prevState) => ({
          salaryFields: {
            ...prevState.salaryFields,
            basic: res.data.data.employeeSalary
              ? res.data.data.employeeSalary.basic
              : 0,
            epfo: res.data.data.employeeSalary
              ? res.data.data.employeeSalary.epfo
              : 0,
            esic: res.data.data.employeeSalary
              ? res.data.data.employeeSalary.esic
              : 0,
            hra: res.data.data.employeeSalary
              ? res.data.data.employeeSalary.hra
              : 0,
            conveyance: res.data.data.employeeSalary
              ? res.data.data.employeeSalary.conveyance
              : 0,
            travelling: res.data.data.employeeSalary
              ? res.data.data.employeeSalary.travelling
              : 0,
            allowance: res.data.data.employeeSalary
              ? res.data.data.employeeSalary.allowance
              : 0,
          },
        }));
        let totalAmount = Object.entries(this.state.salaryFields).reduce(
          (finalValue, [key, value]) => {
            return finalValue + Number(value);
          },
          0
        );
        this.setState((prevState) => ({
          salaryTotal: totalAmount,
        }));
        //console.log("emp data", this.state.employeeData);
        //console.log("userdata", this.state.user);
      } else {
        alert("something went wrong");
      }
    } catch (err) {
      //console.error(err);
    }
  };

  handleChange = (e) => {
    // console.log(e.target.name, e.target.value);
    this.validator.showMessageFor(e.target.name);
    const value = e.target.value;
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        [e.target.name]: value,
      },
    }));
  };

  handleSalaryChange = (e) => {
    // console.log(e.target.name, e.target.value);
    this.validatorSalary.showMessageFor(e.target.name);
    const value = e.target.value;
    this.setState(
      (prevState) => ({
        salaryFields: {
          ...prevState.salaryFields,
          [e.target.name]: value,
        },
      }),
      () => {
        console.log("total", this.state.salaryFields);
        let totalAmount = Object.entries(this.state.salaryFields).reduce(
          (finalValue, [key, value]) => {
            return finalValue + Number(value);
          },
          0
        );
        this.setState((prevState) => ({
          salaryTotal: totalAmount,
        }));
      }
    );
  };

  handleChangePerk = (selectedOption) => {
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        perks: selectedOption,
      },
      selectedPerks: selectedOption,
    }));
    console.log("handleChangePerk", this.state);
  };

  loadMaster = (e) => {
    let userData = getUserData();

    fetch(apiListRoles, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          //console.log("roles", data.data.roles.rows);
          this.setState({
            masterListRoles: data.data.roles.rows,
          });
        } else {
          alert("something went wrong.");
        }
      });

    fetch(apiListProjects, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          //console.log("project list", data.data.projects.rows);
          this.setState({
            masterListProjects: data.data.projects.rows,
          });
        } else {
          alert("something went wrong.");
        }
      });

    fetch(apiGetMasterByType + "?type=designation", {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          this.setState({
            masterListDesignation: data.data.masters,
          });
        } else {
          alert("something went wrong.");
        }
      });

    fetch(apiGetMasterByType + "?type=company_perk", {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          //console.log("perks list", data.data.masters);
          this.setState({
            masterListCompanyPerk: data.data.masters,
          });
        } else {
          alert("something went wrong.");
        }
      });
  };

  submitPromotionForm = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      let userData = getUserData();

      this.setState({ isLoading: true });
      this.state.fields.employee = this.state.employeeData.id;
      axios
        .post(apiCreatePromotion, this.state.fields, {
          headers: {
            Authorization: "Bearer " + userData.token,
          },
        })
        .then(
          (response) => {
            //console.log(response);
            this.setState({ isLoading: false });
            toast.info("Employee promoted", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
            this.setState(this.initialState);
            window.location = "";
          },
          (error) => {
            if (error.response.status == 500 || error.response.status == 400) {
              toast.error(error.response.data.errorMessage, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              this.setState({ isLoading: false });
            }
          }
        );
    } else {
      /* console.log("fail");
      console.log("errors", this.validator.getErrorMessages()); */
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  submitSalaryForm = (e) => {
    e.preventDefault();
    if (this.validatorSalary.allValid()) {
      let userData = getUserData();

      this.setState({ isLoading: true });
      this.state.salaryFields.employee = this.state.employeeData.id;
      axios
        .post(apiCreateSalary, this.state.salaryFields, {
          headers: {
            Authorization: "Bearer " + userData.token,
          },
        })
        .then(
          (response) => {
            //console.log(response);
            this.setState({ isLoading: false });
            toast.info("Salary Saved", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
            this.setState(this.initialState);
            window.location = "";
          },
          (error) => {
            if (error.response.status == 500 || error.response.status == 400) {
              toast.error(error.response.data.errorMessage, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              this.setState({ isLoading: false });
            }
          }
        );
    } else {
      /* console.log("fail");
      console.log("errors", this.validator.getErrorMessages()); */
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  componentDidMount() {
    this.loadEmployee();
    this.loadMaster();
  }

  render() {
    //console.log("inside render", this.state.assignedProjects);

    return (
      <>
        <Helmet>
          <title>Employees | MRC</title>
        </Helmet>
        <section className="admin">
          <AdminHeader />
          <div className="wrapper d-flex">
            <AdminMenu />

            <div className="wrapper_body">
              <div className="row">
                <div className="col m8 s12">
                  <h3 className="heading">Employee Detail</h3>
                </div>
              </div>
              {/* <div className='category-card'> */}
              <div className="row">
                <div className="col s12">
                  <div className="outer">
                    <div className="listing">
                      {this.state.isListLoading ? (
                        <BulletList />
                      ) : (
                        <div className="table-responsive">
                          <table>
                            <thead>
                              <tr>
                                <th>Basic Details</th>
                                <th> </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td data-th="Emp ID">
                                  <strong>Name</strong>
                                </td>
                                <td data-th="Name">
                                  <strong>
                                    {this.state.employeeData.name}
                                  </strong>
                                </td>
                              </tr>
                              <tr>
                                <td data-th="Emp ID">
                                  <strong>Employee Id</strong>
                                </td>
                                <td data-th="Name">
                                  <strong>
                                    {this.state.employeeData.empId}
                                  </strong>
                                </td>
                              </tr>

                              <tr>
                                <td data-th="Emp ID">
                                  <strong>Email</strong>
                                </td>
                                <td data-th="Name">
                                  <strong>{this.state.user.email}</strong>
                                </td>
                              </tr>
                              <tr>
                                <td data-th="Emp ID">
                                  <strong>Mobile No.</strong>
                                </td>
                                <td data-th="Name">
                                  <strong>
                                    {this.state.employeeData.mobileNo}
                                  </strong>
                                </td>
                              </tr>
                              <tr>
                                <td data-th="Emp ID">
                                  <strong>Date of Birth</strong>
                                </td>
                                <td data-th="Name">
                                  <strong>{this.state.employeeData.dob}</strong>
                                </td>
                              </tr>
                              <tr>
                                <td data-th="Emp ID">
                                  <strong>Role</strong>
                                </td>
                                <td data-th="Name">
                                  {this.state.masterListRoles.map(
                                    (role) =>
                                      role.id ==
                                        this.state.employeeData.role && (
                                        <strong>{role.name}</strong>
                                      )
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td data-th="Emp ID">
                                  <strong>Designation</strong>
                                </td>
                                <td data-th="Name">
                                  <strong>
                                    {this.state.employeeData.designation}
                                  </strong>
                                </td>
                              </tr>
                              <tr>
                                <td data-th="Emp ID">
                                  <strong>Address</strong>
                                </td>
                                <td data-th="Name">
                                  <strong>
                                    {this.state.employeeData.address}
                                  </strong>
                                </td>
                              </tr>
                              <tr>
                                <td data-th="Emp ID">
                                  <strong>Monthly Salary (CTC)</strong>
                                </td>
                                <td data-th="Name">
                                  <strong>
                                    {this.state.employeeData.salary}
                                  </strong>
                                </td>
                              </tr>
                              <tr>
                                <td data-th="ESIC">
                                  <strong>ESIC</strong>
                                </td>
                                <td data-th="esic">
                                  <strong>
                                    {this.state.employeeData.esic}
                                  </strong>
                                </td>
                              </tr>
                              <tr>
                                <td data-th="Date of Joining">
                                  <strong>Date of Joining</strong>
                                </td>
                                <td data-th="Date of Joining">
                                  <strong>{this.state.employeeData.doj}</strong>
                                </td>
                              </tr>
                              <tr>
                                <td data-th="Emp ID">
                                  <strong>PAN No.</strong>
                                </td>
                                <td data-th="Name">
                                  <strong>{this.state.employeeData.pan}</strong>
                                </td>
                              </tr>
                              <tr>
                                <td data-th="Emp ID">
                                  <strong>Adhaar No.</strong>
                                </td>
                                <td data-th="Name">
                                  <strong>
                                    {this.state.employeeData.adhaar}
                                  </strong>
                                </td>
                              </tr>
                              <tr>
                                <td data-th="Emp ID">
                                  <strong>Bank</strong>
                                </td>
                                <td data-th="Name">
                                  <strong>
                                    {this.state.employeeData.bankName}
                                  </strong>
                                </td>
                              </tr>
                              <tr>
                                <td data-th="Emp ID">
                                  <strong>Account No.</strong>
                                </td>
                                <td data-th="Name">
                                  <strong>
                                    {this.state.employeeData.accountNo}
                                  </strong>
                                </td>
                              </tr>
                              <tr>
                                <td data-th="Emp ID">
                                  <strong>IFSC Code</strong>
                                </td>
                                <td data-th="Name">
                                  <strong>
                                    {this.state.employeeData.ifscCode}
                                  </strong>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* </div> */}

              <div className="category-card">
                <div className="row">
                  <div className="col s12">
                    <Tabs
                      className="tab-demo z-depth-1 tab-custom"
                      scope="tabs-22"
                    >
                      <Tab
                        active
                        options={{
                          duration: 300,
                          onShow: null,
                          responsiveThreshold: Infinity,
                          swipeable: false,
                        }}
                        title="Documents"
                      >
                        {/* <h1>Test 1</h1> */}
                        <div className="listing">
                          {this.state.isListLoading ? (
                            <BulletList />
                          ) : (
                            <div className="table-responsive">
                              <table>
                                <thead>
                                  <tr>
                                    <th>List of Documents</th>
                                    <th>View</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td data-th="Emp ID">
                                      <strong>Resume</strong>
                                    </td>
                                    <td data-th="Name">
                                      {this.state.employeeData.resume != "" ? (
                                        <>
                                          <a
                                            href={
                                              this.state.employeeData.resume
                                            }
                                            className="actions"
                                            target={"_blank"}
                                          >
                                            View
                                            <i class="material-icons">
                                              file_download
                                            </i>
                                          </a>
                                        </>
                                      ) : null}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td data-th="Emp ID">
                                      <strong>Qualification Certificate</strong>
                                    </td>
                                    <td data-th="Name">
                                      {this.state.employeeData
                                        .qualificationCertificates ? (
                                        <>
                                          <a
                                            href={
                                              this.state.employeeData
                                                .qualificationCertificates
                                            }
                                            className="actions"
                                            target={"_blank"}
                                          >
                                            View
                                            <i class="material-icons">
                                              file_download
                                            </i>
                                          </a>
                                        </>
                                      ) : null}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td data-th="Emp ID">
                                      <strong>Experience Certificate</strong>
                                    </td>
                                    <td data-th="Name">
                                      {this.state.employeeData
                                        .experienceCertificate ? (
                                        <>
                                          <a
                                            href={
                                              this.state.employeeData
                                                .experienceCertificate
                                            }
                                            className="actions"
                                            target={"_blank"}
                                          >
                                            View
                                            <i class="material-icons">
                                              file_download
                                            </i>
                                          </a>
                                        </>
                                      ) : null}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td data-th="Emp ID">
                                      <strong>Adhaar</strong>
                                    </td>
                                    <td data-th="Name">
                                      {this.state.employeeData.adhaarFile ? (
                                        <>
                                          <a
                                            href={
                                              this.state.employeeData.adhaarFile
                                            }
                                            className="actions"
                                            target={"_blank"}
                                          >
                                            View
                                            <i class="material-icons">
                                              file_download
                                            </i>
                                          </a>
                                        </>
                                      ) : null}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td data-th="Emp ID">
                                      <strong>PAN Card upload</strong>
                                    </td>
                                    <td data-th="Name">
                                      {this.state.employeeData.panCardFile ? (
                                        <>
                                          <a
                                            href={
                                              this.state.employeeData
                                                .panCardFile
                                            }
                                            className="actions"
                                            target={"_blank"}
                                          >
                                            View
                                            <i class="material-icons">
                                              file_download
                                            </i>
                                          </a>
                                        </>
                                      ) : null}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td data-th="Emp ID">
                                      <strong>Driving License Upload</strong>
                                    </td>
                                    <td data-th="Name">
                                      {this.state.employeeData
                                        .drivingLicenseFile ? (
                                        <>
                                          <a
                                            href={
                                              this.state.employeeData
                                                .drivingLicenseFile
                                            }
                                            className="actions"
                                            target={"_blank"}
                                          >
                                            View
                                            <i class="material-icons">
                                              file_download
                                            </i>
                                          </a>
                                        </>
                                      ) : null}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          )}
                        </div>
                      </Tab>
                      <Tab
                        options={{
                          duration: 300,
                          onShow: null,
                          responsiveThreshold: Infinity,
                          swipeable: false,
                        }}
                        title="Projects"
                      >
                        <div className="listing">
                          <div className="table-responsive">
                            <table>
                              <thead>
                                <tr>
                                  <th>Project Name</th>
                                  <th>Client</th>
                                  <th>Amount</th>
                                  <th>Address</th>
                                  <th>Pan</th>
                                  <th>GST</th>
                                  <th>Awarded Date</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.assignedProjects.map((project_id) =>
                                  this.state.masterListProjects
                                    .filter((item) => item.id === project_id)
                                    .map((project) => (
                                      <tr key={project.id}>
                                        <td data-th="Emp ID">
                                          <strong>{project.name}</strong>
                                        </td>
                                        <td data-th="Emp ID">
                                          <strong>{project.client}</strong>
                                        </td>
                                        <td data-th="Emp ID">
                                          <strong>{project.amount}</strong>
                                        </td>
                                        <td data-th="Emp ID">
                                          <strong>{project.address}</strong>
                                        </td>
                                        <td data-th="Emp ID">
                                          <strong>{project.pan}</strong>
                                        </td>
                                        <td data-th="Emp ID">
                                          <strong>{project.gst}</strong>
                                        </td>
                                        <td data-th="Emp ID">
                                          <strong>{project.awardedDate}</strong>
                                        </td>
                                      </tr>
                                    ))
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </Tab>
                      <Tab
                        active
                        options={{
                          duration: 300,
                          onShow: null,
                          responsiveThreshold: Infinity,
                          swipeable: false,
                        }}
                        title="Promotion"
                      >
                        <div className="listing">
                          {this.state.isListLoading ? (
                            <BulletList />
                          ) : (
                            <div className="table-responsive">
                              <table className="input-new-tab">
                                <thead>
                                  <tr>
                                    <th width={400}>Promotion Details</th>
                                    <th>Values</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td data-th="Emp ID">
                                      <strong>Designation</strong>
                                    </td>
                                    <td data-th="Name">
                                      <select
                                        name="designation"
                                        value={this.state.fields.designation}
                                        onChange={this.handleChange}
                                        onBlur={this.handleBlur}
                                      >
                                        <option selected>
                                          {" "}
                                          Select Designation
                                        </option>
                                        {this.state.masterListDesignation.map(
                                          (designation, index) => (
                                            <>
                                              <option value={designation.name}>
                                                {designation.name}
                                              </option>
                                            </>
                                          )
                                        )}
                                      </select>
                                      <label className="error">
                                        {this.validator.message(
                                          "designation",
                                          this.state.fields.designation,
                                          "required"
                                        )}
                                      </label>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td data-th="Emp ID">
                                      <strong>Perks</strong>
                                    </td>
                                    <td data-th="Name">
                                      <Select
                                        isMulti
                                        name="perks"
                                        mode="multiple"
                                        className="multiSelect"
                                        animation={"slide-up"}
                                        ref={this.selectInputRef}
                                        onChange={this.handleChangePerk}
                                        options={this.state.masterListCompanyPerk.map(
                                          (perk) => ({
                                            value: perk.name,
                                            label: perk.name,
                                          })
                                        )}
                                      />
                                      <label className="error">
                                        {this.validator.message(
                                          "perks",
                                          this.state.fields.perks,
                                          "required"
                                        )}
                                      </label>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td data-th="Emp ID">
                                      <strong>Monthly Salary (CTC)</strong>
                                    </td>
                                    <td data-th="Name">
                                      {" "}
                                      <input
                                        type={"text"}
                                        style={{ width: "100%" }}
                                        name="salary"
                                        value={this.state.fields.salary}
                                        onChange={this.handleChange}
                                      />
                                      <label className="error">
                                        {this.validator.message(
                                          "salary",
                                          this.state.fields.salary,
                                          "required|numeric"
                                        )}
                                      </label>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td data-th="Emp ID">
                                      <strong>Remarks</strong>
                                    </td>
                                    <td data-th="Name">
                                      <input
                                        type={"text"}
                                        name="remarks"
                                        value={this.state.fields.remarks}
                                        onChange={this.handleChange}
                                        style={{ width: "100%" }}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="feature" colSpan={2}>
                                      <button
                                        className="theme_btn btn waves-effect me-2"
                                        onClick={(e) =>
                                          this.submitPromotionForm(e)
                                        }
                                      >
                                        Promote
                                      </button>
                                      <button className="theme_btn btn waves-effect red">
                                        Leave
                                      </button>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <div className="table-responsive">
                                <table className="input-new-tab">
                                  <thead>
                                    <tr>
                                      <th width={400}>Date</th>
                                      <th width={400}>Designation</th>
                                      <th width={400}>Salary</th>
                                      <th width={400}>Perks</th>
                                      <th width={400}>Remarks</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.employeePromotions.map(
                                      (promotion, index) => (
                                        <>
                                          {" "}
                                          <tr>
                                            <td>
                                              {" "}
                                              <Moment format="YYYY-MM-DD">
                                                {promotion.createdAt}
                                              </Moment>
                                            </td>
                                            <td>{promotion.designation}</td>
                                            <td>
                                              <i class="fas fa-rupee-sign"></i>{" "}
                                              {promotion.salary}
                                            </td>
                                            <td>
                                              {JSON.parse(promotion.perks)
                                                .map(function (val) {
                                                  return val;
                                                })
                                                .join(", ")}
                                            </td>
                                            <td>{promotion.remarks}</td>
                                          </tr>
                                        </>
                                      )
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          )}
                        </div>
                      </Tab>
                      <Tab
                        active
                        options={{
                          duration: 300,
                          onShow: null,
                          responsiveThreshold: Infinity,
                          swipeable: false,
                        }}
                        title="Salary structure"
                      >
                        {/* <h1>Test 1</h1> */}
                        <div className="listing">
                          {this.state.isListLoading ? (
                            <BulletList />
                          ) : (
                            <div className="table-responsive">
                              <table className="input-new-tab">
                                <thead>
                                  <tr>
                                    <th width={400}>Salary structure</th>
                                    <th>Values</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td data-th="Emp ID">
                                      <strong>Basic Salary</strong>
                                    </td>
                                    <td data-th="Emp ID">
                                      <input
                                        type={"text"}
                                        name="basic"
                                        value={this.state.salaryFields.basic}
                                        onChange={this.handleSalaryChange}
                                      />
                                      <label className="error">
                                        {this.validatorSalary.message(
                                          "basic",
                                          this.state.salaryFields.basic,
                                          "required|numeric"
                                        )}
                                      </label>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td data-th="Emp ID">
                                      <strong>EPFO</strong>
                                    </td>
                                    <td data-th="Emp ID">
                                      <input
                                        type={"text"}
                                        name="epfo"
                                        value={this.state.salaryFields.epfo}
                                        onChange={this.handleSalaryChange}
                                      />
                                      <label className="error">
                                        {this.validatorSalary.message(
                                          "epfo",
                                          this.state.salaryFields.epfo,
                                          "required|numeric"
                                        )}
                                      </label>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td data-th="Emp ID">
                                      <strong>ESIC</strong>
                                    </td>
                                    <td data-th="Emp ID">
                                      <input
                                        type={"text"}
                                        name="esic"
                                        value={this.state.salaryFields.esic}
                                        onChange={this.handleSalaryChange}
                                      />
                                      <label className="error">
                                        {this.validatorSalary.message(
                                          "esic",
                                          this.state.salaryFields.esic,
                                          "required|numeric"
                                        )}
                                      </label>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td data-th="Emp ID">
                                      <strong>HRA</strong>
                                    </td>
                                    <td data-th="Emp ID">
                                      <input
                                        type={"text"}
                                        name="hra"
                                        value={this.state.salaryFields.hra}
                                        onChange={this.handleSalaryChange}
                                      />
                                      <label className="error">
                                        {this.validatorSalary.message(
                                          "hra",
                                          this.state.salaryFields.hra,
                                          "required|numeric"
                                        )}
                                      </label>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td data-th="Emp ID">
                                      <strong>Conveyance Allowance</strong>
                                    </td>
                                    <td data-th="Emp ID">
                                      <input
                                        type={"text"}
                                        name="conveyance"
                                        value={
                                          this.state.salaryFields.conveyance
                                        }
                                        onChange={this.handleSalaryChange}
                                      />
                                      <label className="error">
                                        {this.validatorSalary.message(
                                          "conveyance",
                                          this.state.salaryFields.conveyance,
                                          "required|numeric"
                                        )}
                                      </label>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td data-th="Emp ID">
                                      <strong>Travelling Allowance </strong>
                                    </td>
                                    <td data-th="Emp ID">
                                      <input
                                        type={"text"}
                                        name="travelling"
                                        value={
                                          this.state.salaryFields.travelling
                                        }
                                        onChange={this.handleSalaryChange}
                                      />
                                      <label className="error">
                                        {this.validatorSalary.message(
                                          "travelling",
                                          this.state.salaryFields.travelling,
                                          "required|numeric"
                                        )}
                                      </label>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td data-th="Emp ID">
                                      <strong className="text-amount">
                                        Total
                                      </strong>
                                    </td>
                                    <td data-th="Emp ID">
                                      <h3 className="dollar-heading">
                                        <i class="fas fa-rupee-sign"></i>{" "}
                                        {this.state.salaryTotal}
                                      </h3>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td data-th="Emp ID">
                                      <strong></strong>
                                    </td>
                                    <td data-th="Emp ID">
                                      <button
                                        className="theme_btn btn waves-effect"
                                        onClick={(e) =>
                                          this.submitSalaryForm(e)
                                        }
                                      >
                                        Save Structure
                                      </button>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          )}
                        </div>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </section>
      </>
    );
  }
}
