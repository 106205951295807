import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class MnavBar extends Component {
  render() {
    return (
      <div>
        <aside className="aside_navbar">
          <ul>
            <li>
              <i />
              <Link to="/Projects">All Projects</Link>
            </li>
            <li>
              <i />
              <Link to="/Employee">Employee</Link>
            </li>
            <li>
              <i />
              <Link to="/Roles">Roles</Link>
            </li>
            <li>
              <i />
              <Link to="/Contractor">Contractor</Link>
            </li>
          </ul>
        </aside>
      </div>
    );
  }
}
