import React, { Component } from "react";
import Footer from "../../components/Footer";
import AdminHeader from "../../components/header/AdminHeader";
import AdminMenu from "../../components/sidebar/AdminMenu";
import ButtonLoader from "../../components/ButtonLoader";
import axios from "axios";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "materialize-css";
import { Modal, Badge } from "react-materialize";
import { getUserData } from "../../helpers/storage";
import SweetAlert from "react-bootstrap-sweetalert";
import SimpleReactValidator from "simple-react-validator";
import { customStyles } from "../../helpers/util";
import Pagination from "rc-pagination";
import Select from "rc-select";
import "rc-select/assets/index.less";
import "../../assets/pagination.less";
import localeInfo from "../../helpers/en_US";
import Multiselect from "multiselect-react-dropdown";

import {
  apiListEmployees,
  apiCreateEmployee,
  apiGetEmployee,
  apiDeleteEmployee,
  apiGetMasterByType,
  apiEditEmployee,
  apiListRoles,
  apiListProjects,
  apiChangePasswordForAdmin,
} from "../../config/endpoints";
import { Helmet } from "react-helmet";
import { BulletList } from "react-content-loader";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AutoComplete from "react-google-autocomplete";
import InputMask from "react-input-mask";

const initialState = {
  fields: {
    name: "",
    fatherName: "",
    address: "",
    dob: "",
    adhaar: "",
    drivingLicense: "",
    mobileNo: "",
    bankName: "",
    accountNo: "",
    ifscCode: "",
    pan: "",
    uan: "",
    designation: "",
    salary: "",
    doj: "",
    esic: "",
    perks: [],
    projects: [],
    accommodation: "",
    email: "",
    password: "",
    confirmPassword: "",
    empId: "",
    role: "",
    newpassword: "",
    newpassword2: "",
  },
  searchFields: { name: "", designation: "", status: "", projects: "" },
  resume: "",
  qualificationCertificates: "",
  experienceCertificate: "",
  adhaarFile: "",
  panCardFile: "",
  drivingLicenseFile: "",
  isLoading: false,
  redirect: false,
  errors: {},
  employeeList: [],
  employeeCount: 0,
  isModalOpen: false,
  isPasswordModalOpen: false,
  isListLoading: false,
  alert: false,
  deleteId: "",
  masterListCompanyPerk: [],
  masterListDesignation: [],
  masterListRoles: [],
  masterListProjects: [],
  editId: false,
  editItem: false,
  passId: false,
  selectedProjects: [],
  selectedPerks: [],
  pageSize: 30,
  current: 1,
  options: [
    { name: "Option 1️⃣", id: 1 },
    { name: "Option 2️⃣", id: 2 },
  ],
};

export default class Employee extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.selectInputRef = React.createRef();
    this.projectReset = this.projectReset.bind(this);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.validator1 = new SimpleReactValidator({ autoForceUpdate: this });
    this.fileChange = this.fileChange.bind(this);
    this.editEmployee = this.editEmployee.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDate = this.handleDate.bind(this);
    this.handlePhone = this.handlePhone.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.changePassword = this.changePassword.bind(this);
  }

  onChangePagination = (page) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        current: page,
      }),
      this.loadEmployees
    );
  };

  onChangePageSize = (current, pageSize) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        current: current,
        pageSize: pageSize,
      }),
      this.loadEmployees
    );
  };

  onShowSizeChange = (current, pageSize) => {
    console.log(current);
    this.setState({ pageSize });
  };

  fileChange(e) {
    console.log(e.target.name, e.target.files[0].name);
    if (e.target.files[0]) {
      this.setState({ [e.target.name]: e.target.files[0] });
    }
    //console.log("file change", this.state);
  }
  handleDate = (event) => {
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        dob: event.target.value,
      },
    }));
    //console.log(this.state);
  };

  handleChangePerk = (selectedOption) => {
    //this.setState({ selectedOption });
    //console.log("event..", event.target.value);
    //this.validator.showMessageFor(e.target.name);
    //console.log(`Perk selected:`, selectedOption);
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
      },
      selectedPerks: selectedOption,
    }));
    //console.log("handleChangePerk", this.state);
  };

  handleChangeProject = (selectedOption) => {
    //this.setState({ selectedOption });
    //console.log("event..", event.target.value);
    //this.validator.showMessageFor(e.target.name);
    //console.log(`Option selected:`, selectedOption);

    //this.setState({ selectedProjects: selectedOption });
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
      },
      selectedProjects: selectedOption,
    }));
    console.log("handleChangeProject", this.state);
  };

  /* handleChangeProject = (event, selectedOption) => {
    // let newPerk = this.state.fields.perks;
    // newPerk = "," + event.target.value;
    //console.log(event.target.name, event.target.value);
    //console.log(event.target.name, event.target.checked);

    console.log(`Option selected:`, typeof selectedOption);
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        projects: [...this.state.fields.projects, event.target.value],
      },
    }));
    console.log("rajat projects", this.state.fields.projects);
  }; */

  handlePhone = (event) => {
    this.validator.showMessageFor(event.target.name);
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        mobileNo: event.target.value,
      },
    }));
    //console.log(this.state);
  };

  handleSearch = (e) => {
    this.validator.showMessageFor(e.target.name);
    const value = e.target.value;
    this.setState((prevState) => ({
      searchFields: {
        ...prevState.searchFields,
        [e.target.name]: value,
      },
    }));
  };

  clearFilter = () => {
    this.myFormRef.reset();
    //this.setState(this.initialState, this.loadMasterData);
    this.setState(
      {
        searchFields: {
          name: "",
          designation: "",
          status: "",
          projects: "",
        },
      },
      this.loadEmployees
    );
  };

  submitSearch = (e) => {
    e.preventDefault();
    let userData = getUserData();
    this.setState({ isLoading: true });
    //this.setState({ filteredData: true });
    this.setState({ isListLoading: true });
    fetch(
      apiListEmployees +
        "?page=" +
        this.state.current +
        "&pagesize=" +
        this.state.pageSize +
        "&name=" +
        this.state.searchFields.name +
        "&designation=" +
        this.state.searchFields.designation +
        "&status=" +
        this.state.searchFields.status +
        "&projects=" +
        this.state.searchFields.projects,
      {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + userData.token,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data.data.employees.rows);
        if (data.code == 200) {
          this.setState({
            employeeList: data.data.employees.rows,
            employeeCount: data.data.employees.count,
            isListLoading: false,
          });
          this.setState((prevState) => ({
            fields: {
              // object that we want to update
              ...prevState.fields, // keep all other key-value pairs
              empId: data.data.empId, // update the value of specific key
            },
          }));
        } else {
          alert("something went wrong.");
        }
        // setTimeout(function () {
      });
  };

  loadEmployees = (e) => {
    let userData = getUserData();
    this.setState({ isListLoading: true });
    fetch(
      apiListEmployees +
        "?page=" +
        this.state.current +
        "&pagesize=" +
        this.state.pageSize,
      {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + userData.token,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data.data.employees.rows);
        if (data.code == 200) {
          this.setState({
            employeeList: data.data.employees.rows,
            employeeCount: data.data.employees.count,
            isListLoading: false,
          });
          this.setState((prevState) => ({
            fields: {
              // object that we want to update
              ...prevState.fields, // keep all other key-value pairs
              empId: data.data.empId, // update the value of specific key
            },
          }));
        } else {
          alert("something went wrong.");
        }
        // setTimeout(function () {
      });
  };

  loadMaster = (e) => {
    let userData = getUserData();
    fetch(apiGetMasterByType + "?type=designation", {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          this.setState({
            masterListDesignation: data.data.masters,
          });
        } else {
          alert("something went wrong.");
        }
      });
    fetch(apiGetMasterByType + "?type=company_perk", {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          //console.log("perks list", data.data.masters);
          this.setState({
            masterListCompanyPerk: data.data.masters,
          });
        } else {
          alert("something went wrong.");
        }
      });

    fetch(apiListRoles, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          //console.log("roles", data.data.roles.rows);
          this.setState({
            masterListRoles: data.data.roles.rows,
          });
        } else {
          alert("something went wrong.");
        }
      });

    fetch(apiListProjects, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          //console.log("project list", data.data.projects.rows);
          let projectsList = data.data.projects.rows.map((project) => ({
            id: project.id,
            name: project.name,
          }));
          this.setState({
            masterListProjects: data.data.projects.rows,
            projectsList: projectsList,
          });
          console.log("list", projectsList);
        } else {
          alert("something went wrong.");
        }
      });
  };
  editEmployee(id) {
    this.setState({ editItem: true, editId: id });
    this.handleOpenModal();
    this.loadMaster();
    let userData = getUserData();
    fetch(apiGetEmployee + "?id=" + id, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        //console.log("single emp data", data.data.employee);
        if (data.code == 200) {
          this.setState({
            fields: data.data.employee,
            /* resume: data.data.employee.resume,
            qualificationCertificates:
              data.data.employee.qualificationCertificates,
            experienceCertificate: data.data.employee.experienceCertificate,
            adhaarFile: data.data.employee.adhaarFile, */
            selectedProjects: data.data.employee.projects,
            selectedPerks: data.data.employee.perks,
          });
          this.setState((prevState) => ({
            fields: {
              // object that we want to update
              ...prevState.fields, // keep all other key-value pairs
              email: data.data.employee.user.email, // update the value of specific key
            },
          }));
        } else {
          alert("something went wrong.");
        }
      });
  }
  componentDidMount() {
    this.loadEmployees();
    this.loadMaster();
    //console.log("componentDidMount", this.state.fields.projects);
  }

  handleCloseModal = () => {
    this.setState(initialState);
    this.projectReset();
    this.loadEmployees();
    this.loadMaster();
  };

  handleOpenModal = () => {
    this.setState({ isModalOpen: true });
    this.validator.hideMessages();
    //console.log("model open", this.state);
  };

  projectReset = () => {
    // this.selectInputRef.current.select.clearValue();
  };

  showAlert = (id) => {
    //console.log(id);
    this.setState({
      alert: true,
      deleteId: id,
    });
  };
  setLocation = (e) => {
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        address: e,
      },
    }));
  };
  hideAlert = () => {
    this.setState({
      alert: false,
    });
  };
  deleteEmployee = (e) => {
    //console.log("delete", this.state);
    this.setState({ alert: false });
    let userData = getUserData();
    axios
      .post(
        apiDeleteEmployee,
        { id: this.state.deleteId },
        {
          headers: {
            Authorization: "Bearer " + userData.token,
          },
        }
      )
      .then(
        (response) => {
          //console.log(response);
          this.setState({ isLoading: false });
          toast.info("Employee deleted", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          this.setState(this.initialState);
          this.loadEmployees();
        },
        (error) => {
          if (error.response.status == 500 || error.response.status == 400) {
            toast.error(error.response.data.errorMessage, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            this.setState({ isLoading: false });
          }
        }
      );
  };

  handleOpenPasswordModal = (id) => {
    this.setState({ passId: id });
    this.setState({ isPasswordModalOpen: true });
    this.validator1.hideMessages();
  };
  handleClosePasswordModal = () => {
    this.setState({ isPasswordModalOpen: false });
    this.setState(initialState);
    this.projectReset();
    this.loadEmployees();
    this.loadMaster();
    this.validator1.hideMessages();
  };

  changePassword = (e) => {
    e.preventDefault();
    console.log("change pass called");
    console.log("oldpass", this.state.fields.newpassword2);
    console.log("newpass", this.state.fields.newpassword);

    if (this.validator1.allValid()) {
      console.log("change pass called");

      const userData = getUserData();

      let data = {
        newPassword: this.state.fields.newpassword,
        userId: this.state.passId,
      };
      console.log("change password data", data);
      this.setState({ isLoading: true });
      axios
        .post(apiChangePasswordForAdmin, data, {
          headers: {
            Authorization: "Bearer " + userData.token,
          },
        })
        .then(
          (response) => {
            console.log(response);
            //setUserData(response.data.data);
            this.setState({ isLoading: false });
            toast.info("Password updated", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
            this.setState(this.initialState);
            this.handleClosePasswordModal();
          },
          (error) => {
            if (error.response.status == 500 || error.response.status == 400) {
              toast.error(error.response.data.errorMessage, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              this.setState({ isLoading: false });
            }
          }
        );
    } else {
      this.validator1.showMessages();
      this.forceUpdate();
    }
  };

  handleChange = (e) => {
    // console.log(e.target.name, e.target.value);
    this.validator.showMessageFor(e.target.name);
    const value = e.target.value;
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        [e.target.name]: value,
      },
    }));
  };

  handleBlur = (e) => {
    this.validator.showMessageFor(e.target.name);
  };

  submitForm = (e) => {
    e.preventDefault();
    //console.log("submit");
    console.log("selected projects", this.state.selectedProjects);
    if (this.validator.allValid()) {
      /* console.log("hey", this.state);
      console.log(getUserData()); */
      let userData = getUserData();

      // console.log("selected projects", this.state.selectedProjects);
      // return;
      //setting project array with project id

      if (!this.state.editItem) {
        let project = [];

        //console.log("after appending", project);

        if (this.state.selectedProjects.length !== 0) {
          for (let i = 0; i < this.state.selectedProjects.length; i++) {
            project.push(this.state.selectedProjects[i].value);
          }
        }
        let perk = [];

        if (this.state.selectedPerks.length !== 0) {
          for (let i = 0; i < this.state.selectedPerks.length; i++) {
            perk.push(this.state.selectedPerks[i].value);
          }
        }
        /* console.log("projects value in submit", project);
        console.log("perks value in submit", perk); */

        var bodyFormData = new FormData();
        bodyFormData.append("name", this.state.fields.name);
        bodyFormData.append("fatherName", this.state.fields.fatherName);
        bodyFormData.append("address", this.state.fields.address);
        bodyFormData.append("dob", this.state.fields.dob);
        bodyFormData.append("adhaar", this.state.fields.adhaar);
        bodyFormData.append("drivingLicense", this.state.fields.drivingLicense);
        bodyFormData.append("mobileNo", this.state.fields.mobileNo);
        bodyFormData.append("bankName", this.state.fields.bankName);
        bodyFormData.append("accountNo", this.state.fields.accountNo);
        bodyFormData.append("ifscCode", this.state.fields.ifscCode);
        bodyFormData.append("pan", this.state.fields.pan);
        bodyFormData.append("uan", this.state.fields.uan);
        bodyFormData.append("designation", this.state.fields.designation);
        bodyFormData.append("salary", this.state.fields.salary);
        bodyFormData.append("doj", this.state.fields.doj);
        bodyFormData.append("esic", this.state.fields.esic);

        //bodyFormData.append("perks", this.state.fields.perks);
        bodyFormData.append("perks", this.state.selectedPerks);

        //passing id array to projects
        bodyFormData.append("projects", this.state.selectedProjects);
        bodyFormData.append("accommodation", this.state.fields.accommodation);
        bodyFormData.append("password", this.state.fields.password);
        bodyFormData.append("email", this.state.fields.email);
        bodyFormData.append("empId", this.state.fields.empId);
        bodyFormData.append("role", this.state.fields.role);
        bodyFormData.append("resume", this.state.resume);
        bodyFormData.append("adhaarFile", this.state.adhaarFile);
        bodyFormData.append(
          "qualificationCertificates",
          this.state.qualificationCertificates
        );
        bodyFormData.append(
          "experienceCertificate",
          this.state.experienceCertificate
        );
        bodyFormData.append("panCardFile", this.state.panCardFile);
        bodyFormData.append(
          "drivingLicenseFile",
          this.state.drivingLicenseFile
        );

        this.setState({ isLoading: true });
        axios
          .post(apiCreateEmployee, bodyFormData, {
            headers: {
              Authorization: "Bearer " + userData.token,
            },
          })
          .then(
            (response) => {
              //console.log(response);
              // this.setState({ isLoading: false });
              toast.info("Employee created", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
              this.setState(this.initialState);
              this.handleCloseModal();
            },
            (error) => {
              if (
                error.response.status == 500 ||
                error.response.status == 400
              ) {
                toast.error(error.response.data.errorMessage, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                this.setState({ isLoading: false });
              }
            }
          );
      } else {
        let project = [];

        //console.log("after appending", project);
        if (this.state.fields.projects) {
          let splitProject = this.state.fields.projects.split`,`.map((x) => +x);
          //console.log("splitProject", splitProject);
          project = [...splitProject];
          //console.log("after concat", project);
        }
        if (this.state.selectedProjects.length !== 0) {
          for (let i = 0; i < this.state.selectedProjects.length; i++) {
            project.push(this.state.selectedProjects[i].value);
          }
        }
        let perk = [];
        if (this.state.fields.perks) {
          let splitPerks = this.state.fields.perks.split(",");
          //console.log("splitPerks", splitPerks);
          perk = [...splitPerks];
          //console.log("after concat", perk);
        }
        if (this.state.selectedPerks.length !== 0) {
          for (let i = 0; i < this.state.selectedPerks.length; i++) {
            perk.push(this.state.selectedPerks[i].value);
          }
        }

        //console.log("inside edit employee");
        var bodyFormData = new FormData();
        bodyFormData.append("name", this.state.fields.name);
        bodyFormData.append("fatherName", this.state.fields.fatherName);
        bodyFormData.append("address", this.state.fields.address);
        bodyFormData.append("dob", this.state.fields.dob);
        bodyFormData.append("adhaar", this.state.fields.adhaar);
        bodyFormData.append("drivingLicense", this.state.fields.drivingLicense);
        bodyFormData.append("mobileNo", this.state.fields.mobileNo);
        bodyFormData.append("bankName", this.state.fields.bankName);
        bodyFormData.append("accountNo", this.state.fields.accountNo);
        bodyFormData.append("ifscCode", this.state.fields.ifscCode);
        bodyFormData.append("pan", this.state.fields.pan);
        bodyFormData.append("uan", this.state.fields.uan);
        bodyFormData.append("designation", this.state.fields.designation);
        bodyFormData.append("salary", this.state.fields.salary);
        bodyFormData.append("doj", this.state.fields.doj);
        bodyFormData.append("esic", this.state.fields.esic);
        bodyFormData.append("status", this.state.fields.status);

        /* bodyFormData.append("perks", this.state.fields.perks);
        bodyFormData.append("projects", this.state.fields.projects);
         */

        bodyFormData.append("perks", this.state.selectedPerks);
        bodyFormData.append("projects", this.state.selectedProjects);

        bodyFormData.append("accommodation", this.state.fields.accommodation);
        bodyFormData.append("password", this.state.fields.password);
        bodyFormData.append("email", this.state.fields.email);
        bodyFormData.append("resume", this.state.resume);
        bodyFormData.append("empId", this.state.fields.empId);
        bodyFormData.append("role", this.state.fields.role);

        bodyFormData.append("id", this.state.editId);
        bodyFormData.append("adhaarFile", this.state.adhaarFile);

        bodyFormData.append(
          "qualificationCertificates",
          this.state.qualificationCertificates
        );
        bodyFormData.append(
          "experienceCertificate",
          this.state.experienceCertificate
        );
        bodyFormData.append(
          "experienceCertificate",
          this.state.experienceCertificate
        );
        bodyFormData.append("panCardFile", this.state.panCardFile);
        bodyFormData.append(
          "drivingLicenseFile",
          this.state.drivingLicenseFile
        );

        this.setState({ isLoading: true });
        axios
          .post(apiEditEmployee, bodyFormData, {
            headers: {
              Authorization: "Bearer " + userData.token,
            },
          })
          .then(
            (response) => {
              //console.log(response);
              // this.setState({ isLoading: false });
              toast.info("Employee Updated", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
              this.setState(this.initialState);
              this.handleCloseModal();
            },
            (error) => {
              if (
                error.response.status == 500 ||
                error.response.status == 400
              ) {
                toast.error(error.response.data.errorMessage, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                this.setState({ isLoading: false });
              }
            }
          );
      }
    } else {
      /* console.log("fail");
      console.log("errors", this.validator.getErrorMessages()); */
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  render() {
    this.validator.purgeFields();

    return (
      <React.Fragment>
        <Helmet>
          <title>Employees | MRC</title>
        </Helmet>
        <section className="admin">
          <AdminHeader></AdminHeader>
          <div className="wrapper d-flex">
            <AdminMenu></AdminMenu>

            <div className="wrapper_body">
              <div className="row d-flex align-items-center">
                <div className="col m0 d-flex align-items-center">
                  <h3 className="heading">Employees</h3>
                </div>
                <Modal
                  actions={[,]}
                  bottomSheet={false}
                  fixedFooter={false}
                  id="Modal-0"
                  className="cstm-modal"
                  open={this.state.isPasswordModalOpen}
                  options={{
                    dismissible: false,
                    endingTop: "10%",
                    inDuration: 250,
                    onCloseEnd: null,
                    onCloseStart: null,
                    onOpenEnd: null,
                    onOpenStart: null,
                    opacity: 0.5,
                    outDuration: 250,
                    preventScrolling: true,
                    startingTop: "4%",
                  }}
                >
                  {/* Close Modal */}
                  <a
                    href="javascript:void(0)"
                    className="modal-close"
                    onClick={this.handleClosePasswordModal}
                  >
                    <span className="material-icons">chevron_right</span>
                  </a>
                  {/* Modal Content */}
                  <h3 className="heading">Change Password</h3>
                  <form action>
                    <div className="row">
                      <div className="col  s12">
                        <label className="label">New Password</label>
                        <input
                          type="password"
                          placeholder="New Password"
                          name="newpassword"
                          onChange={this.handleChange}
                          onBlur={this.handleBlur}
                        />
                        <label className="error">
                          {this.validator1.message(
                            "newpassword",
                            this.state.fields.newpassword,
                            "required"
                          )}
                        </label>
                      </div>

                      <div className="col  s12">
                        <label className="label">Confirm Password</label>
                        <input
                          type="password"
                          placeholder="Confirm password"
                          name="newpassword2"
                          onChange={this.handleChange}
                          onBlur={this.handleBlur}
                        />
                        <label className="error">
                          {this.validator1.message(
                            "confirm_password",
                            this.state.fields.newpassword2,
                            `required|in:${this.state.fields.newpassword}`,
                            { messages: { in: "Passwords need to match!" } }
                          )}
                        </label>
                      </div>

                      <div className="row">
                        <div className="col s12 right-align">
                          <button
                            type="submit"
                            className="theme_btn btn"
                            onClick={this.changePassword}
                          >
                            Update password
                            {this.state.isLoading ? (
                              <ButtonLoader></ButtonLoader>
                            ) : null}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </Modal>

                <div className="col m4 right-align">
                  <a
                    href="javascript:void(0)"
                    className="theme_btn btn waves-effect"
                    onClick={this.handleOpenModal}
                  >
                    <i className="material-icons">add</i> New Employee
                  </a>
                  <Modal
                    actions={[,]}
                    bottomSheet={false}
                    fixedFooter={false}
                    id="Modal-0"
                    open={this.state.isModalOpen}
                    className="cstm-modal"
                    options={{
                      dismissible: false,
                      endingTop: "10%",
                      inDuration: 250,
                      onCloseEnd: null,
                      onCloseStart: null,
                      onOpenEnd: null,
                      onOpenStart: null,
                      opacity: 0.5,
                      outDuration: 250,
                      preventScrolling: true,
                      startingTop: "4%",
                    }}
                  >
                    {/* Close Modal */}
                    <a
                      href="javascript:void(0)"
                      className="modal-close"
                      onClick={this.handleCloseModal}
                    >
                      <span className="material-icons">chevron_right</span>
                    </a>
                    {/* Modal Content */}
                    <h3 className="heading">
                      {this.state.editItem
                        ? "Update Employee"
                        : "Create Employee"}
                    </h3>
                    <p>
                      Enter details to {this.state.editItem ? "update" : "new"}{" "}
                      employee
                    </p>
                    <form
                      action
                      id="createEmployeeForm"
                      ref={(ele) => (this.myFormRef = ele)}
                    >
                      <fieldset>
                        <div className="row">
                          <div className="col  s12">
                            <label className="label">Employee ID</label>
                            <input
                              type="text"
                              placeholder="Employee ID"
                              name="empId"
                              readOnly
                              value={this.state.fields.empId}
                            />
                          </div>

                          <div className="col  s12">
                            <label className="label">Employee Name</label>
                            <input
                              type="text"
                              placeholder="Employee Name"
                              name="name"
                              value={this.state.fields.name}
                              onChange={this.handleChange}
                              onBlur={this.handleBlur}
                            />
                            <label className="error">
                              {this.validator.message(
                                "name",
                                this.state.fields.name,
                                "required|alpha_space"
                              )}
                            </label>
                            {/* <label className="error">
                                  {this.state.errors.name
                                    ? this.state.errors.name
                                    : ""}
                                </label> */}
                          </div>

                          <div className="col s12">
                            <label className="label">
                              Employee Father's Name
                            </label>
                            <input
                              type="text"
                              placeholder="Employee Father's Name"
                              name="fatherName"
                              value={this.state.fields.fatherName}
                              onChange={this.handleChange}
                              onBlur={this.handleBlur}
                            />
                            <label className="error">
                              {this.validator.message(
                                "fatherName",
                                this.state.fields.fatherName,
                                "required|alpha_space"
                              )}
                            </label>
                          </div>

                          <div className="col s12">
                            <label className="label">Employee Address</label>
                            <AutoComplete
                              apiKey={process.env.REACT_APP_GOOGLE_KEY}
                              onPlaceSelected={(place) => {
                                //console.log(place.formatted_address);
                                this.setLocation(place.formatted_address);
                              }}
                              defaultValue={this.state.fields.address}
                              name="address"
                              onChange={this.handleChange}
                              onBlur={this.handleBlur}
                            />
                            {/* <input
                                  type="text"
                                  placeholder=""
                                  name="address"
                                  onBlur={this.form.handleBlurEvent}
                                  onChange={this.form.handleChangeEvent}
                                  value={this.state.fields.address}
                                /> */}
                            <label className="error">
                              {this.validator.message(
                                "address",
                                this.state.fields.address,
                                "required"
                              )}
                            </label>
                          </div>

                          <div className="col  s12">
                            <label className="label">DOB</label>
                            {/* <DatePicker
                                  dateFormat="MMMM d, yyyy"
                                  className="form-control"
                                  placeholder="DOB"
                                  name="dob"
                                  onBlur={this.form.handleBlurEvent}
                                  selected={this.state.startDate}
                                  onChange={this.handleChange}
                                  // value={this.state.fields.dob}
                                /> */}
                            {/* <InputMask
                              mask='99/99/9999'
                              className='form-control'
                              onChange={this.handleDate}
                              onSelect={this.handleDate}
                              name='dob'
                              placeholder='Date of birth'
                              value={this.state.fields.dob}
                              onChange={this.handleChange}
                              onBlur={this.handleBlur}
                            /> */}
                            <input
                              type="date"
                              name="dob"
                              onChange={this.handleChange}
                              onBlur={this.handleBlur}
                              value={this.state.fields.dob}
                            />
                            {/* <input
                                  type="text"
                                  placeholder="DOB"
                                  name="dob"
                                  onBlur={this.form.handleBlurEvent}
                                  onChange={this.form.handleChangeEvent}
                                  value={this.state.fields.dob}
                                /> */}
                            {/* <input
                              type='date'
                              placeholder='DOB'
                              name='dob'
                              onChange={this.handleChange}
                              onBlur={this.handleBlur}
                              value={this.state.fields.dob}
                            /> */}
                            <label className="error">
                              {this.validator.message(
                                "dob",
                                this.state.fields.dob,
                                "required"
                              )}
                            </label>
                          </div>

                          <div className="col s12">
                            <label className="label">Aadhaar</label>
                            <input
                              type="text"
                              placeholder="Aadhaar"
                              name="adhaar"
                              value={this.state.fields.adhaar}
                              onChange={this.handleChange}
                              onBlur={this.handleBlur}
                            />
                            {/* <label className="error">
                              {this.validator.message(
                                "adhaar",
                                this.state.fields.adhaar,
                                "required|regex:^[0-9]{4}[ -]?[0-9]{4}[ -]?[0-9]{4}$"
                              )}
                            </label> */}
                          </div>

                          <div className="col  s12">
                            <label className="label">Driving License</label>
                            <input
                              type="text"
                              placeholder="Driving License"
                              name="drivingLicense"
                              value={this.state.fields.drivingLicense}
                              onChange={this.handleChange}
                              onBlur={this.handleBlur}
                            />
                            {/* <label className="error">
                              {this.validator.message(
                                "drivingLicense",
                                this.state.fields.drivingLicense,
                                "required"
                              )}
                            </label> */}
                          </div>

                          <div className="col s12">
                            <label className="label">Email</label>
                            <input
                              type="email"
                              placeholder="Email"
                              name="email"
                              value={this.state.fields.email}
                              onChange={this.handleChange}
                              onBlur={this.handleBlur}
                            />
                            <label className="error">
                              {this.validator.message(
                                "email",
                                this.state.fields.email,
                                "required|email"
                              )}
                            </label>
                          </div>

                          <div className="col  s12">
                            <label className="label">Mobile No.</label>

                            <input
                              type="text"
                              placeholder="Mobile No."
                              name="mobileNo"
                              value={this.state.fields.mobileNo}
                              onChange={this.handleChange}
                              onBlur={this.handleBlur}
                            />

                            <label className="error">
                              {this.validator.message(
                                "mobileNo",
                                this.state.fields.mobileNo,
                                "required|phone"
                              )}
                            </label>
                          </div>

                          {this.state.editItem ? null : (
                            <>
                              <div className="col m6 s12">
                                <label className="label">Password</label>
                                <input
                                  type="Password"
                                  placeholder="*******"
                                  name="password"
                                  value={this.state.fields.password}
                                  onChange={this.handleChange}
                                  onBlur={this.handleBlur}
                                />
                                {this.state.editItem ? null : (
                                  <label className="error">
                                    {this.validator.message(
                                      "password",
                                      this.state.fields.password,
                                      "required"
                                    )}
                                  </label>
                                )}
                              </div>

                              <div className="col m6 s12">
                                <label className="label">
                                  Confirm Password
                                </label>
                                <input
                                  type="Password"
                                  placeholder="*******"
                                  name="confirmPassword"
                                  value={this.state.fields.confirmPassword}
                                  onChange={this.handleChange}
                                  onBlur={this.handleBlur}
                                />
                                {this.state.editItem ? null : (
                                  <label className="error">
                                    {this.validator.message(
                                      "confirmPassword",
                                      this.state.fields.confirmPassword,
                                      "required"
                                    )}
                                  </label>
                                )}
                              </div>
                            </>
                          )}
                        </div>
                      </fieldset>

                      <fieldset>
                        <div className="row">
                          <div className="button-wrap col  s12">
                            <label className="new-button" htmlFor="upload">
                              Upload Resume
                              <input
                                id="upload"
                                type="file"
                                name="resume"
                                onChange={this.fileChange}
                              />
                            </label>
                            <br />
                            {this.state.resume ? (
                              <>
                                <span>
                                  {this.state.resume.name}
                                  <i class="tiny material-icons">attach_file</i>
                                </span>
                              </>
                            ) : (
                              this.state.editItem && (
                                <>
                                  <span>
                                    {this.state.fields.resume &&
                                      this.state.fields.resume.split("/").pop()}
                                    <i class="tiny material-icons">
                                      attach_file
                                    </i>
                                  </span>
                                </>
                              )
                            )}

                            {/*  {this.state.editItem ? null : (
                              <label className="error">
                                {this.validator.message(
                                  "resume",
                                  this.state.resume,
                                  "required"
                                )}
                              </label>
                            )} */}
                          </div>
                          <div className="button-wrap col  s12">
                            <label className="new-button" htmlFor="upload2">
                              Upload Qualification Certificates
                              <input
                                id="upload2"
                                type="file"
                                name="qualificationCertificates"
                                onChange={this.fileChange}
                              />
                            </label>
                            <br />
                            {this.state.qualificationCertificates ? (
                              <>
                                <span>
                                  {this.state.qualificationCertificates.name}
                                  <i class="tiny material-icons">attach_file</i>
                                </span>
                              </>
                            ) : (
                              this.state.editItem && (
                                <>
                                  <span>
                                    {this.state.fields
                                      .qualificationCertificates &&
                                      this.state.fields.qualificationCertificates
                                        .split("/")
                                        .pop()}
                                    <i class="tiny material-icons">
                                      attach_file
                                    </i>
                                  </span>
                                </>
                              )
                            )}
                            {/* {this.state.editItem ? null : (
                              <label className="error">
                                {this.validator.message(
                                  "qualificationCertificates",
                                  this.state.qualificationCertificates,
                                  "required"
                                )}
                              </label>
                            )} */}
                          </div>
                          <div className="button-wrap col  s12">
                            <label className="new-button" htmlFor="upload3">
                              Upload Experience Certificates
                              <input
                                id="upload3"
                                type="file"
                                name="experienceCertificate"
                                onChange={this.fileChange}
                              />
                            </label>{" "}
                            <br />
                            {this.state.experienceCertificate ? (
                              <>
                                <span>
                                  {this.state.experienceCertificate.name}
                                  <i class="tiny material-icons">attach_file</i>
                                </span>
                              </>
                            ) : (
                              this.state.editItem && (
                                <>
                                  <span>
                                    {this.state.fields.experienceCertificate &&
                                      this.state.fields.experienceCertificate
                                        .split("/")
                                        .pop()}
                                    <i class="tiny material-icons">
                                      attach_file
                                    </i>
                                  </span>
                                </>
                              )
                            )}
                            {/* {this.state.editItem ? null : (
                              <label className="error">
                                {this.validator.message(
                                  "experienceCertificate",
                                  this.state.experienceCertificate,
                                  "required"
                                )}
                              </label>
                            )} */}
                          </div>
                          <div className="button-wrap col  s12">
                            <label className="new-button" htmlFor="upload4">
                              Upload Aadhaar Card
                              <input
                                id="upload4"
                                type="file"
                                name="adhaarFile"
                                onChange={this.fileChange}
                              />
                            </label>
                            <br />
                            {this.state.adhaarFile ? (
                              <>
                                <span>
                                  {this.state.adhaarFile.name}
                                  <i class="tiny material-icons">attach_file</i>
                                </span>
                              </>
                            ) : (
                              this.state.editItem && (
                                <>
                                  <span>
                                    {this.state.fields.adhaarFile &&
                                      this.state.fields.adhaarFile
                                        .split("/")
                                        .pop()}
                                    <i class="tiny material-icons">
                                      attach_file
                                    </i>
                                  </span>
                                </>
                              )
                            )}

                            {/* {this.state.editItem ? null : (
                              <label className="error">
                                {this.validator.message(
                                  "adhaarFile",
                                  this.state.adhaarFile,
                                  "required"
                                )}
                              </label>
                            )} */}
                          </div>
                          <div className="button-wrap col  s12">
                            <label className="new-button" htmlFor="upload5">
                              PAN Card upload
                              <input
                                id="upload5"
                                type="file"
                                name="panCardFile"
                                onChange={this.fileChange}
                              />
                            </label>
                            <br />
                            {this.state.panCardFile ? (
                              <>
                                <span>
                                  {this.state.panCardFile.name}
                                  <i class="tiny material-icons">attach_file</i>
                                </span>
                              </>
                            ) : (
                              this.state.editItem && (
                                <>
                                  <span>
                                    {this.state.fields.panCardFile &&
                                      this.state.fields.panCardFile
                                        .split("/")
                                        .pop()}
                                    <i class="tiny material-icons">
                                      attach_file
                                    </i>
                                  </span>
                                </>
                              )
                            )}

                            {/* {this.state.editItem ? null : (
                              <label className="error">
                                {this.validator.message(
                                  "adhaarFile",
                                  this.state.adhaarFile,
                                  "required"
                                )}
                              </label>
                            )} */}
                          </div>
                          <div className="button-wrap col  s12">
                            <label className="new-button" htmlFor="upload6">
                              Driving License Upload
                              <input
                                id="upload6"
                                type="file"
                                name="drivingLicenseFile"
                                onChange={this.fileChange}
                              />
                            </label>
                            <br />
                            {this.state.drivingLicenseFile ? (
                              <>
                                <span>
                                  {this.state.drivingLicenseFile.name}
                                  <i class="tiny material-icons">attach_file</i>
                                </span>
                              </>
                            ) : (
                              this.state.editItem && (
                                <>
                                  <span>
                                    {this.state.fields.drivingLicenseFile &&
                                      this.state.fields.drivingLicenseFile
                                        .split("/")
                                        .pop()}
                                    <i class="tiny material-icons">
                                      attach_file
                                    </i>
                                  </span>
                                </>
                              )
                            )}

                            {/* {this.state.editItem ? null : (
                              <label className="error">
                                {this.validator.message(
                                  "adhaarFile",
                                  this.state.adhaarFile,
                                  "required"
                                )}
                              </label>
                            )} */}
                          </div>
                        </div>
                      </fieldset>

                      <fieldset>
                        <div className="row">
                          <div className="col m6 s12">
                            <label className="label">Bank Name</label>

                            <input
                              type="text"
                              placeholder="Bank Name"
                              name="bankName"
                              value={this.state.fields.bankName}
                              onChange={this.handleChange}
                              onBlur={this.handleBlur}
                            />
                            {/*  <label className="error">
                              {this.validator.message(
                                "bankName",
                                this.state.fields.bankName,
                                "required"
                              )}
                            </label> */}
                          </div>

                          <div className="col m6 s12">
                            <label className="label">Account No.</label>

                            <input
                              type="text"
                              placeholder="Account No."
                              name="accountNo"
                              value={this.state.fields.accountNo}
                              onChange={this.handleChange}
                              onBlur={this.handleBlur}
                            />
                            {/* <label className="error">
                              {this.validator.message(
                                "accountNo",
                                this.state.fields.accountNo,
                                "required"
                              )}
                            </label> */}
                          </div>

                          <div className="col m6 s12">
                            <label className="label">IFSC Code</label>

                            <input
                              type="text"
                              placeholder="IFSC Code"
                              name="ifscCode"
                              value={this.state.fields.ifscCode}
                              onChange={this.handleChange}
                              onBlur={this.handleBlur}
                            />
                            {/* <label className="error">
                              {this.validator.message(
                                "ifscCode",
                                this.state.fields.ifscCode,
                                "required"
                              )}
                            </label> */}
                          </div>

                          <div className="col m6 s12">
                            <label className="label">PAN</label>

                            <input
                              type="text"
                              placeholder="PAN"
                              name="pan"
                              value={this.state.fields.pan}
                              onChange={this.handleChange}
                              onBlur={this.handleBlur}
                            />
                            {/* <label className="error">
                              {this.validator.message(
                                "pan",
                                this.state.fields.pan,
                                "required"
                              )}
                            </label> */}
                          </div>

                          <div className="col m6 s12">
                            <label className="label">UAN</label>

                            <input
                              type="text"
                              placeholder="UAN"
                              name="uan"
                              value={this.state.fields.uan}
                              onChange={this.handleChange}
                              onBlur={this.handleBlur}
                            />
                            {/* <label className="error">
                              {this.validator.message(
                                "uan",
                                this.state.fields.uan,
                                "required"
                              )}
                            </label> */}
                          </div>
                        </div>
                      </fieldset>

                      <fieldset>
                        <div className="col s12">
                          {this.state.editItem && (
                            <>
                              <label className="label">Assigned Projects</label>
                              <input
                                type="text"
                                name="assProject"
                                readOnly
                                value={this.state.masterListProjects
                                  .filter(
                                    (item) =>
                                      this.state.fields.projects.indexOf(
                                        item.id
                                      ) > -1
                                  )
                                  .map((project) => project.name)}
                              />
                              <span></span>
                            </>
                          )}

                          <label className="label">Projects</label>

                          {/* <Select
                            id='projects'
                            multiple
                            name='projects'
                            options={{
                              classes: "",
                              dropdownOptions: {
                                alignment: "left",
                                autoTrigger: true,
                                closeOnClick: false,
                                constrainWidth: true,
                                coverTrigger: true,
                                hover: false,
                                inDuration: 150,
                                onCloseEnd: null,
                                onCloseStart: null,
                                onOpenEnd: null,
                                onOpenStart: null,
                                outDuration: 250,
                              },
                            }}
                            value={this.state.fields.projects}
                            onChange={this.handleChangeProject}
                            type='hidden'
                          >
                            <option disabled value=''>
                              Choose Projects
                            </option>
                            {this.state.masterListProjects.map(
                              (project, index) => (
                                <>
                                  <option value={project.id}>
                                    {project.name}
                                  </option>
                                </>
                              )
                            )}
                          </Select>
                          
                            //defaultValue={[colourOptions[2], colourOptions[3]]}
                          */}

                          {this.state.editItem ? (
                            <>
                              <Select
                                styles={customStyles}
                                multiple
                                mode="multiple"
                                className="multiSelect"
                                animation={"slide-up"}
                                ref={this.selectInputRef}
                                name="projects"
                                onChange={this.handleChangeProject}
                                options={this.state.masterListProjects.map(
                                  (project) => ({
                                    value: project.id,
                                    label: project.name,
                                  })
                                )}
                              />
                            </>
                          ) : (
                            <>
                              <Select
                                IsMulti={true}
                                multiple={true}
                                mode="multiple"
                                className="multiSelect"
                                animation={"slide-up"}
                                ref={this.selectInputRef}
                                name="projects"
                                onChange={this.handleChangeProject}
                                options={this.state.masterListProjects.map(
                                  (project) => ({
                                    value: project.id,
                                    label: project.name,
                                  })
                                )}
                              />
                              <label className="error">
                                {this.validator.message(
                                  "projects",
                                  this.state.selectedProjects,
                                  "required"
                                )}
                              </label>
                            </>
                          )}
                          {/* <select
                            name="project"
                            value={this.state.fields.project}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                          >
                            <option selected> Select</option>
                            {this.state.masterListProjects.map(
                              (project, index) => (
                                <>
                                  <option value={project.name}>
                                    {project.name}
                                  </option>
                                </>
                              )
                            )}
                          </select> */}
                        </div>

                        <div className="col s12">
                          <label className="label">Designation</label>
                          <select
                            name="designation"
                            value={this.state.fields.designation}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                          >
                            <option selected> Select</option>
                            {this.state.masterListDesignation.map(
                              (designation, index) => (
                                <>
                                  <option value={designation.name}>
                                    {designation.name}
                                  </option>
                                </>
                              )
                            )}
                          </select>
                          <label className="error">
                            {this.validator.message(
                              "designation",
                              this.state.fields.designation,
                              "required"
                            )}
                          </label>
                        </div>

                        <div className="col s12">
                          <label className="label">Roles</label>
                          <select
                            name="role"
                            value={this.state.fields.role}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                          >
                            <option selected> Select</option>
                            {this.state.masterListRoles.map((role, index) => (
                              <>
                                <option value={role.id}>{role.name}</option>
                              </>
                            ))}
                          </select>
                          <label className="error">
                            {this.validator.message(
                              "role",
                              this.state.fields.role,
                              "required"
                            )}
                          </label>
                        </div>

                        <div className="col m6 s12">
                          <label className="label">Monthly Salary (CTC)</label>
                          <input
                            type="text"
                            placeholder="Salary Details"
                            name="salary"
                            value={this.state.fields.salary}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                          />
                          <label className="error">
                            {this.validator.message(
                              "salary",
                              this.state.fields.salary,
                              "required"
                            )}
                          </label>
                        </div>
                        <div className="col m6 s12">
                          <label className="label">Date of Joining</label>
                          <input
                            type="date"
                            placeholder="Date of Joining"
                            name="doj"
                            value={this.state.fields.doj}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                          />
                          <label className="error">
                            {this.validator.message(
                              "doj",
                              this.state.fields.doj,
                              "required"
                            )}
                          </label>
                        </div>
                        <div className="col m6 s12">
                          <label className="label">ESIC Number</label>
                          <input
                            type="text"
                            placeholder="ESIC Number"
                            name="esic"
                            value={this.state.fields.esic}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                          />
                          <label className="error">
                            {this.validator.message(
                              "esic",
                              this.state.fields.esic,
                              "required"
                            )}
                          </label>
                        </div>
                      </fieldset>

                      <fieldset>
                        {this.state.editItem && (
                          <>
                            <label className="label">Assigned Perks</label>
                            <input
                              type="text"
                              name="assProject"
                              readOnly
                              value={this.state.masterListCompanyPerk.map(
                                (perk) => perk.name
                              )}
                            />
                          </>
                        )}

                        <label className="label label-heading">
                          Company Perks
                        </label>
                        <div className="col s12">
                          {this.state.editItem ? (
                            <Select
                              isMulti
                              name="perks"
                              mode="multiple"
                              className="multiSelect"
                              animation={"slide-up"}
                              ref={this.selectInputRef}
                              onChange={this.handleChangePerk}
                              options={this.state.masterListCompanyPerk.map(
                                (perk) => ({
                                  value: perk.name,
                                  label: perk.name,
                                })
                              )}
                            />
                          ) : (
                            <>
                              <Select
                                isMulti
                                name="perks"
                                mode="multiple"
                                className="multiSelect"
                                animation={"slide-up"}
                                ref={this.selectInputRef}
                                onChange={this.handleChangePerk}
                                options={this.state.masterListCompanyPerk.map(
                                  (perk) => ({
                                    value: perk.name,
                                    label: perk.name,
                                  })
                                )}
                              />
                              {/* <label className="error">
                                {this.validator.message(
                                  "perks",
                                  this.state.selectedPerks,
                                  "required"
                                )}
                              </label> */}
                            </>
                          )}
                        </div>

                        <div className="col m6 s12">
                          <label className="label label-heading">
                            Accommodation
                          </label>
                          <select
                            name="accommodation"
                            value={this.state.fields.accommodation}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                          >
                            <option selected> Select Accommodation</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>
                          <label className="error">
                            {this.validator.message(
                              "accommodation",
                              this.state.fields.accommodation,
                              "required"
                            )}
                          </label>
                        </div>
                      </fieldset>
                      {this.state.editItem && (
                        <div className="col m6 s12">
                          <label className="label label-heading">Status</label>
                          <select
                            name="status"
                            value={this.state.fields.status}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                          >
                            <option selected> Select Status</option>
                            <option value="1">Active</option>
                            <option value="2">Inactive</option>
                          </select>
                          <label className="error">
                            {this.validator.message(
                              "status",
                              this.state.fields.status,
                              "required"
                            )}
                          </label>
                        </div>
                      )}

                      <div className="row">
                        <div className="col s12 right-align">
                          <button
                            form="createEmployeeForm"
                            className="theme_btn btn"
                            onClick={this.submitForm}
                          >
                            {this.state.editItem
                              ? "Update Employee"
                              : "Add Employee"}
                            {this.state.isLoading ? (
                              <ButtonLoader></ButtonLoader>
                            ) : null}
                          </button>
                        </div>
                      </div>
                    </form>
                  </Modal>
                </div>
              </div>
              <div className="projects employee">
                <div className="row">
                  <div className="col s12">
                    <p className="grey-text">
                      <b>Filters</b>
                    </p>
                    <form ref={(ele) => (this.myFormRef = ele)}>
                      <div className="row">
                        <div className="col m3 s12">
                          <label>Name</label>
                          <input
                            type="text"
                            className=""
                            placeholder="Name"
                            name="name"
                            onChange={this.handleSearch}
                          />
                        </div>

                        <div className="col m3 s12">
                          <label>Designation</label>
                          <input
                            type="text"
                            className=""
                            placeholder="Designation"
                            name="designation"
                            onChange={this.handleSearch}
                          />
                        </div>

                        <div className="col m3 s12">
                          <label>Projects</label>
                          <Select
                            IsMulti={true}
                            multiple={true}
                            mode="multiple"
                            className="multiSelect"
                            animation={"slide-up"}
                            ref={this.selectInputRef}
                            name="projects"
                            // onChange={this.handleChangeProject}
                            options={this.state.masterListProjects.map(
                              (project) => ({
                                value: project.id,
                                label: project.name,
                              })
                            )}
                          />
                        </div>

                        <div className="col m3 s12">
                          <label>Status</label>
                          <select name="status" onChange={this.handleSearch}>
                            <option selected>Status</option>
                            <option value={1}>Active</option>
                            <option value={2}>Inactive</option>
                          </select>
                        </div>

                        <div className="col m2 s12"></div>
                      </div>

                      <div className="row">
                        <div class="col m3 s12">
                          <a
                            href="javascript:void(0)"
                            class="red-text pt-1"
                            onClick={this.clearFilter}
                          >
                            Clear Filter
                          </a>

                          <button
                            type="submit"
                            className="theme_btn btn filter-submit"
                            onClick={this.submitSearch}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="row">
                  <div className="col s12">
                    <div className="table-responsive">
                      <table>
                        <thead>
                          <tr>
                            <th>Emp ID</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Roles</th>
                            <th>Status</th>
                            <th>Change Password</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.isListLoading ? (
                            <tr>
                              <td colSpan={5}>
                                <BulletList></BulletList>
                              </td>
                            </tr>
                          ) : (
                            this.state.employeeList.map((employee, index) => (
                              <>
                                <tr key={index}>
                                  <td data-th="Emp ID">{employee.empId}</td>
                                  <td data-th="Name">{employee.name}</td>
                                  <td data-th="Email">{employee.user.email}</td>
                                  <td data-th="Phone">{employee.mobileNo}</td>
                                  <td data-th="Roles">
                                    {this.state.masterListRoles.map(
                                      (role) =>
                                        role.id == employee.role && (
                                          <p className="chip">{role.name}</p>
                                        )
                                    )}
                                  </td>
                                  <td>
                                    {employee.status === 1 ? (
                                      <b className="green-text">Active</b>
                                    ) : employee.status === 2 ? (
                                      <b className="red-text">Inactive</b>
                                    ) : null}
                                  </td>
                                  <td>
                                    <a
                                      href="#Modal-0"
                                      className="actions"
                                      node="button"
                                      onClick={() =>
                                        this.handleOpenPasswordModal(
                                          employee.user.id
                                        )
                                      }
                                    >
                                      <i className="material-icons">lock</i>
                                    </a>
                                  </td>
                                  <td>
                                    <Link
                                      to={`/employeedetails/${employee.id}`}
                                      className="actions"
                                    >
                                      <i className="material-icons">
                                        remove_red_eye
                                      </i>
                                    </Link>
                                    <a
                                      href="javascript:void(0)"
                                      className="actions"
                                      node="button"
                                      onClick={() =>
                                        this.editEmployee(employee.id)
                                      }
                                    >
                                      <i className="material-icons">edit</i>
                                    </a>
                                    {/* <a
                                      href='javascript:void(0)'
                                      className='actions'
                                      onClick={() =>
                                        this.showAlert(employee.id)
                                      }
                                    >
                                      <i className='material-icons'>delete</i>
                                    </a> */}
                                  </td>
                                </tr>
                              </>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div
                  className="col s12"
                  style={{ marginTop: 20, marginBottom: 20 }}
                >
                  <Pagination
                    pageSize={this.state.pageSize}
                    onChange={this.onChangePagination}
                    current={this.state.current}
                    total={this.state.employeeCount}
                    showSizeChanger
                    defaultPageSize={this.state.pageSize}
                    defaultCurrent={this.state.current}
                    onShowSizeChange={this.onChangePageSize}
                    locale={localeInfo}
                    selectComponentClass={Select}
                    pageSizeOptions={[10, 30, 50, 100]}
                    showTotal={(total, range) =>
                      `${range[0]} - ${range[1]} of ${total} items`
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          {this.state.alert && (
            <SweetAlert
              onConfirm={this.deleteEmployee}
              onCancel={this.hideAlert}
              focusCancelBtn={true}
              warning
              showCancel
              confirmBtnText="Yes, delete it!"
              confirmBtnBsStyle="danger"
              title="Are you sure?"
            />
          )}
          <Footer />
        </section>
        {/* Add Employee Modal */}
      </React.Fragment>
    );
  }
}
