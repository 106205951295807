import React, { Component } from "react";
import { getUserData } from "../helpers/storage";
import { apiGetEmployee } from "../config/endpoints";
import { BulletList } from "react-content-loader";

export default class EmployeeProfile extends Component {
  constructor(props) {
    super(props);
    let userData = getUserData();
    this.state = {
      userData: userData,
      employeeData: {},
      user: {},
      isListLoading: false,
    };
  }

  componentWillMount() {
    let userData = getUserData();
    fetch(apiGetEmployee + "?id=" + this.state.userData.user.userKey, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("employee data", data.data.employee);
        if (data.code === 200) {
          this.setState({
            employeeData: data.data.employee,
            user: data.data.employee.user,
            isListLoading: false,
          });

          console.log("cur", this.state);
        } else {
          alert("something went wrong.");
        }
      });
  }

  render() {
    return (
      <>
        {this.state.isListLoading ? (
          <BulletList></BulletList>
        ) : (
          <>
            <div>
              <form>
                <label>Name:</label>
                <input
                  type="text"
                  name="name"
                  value={this.state.employeeData.name}
                  readOnly
                  /* onBlur={this.form.handleBlurEvent}
                  onChange={this.form.handleChangeEvent} */
                />
                <label>Emp Id:</label>
                <input
                  type="text"
                  name="empId"
                  value={this.state.employeeData.empId}
                  readOnly
                />
                <label>Date of Birth:</label>
                <input
                  type="text"
                  name="dob"
                  value={this.state.employeeData.dob}
                  readOnly
                />

                <label>Email:</label>
                <input
                  type="text"
                  name="email"
                  value={this.state.user.email}
                  readOnly
                />
                <label>Father's Name:</label>
                <input
                  type="text"
                  name="fatherName"
                  value={this.state.employeeData.fatherName}
                  readOnly
                />
                <label>Address</label>
                <input
                  type="text"
                  name="address"
                  value={this.state.employeeData.address}
                  readOnly
                />
                <label>Mobile No:</label>
                <input
                  type="text"
                  name="mobileNo"
                  value={this.state.employeeData.mobileNo}
                  readOnly
                />
                <label>Designation:</label>
                <input
                  type="text"
                  name="designation"
                  value={this.state.employeeData.designation}
                  readOnly
                />
              </form>
            </div>
          </>
        )}
      </>
    );
  }
}
