import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import ButtonLoader from "../../components/ButtonLoader";
import axios from "axios";
import Footer from "../../components/Footer";
import ProjectHeader from "../../components/header/ProjectHeader";
import Sidebar from "../../components/sidebar/Sidebar";
import ProjectMenu from "../../components/sidebar/ProjectMenu";
import { toast } from "react-toastify";
import { Modal, Badge, Icon } from "react-materialize";
import { setUserData, getUserData, getProjectID } from "../../helpers/storage";

import { formatAmount, customStyles } from "../../helpers/util";
import SweetAlert from "react-bootstrap-sweetalert";
import { BulletList } from "react-content-loader";
import SimpleReactValidator from "simple-react-validator";
import { Helmet } from "react-helmet";
import Pagination from "rc-pagination";
import Select from "rc-select";
import "rc-select/assets/index.less";
import "../../assets/pagination.less";
import localeInfo from "../../helpers/en_US";
import Moment from "react-moment";
// import { Select as Select2 } from "react-select";
import { Dropdown } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";

import {
  apiListExpenseHeadsParents,
  apiListExpenseSubHeads,
  apiListDaybook,
  apigetDaybook,
  apiDaybookExpenseLog,
  apiListVendors,
  apiListProjects,
  apiDaybookCreate,
  apiDaybookEdit,
  apiListAllExpenseSubHeads,
  apiGetMe,
  apiDaybookDelete,
  apiListPo,
} from "../../config/endpoints";
const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];
const initialState = {
  fields: {
    time: "",
    date: "",
    project: "",
    head: "",
    subhead: "",
    vendor: "",
    billno: "",
    amount: "",
    paymentmode: "",
    remarks: "",
    status: "",
    po: 0,
  },
  isLoading: false,
  redirect: false,
  errors: {},
  expenseHeadList: [],
  poList: [],
  isModalOpen: false,
  isViewModalOpen: false,
  isStatusModalOpen: false,
  isListLoading: false,
  alert: false,
  deleteId: "",
  editId: false,
  editItem: false,
  expenseHeadParentsList: [],
  expenseSubHeadList: [],
  allExpenseSubHeadList: [],
  vendorList: [],
  projectList: [],
  logs: [],
  bill: null,
  user: [],
  userPermission: [],
  cashInHand: "",
  selectOptionForSubhead: [],
  selectOptionForVendor: [],
  selectOptionForVendorEdit: [],
  filteredData: false,
  pageSize: 30,
};

export default class Daybook extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.fileChange = this.fileChange.bind(this);
  }

  onChangePagination = (page) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        current: page,
      }),
      this.loadMasterData
    );
  };
  onChangePageSize = (current, pageSize) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        current: current,
        pageSize: pageSize,
      }),
      this.loadMasterData
    );
  };

  onShowSizeChange = (current, pageSize) => {
    console.log(current);
    this.setState({ pageSize });
  };

  handleCloseModal = () => {
    this.setState(initialState);
    this.loadMasterData();
    this.validator.hideMessages();
  };

  handleOpenModal = () => {
    this.loadMasterData();
    this.setState({ isModalOpen: true });
    this.validator.hideMessages();
  };
  handleOpenViewModal = (id) => {
    this.getDaybookExpenseLog(id);
    this.setState({ isViewModalOpen: true });
  };
  handleCloseViewModal = () => {
    this.setState({ isViewModalOpen: false });
  };

  handleOpenStatusModal = (id) => {
    this.setState({ isStatusModalOpen: true });
  };
  handleCloseStatusModal = () => {
    this.setState({ isStatusModalOpen: false });
    this.setState(
      {
        fields: {
          vendor: "",
          subhead: "",
          to: "",
          from: "",
          paymentmode: "",
        },
      },
      this.loadMasterData
    );
  };

  editStatus = (id) => {
    this.setState({ editItem: true, editId: id });
    this.handleOpenStatusModal();
    let userData = getUserData();
    let projectId = getProjectID();

    fetch(apigetDaybook + "?id=" + id + "&project_id=" + projectId, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          //console.log("singledaybook data for status", data.data.dayBook);
          this.setState({
            fields: data.data.dayBook,
          });
        } else {
          alert("something went wrong.");
        }
      });
  };

  submitStatus = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      const userData = getUserData();
      var bodyFormData = new FormData();

      bodyFormData.append("remarks", this.state.fields.remarks);
      bodyFormData.append("status", this.state.fields.status);
      bodyFormData.append("id", this.state.editId);

      this.setState({ isLoading: true });
      axios
        .post(apiDaybookEdit, bodyFormData, {
          headers: {
            Authorization: "Bearer " + userData.token,
          },
        })
        .then(
          (response) => {
            //console.log("edit respose", response);
            localStorage.setItem(
              "additionalData",
              JSON.stringify(response.data.data.user)
            );
            this.setState({ isLoading: false });
            toast.info("Record updated", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
            this.setState(this.initialState);
            this.handleCloseStatusModal();
          },
          (error) => {
            if (error.response.status == 500 || error.response.status == 400) {
              toast.error(error.response.data.errorMessage, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              this.setState({ isLoading: false });
            }
          }
        );
    }
  };

  fileChange = (e) => {
    //console.log("test", e);
    if (e.target.files[0]) {
      //console.log("picture: ", e.target.files);
      this.setState({ bill: e.target.files[0] });
    }
  };

  handleSearchSelect = (e, value) => {
    this.setState(
      (prevState) => ({
        fields: {
          ...prevState.fields,
          vendor: value.key,
        },
        selectOptionForVendorEdit: [
          {
            value: value.value,
            label: value,
            key: value.key,
            name: "vendor",
          },
        ],
      }),
      () => {
        console.log("Selected", this.state.fields);
      }
    );
  };

  handleChange = (e) => {
    //console.log(e.target.name, e.target.value);
    this.validator.showMessageFor(e.target.name);
    const value = e.target.value;
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        [e.target.name]: value,
      },
    }));
    if (e.target.name == "head") {
      let userData = getUserData();
      fetch(apiListExpenseSubHeads + "?id=" + value, {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + userData.token,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.code == 200) {
            this.setState({
              expenseSubHeadList: data.data.expensehead,
            });
          } else {
            alert("something went wrong.");
          }
        });
    }
  };

  handleSearch = (e) => {
    this.validator.showMessageFor(e.target.name);
    const value = e.target.value;
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        [e.target.name]: value,
      },
    }));
  };
  handleBlur = (e) => {
    this.validator.showMessageFor(e.target.name);
  };
  editMaster(id) {
    // Assuming only image
    this.setState({ editItem: true, editId: id });
    this.handleOpenModal();
    let userData = getUserData();
    let projectId = getProjectID();
    fetch(apigetDaybook + "?id=" + id + "&project_id=" + projectId, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          //console.log("single daybook", data.data.dayBook);
          this.setState({
            fields: data.data.dayBook,
          });

          const vendorOption = [
            {
              value: data.data.dayBook.vendors.id,
              label: data.data.dayBook.vendors.name,
              name: "vendor",
            },
          ];
          this.setState({ selectOptionForVendorEdit: vendorOption });

          fetch(apiListExpenseSubHeads + "?id=" + data.data.dayBook.head, {
            method: "get",
            headers: new Headers({
              Authorization: "Bearer " + userData.token,
            }),
          })
            .then((response) => response.json())
            .then((data) => {
              if (data.code == 200) {
                this.setState({
                  expenseSubHeadList: data.data.expensehead,
                });
              } else {
                alert("something went wrong.");
              }
            });
        } else {
          alert("something went wrong.");
        }
      });
    fetch(apiDaybookExpenseLog + "?id=" + id + "&project_id=" + projectId, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          this.setState({
            logs: data.data.log,
          });
        } else {
          alert("something went wrong.");
        }
      });
  }
  getDaybookExpenseLog(id) {
    let userData = getUserData();
    let projectId = getProjectID();
    fetch(apiDaybookExpenseLog + "?id=" + id + "&project_id=" + projectId, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          this.setState({
            logs: data.data.log,
          });
        } else {
          alert("something went wrong.");
        }
      });
  }
  submitSearch = (e) => {
    e.preventDefault();
    let userData = getUserData();
    let projectId = getProjectID();
    let data = {
      from: this.state.fields.from,
      to: this.state.fields.to,
      paymentmode: this.state.fields.paymentmode,
      vendor: this.state.fields.vendor,
    };
    this.setState({ isLoading: true });
    //this.setState({ filteredData: true });
    fetch(
      apiListDaybook +
        "?project_id=" +
        projectId +
        "&vendor=" +
        this.state.fields.vendor +
        "&status=" +
        this.state.fields.status +
        "&from=" +
        (this.state.fields.from != undefined ? this.state.fields.from : "") +
        "&to=" +
        (this.state.fields.to != undefined ? this.state.fields.to : "") +
        "&paymentmode=" +
        this.state.fields.paymentmode +
        "&subhead=" +
        this.state.fields.subhead,
      {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + userData.token,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          //console.log("filtered data", data.data.dayBook);
          this.setState({
            dayBook: data.data.dayBook,
            dayBookCount: data.data.dayBook.count,
          });
        } else {
          alert("something went wrong.");
        }
      });
  };
  submitForm = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      let userData = getUserData();
      if (!this.state.editItem) {
        var bodyFormData = new FormData();
        bodyFormData.append("amount", this.state.fields.amount);
        bodyFormData.append("billno", this.state.fields.billno);
        bodyFormData.append("date", this.state.fields.date);
        bodyFormData.append("paymentmode", this.state.fields.paymentmode);
        bodyFormData.append("head", this.state.fields.head);
        bodyFormData.append("project", this.state.fields.project);
        bodyFormData.append("remarks", this.state.fields.remarks);
        bodyFormData.append("subhead", this.state.fields.subhead);
        bodyFormData.append("time", this.state.fields.time);
        bodyFormData.append("vendor", this.state.fields.vendor);
        bodyFormData.append("po", this.state.fields.po);
        bodyFormData.append("bill", this.state.bill);

        this.setState({ isLoading: true });
        axios
          .post(apiDaybookCreate, bodyFormData, {
            headers: {
              Authorization: "Bearer " + userData.token,
            },
          })
          .then(
            (response) => {
              //console.log(response);
              this.setState({ isLoading: false });
              toast.info("Record created", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
              this.setState(this.initialState);
              this.handleCloseModal();
            },
            (error) => {
              if (
                error.response.status == 500 ||
                error.response.status == 400
              ) {
                toast.error(error.response.data.errorMessage, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                this.setState({ isLoading: false });
              }
            }
          );
      } else {
        var bodyFormData = new FormData();
        bodyFormData.append("amount", this.state.fields.amount);
        bodyFormData.append("billno", this.state.fields.billno);
        bodyFormData.append("date", this.state.fields.date);
        bodyFormData.append("paymentmode", this.state.fields.paymentmode);
        bodyFormData.append("head", this.state.fields.head);
        bodyFormData.append("project", this.state.fields.project);
        bodyFormData.append("remarks", this.state.fields.remarks);
        bodyFormData.append("subhead", this.state.fields.subhead);
        bodyFormData.append("time", this.state.fields.time);
        bodyFormData.append("vendor", this.state.fields.vendor);
        bodyFormData.append("bill", this.state.bill);
        bodyFormData.append("po", this.state.fields.po);
        bodyFormData.append("id", this.state.editId);

        this.setState({ isLoading: true });
        axios
          .post(apiDaybookEdit, bodyFormData, {
            headers: {
              Authorization: "Bearer " + userData.token,
            },
          })
          .then(
            (response) => {
              //console.log(response);
              this.setState({ isLoading: false });
              toast.info("Record updated", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
              this.setState(this.initialState);
              this.handleCloseModal();
            },
            (error) => {
              if (
                error.response.status == 500 ||
                error.response.status == 400
              ) {
                toast.error(error.response.data.errorMessage, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                this.setState({ isLoading: false });
              }
            }
          );
      }
    } else {
      /* console.log("fail");
      console.log("errors", this.validator.getErrorMessages()); */
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  loadMasterData = (e) => {
    let userData = getUserData();
    //console.log("currentuserdata",userData.permissions["create_daybook[create]"]);
    let projectId = getProjectID();
    this.setState({
      user: userData.user,
      userPermission: userData.permissions,
    });

    this.setState({ isListLoading: true });
    fetch(
      apiListDaybook +
        "?project_id=" +
        projectId +
        "&page=" +
        this.state.current +
        "&pagesize=" +
        this.state.pageSize,
      // "&vendor=" +
      // this.state.fields.vendor +
      // "&from=" +
      // (this.state.fields.from != undefined ? this.state.fields.from : "") +
      // "&to=" +
      // (this.state.fields.to != undefined ? this.state.fields.to : "") +
      // "&paymentmode=" +
      // this.state.fields.paymentmode +
      // "&subhead=" +
      // this.state.fields.subhead,
      {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + userData.token,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          console.log("daybook", data.data.dayBook);
          this.setState({
            dayBook: data.data.dayBook,
            dayBookCount: data.data.dayBook.count,
          });
        } else {
          alert("something went wrong.");
        }
      });

    fetch(apiListExpenseHeadsParents, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          this.setState({
            expenseHeadParentsList: data.data.expensehead,
          });
        } else {
          alert("something went wrong.");
        }
      });

    fetch(apiListVendors + "?pagesize=10000", {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          this.setState({
            vendorList: data.data.vendors.rows,
          });

          //trial code
          const vendorOption = data.data.vendors.rows.map((vendor) => ({
            value: vendor.name,
            text: vendor.label,
            key: vendor.id,
            name: "vendor",
          }));
          // console.log("vendorOption", vendorOption);

          this.setState({ selectOptionForVendor: vendorOption });
        } else {
          alert("something went wrong.");
        }
      });

    fetch(apiListProjects, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          this.setState({
            projectList: data.data.projects.rows,
            isListLoading: false,
          });
        } else {
          alert("something went wrong.");
        }
      });

    fetch(apiListAllExpenseSubHeads, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          //console.log("all expense SubHead", data.data.expenseheads.rows);
          this.setState({
            allExpenseSubHeadList: data.data.expenseheads.rows,
          });

          //trial code
          const subHeadOption = data.data.expenseheads.rows.map((sub) => ({
            value: sub.id,
            label: sub.name,
            name: "subhead",
          }));
          this.setState({ selectOptionForSubhead: subHeadOption });
        } else {
          alert("something went wrong.");
        }
      });

    fetch(apiListPo + "", {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          this.setState((prevState) => ({
            poList: data.data.po.rows,
            poCount: data.data.po.count,
          }));
        } else {
          alert("something went wrong.");
        }
      });
  };

  clearFilter = () => {
    this.myFormRef.reset();
    //this.setState(this.initialState, this.loadMasterData);
    this.setState(
      {
        fields: {
          vendor: "",
          subhead: "",
          to: "",
          from: "",
          paymentmode: "",
        },
      },
      this.loadMasterData
    );
  };

  deleteDb = (id) => {
    confirmAlert({
      title: "Confirm",
      message: "Are you sure to delete.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            let userData = getUserData();
            axios
              .post(
                apiDaybookDelete,
                { id: id },
                {
                  headers: {
                    Authorization: "Bearer " + userData.token,
                  },
                }
              )
              .then(
                (response) => {
                  this.setState({ isLoading: false });
                  toast.info("Record deleted", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                  });
                  this.setState(this.initialState);
                  this.loadMasterData();
                },
                (error) => {
                  if (
                    error.response.status == 500 ||
                    error.response.status == 400
                  ) {
                    toast.error(error.response.data.errorMessage, {
                      position: "top-right",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                    this.setState({ isLoading: false });
                  }
                }
              );
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  handleSearchSelectInput = (value) => {
    console.log("from search", value);
    let array = this.state.selectOptionForVendor;
    var bedrooms = array.filter((vendor) => vendor.label.includes(value));
    console.log("filtered", bedrooms);
    this.setState({ selectOptionForVendor: bedrooms });
  };

  handleSearchSelectPo = (e, value) => {
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        po: value.key,
      },
    }));
  };

  componentDidMount() {
    this.setState({ current: 1 }, () => this.loadMasterData());
    // this.loadMasterData();
    let userData = getUserData();

    //update user data
    fetch(apiGetMe, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userData.token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 200) {
          //console.log("all expense SubHead", data.data.expenseheads.rows);
          localStorage.setItem(
            "additionalData",
            JSON.stringify(data.data.additionalData)
          );
          this.setState({ cashInHand: data.data.additionalData.cashinhand });
        } else {
          alert("something went wrong.");
        }
      });
    //update user data

    this.setState({ cashInHand: userData.additionalData.cashinhand });
  }
  render() {
    this.validator.purgeFields();

    return (
      <React.Fragment>
        <Helmet>
          <title>Daybook| MRC</title>
        </Helmet>
        <section className="adminProject">
          <div className="wrapper d-flex">
            <Sidebar />
            <div className="wrapper_right">
              <ProjectHeader />
              <div className="d-flex">
                <ProjectMenu />
                <div className="wrapper_body">
                  <div className="row">
                    <div className="col m4 s12">
                      <h3 className="heading">Daybook</h3>
                    </div>

                    <div className="col m4 center-align">
                      {/* {this.state.user.userType == 3 ? (
                        <Badge className="green cashinhand" newIcon>
                          Cash in Hand:{" "}
                          <i className="fa fa-rupee-sign">&nbsp;</i>
                          {formatAmount(this.state.cashInHand)}
                        </Badge>
                      ) : null} */}
                    </div>
                    <div className="col m4 right-align">
                      {this.state.user.userType == "2" ||
                      (this.state.userPermission &&
                        this.state.userPermission["create_daybook[create]"]) ? (
                        <a
                          href="javascript:void(0)"
                          className="theme_btn btn "
                          onClick={this.handleOpenModal}
                        >
                          <i className="material-icons">add&nbsp;</i>New Entry
                        </a>
                      ) : null}

                      <Modal
                        actions={[,]}
                        bottomSheet={false}
                        fixedFooter={false}
                        id="Modal-0"
                        open={this.state.isModalOpen}
                        className="cstm-modal"
                        options={{
                          dismissible: false,
                          endingTop: "10%",
                          inDuration: 250,
                          onCloseEnd: null,
                          onCloseStart: null,
                          onOpenEnd: null,
                          onOpenStart: null,
                          opacity: 0.5,
                          outDuration: 250,
                          preventScrolling: true,
                          startingTop: "4%",
                        }}
                      >
                        {/* Close Modal */}
                        <a
                          href="javascript:void(0)"
                          className="modal-close"
                          onClick={this.handleCloseModal}
                        >
                          <span className="material-icons">chevron_right</span>
                        </a>
                        {/* Modal Content */}
                        <h3 className="heading">Daybook</h3>
                        <p>Enter details to create new entry</p>
                        <form action id="createListForm">
                          <div className="row">
                            {/* <div className="col s12">
                              <label className="label">Entry Time</label>
                              <input
                                type="time"
                                placeholder="Time"
                                name="time"
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                                value={this.state.fields.time}
                              />
                              <label className="error">
                                {this.validator.message(
                                  "time",
                                  this.state.fields.time,
                                  "required"
                                )}
                              </label>
                            </div> */}
                            <div className="col s12">
                              <label className="label">Project</label>
                              <select
                                name="project"
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                                value={this.state.fields.project}
                              >
                                <option>Select</option>
                                {this.state.projectList.map(
                                  (project, index) => (
                                    <>
                                      <option value={project.id}>
                                        {project.name}
                                      </option>
                                    </>
                                  )
                                )}
                              </select>
                              <label className="error">
                                {this.validator.message(
                                  "project",
                                  this.state.fields.project,
                                  "required"
                                )}
                              </label>
                            </div>
                            <div className="col s12">
                              <label className="label">Date</label>
                              <input
                                type="date"
                                placeholder="Purchase Date"
                                name="date"
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                                value={this.state.fields.date}
                              />
                              <label className="error">
                                {this.validator.message(
                                  "date",
                                  this.state.fields.date,
                                  "required"
                                )}
                              </label>
                            </div>
                            <div className="col s12">
                              <label className="label">Head</label>
                              <select
                                name="head"
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                                value={this.state.fields.head}
                              >
                                <option selected>Select</option>
                                {this.state.expenseHeadParentsList.map(
                                  (expenseHead, index) => (
                                    <>
                                      <option value={expenseHead.id}>
                                        {expenseHead.name}
                                      </option>
                                    </>
                                  )
                                )}
                              </select>
                              <label className="error">
                                {this.validator.message(
                                  "head",
                                  this.state.fields.head,
                                  "required"
                                )}
                              </label>
                            </div>
                            <div className="col s12">
                              <label className="label">Sub Head</label>
                              <select
                                name="subhead"
                                onChange={this.handleChange}
                                value={this.state.fields.subhead}
                              >
                                <option selected>Select</option>
                                {this.state.expenseSubHeadList.map(
                                  (expenseHead, index) => (
                                    <>
                                      <option value={expenseHead.id}>
                                        {expenseHead.name}
                                      </option>
                                    </>
                                  )
                                )}
                              </select>
                              {/* <label className="error">
                                {this.validator.message(
                                  "subhead",
                                  this.state.fields.name,
                                  "required"
                                )}
                              </label> */}
                            </div>
                            <div className="col s12">
                              <label className="label">Vendor</label>
                              {/* <select
                                name="vendor"
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                                value={this.state.fields.vendor}
                              >
                                <option selected>Select</option>
                                {this.state.vendorList.map((vendor, index) => (
                                  <>
                                    <option value={vendor.id}>
                                      {vendor.name}
                                    </option>
                                  </>
                                ))}
                              </select> */}

                              <Select
                                // styles={customStyles}
                                name="vendor"
                                placeholder="Select Vendor"
                                options={this.state.selectOptionForVendor}
                                onChange={this.handleSearchSelect}
                                value={
                                  this.state.selectOptionForVendorEdit[0]
                                    ? this.state.selectOptionForVendorEdit[0]
                                        .value
                                    : ""
                                }
                                id={"selectvendor"}
                                showSearch={true}
                                // onSearch={(e) =>
                                //   this.handleSearchSelectInput(e)
                                // }
                                dropdownClassName={"force-zindex"}
                              />

                              <label className="error">
                                {this.validator.message(
                                  "vendor",
                                  this.state.fields.vendor,
                                  "required"
                                )}
                              </label>
                            </div>
                            <div className="col m6 s12">
                              <label className="label">Bill No.</label>
                              <input
                                type="text"
                                placeholder="Bill number"
                                name="billno"
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                                value={this.state.fields.billno}
                              />
                              <label className="error">
                                {this.validator.message(
                                  "billno",
                                  this.state.fields.billno,
                                  "required"
                                )}
                              </label>
                            </div>

                            <div className="col s12">
                              <label className="label">Po Number</label>

                              <Select
                                // styles={customStyles}
                                name="po"
                                placeholder="Select Po"
                                options={this.state.poList.map((po) => ({
                                  value: po.ponumber,
                                  text: po.ponumber,
                                  key: po.id,
                                  name: "po",
                                }))}
                                onChange={this.handleSearchSelectPo}
                                id={"selectpo"}
                                showSearch={true}
                                // onSearch={(e) =>
                                //   this.handleSearchSelectInput(e)
                                // }
                                dropdownClassName={"force-zindex"}
                              />
                            </div>

                            <div className="col m6 s12">
                              <label className="label">Amount</label>
                              <input
                                type="text"
                                placeholder="Amount"
                                name="amount"
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                                value={this.state.fields.amount}
                              />
                              <label className="error">
                                {this.validator.message(
                                  "amount",
                                  this.state.fields.amount,
                                  "required"
                                )}
                              </label>
                              <span className="icon">
                                <i />
                              </span>
                            </div>

                            <div className="col s12">
                              <label className="label">Payment Mode</label>
                              <select
                                name="paymentmode"
                                onChange={this.handleChange}
                                value={this.state.fields.paymentmode}
                              >
                                <option selected>Select</option>
                                <option value={1}>Cash</option>
                                <option value={2}>Bank</option>
                              </select>
                              {/* <label className="error">
                                {this.validator.message(
                                  "paymentmode",
                                  this.state.fields.paymentmode,
                                  "required"
                                )}
                              </label> */}
                            </div>
                            <div className="col m6 s12">
                              <label className="label">Bill Upload</label>
                              <div className="button-wrap col  s12">
                                <label className="new-button" for="upload">
                                  Bill Upload
                                  <input
                                    id="upload"
                                    type="file"
                                    name="bill"
                                    onChange={this.fileChange}
                                  />
                                </label>
                                <br />
                                {this.state.bill && (
                                  <>
                                    <span>
                                      {this.state.bill.name}
                                      <i class="tiny material-icons">
                                        attach_file
                                      </i>
                                    </span>
                                  </>
                                )}

                                {/* {this.state.editItem ? null : (
                                  <label className="error">
                                    {this.validator.message(
                                      "bill upload",
                                      this.state.bill,
                                      "required"
                                    )}
                                  </label>
                                )} */}
                              </div>
                            </div>
                            <div className="col  s12">
                              <label className="label">Remarks</label>
                              <input
                                type="text"
                                placeholder="Remarks"
                                name="remarks"
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                                value={this.state.fields.remarks}
                              />
                              <label className="error">
                                {this.validator.message(
                                  "remarks",
                                  this.state.fields.remarks,
                                  "required"
                                )}
                              </label>
                              <span className="icon">
                                <i />
                              </span>
                            </div>
                            <div className="row">
                              <div className="col s12 right-align">
                                <button
                                  type="submit"
                                  className="theme_btn btn"
                                  onClick={this.submitForm}
                                >
                                  {this.state.editItem
                                    ? "Update Record"
                                    : "Add Record"}
                                  {this.state.isLoading ? (
                                    <ButtonLoader></ButtonLoader>
                                  ) : null}
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                        <p>Activity Logs</p>
                        <table>
                          {this.state.logs
                            ? this.state.logs.map((lg, index) => (
                                <tr>
                                  <td>{lg.log}</td>
                                </tr>
                              ))
                            : null}
                        </table>
                      </Modal>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col s12">
                      <p className="grey-text">
                        <b>Filters</b>
                      </p>
                      <form ref={(ele) => (this.myFormRef = ele)}>
                        <div className="row">
                          {/* <div class="col m3 s12">
                            <input type="text" placeholder="Search" name="Search">
                            </div> */}
                          <div className="col m2 s12">
                            {/* <select name="vendor" onChange={this.handleSearch}>
                              <option selected>Select Vendor</option>
                              {this.state.vendorList.map((vendor, index) => (
                                <>
                                  <option value={vendor.id}>
                                    {vendor.name}
                                  </option>
                                </>
                              ))}
                            </select> */}
                            <Select
                              styles={customStyles}
                              placeholder="Select Vendor"
                              options={this.state.selectOptionForVendor}
                              onChange={this.handleSearchSelect}
                              showSearch={true}
                            />
                          </div>

                          <div className="col m2 s12">
                            <select name="subhead" onChange={this.handleSearch}>
                              <option selected>Select SubHead</option>
                              {this.state.allExpenseSubHeadList.map(
                                (subhead, index) => (
                                  <>
                                    <option value={subhead.id}>
                                      {subhead.name}
                                    </option>
                                  </>
                                )
                              )}
                            </select>
                            {/* <Select
                              styles={customStyles}
                              placeholder="Select SubHead"
                              options={this.state.selectOptionForSubhead}
                              onChange={this.handleSearchSelect}
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 14,
                                borderColor: "#f4f4f4",

                                colors: {
                                  ...theme.colors,
                                  primary25: "gray",
                                  primary: "black",
                                },
                              })}
                            /> */}
                          </div>
                          <div className="col m3 s12">
                            <input
                              type="date"
                              className=""
                              placeholder="From Date"
                              name="from"
                              onChange={this.handleSearch}
                            />
                          </div>

                          <div className="col m3 s12">
                            {/* <span>-</span> */}
                            <input
                              type="date"
                              className=""
                              placeholder="To Date"
                              name="to"
                              onChange={this.handleSearch}
                            />
                          </div>
                          <div className="col m2 s12">
                            <select
                              name="paymentmode"
                              onChange={this.handleSearch}
                            >
                              <option selected>Payment</option>
                              <option value={1}>Cash</option>
                              <option value={2}>Bank</option>
                            </select>
                          </div>

                          <div className="col m2 s12">
                            <select name="status" onChange={this.handleSearch}>
                              <option selected>Status</option>
                              <option value={0}>Pending</option>
                              <option value={1}>Verified</option>
                              <option value={2}>Rejected</option>
                            </select>
                          </div>
                        </div>

                        <div className="row">
                          {/* <a href="#!" className="red-text pt-1 d-flex">Clear Filter</a> */}
                          {/* <button type="submit" className="red-text pt-1">Clear Filter</button> */}
                          <div class="col m3 s12">
                            <a
                              href="javascript:void(0)"
                              class="red-text pt-1"
                              onClick={this.clearFilter}
                            >
                              Clear Filter
                            </a>

                            <button
                              type="submit"
                              className="theme_btn btn filter-submit"
                              onClick={this.submitSearch}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>

                    <div className="col s12">
                      <div className="table-responsive">
                        <table>
                          <thead>
                            <tr>
                              <th>Entry Time</th>
                              {this.state.user.userType == "2" && (
                                <th>Employee</th>
                              )}

                              <th>Project</th>
                              <th>Date</th>
                              <th>Sub Head</th>
                              <th>Bill No.</th>
                              <th>Bill</th>
                              <th>Amount</th>
                              <th>Type</th>
                              <th style={{ width: "10%" }}>Vendor</th>
                              <th style={{ width: "10%" }}>Remarks</th>
                              <th>Status</th>
                              <th>Log</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.isListLoading ? (
                              <tr>
                                <td colSpan={5}>
                                  <BulletList></BulletList>
                                </td>
                              </tr>
                            ) : this.state.dayBook &&
                              this.state.dayBook.count > 0 ? (
                              this.state.dayBook.rows.map((db, index) => (
                                <>
                                  <tr key={index}>
                                    <td data-th="Time">
                                      <Moment format="YYYY-MM-DD">
                                        {db.createdAt}
                                      </Moment>
                                      &nbsp;&nbsp;
                                      <Moment format="hh:mm:ss">
                                        {db.createdAt}
                                      </Moment>
                                    </td>
                                    {this.state.user.userType == "2" && (
                                      <td data-th="Name">
                                        {db.employees.firstName}
                                      </td>
                                    )}

                                    <td data-th="Project">
                                      {db.projects.name}
                                    </td>
                                    <td data-th="Date">{db.date}</td>
                                    <td data-th="Subhead">
                                      {db.subheads ? db.subheads.name : ""}
                                    </td>
                                    <td data-th="Billno">{db.billno}</td>
                                    <td data-th="Bill">
                                      {db.bill && (
                                        <a href={db.bill} className="actions">
                                          <i class="material-icons">
                                            file_download
                                          </i>
                                        </a>
                                      )}
                                    </td>

                                    <td data-th="Amount">
                                      <i class="fas fa-rupee-sign"></i>{" "}
                                      {formatAmount(db.amount)}
                                    </td>
                                    <td data-th="Mode">
                                      {db.paymentmode == 1 ? "Cash" : "Bank"}
                                    </td>
                                    <td
                                      style={{ width: "10%" }}
                                      data-th="vendor"
                                    >
                                      {db.vendors ? db.vendors.name : "-"}
                                    </td>
                                    <td data-th="remarks">{db.remarks}</td>

                                    <td>
                                      <Modal
                                        actions={[,]}
                                        bottomSheet={false}
                                        fixedFooter={false}
                                        id="Modal-0"
                                        className="cstm-modal"
                                        open={this.state.isStatusModalOpen}
                                        options={{
                                          dismissible: false,
                                          endingTop: "10%",
                                          inDuration: 250,
                                          onCloseEnd: null,
                                          onCloseStart: null,
                                          onOpenEnd: null,
                                          onOpenStart: null,
                                          opacity: 0.5,
                                          outDuration: 250,
                                          preventScrolling: true,
                                          startingTop: "4%",
                                        }}
                                      >
                                        {/* Close Modal */}
                                        <a
                                          href="javascript:void(0)"
                                          className="modal-close"
                                          onClick={this.handleCloseStatusModal}
                                        >
                                          <span className="material-icons">
                                            chevron_right
                                          </span>
                                        </a>
                                        {/* Modal Content */}
                                        <h3 className="heading">
                                          Update Status
                                        </h3>
                                        <form action id="createListForm">
                                          <div className="row">
                                            <div className="col  s12">
                                              <label className="label">
                                                Amount
                                              </label>
                                              <input
                                                type="text"
                                                placeholder="Amount"
                                                name="amount"
                                                readOnly
                                                value={this.state.fields.amount}
                                              />
                                              <label className="error">
                                                {this.validator.message(
                                                  "amount",
                                                  this.state.fields.amount,
                                                  "required|numeric"
                                                )}
                                              </label>
                                            </div>
                                            <div className="col s12">
                                              <label className="label">
                                                Status
                                              </label>
                                              <select
                                                name="status"
                                                onChange={this.handleChange}
                                                value={this.state.fields.status}
                                              >
                                                <option selected>Select</option>
                                                <option value="1">
                                                  Verified
                                                </option>
                                                <option value="2">
                                                  Reject
                                                </option>
                                              </select>
                                            </div>

                                            <div className="col  s12">
                                              <label className="label">
                                                Remarks
                                              </label>
                                              <input
                                                type="text"
                                                placeholder="Remarks"
                                                name="remarks"
                                                onChange={this.handleChange}
                                                onBlur={this.handleBlur}
                                                value={
                                                  this.state.fields.remarks
                                                }
                                              />
                                              <label className="error">
                                                {this.validator.message(
                                                  "remarks",
                                                  this.state.fields.remarks,
                                                  "required"
                                                )}
                                              </label>
                                            </div>
                                            <div className="row">
                                              <div className="col s12 right-align">
                                                <button
                                                  type="submit"
                                                  className="theme_btn btn"
                                                  onClick={this.submitStatus}
                                                >
                                                  Update
                                                  {this.state.isLoading ? (
                                                    <ButtonLoader></ButtonLoader>
                                                  ) : null}
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </form>
                                      </Modal>
                                      {this.state.user.userType == "2" ||
                                      (this.state.userPermission &&
                                        this.state.userPermission[
                                          "approve_daybook[create]"
                                        ]) ? (
                                        db.status == 1 ? (
                                          <b className="green-text">Verified</b>
                                        ) : db.status == 2 ? (
                                          <a
                                            href="javascript:void(0)"
                                            className="actions"
                                            node="button"
                                            onClick={() =>
                                              this.editStatus(db.id)
                                            }
                                          >
                                            <b className="red-text">Rejected</b>
                                          </a>
                                        ) : (
                                          <a
                                            href="javascript:void(0)"
                                            className="actions"
                                            node="button"
                                            onClick={() =>
                                              this.editStatus(db.id)
                                            }
                                          >
                                            <b className="amber-text">
                                              Pending
                                            </b>
                                          </a>
                                        )
                                      ) : db.status == 1 ? (
                                        <b className="green-text">Approved</b>
                                      ) : db.status == 2 ? (
                                        <b className="red-text">Rejected</b>
                                      ) : (
                                        <b className="amber-text">Pending</b>
                                      )}
                                    </td>

                                    <td>
                                      <Modal
                                        bottomSheet={false}
                                        fixedFooter={false}
                                        id="Modal-0"
                                        open={this.state.isViewModalOpen}
                                        className="cstm-modal"
                                        options={{
                                          dismissible: false,
                                          endingTop: "10%",
                                          inDuration: 250,
                                          onCloseEnd: null,
                                          onCloseStart: null,
                                          onOpenEnd: null,
                                          onOpenStart: null,
                                          opacity: 0.5,
                                          outDuration: 250,
                                          preventScrolling: true,
                                          startingTop: "4%",
                                        }}
                                      >
                                        {/* Close Modal */}
                                        <a
                                          href="javascript:void(0)"
                                          className="modal-close"
                                          onClick={this.handleCloseViewModal}
                                        >
                                          <span className="material-icons">
                                            chevron_right
                                          </span>
                                        </a>
                                        {/* Modal Content */}
                                        <h3 className="heading">
                                          Activity Logs
                                        </h3>
                                        <p>
                                          Details of the actions in this entry
                                        </p>
                                        <table>
                                          {this.state.logs
                                            ? this.state.logs.map(
                                                (lg, index) => (
                                                  <tr>
                                                    <td>{lg.log}</td>
                                                  </tr>
                                                )
                                              )
                                            : null}
                                        </table>
                                      </Modal>
                                      <a
                                        href="javascript:void(0)"
                                        className="actions"
                                        node="button"
                                        onClick={() =>
                                          this.handleOpenViewModal(db.id)
                                        }
                                      >
                                        View
                                      </a>
                                    </td>
                                    <td>
                                      {this.state.user.userType == "2" ||
                                      (this.state.userPermission &&
                                        this.state.userPermission[
                                          "create_daybook[edit]"
                                        ]) ? (
                                        db.status != 1 ? (
                                          <a
                                            href="javascript:void(0)"
                                            className="actions"
                                            node="button"
                                            onClick={() =>
                                              this.editMaster(db.id)
                                            }
                                          >
                                            <i className="material-icons">
                                              edit
                                            </i>
                                          </a>
                                        ) : null
                                      ) : null}
                                      {db.status != 1 ? (
                                        <a
                                          href="javascript:void(0)"
                                          className="actions"
                                          node="button"
                                          onClick={() => this.deleteDb(db.id)}
                                        >
                                          <i className="material-icons">
                                            delete
                                          </i>
                                        </a>
                                      ) : null}
                                    </td>
                                  </tr>
                                </>
                              ))
                            ) : (
                              ""
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {/* pagination */}
                    <div
                      className="col s12"
                      style={{ marginTop: 20, marginBottom: 20 }}
                    >
                      <Pagination
                        pageSize={this.state.pageSize}
                        onChange={this.onChangePagination}
                        current={this.state.current}
                        total={this.state.dayBookCount}
                        showSizeChanger
                        defaultPageSize={this.state.pageSize}
                        defaultCurrent={this.state.current}
                        onShowSizeChange={this.onChangePageSize}
                        locale={localeInfo}
                        selectComponentClass={Select}
                        pageSizeOptions={[10, 30, 50, 100]}
                        showTotal={(total, range) =>
                          `${range[0]} - ${range[1]} of ${total} items`
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </section>
        {/* Create_new_project_modal Modal */}
        <div
          id="create_new_list_modal"
          className="new-list-modal cstm-modal modal"
        >
          {/* Close Modal */}
          <a href="javascript:void(0)" className="modal-close">
            <span className="material-icons">chevron_right</span>
          </a>
          {/* Modal Content */}
          <h3 className="heading">Daybook Form</h3>
          <p>Enter details to create new list</p>
          <form action id="createListForm">
            <div className="row">
              <div className="col s12">
                <label className="label">Entry Time</label>
                <input
                  type="time"
                  placeholder="Time"
                  name="Entry Time"
                  required
                />
              </div>
              <div className="col s12">
                <label className="label">Project</label>
                <select name="Project">
                  <option disabled selected>
                    Select
                  </option>
                  <option value={1}></option>
                  <option value={2}></option>
                </select>
              </div>
              <div className="col s12">
                <label className="label">Date</label>
                <input
                  type="date"
                  placeholder="Purchase Date"
                  name="Purchase Date"
                />
              </div>
              <div className="col s12">
                <label className="label">Head</label>
                <select name="Head">
                  <option disabled selected>
                    Select
                  </option>
                  <option value={1}></option>
                  <option value={2}></option>
                </select>
              </div>
              <div className="col s12">
                <label className="label">Sub Head</label>
                <select name="Sub Head">
                  <option disabled selected>
                    Select
                  </option>
                  <option value={1}></option>
                  <option value={2}></option>
                </select>
              </div>
              <div className="col s12">
                <label className="label">Vendor</label>
                <select name="Vendor">
                  <option disabled selected>
                    Select
                  </option>
                  <option value={1}></option>
                  <option value={2}></option>
                </select>
              </div>
              <div className="col m6 s12">
                <label className="label">Bill No.</label>
                <input
                  type="text"
                  placeholder="Bill number"
                  name="Bill number"
                />
              </div>

              <div className="col m6 s12">
                <label className="label">Amount</label>
                <input type="text" placeholder="Amount" name="Amount" />
                <span className="icon">
                  <i />
                </span>
              </div>

              <div className="col s12">
                <label className="label">Payment Mode</label>
                <select name="Cash / Bank">
                  <option disabled selected>
                    Select
                  </option>
                  <option value={1}>Cash</option>
                  <option value={2}>Bank</option>
                </select>
              </div>

              <div className="col  s12">
                <label className="label">Remarks</label>
                <input type="text" placeholder="Remarks" name="Remarks" />
                <span className="icon">
                  <i />
                </span>
              </div>
              <div className="row">
                <div className="col s12 right-align">
                  <button
                    type="submit"
                    className="theme_btn btn waves-effect waves-light"
                  >
                    Add List
                    <ButtonLoader></ButtonLoader>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </React.Fragment>
    );
  }
}
