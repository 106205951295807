import React, { Component } from "react";
import { getUserData } from "../helpers/storage";
import { apiEditUser } from "../config/endpoints";
import { BulletList } from "react-content-loader";
import ButtonLoader from "../components/ButtonLoader";
import axios from "axios";
import { toast } from "react-toastify";
import ReactFormInputValidation from "react-form-input-validation";
const initialState = {
  /* etc */
  fields: {
    name: "",
    ownerName: "",
    address: "",
    website: "",
    email: "",
    password: "",
    confirmPassword: "",
  },
  logo: false,
  isLoading: false,
  redirect: false,
  errors: {},
  companyList: [],
  alert: false,
  editItem: false,
  isModalOpen: false,
  isListLoading: false,
  deleteId: false,
  editId: false,
  user: false,
};

export default class AdminProfile extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    let userData = getUserData();
    this.state = {
      userData: userData,
      errors: {},
    };
    this.logoChange = this.logoChange.bind(this);

    this.form = new ReactFormInputValidation(this);
    this.form.useRules({
      password: "required",
      name: "required",
      ownerName: "required",
      address: "required",
      website: "required|url",
      email: "required|email",
    });
    this.form.onformsubmit = (fields) => {
      // Do you ajax calls here.
      //console.log(this.state);
      //console.log(getUserData());
      let userData = getUserData();

      var bodyFormData = new FormData();
      bodyFormData.append("name", this.state.fields.name);
      bodyFormData.append("ownerName", this.state.fields.ownerName);
      bodyFormData.append("password", this.state.fields.password);
      bodyFormData.append("email", this.state.fields.email);
      bodyFormData.append("id", this.state.editId);

      this.setState({ isLoading: true });
      axios
        .post(apiEditUser, bodyFormData, {
          headers: {
            Authorization: "Bearer " + userData.token,
          },
        })
        .then(
          (response) => {
            //console.log(response);
            this.setState({ isLoading: false });
            toast.info("Profile updated", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
            // this.setState(this.initialState);
            // this.handleCloseModal();
          },
          (error) => {
            if (
              error.response.status === 500 ||
              error.response.status === 400
            ) {
              toast.error(error.response.data.errorMessage, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              this.setState({ isLoading: false });
            }
          }
        );
    };
  }
  setLocation = (e) => {
    this.setState((prevState) => ({
      fields: {
        // object that we want to update
        ...prevState.fields, // keep all other key-value pairs
        address: e, // update the value of specific key
      },
    }));
    //console.log(this.state);
  };

  componentWillMount() {
    let userData = getUserData();
    this.setState({ user: userData });
    this.setState((prevState) => ({
      fields: {
        // object that we want to update
        ...prevState.fields,
        email: userData.user.email,
        name: userData.user.firstName,
      },
    }));
    // fetch(apiGetCompany + "?id=" + this.state.userData.user.userKey, {
    //   method: "get",
    //   headers: new Headers({
    //     Authorization: "Bearer " + userData.token,
    //   }),
    // })
    //   .then((response) => response.json())
    //   .then((data) => {
    //     console.log(data.data.company);
    //     if (data.code === 200) {
    //       this.setState({
    //         fields: data.data.company,
    //         isListLoading: false,
    //         editId: this.state.userData.user.userKey,
    //       });
    //       this.setState((prevState) => ({
    //         fields: {
    //           // object that we want to update
    //           ...prevState.fields, // keep all other key-value pairs
    //           email: data.data.company.user.email, // update the value of specific key
    //         },
    //       }));
    //       console.log("cur", this.state);
    //     } else {
    //       alert("something went wrong.");
    //     }
    // setTimeout(function () {
    //   });
  }
  logoChange = (e) => {
    // Assuming only image
    if (e.target.files[0]) {
      //console.log("picture: ", e.target.files);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        //console.log(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
      this.setState({ logo: e.target.files[0] });
    }
    // Updating the city
  };
  render() {
    return (
      <>
        {this.state.isListLoading ? (
          <BulletList></BulletList>
        ) : (
          <>
            <div>
              <form onSubmit={this.form.handleSubmit}>
                {/* <label for="img">Upload Profile Pic:</label>
                                        <input type="file" id="img" name="img" accept="image/*" />
                                        <br /> */}

                {/* <div className="col  s12">
                  <label for="img"> Upload Profile Picture</label>
                  <input type="file" id="img" name="img" accept="image/*" />
                </div> */}

                <div className="button-wrap col  s12">
                  <label className="new-button" for="upload">
                    {" "}
                    Upload Image
                    <input
                      id="upload"
                      type="file"
                      name="logo"
                      onChange={this.logoChange}
                    />
                  </label>
                </div>

                <br />

                <label>Name:</label>
                <input
                  type="text"
                  name="name"
                  value={this.state.fields.name}
                  onBlur={this.form.handleBlurEvent}
                  onChange={this.form.handleChangeEvent}
                ></input>

                <label>Email:</label>
                <input
                  type="text"
                  name="email"
                  value={this.state.fields.email}
                  onBlur={this.form.handleBlurEvent}
                  onChange={this.form.handleChangeEvent}
                ></input>

                {/* <label>Phone:</label>
          <input type="text"></input> */}

                <div className="col s12 right-align">
                  <button type="submit" className="theme_btn btn">
                    Submit
                    {this.state.isLoading ? (
                      <ButtonLoader></ButtonLoader>
                    ) : null}
                  </button>
                </div>
              </form>
            </div>
          </>
        )}
      </>
    );
  }
}
